
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class PazAjaxTable extends Vue {

  @Prop({default: () => { return []}})
  public items!: object[];

  @Prop({default: false})
  public loading!: boolean;
  @Prop({default: () => { return []}})
  headers!: object[];

  @Prop({default: ''})
  public search!: string;

  getItemValue(item: object,key : string){
    if(!key.includes('.')){
      if(item.hasOwnProperty(key)){
        return item[key];
      }
      return null;
    }
    const keys = key.split('.');
    for (const k of keys) {
      if(item.hasOwnProperty(k)){
        item = item[k];
      }
      else{
        return item;
      }
    }
    return item;
  }
}
