
import {Component, Prop, VModel} from "vue-property-decorator";
import Vue from "vue";

@Component
export default class PazListFilterSelector extends Vue {

  @VModel()
  public selectedItems!: [];

  @Prop()
  public items!: object[];

  @Prop({default: ''})
  public label!: string;

  @Prop({default: ''})
  public placeholder!: string;
  @Prop({default: 'nameTech'})
  public itemText!: string;
  @Prop({default: 'name'})
  public itemValue!: string;

  get allSelected(): boolean {
    return this.selectedItems.length === this.items.length;
  }

  get someSelected(): boolean {
    return this.selectedItems.length > 0 && !this.allSelected;
  }

  get dropdownIcon(): string {
    if (this.allSelected) {
      return 'mdi-close-box';
    }
    if (this.someSelected) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  removeSelected(item: object) {
    this.selectedItems.splice(this.selectedItems.indexOf(item[this.itemValue]), 1);
  }
  getColorFor(item: object){
    if(item.hasOwnProperty('color')){
      return item.color;
    }
    return 'primary';
  }
  toggle() {
    this.$nextTick(() => {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.items.map(i => {
          return i[this.itemValue];
        });
      }
    });
  }
}
