
import { Component, Vue, Prop } from 'vue-property-decorator';
import QRCode from "qrcode";
import JsBarcode from "jsbarcode";
import { IAttributeCardPoint } from '../../../models/member_card.model';

@Component
export default class extends Vue {

  @Prop() point!: IAttributeCardPoint;
  @Prop() elStyle!: string;
  @Prop() clientView?: boolean;

  private canvasParams = {
    width: this.point.width || 60,
    height: this.point.height || 60
  }

  loadCanvas() {
    if (this.point && this.point.value) {
      const canvasElement = this.$refs.codecanvasElement;
      if (canvasElement && this.point.fieldType === 'qrcode') {
        QRCode.toCanvas(
          canvasElement,
          this.point.value,
          this.canvasParams || {},
          function (error) {
            if (error) console.error(error);
          }
        );
      }
      else if (this.point && this.point.fieldType === 'barcode') {
        JsBarcode(canvasElement, this.point.value, {
          format: "CODE128",
          displayValue: false
        });
      }
    }
  }

  get getElementStyle() {
    return (this.point.system === '_barcode' && this.clientView) ?
      `width:${this.point.width}px;height:${this.point.height}px;transform:${this.point.transform};` :
       this.elStyle 
  }

  mounted() {
    this.loadCanvas();
  }
}
