
import { Component, Vue, Prop } from "vue-property-decorator";
import pdf from "vue-pdf";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";

@Component({
  components: {
    pdf,
    PazTooltipButton,
  },
})
export default class ProductionDocs extends Vue {
  @Prop() public record!: object;

  getFileUrl(url: string) {
    return url && process.env.VUE_APP_BACKEND_BASE_URL + url;
  }

}
