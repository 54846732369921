
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IAttributeCardPoint, ICardMemberModel } from '../../../models/member_card.model';
import CanvasPlotAttribute from "../atoms/CanvasPlotAttributeElement.vue";
import dayjs from "dayjs";

@Component({
  components: {
    CanvasPlotAttribute
  }
})
export default class CardDesignAttributeBlock extends Vue {

  @Prop() point!: IAttributeCardPoint;
  @Prop() viewPlotType!: boolean;
  @Prop() setStyle?: string;
  @Prop() dynamicKey?: string | null;
  @Prop() showPointText?: boolean;
  @Prop() currentMember?: ICardMemberModel;
  @Prop() clientView?: boolean;


  get getFinalValue() {
    return (this.point.isMemberAttr && this.currentMember) 
      ? this.currentMember[this.point.text] : this.point.value;
  }

  getKeyValue(value: string) {
    const parsedArray = JSON.parse(value);
    if (this.dynamicKey) {
      const preparingValue = parsedArray && parsedArray.find(pa => pa && pa.key === this.dynamicKey);
      return preparingValue && preparingValue.value;
    } else {
      return parsedArray && typeof parsedArray === 'object' && parsedArray.length && parsedArray[0].value
    }
  }

  get datetimeFormated() {
    const formatedDate = dayjs(this.point.value).format('YYYY-MM-DD');
    return dayjs(formatedDate).isValid() ? formatedDate : this.point.value;
  }

}//
