import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { dataTableModel } from "../../../models/dataTable.model"
import { IDossier } from '../../../models/dossier.model';

@Component({
  components: {
    PazTooltipButton
  }
})
export default class DossierListMixin extends Vue {
  /**
* Props
*/
  @Prop() dossierAccount!: IDossier[];
  @Prop({ default: false }) public loading!: boolean;

  /**
 * Data
 */
  public expanded: [] = [];
  public search: string = "";
  public selectedGroupItem: IDossier | null = null;


  // Course data-table
  public dossierListPageHeaders: dataTableModel = {
    main: [
      {
        text: "Name",
        align: "left",
        class: "cell-truncate nowrap",
        sortable: true,
        cellClass: "col-2",
        value: "name",
      },
      {
        text: "Description",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "description",
        cellClass: "col-4"
      },
      {
        text: "Status",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "status",
        cellClass: "text-center col-2"
      },
      {
        text: "Autoreset",
        align: "right",
        class: "cell-truncate",
        sortable: false,
        value: "isAutoreset",
        cellClass: "text-center col col-2"
      }
    ],
  };
  public footer: object = {
    "items-per-page-options": [50, 100, 200],
  };
  /**
   * Methods
   */
  row_classes() {
    return "v-row-group__subrow";
  }

  openItem(item:IDossier) {
    const listElem = this.$refs[`list-${item.id}`];
    if (listElem) listElem[0].showItemFiles();
  }


  showDossierDetails(item: IDossier) {
    this.$router.push({ path: `dossier/${item.id}` })
  }

  editDossier(item: IDossier, isTop?: boolean) {
    this.$emit('onDossierEdit', {item, isTop});
  }

  deleteDossier(item: IDossier) {
    this.$emit('onDossierDelete', item);
  }

  getStatusString(status: boolean) {
    return status ? 'active' : 'inactive';
  }

  onSelectListGroup(item: IDossier) {
    this.openItem(item);
    if (item.id === (this.selectedGroupItem && this.selectedGroupItem.id ) ) {
      this.selectedGroupItem = null;
      return
    }
    this.selectedGroupItem = item;
  }

  /**
   * Getters
   */
  get dossierListHeaders() {
    return this.isUserAdmin ? this.dossierListPageHeaders.main : this.dossierListPageHeaders.main.filter(h => h && h.value !== 'isAutoreset');
  }

  get dossierListBody() {
    return this.dossierAccount;
  }

  get getFilterDossiers() :IDossier[] {
    return this.dossierAccount;
  }

}//
