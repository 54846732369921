
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import {
  createContractAsset,
  getAssetDetails,
  getContractAssetTypes,
  getTransactionMutations,
  updateContractAsset,
  setSubjectCountMutation
} from "../../../../services/contracts-service";
import {
  AssetContractType,
  ContractApiInterface,
  ContractAsset,
  ContractRegistration,
  TransactionSubject,
  ITransactionUnit,
  ContractLine
} from "../../../../models/contracts.model";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import * as dayjs from 'dayjs';

@Component({
  components: {
    PazLoader,
    PazToast,
    PazMenuDatePicker,
  },
  methods: {
    formatDateTime
  }
})
export default class AssetDetailsModal extends Vue {
  @VModel({
    default: false,
  })
  public showAssetDialog!: boolean;

  @Prop() selectedAsset!: any;
  @Prop() selectedContract!: ContractApiInterface | null;
  @Prop({ default: [] }) transactionSubjects!: TransactionSubject[];

  public loading: boolean = false;
  public assetFormModel: boolean = false;
  public assetDetailsObject = {
    createdAt: dayjs().format("YYYY-MM-DD HH:mm")
  } as ContractAsset;
  public assetTypes = [] as AssetContractType[];
  public registrationsArray = [] as ContractRegistration[];
  public transactionsCount = [];

  /* SECTION Asset headers */
  public getAssetTransactionsCountTable = [
    {
      text: "S/N",
      align: "left",
      cellClass: "nowrap",
      value: "id",
    },
    {
      text: "Subject",
      align: "left",
      cellClass: "nowrap",
      value: "id",
    },
    {
      text: "Start date",
      align: "left",
      cellClass: "nowrap",
      value: "startDate",
    },
    {
      text: "End date",
      align: "left",
      cellClass: "nowrap",
      value: "endDate",
    },
    {
      text: "Count",
      align: "right",
      cellClass: "nowrap",
      value: "count",
      sortable: false,
    },
  ];

  /* SECTION Transactions count headers */
  public assetTransactionCount = [
    {
      text: "S/N",
      align: "left",
      cellClass: "nowrap",
      value: "id",
    },
    {
      text: "Subject",
      align: "left",
      cellClass: "nowrap",
      value: "subject",
    },
    {
      text: "Start date",
      align: "left",
      cellClass: "nowrap",
      value: "startDate",
    },
    {
      text: "End date",
      align: "left",
      cellClass: "nowrap",
      value: "endDate",
    },
    {
      text: "Start Val",
      align: "left",
      cellClass: "nowrap",
      value: "startValue",
    },
    {
      text: "End Val",
      align: "left",
      cellClass: "nowrap",
      value: "endValue",
    }, {
      text: "Count",
      align: "left",
      cellClass: "nowrap",
      value: "quantity",
    },

  ];

  async getOneAsset() {
    this.loading = true;
    this.assetTypes = await getContractAssetTypes();
    if (this.selectedAsset) {
      this.transactionsCount = await getTransactionMutations({
        expand: "transactionSubject,transaction",
        filter: JSON.stringify({ contractAsset: this.selectedAsset.id }),
      });
      const response = await getAssetDetails(
        { expand: "transactionSubject,contract,contractLine" },
        this.selectedAsset.id
      );
      if (response) {
        this.assetDetailsObject = response;
        this.assetDetailsObject.startDate = response.createdAt;
      }
    }
    this.loading = false;
  }

  setObjectData<T extends keyof ContractAsset>(value: any, key: T) {
    this.assetDetailsObject[key] = value;
  }

  onChangeRegisterArray(index: number, item: ContractRegistration) {
    this.registrationsArray[index] = item;
  }

  onChangeRegistrationBox(index: number, status: boolean) {
    this.registrationsArray[index].status = status
  }

  async saveRegistrations() {
    this.loading = true;
    const registrationStructureArr = this.registrationsArray.map((r: ContractRegistration) => {
      return {
        nameTech: r.nameTech,
        selected: r.status,
        startCount: r.startValue
      }
    })
    const response = await setSubjectCountMutation(this.selectedAsset.id, { subjects: registrationStructureArr });
    if (!response || !response.success) {
      this.$refs.toast.show({
        message: response?.response?.data?.detail || "An error occurred, registrations hasn't been saved",
        icon: "mdi-close-circle",
        color: "red",
      });
      this.loading = false;
      return
    }
    this.loading = false;
    this.showAssetDialog = false;
  }

  getFormatDate(date: Date | null) {
    return date && formatDateTime(date, null, "YYYY-MM-DD");
  }

  get getContractAssetsHeaders() {
    return this.assetTransactionCount || [];
  }

  get getContractAssetsCount() {
    return this.transactionsCount;
  }

  get getRegistrationArray() {

    let regArr = this.transactionSubjects.map((item: ContractRegistration) => {
      this.transactionsCount.forEach((sb: ITransactionUnit) => {
        item.startValue =
          (sb.transaction?.transactionSubject?.id === item.id) ? sb.startValue : "0";
      });
      item.status = false;
      return item;
    });

    const filteredRegistrations =
      regArr.filter(s => s &&
        this.assetDetailsObject?.contract?.contractLine?.map((cl: ContractLine) => cl.transactionSubject?.id).includes(s.id))

    this.registrationsArray = filteredRegistrations;
    return filteredRegistrations

  }

  //NOTE: SAVE ASSET
  async saveAsset() {
    const assetData = {
      contractId: this.selectedContract?.id,
      ...this.assetDetailsObject
    }
    const response = !this.selectedAsset ?
      await createContractAsset(assetData) :
      await updateContractAsset(this.selectedAsset.id,
        assetData
      );
    if (!response || !response.hasOwnProperty("id")) {
      this.$parent.$refs.toast.show({
        message: "An error occurred, asset hasn't been saved",
        icon: "mdi-close-circle",
        color: "red",
      });
      return;
    }
    this.showAssetDialog = false;
    this.$emit('onAssetUpdate');
  }

  mounted() {
    this.getOneAsset();
  }
} //
