

import PazLoader from '@pazion/pazion-core/src/components/utils/PazLoader.vue'
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import ProgressArrow from "../../assets/ProgressArrow.vue";
import { createStockMutation } from "../../services/catalog-service";
import { AxiosError } from "axios";
import { IStockMutationTransactionType } from "../../models/stock";
import { IProduct } from "../../models/product";
import { IWarehouse } from "../../models/warehouse";
import PazProductAsyncPicker from "../picker/PazProductAsyncPicker.vue";

@Component({
  components: {
    ProgressArrow,
    PazLoader,
    PazProductAsyncPicker
  }
})
export default class PazStockMutationDialog extends Vue {
  $refs!: {
    productSelect: HTMLSelectElement
  };

  @VModel({
    default: false
  })
  showStockDialog!: boolean;

  @Prop()
  productId: number | undefined;
  @Prop()
  transactionTypes: IStockMutationTransactionType[] | undefined;
  @Prop()
  products: IProduct[] | undefined;
  @Prop()
  public warehouses: IWarehouse[] | undefined;

  public amount: number = 0;
  public selectedTransactionType: string = 'receipt';
  public loading: boolean = false;
  public selectedProductId: number | null = null;
  public selectedWarehouse: number | null = null;

  onChangeProductSelect(selectedProductId: number) {    
    this.selectedProductId = selectedProductId;
  }

  async addStockMutation() {
    try {
      let payload: object = {
        productId: this.selectedProductId,
        amount: this.amount,
        warehouse: this.selectedWarehouse,
        transactionType: this.selectedTransactionType
      };
      let response = await createStockMutation(payload);
      if (response.success) {
        this.$emit('stock:created');
        this.showStockDialog = !this.showStockDialog;
        this.$parent.$refs.toast.show({ message: `Mutation for Stock of product with SKU : ${response.input.sku} has been created` });
      }
    } catch (error) {
      let response = (error as AxiosError).response;

      if (response && response.status !== 500) {
        this.$parent.$refs.toast.show({ message: response.data.msg, color: 'red', icon: 'mdi-close-circle' });
        return;
      }
      this.$parent.$refs.toast.show({
        message: 'An error occurred, please contact the administrator',
        icon: 'mdi-close-circle',
        color: 'red'
      });
    }
  }

}//
