import { deleteRequest, getRequest, putRequest, postRequest, patchRequest } from '@pazion/pazion-core/src/services/auth-service';
import { stringifyUrl } from 'query-string';

const basePath = 'catalog';

/**
 * Get shipping size
 */
export async function getShippingSize() {
  return (await getRequest('shipping-sizes')).data;
}

/**
 * Get Shipping Methods
 * @param offerId: number
 */
export async function getShippingMethods(offerId: number) {
  return (await getRequest(`shipping-methods/${offerId}`)).data;
}

/**
 * Get Offers with filters
 * @param queryParams: { filter: {id: 1, isDeleted":0}, expand: ''
 */
export async function getOffers(queryParams?: object, offerId?: number) {
  const fullUrl = !offerId ? `${basePath}/offers` : `${basePath}/offers/${offerId}`;
  if (queryParams) {
    const ParamsUrl = stringifyUrl({
      url: fullUrl,
      query: {
        ...queryParams
      }
    });
    return (await getRequest(ParamsUrl)).data;
  }
  return (await getRequest(fullUrl)).data;
}

/**
 * Get offer history
 * @param queryParams: {filter: {offer: 1}}
 */
export async function getOffersHistory(queryParams?: object) {
  const fullUrl = `${basePath}/offer-history`;
  if (queryParams) {
    const ParamsUrl = stringifyUrl({
      url: fullUrl,
      query: {
        filter: JSON.stringify(queryParams)
      }
    });
    return (await getRequest(ParamsUrl)).data;
  }
  return (await getRequest(fullUrl)).data;
}

/**
 * Get offer deefault margins
 */
export async function getMarginsValue() {
  return (await getRequest(`/offer-margins`)).data;
}

/**
 * Upload Offers CSV file
 * @param fileData (BLOB, CSV file)
 */
export async function uploadOffers(fileData: Blob) {
  let formData = new FormData();
  formData.append('file', fileData);

  return (await postRequest(
    `/offer-bulk-import`,
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })).data;
}

/**
 * Get fake fifle data
 */
export async function getFileData() {
  return (await getRequest(`/offer-bulk-import`)).data;
}

/**
 * Create offer
 * @param data
 */
export async function createOffer(data: object) {
  return (await postRequest(`${basePath}/offers`, data)).data;
}

/**
 * Update offer
 * @param data full offer data
 * @param offerId: number
 * @returns
 */
export async function updateOffer(data: object, offerId: number) {
  return (await putRequest(`${basePath}/offers/${offerId}`, data)).data;
}

/**
 * Update offer (partially)
 * @param data full offer data
 * @param offerId: number
 * @returns
 */
export async function upgrateOffer(data: object, offerId: number) {
  return (await patchRequest(`${basePath}/offers/${offerId}`, data)).data;
}

/**
 * Soft delete
 * @param offerId
 */
export async function deleteOffer(offerId: number) {
  return (await deleteRequest(`${basePath}/offers/${offerId}`)).data;
}












