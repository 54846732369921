import _Vue from 'vue';

export interface IProjectSettings {
  config: {
    [key: string]: string | boolean
  },
  featureFlags: {
    [key: string]: boolean
  }
  apiPackage: {
    [key: string]: boolean
  }
}

export function setConfigLocal(Vue: typeof _Vue) {
/**
 * Config provider
 */
  Vue.prototype.$productionTip = false;
  Vue.prototype.$orderListingType = "order";
  Vue.prototype.$measureCalculationUnit = "mm";
  Vue.prototype.$orderAutoReference = false;
  /**
    * featureFlags
    * */
  Vue.prototype.$enforceTwoFactor = false;
  Vue.prototype.$ediApiManagement = false;
  Vue.prototype.$copyOrder = false;
  Vue.prototype.$editOrder = false;
  Vue.prototype.$orderManagement = false;
  Vue.prototype.$orderDetails = false;
  Vue.prototype.$createDelivery = false;
  Vue.prototype.$showDeliveryDialog = false;
  Vue.prototype.$editOrderLineStatus = false;
  Vue.prototype.$stockManagement = false;
  Vue.prototype.$productManagement = false;
  Vue.prototype.$productionManagement = false;
  Vue.prototype.$dossierManagement = false;
  Vue.prototype.$orderDossierManagement = false;
  Vue.prototype.$accountManagement = false;
  Vue.prototype.$orderlineMeasureCalculation = false;
  Vue.prototype.$orderQuickMenu = false;
  Vue.prototype.$offerManagement = false;
  Vue.prototype.$repriceManagement = false;
  Vue.prototype.$returnManagement = false;
  Vue.prototype.$invoiceManagement = false;
  Vue.prototype.$contractManagement = false;
  Vue.prototype.$transactionManagement = false;
  Vue.prototype.$pricelistManagement = false;
  Vue.prototype.$logManagement = false;
  Vue.prototype.$queueManagement = false;
  Vue.prototype.$membersCardManagement = false;

  /**
  * Api external packages
  */
  Vue.prototype.$amazonSettings = false;
  Vue.prototype.$boldotcomSettings = false;
  Vue.prototype.$bumbalSettings = false;
  Vue.prototype.$dynamicsSettings = false;
  Vue.prototype.$exactonlineSettings = false;
  Vue.prototype.$opencartSettings = false;
  Vue.prototype.$postnlSettings = false;
  Vue.prototype.$shopwareSettings = false;
  Vue.prototype.$woocommerceSettings = false;
  Vue.prototype.$devPackage = false;
};
