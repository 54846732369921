
import { Component, Mixins, Watch } from "vue-property-decorator";
import InvoicesGeneratorMixin from "./mixins/invoices-generator.mixin";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { getInvoicesTable } from "../../services/contracts-service";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import { extractDate, formatDate, formatPrice } from "../../helpers/format";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";

@Component({
  methods: {
    formatPrice, extractDate, formatDate
  },
  components: {
    PazLoader,
    PazTooltipButton,
    PazToast
  },
})
export default class InvoiceGenerator extends Mixins(
  InvoicesGeneratorMixin
) {
  public loading: boolean = false;
  public invoicesTable = {};

  async getDefaultInvoicesTable() {
    this.loading = true;
    this.invoicesTable = {};
    const response = await getInvoicesTable();
    if (response) {
      this.invoicesTable = Object.values(response);
    }
    this.loading = false;
  }

  getFieldByKey(item: any, key: string) {
    const curItem = item[0];
    return (curItem && curItem.hasOwnProperty(key)) ? curItem[key] : undefined
  }

  mounted() {
    this.getDefaultInvoicesTable();
  }

  /**
   * LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/contracts/mixins/invoices-generator.mixin.ts
   **/
}
