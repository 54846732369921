import { IDossier } from '../models/dossier.model';
import { getRequest, postRequest, putRequest, deleteRequest } from '@pazion/pazion-core/src/services/auth-service';
import { stringifyUrl } from 'query-string';

/** 
 * Get all dossiers
 * @param userID 
 * @param isAdmin 
 * @returns 
 */
export async function getDossierAccount(params?: { [key: string]: string | boolean}):Promise<void> {

  let queryParams = {
    url: '/base/dossier',
    query: {}
  }
  if (params) queryParams.query = params;
  const url = stringifyUrl(queryParams);
  return (await getRequest(url)).data;
}

/** 
 * Get documents by dossiers
 * @param id 
 * @returns 
 */
export async function fetchDossierDocuments(id: number, query?: { [key: string]: string | boolean }) {
  let queryParams = {
    url: '/base/dossier-files',
    query: query
  } 
  if (!query || Object.keys(query).length === 0 ) queryParams.url += '/' + id 
  const url = stringifyUrl(queryParams);
  return (await getRequest(url)).data;
}

/**
 * Get one dossier by id
 * @param id 
 * @returns 
 */
export async function fetchExtraDossierInfo(id: number) {
  return (await getRequest('/base/dossier/' + id)).data;
}

/**
 * get file url
 * @param fileId 
 * @returns 
 */
export async function getFileUrl(fileId: number) {
  return (await getRequest(`/base/download-dossier-file/${fileId}`)).data
}

/**
 * Create dossier
 * @param data 
 * @returns 
 */
export async function createDossierApi(data: IDossier) {
  return (await postRequest('/base/dossier/0', data)).data
}

/**
 * Delete dossier
 * @param id 
 * @returns 
 */
export async function deleteDossier(id: number) {
  return (await deleteRequest(`/base/dossier/${id}`)).data
}

/**
 * Update dossier properties
 * @param id 
 * @param data 
 * @returns 
 */
export async function updateDossierProperties(id: number, data: any) {
  return (await putRequest(`/base/dossier/${id}`, data)).data;
}


/**
 * Mutate properties in DB by filedName
 * @param dossierId 
 * @param fieldName 
 * @param data 
 * @returns 
 */ 
export async function mutateDossierProperty(dossierId:string, data: any) {
  return (await putRequest('/base/dossier-property/' + dossierId, data)).data;
}

/**
 * Fetch properties in DB by filedName
 * @param dossierId 
 * @param fieldName 
 * @param data 
 * @returns 
 */
export async function fetchDossierProperty(dossierId: number) {
  return (await getRequest('/base/dossier/' + dossierId)).data;
}


/**
 * Upload files to dossier or user
 * @param dossierId 
 * @param file 
 * @param description? 
 * @param version ?
 * @param field?
 * @param userId? 
 * @returns 
 */
export async function uploadDossierFile(
  dossierId: number|undefined, 
  file: File|null, 
  description?: string, 
  version?: string, 
  fileId?: number,
  userId?: number
  ) {
  let formData = new FormData();
  
  dossierId && formData.append('dossier', dossierId.toString()); 
  file && formData.append('file', file, file.name); 
  description && formData.append('description', description); 
  version && formData.append('version', version); 
  userId && formData.append('user', userId.toString());

  const finalUrl = '/base/dossier-files/' + (fileId ? fileId : 0);
  return (await postRequest(finalUrl, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })).data;
}

/**
 * Delete dosser file
 * @param fileId 
 * @returns 
 */
export async function deleteDossierFile(fileId: number) {
  return (await deleteRequest(`/base/dossier-files/${fileId}`)).data;
}


/**
 * @param dossierId 
 * @param data: {userId, action: 'add' || 'remove' } 
 * @returns 
 */
export async function shareDossier(dossierId: number, data: any) {
  return (await putRequest('/base/dossier-users/' + dossierId, data)).data;
}


export async function fetchOrderDocuments(orderId: number) {
  return (await getRequest(`catalog/order-files/${orderId}`)).data;
}


/**NOTE: Get files by User */
export async function fetchFilesByUser(userId: number) {
  return (await getRequest(`/base/dossier-files?user=${userId}`)).data;
}