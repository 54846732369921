
import Vue from 'vue';
import {Component, Prop} from "vue-property-decorator";
import {IApiCategory} from "@pazion/pazion-catalog/src/models/category";
import {IProduct} from "@pazion/pazion-catalog/src/models/product";


@Component({
  filters: {
    str_limit: function (value: any, size: any) {
      if (!value) return '';

      value = value.toString();
      value = value.replace("Divina.One", "");
      value = value.replace("BAO-MED", "");

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '...';
    }
  }
})
export default class QuickMenu extends Vue {
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({default: []}) categoriesProducts!: IApiCategory[];
  @Prop() orderDetailsData: any;
  hidden: boolean = true;
  @Prop({
    default: () => {
    }
  }) handleOnClickQuickMenuProductButton: ((product: IProduct) => {}) | undefined;
}//


