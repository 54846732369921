
import Vue from "vue";
import {authentication} from "../../store";
import {Component} from "vue-property-decorator";
import {getAccount} from "../../services/auth-service";

@Component({})
export default class Profile extends Vue {
  public profileUser = {};
  public loading: boolean = false;
  public account = {};

  get getProfile() {
    return authentication.getProfile;
  }

  async mounted() {
    authentication.loadProfile();
    this.loading = true;
    this.account = authentication.getProfile;
    const response = await getAccount(authentication.getProfile.id);
    if (response) this.profileUser = response;
    this.loading = false;

  }
}
