
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

export interface IQueueJobState {
  nameTech: string;
  label: string;
  retry: boolean;
  cancel: boolean;
  color?: string;
  inProgress?: boolean;
  isRecurringOption: string;
}

export const ALL_STATES: IQueueJobState[] = [
  {
    nameTech: 'START__QUEUED_FOR_RETRY',
    label: 'Queued for retry',
    retry: false,
    cancel: true,
    isRecurringOption: 'Both'
  },
  {nameTech: 'START__NEW_PENDING', label: 'New and pending', retry: false, cancel: true, isRecurringOption: 'Both'},
  {
    nameTech: 'START__RECURRING_REQUEUED',
    label: 'Recurring and requeued',
    retry: false,
    cancel: true,
    isRecurringOption: 'Yes'
  },
  {
    nameTech: 'PROCESS__INIT_PASSED__PRE_RUN_CHECK_IS_IN_PROGRESS',
    label: 'Pre-run check in progress',
    color: 'warning',
    retry: true,
    cancel: true,
    inProgress: true,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS__PRE_RUN_CHECK_PASSED__RUN_IS_IN_PROGRESS',
    label: 'Run in progress',
    color: 'warning',
    retry: true,
    cancel: true,
    inProgress: true,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS__RUN_PASSED__VERIFY_IS_IN_PROGRESS',
    label: 'Verification in progress',
    color: 'warning',
    retry: true,
    cancel: true,
    inProgress: true,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS_ERROR__INIT_FAILED',
    label: 'Init failed',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS_ERROR__PRE_RUN_CHECK_FAILED',
    label: 'Pre-run check failed',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS_ERROR__RUN_FAILED',
    label: 'Run failed',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'PROCESS_ERROR__VERIFY_FAILED',
    label: 'Verification failed',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'ERROR__CONFLICTING_WITH_OTHER_JOB',
    label: 'Conflict with other job',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'ERROR__INTERRUPTED',
    label: 'Interrupted',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
  {
    nameTech: 'ERROR__CANCELED',
    label: 'Canceled',
    color: 'error',
    retry: true,
    cancel: false,
    isRecurringOption: 'No'},
  {
    nameTech: 'RECURRING__PAUSED',
    label: 'Paused',
    retry: true,
    cancel: false,
    isRecurringOption: 'Yes'},
  {
    nameTech: 'DONE__VERIFY_PASSED',
    label: 'Verification passed',
    color: 'success',
    retry: true,
    cancel: false,
    isRecurringOption: 'Both'
  },
];

@Component
export default class QueueJobState extends Vue {

  @Prop({default: ''})
  public state!: string;

  get stateObject(): IQueueJobState | undefined {
    const currentState = this.state;
    return ALL_STATES.find(function (state) {
      return (state.nameTech === currentState);
    });
  }

  get stateColor() {
    return this.stateObject?.color;
  }

  get stateLabel() {
    return this.stateObject?.label;
  }
}
