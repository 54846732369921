
import { Component, Vue, Watch } from "vue-property-decorator";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import bulkPorductsImport, {
  deleteProduct,
  getViewProductsPaginated,
  getProductAttributes,
  getWarehouses,
} from "../../services/catalog-service";
import { formatPrice } from "../../helpers/format";
import PazProductDialog from "../../components/dialog/PazProductDialog.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue"
import PazOrderAttributesDialog from "../../components/dialog/PazOrderAttributeDialog.vue";
import AttributesTab from "../../components/dialog/tabs/AttributesTab.vue";
import { IApiProduct, IproductAttributes } from "../../models/product";
import { ITablePaginationOptions } from "../../models/base";
import { IWarehouse } from "../../models/warehouse";

@Component({
  components: {
    PazRefreshButton,
    PazProductDialog,
    PazTooltipButton,
    PazConfirmation,
    PazToast,
    PazOrderAttributesDialog,
    AttributesTab,
    FileUploader
  },
  methods: {
    formatPrice,
  },
})
export default class ProductListingPage extends Vue {
  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
    confirmDeleteProduct: PazConfirmation;
  };

  public showProductDialog: boolean = false;
  public selectedProduct: number | null = 0;
  public extraInfo = {};
  public dialog = false;
  public loading: boolean = false;
  public search: string = "";
  public items = [];
  public stockExpandItem: any = null;
  public productDefaultWarehouse: number | null = null;
  public productStateStatuses = [
    { value: true, label: "Active" },
    { value: 0, label: "Inactive" },
    { value: null, label: "All" },
  ];
  public warehouses: IWarehouse[] | [] = [];
  public headers: object[] = [
    {
      text: "id",
      align: "left",
      sortable: true,
      class: "nowrap",
      value: "product_catalog_product__id",
    },
    {
      text: "",
      class: "nowrap",
      align: "left",
      sortable: false,
      value: "product_catalog_product_locale_media__path",
      cellClass: "productimage",
    },
    {
      text: "sku",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "product_catalog_product__sku",
    },
    {
      text: "name",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "product_catalog_product__name",
    },
    {
      text: "price",
      class: "nowrap",
      cellClass: "nowrap",
      align: "left",
      sortable: true,
      value: "product_catalog_product__price",
    },
    {
      text: "default warehouse",
      class: "nowrap",
      align: "center",
      sortable: true,
      value: "product_catalog_warehouse__name_tech",
    },
    {
      text: "stock",
      class: "nowrap",
      align: "center",
      sortable: true,
      value: "product_catalog_stock__quantity",
    },
    {
      text: "offers",
      class: "nowrap",
      align: "center",
      sortable: true,
      value: "product_catalog_product__offer_count",
    },
    {
      text: "created at",
      class: "nowrap",
      cellClass: "nowrap",
      align: "left",
      sortable: true,
      value: "product_catalog_product__created_at",
    },
    {
      text: "last updated",
      cellClass: "nowrap",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "product_catalog_product__changed_at",
    },
    {
      text: "actions",
      align: "right",
      class: "",
      sortable: false,
      value: "actions",
    },
  ];
  private searchKeyName = "product_catalog_product__name";
  private searchKeySku = "product_catalog_product__sku";
  private searchKeyEan = "product_catalog_product__ean";
  public _timerId = null;
  public productAttributeNames: IproductAttributes[] = [];
  public showAttributesModal: boolean = false;

  /** NOTE: Paginations **/
  public totalItems = 1;
  public paginationOptions: ITablePaginationOptions = { itemsPerPage: 50 };
  public footerProps = {
    itemsPerPageOptions: [25, 50, 100, 200, -1],
  };
  public defaultQueryPaginationsOpt = {
    pagination: 1,
    page: 1,
    perPage: 50,
  };
  public editAble: boolean = true;
  public productId: number = 0;
  public productIsActiveStatus: boolean | null = null;
  public uploadedFile: File | null = null;

  isEmpty(item: any) {
    return item.extra.length == 0 || item.extra == "[]";
  }

  // NOTE: FROM SEARCH ++
  createSearchString(search: string) {
    const searchValue = search.length > 0 ? { like: `%${this.search}%` } : null;
    const currentFilter = this.createFiltersObject();
    let searchObject = {};
    searchObject = {
      filter: JSON.stringify({
        operator: 'AND',
        product_catalog_product__is_deleted: 0,
        ...currentFilter,
        multi: {
          operator: "OR",
        [this.searchKeyName]: searchValue,
        [this.searchKeySku]: searchValue,
        [this.searchKeyEan]: searchValue
        }
      })
    };
    return search.length > 0 ? searchObject : null;
  }

  onSearch() {
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const searchString = this.createSearchString(this.search);
      await this.fetchData(
        searchString,
        this.defaultQueryPaginationsOpt,
        false,
        false,
        undefined,
        undefined,
        false
      );
    }, 1000);
  }

  onProductUpdated() {
    const search = this.createSearchString(this.search);
    this.fetchData(search, this.defaultQueryPaginationsOpt, false , false, undefined, undefined, false);
  }

  createFiltersObject() {
    let currentFilter: {
      product_catalog_stock__default_warehouse_id?: number | null;
      product_catalog_product__is_active?: null | boolean;
      product_catalog_product__is_deleted: number;
    } = {
      product_catalog_stock__default_warehouse_id: null,
      product_catalog_product__is_active: null,
      product_catalog_product__is_deleted: 0
    };
    this.productDefaultWarehouse !== null && this.productDefaultWarehouse
      ? Object.assign(currentFilter, {
          product_catalog_stock__default_warehouse_id:
            this.productDefaultWarehouse,
        })
      : delete currentFilter.product_catalog_stock__default_warehouse_id;

    this.productIsActiveStatus !== null
      ? Object.assign(currentFilter, {
          product_catalog_product__is_active: this.productIsActiveStatus,
        })
      : delete currentFilter.product_catalog_product__is_active;

    return {operator: "AND", ...currentFilter};
  }

  onChangeGroupFilter(clearRef?: boolean) {
    const currentFilter = this.createFiltersObject();
    const search = this.createSearchString(this.search);
    this.fetchData(
      search,
      this.defaultQueryPaginationsOpt,
      true,
      false,
      undefined,
      { filter: JSON.stringify(currentFilter) },
      clearRef
    );
  }

  async getUtilityData() {
    this.productAttributeNames = await getProductAttributes();
    this.warehouses = await getWarehouses({
      filter: JSON.stringify({ isSelectable: 1 }),
    });
  }

/**
 * Bulk products import
 */
  async onFileSelect(file: File) {
    this.uploadedFile = file;
  }

  async showImportProductDialog() {
    const confirm = await this.$refs.confirmUploadProduct.openConfirmDialog(
      "Upload products file",
      ``
    );
    if (confirm && this.uploadedFile) {
      let rsp = await bulkPorductsImport(this.uploadedFile);
      if (rsp.success) {
        this.$refs.toast.show({ message: "Added file to queue" });
      } else {
        this.$refs.toast.show({ message: "Failed to add file to queue", color: "red" });
      }
    }
  }

  // NOTE: Fetch products
  async fetchData(
    search: object | null,
    paginationParams: { [key: string]: number | string } | null,
    isAllRecords?: boolean,
    clearSearch?: boolean,
    order?: { [key: string]: "ASC" | "DESC" },
    filters?: { [key: string]: number | string | null },
    clearRef?: boolean
  ) {
    if (clearSearch) this.search = "";

    const queryParams = search ? search : {};

    order && Object.assign(queryParams, { orderBy: JSON.stringify(order) });
    if (paginationParams) Object.assign(queryParams, paginationParams);
    else Object.assign(queryParams, this.defaultQueryPaginationsOpt);

    filters && !search && Object.assign(queryParams, filters);
    search && Object.assign(queryParams, search);

    !queryParams.hasOwnProperty('filter') && 
      Object.assign(queryParams, {filter: JSON.stringify({product_catalog_product__is_deleted: 0})});

    if (clearRef) {
      //@ts-ignore
      delete queryParams["product_catalog_product__id"];
      this.$router.replace({ query: undefined }).catch(() => {});
    }
    this.loading = true;
    const response = await getViewProductsPaginated(queryParams);
    if (response) {
      this.items = response && response._data;
      this.totalItems = response._pagination._total;
      Object.assign(this.paginationOptions, {
        page: response._pagination._page,
        perPage: isAllRecords ? -1 : response._pagination._per_page,
      });
    }
    this.loading = false;
  }

  toggleStockExpand(item: any) {
    if (this.stockExpandItem === item) {
      this.stockExpandItem = null;
    } else {
      this.stockExpandItem = item;
    }
  }

  formatStock(stock: number) {
    if (isNaN(stock) || stock <= 0) {
      return "0";
    } else {
      return stock;
    }
  }

  showProductDetails(productId: number | null) {
    this.showProductDialog = true;
    this.selectedProduct = productId;
  }

  showAttributeModal(productId: number) {
    this.selectedProduct = productId;
    this.showAttributesModal = true;
  }

  async deleteItem(item: IApiProduct) {
    const confirm = await this.$refs.confirmDeleteProduct.openConfirmDialog(
      "Delete",
      `Do you want to delete product #${item.product_catalog_product__id}?`
    );
    if (!confirm) return;

    let response = await deleteProduct(item.product_catalog_product__id);

    if (!response || !response.hasOwnProperty("id")) {
      this.$refs.toast.show({
        message: "An error occurred, please contact the administrator",
        icon: "mdi-close-circle",
        color: "red",
      });
      return;
    }
    this.fetchData(null, this.defaultQueryPaginationsOpt, false);
  }

  async showRelatedOffers(productId: number) {
    await this.$router.push({
      name: "offers",
      query: { productId: productId.toString() },
    });
  }

  getImage(item: IApiProduct) {
    const imagePath = item.product_catalog_product_locale_media__path;
    if (imagePath && /^(http|https)/.test(imagePath)) return imagePath;
    else if (imagePath)
      return `${process.env.VUE_APP_BACKEND_BASE_URL}${item.product_catalog_product_locale_media__path}`;
    else return this.getDefaultImage();
  }

  get getHeaders() {
    return this.headers.filter((h) =>
      this.$offerManagement ? h : h.text !== "offers"
    );
  }

  get productItems() {
    return this.items && this.items;
  }

  get productDefaultWarehouses() {
    return (this.warehouses && this.warehouses) || [];
  }

  getDefaultImage() {
    return window.location.origin + "/assets/logo.png";
  }

  //NOTE: Set pagination
  @Watch("paginationOptions")
  onUpdatePaginations(val, oldVal) {
    if (
      val.itemsPerPage !== oldVal.itemsPerPage ||
      val.page !== oldVal.page ||
      val.sortBy.length > 0
    ) {
      let orderBy: { [key: string]: "ASC" | "DESC" } | undefined = undefined;
      let pagiantionParams;
      if (val.itemsPerPage > 0)
        pagiantionParams = {
          pagination: 1,
          page: val.page,
          perPage: val.itemsPerPage,
        };
      else {
        pagiantionParams = this.defaultQueryPaginationsOpt;
        pagiantionParams.pageCount = 100;
      }

      const fromOffer = this.$route.query && this.$route.query.fromOffer;

      const currentFilter = this.createFiltersObject();

      fromOffer &&
        Object.assign(currentFilter, {
          product_catalog_product__id: fromOffer,
        });

      const fullFilter = { filter: JSON.stringify(currentFilter) };

      val.sortBy.length > 0 &&
        (orderBy = { [val.sortBy[0]]: val.sortDesc[0] ? "DESC" : "ASC" });

      this.fetchData(
        this.createSearchString(this.search),
        pagiantionParams,
        val.itemsPerPage === -1,
        false,
        orderBy,
        fullFilter
      );
    }
  }

  mounted() {
    this.getUtilityData();
  }
}
