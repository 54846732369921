
import { Component, Mixins, Prop } from "vue-property-decorator";
import Moveable from "vue-moveable";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import {
  getCardAttributeValues,
  getCardMembers,
  getCards,
  updateCard,
} from "../../../services/cards-service";
import {
  ICardAttrValuesModel,
  ICardMemberModel,
  ICardModel,
  ICardRequiredFields,
} from "../../../models/member_card.model";
import CardTemplaterMixin from "../mixins/card-templater";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import CanvasPlotAttribute from "../atoms/CanvasPlotAttributeElement.vue";
import CardDesignAttributeBlock from "../atoms/CardDesignAttributeBlock.vue";
import designAttributesViewer from "../atoms/designAttributesViewer.vue";
import CardOperationsMixin from "../mixins/card-operations";

@Component({
  components: {
    Moveable,
    PazTooltipButton,
    PazLoader,
    CanvasPlotAttribute,
    CardDesignAttributeBlock,
    designAttributesViewer
  },
})
export default class CardDesignConstructor extends Mixins(
  CardTemplaterMixin,
  CardOperationsMixin
) {
  @Prop() cardId!: number;

  $refs!: {
    qrcanvasElement: Element;
  };

  public isMobilePlot: boolean = true;
  public viewPlotType: boolean = false;
  public loading: boolean = false;

  // TODO: TYPES!
  public designAttributes = [] as ICardAttrValuesModel[];
  public systemAttributes = [] as ICardAttrValuesModel[];
  public currentCard = {} as ICardModel;
  public selectedPlotElement: {} | null = null;
  public selectedItem = 0;
  public editablePlot = true;
  public fieldsRequiredList: ICardRequiredFields | null = null;
  public memberRequiredFields: ICardRequiredFields | null = null;
  public firstMember: ICardMemberModel | null = null;
  public selectedVisibleElement: MouseEvent | null = null;


  // NOTE: INIT MOVABLE
  moveable(index: number) {
    let selected = index === this.selectedItem;
    return this.editablePlot ? this.getmovableConfig(selected) : null;
  }

  triggerView(type: boolean) {
    this.viewPlotType = type;
  }

  triggerPlot(status: boolean) {
    this.isMobilePlot = status;
  }

  /** NOTE: Save template */
  async saveCardTemplate() {
    this.loading = true;
    const tamplateAttributes = this.designAttributes.filter(at => {
      at.value = null;
      return at && at.use;
    });
    try {
      const res = await updateCard(this.cardId, { layout: JSON.stringify(tamplateAttributes) });
      if (!res || !res.hasOwnProperty('id')) {
        this.handleSaveError(res, 'Template', 'saved');
        this.loading = false;
        return
      }
      this.systemAttributes = [];
      this.designAttributes = [];
      await this.fetchData();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.handleSaveError(error, 'Template', 'saved');
    }
  }

  async getCardInfo() {
    this.loading = true;
    const res = await getCards({ expand: 'cardMember' }, this.cardId);
    this.fieldsRequiredList = await getCards({ configFields: 1 });
    this.memberRequiredFields = await getCardMembers({ configFields: 1 });
    if (res) {
      this.currentCard = res;
      if (res.cardMember && res.cardMember.length > 0)
        this.firstMember = res.cardMember[0];
    }
    this.loading = false;
  }

  async getCardDesigAttrs() {
    this.designAttributes = [];
    this.loading = true;

    const cardAttrfilterData = { expand: 'cardAttribute', filter: JSON.stringify({ card: this.cardId }) };
    const cardRes = await getCardAttributeValues(cardAttrfilterData);

    if (cardRes && cardRes.length > 0) {
      const filteredUserAttr = cardRes.filter(utr => utr && utr.cardAttribute.isActive);
      this.designAttributes = this.preparingCardAttributes(filteredUserAttr, false);
      this.designAttributes = this.designAttributes.filter((el, index, self) => index === self.findIndex((t) => {
        return t.cardAttributeId === el.cardAttributeId
      }));
    }

    this.showCardStyle();
    //NOTE: Set Previous layout
    await this.renderCurrentLayout();
    this.loading = false;
  }

  showCardStyle() {
    const strtyles = this.getCardStyle &&
      Object.entries(this.getCardStyle).map(([key, value]) => `${key}: ${value}`).join('; ');
    this.selectedPlotElement = {
      text: 'Card',
      style: strtyles,
      fieldType: 'card'
    }
  }

  get getCardAttr() {
    const plotAttrs = [...this.designAttributes];
    return plotAttrs && plotAttrs.filter(plotAttr => {
      return plotAttr && (!plotAttr.system && !plotAttr.isGlobal)
    })
  }

  get getGlobalAttr() {
    const plotAttrs = [...this.designAttributes];
    return plotAttrs && plotAttrs.filter(plotAttr => {
      return plotAttr && (!plotAttr.system && plotAttr.isGlobal);
    })
  }

  get getCardTypeAttrs() {
    return this.systemAttributes && this.systemAttributes.filter(at => at && !at.isMemberAttr);
  }

  get getMemberTypeAttrs() {
    return this.systemAttributes && this.systemAttributes.filter(at => at && at.isMemberAttr);
  }


  get getCardStyle() {
    // TODO: create style creator;
    let cardBackground: { (key: string): string | null };
    if (this.currentCard.background) {
      cardBackground = { 'background-color': this.currentCard.background }
    }
    if (this.currentCard.backgroundImage)
      cardBackground = { ...cardBackground, 'background-image': `url(${this.currentCard.backgroundImage})` }
    return cardBackground
  }

  async fetchData() {
    await this.getCardInfo();
    await this.getCardDesigAttrs();
  }

  mounted() {
    this.fetchData();
  }
}
/**
 * MIXIN:
 * LINK: frontendPackages/pazion-catalog/src/views/cards/mixins/card-templater.ts/
 **/
