
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Chart, registerables} from 'chart.js'

@Component
export default class LineChart extends Vue {
  public $refs!: {
    draw: HTMLCanvasElement
  }
  @Prop({
    default: () => {
    }
  })
  public data!: object;

  @Prop({
    default: 400
  })
  public height!: number;
  @Prop({
    default: 960
  })
  public width!: number;
  private chart: Chart | null = null;

  created() {
    Chart.register(...registerables);
  }

  mounted() {
    this.drawSample(this.$refs.draw, this.data);
  }

  private drawSample(canvas: HTMLCanvasElement, options: any) {
    if (this.chart != null) {
      this.chart.destroy();
    }
    this.chart = new Chart(canvas, options);
  }

  @Watch('data')
  updateData(newData: any, oldData: any) {
    this.drawSample(this.$refs.draw, newData);
  }
}
