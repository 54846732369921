

import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { deleteUser, generateNewApiToken, getUsers } from "../../services/auth-service";
import PazAjaxTable from "../../components/layout/PazAjaxTable.vue";
import PazTextSearch from "../../components/controls/PazTextSearch.vue";
import PazConfirmation from "../../components/utils/PazConfirmation.vue";
import PazRefreshButton from "../../components/controls/PazRefreshButton.vue";
import PazTooltipButton from "../../components/controls/PazTooltipButton.vue";
import PazClipboardButton from "../../components/controls/PazClipboardButton.vue";
import PazEditUserModal from "./modals/pazEditUserModal.vue"

@Component({
  components: {
    PazClipboardButton,
    PazTooltipButton,
    PazConfirmation,
    PazTextSearch,
    PazAjaxTable,
    PazRefreshButton,
    PazEditUserModal
  },
})
export default class UserListing extends Vue {

  @Prop() public includingUsers?: [] | undefined;
  @Prop({ default: false }) public tablePreLoading?: boolean;

  public token: string = '';
  public loading: boolean = true;
  public search: string = "";
  public items: object[] = [];
  public headers: object[] = [
    {
      text: "id",
      align: "right",
      sortable: true,
      value: "id",
    },
    {
      text: "name",
      align: "right",
      sortable: true,
      value: "name",
    },
    {
      text: "role",
      align: "right",
      sortable: true,
      value: "role",
    },
    {
      text: "username(email)",
      align: "left",
      sortable: true,
      value: "username",
    },
    {
      text: "Action",
      align: "right",
      sortable: true,
      value: "extra",
    },
  ];
  showEditUserDialog: boolean = false;
  selectedUserId: number | null = null;


  $refs!: {
    confirm: PazConfirmation;
    response: PazConfirmation;
    confirmWarn: PazConfirmation;
  };

  private $ediApiManagement: boolean | undefined;

  hasApiToken(user: any) {
    if (user['apiSecret'] == null || user['apiSecret'] == '') {
      return false;
    }
    return true;
  }

  async confirmNewApiToken(user: any) {
    this.token = '';
    let extraMessage = '';
    let alreadyHasToken = this.hasApiToken(user);
    if (alreadyHasToken) {
      extraMessage = `Warning: The currently set api key will become <strong>invalid</strong>.<br/><br/>`;
    }
    const confirm = await this.$refs.confirmWarn.openConfirmDialog(
      "Warning",
      extraMessage +
      `Do you want to regenerate a token for '${user.name}'?`
    );
    if (confirm) {
      let rsp = await generateNewApiToken(user.id);
      console.log(rsp)
      this.token = rsp.token;
      await this.$refs.response.openConfirmDialog(
        "Confirmation",
        ''
      );
    }
  }

  async fetchData() {
    this.loading = true;
    this.items = await getUsers();
    this.loading = false;
  }

  async confirmDeletion(user: any) {
    if (this.includingUsers) {
      this.$emit('onUserDeleteFromDossier', user);
      return
    }
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Delete",
      "Are you sure you want to delete `" + user.name + "`?"
    );
    if (confirm) {
      const response = await deleteUser(user.id);
      if (response) await this.fetchData();
    }
  }

  triggerUserEditDialog(id: number | null, isClose: boolean) {
    this.selectedUserId = id;
    this.showEditUserDialog = isClose ? false : true;
  }

  @Watch('includingUsers')
  onUsersChange(value) {
    if (this.includingUsers) this.items = value;
  }

  get isUsingEdiApi(): boolean {
    if (this.$ediApiManagement == undefined) {
      return false;
    }
    return this.$ediApiManagement;

  }

  public mounted() {
    if (!this.includingUsers) this.fetchData();
    else this.items = this.includingUsers;
  }
}
