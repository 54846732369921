
import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";
import { uploadOffers, getFileData } from "../../services/offers-service";

@Component({
  components: {
    PazLoader,
    FileUploader,
  },
})
export default class PazOfferDialog extends Vue {
  @VModel({
    default: false,
  })
  public showOfferImportDialog!: boolean;

  public loading: boolean = false;
  public acceptedDocFormats: string =
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
  public uploadedFile: File | null = null;
  public fileData: String | null  = null;

  async onFileSelect(file: File) {
    this.uploadedFile = file;
  }

  async downloadSampleCSV() {
    this.loading = true;
    const response = await getFileData();
    const data = response && response.content;
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    this.fileData = url;
    this.loading = false;
  }

  async importFile() {
    this.loading = true;
    try {
      const response = await uploadOffers(this.uploadedFile);
      this.$emit("onFileLoad", response && response.success);
      
    } catch (error) {
      this.$emit("onFileLoad", false);
    }
    this.loading = false;
  }

  mounted() {
    this.downloadSampleCSV();
  }
} //
