
import { Component, Prop, VModel, Mixins } from "vue-property-decorator";
import {
  getFulfilment,
  getFulfilmentOrderLines,
  getFulfilmentTransports,
  getOrderLines,
  postOrderDelivery,
  getOrderDetails,
} from "../../services/catalog-service";
import { formatPrice } from "../../helpers/format";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import OrderCustomerTab from "./tabs/OrderCustomerTab.vue";
import FulfilmentTab from "./tabs/FulfilmentTab.vue";
import AttributesTab from "./tabs/AttributesTab.vue";
import { formatDate, formatDateYMD } from "../../helpers/format";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazClipboardButton from "@pazion/pazion-core/src/components/controls/PazClipboardButton.vue";
import OrderItemsTab from "./tabs/OrderItemsTab.vue";
import PazOrderStatus from "../order/PazOrderStatus.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazDateField from "@pazion/pazion-core/src/components/utils/PazDateField.vue";
import OrderDialogMixin from "./mixins/ordersDialogMixin";
import CustomerPicker from "../picker/CustomerPicker.vue";
import { DataTableHeader } from "vuetify";
import { IFulfilment } from "../../models/fulfilment";
import { IOrder, IOrderLine } from "../../models/order";
import PazAdressPicker from "../picker/PazAdressPicker.vue";

@Component({
  components: {
    PazLoader,
    OrderItemsTab,
    OrderCustomerTab,
    FulfilmentTab,
    AttributesTab,
    PazOrderStatus,
    CustomerPicker,
    PazTooltipButton,
    PazConfirmation,
    PazDateField,
    PazClipboardButton,
    PazAdressPicker,
  },
  methods: {
    formatDate,
    formatDateYMD,
    formatPrice,
  },
})
export default class PazOrderDialog extends Mixins(OrderDialogMixin) {
  $refs!: {
    confirm: PazConfirmation;
  };

  @VModel({
    default: false,
  })
  public showDialog!: boolean;

  @Prop({ default: true })
  public editAble!: boolean;

  public orderDate: string = "";

  @Prop({ default: null })
  public orderId: number | null | undefined;
  @Prop({ default: null })
  public order: IOrder | null | undefined;

  public orderData = null;

  public headers: DataTableHeader[] = [
    { text: "EAN", align: "start", sortable: false, value: "id" },
    { text: "Description", align: "center", value: "description" },
    { text: "Stock count", align: "center", value: "stockCount" },
    { text: "Shipped", align: "center", value: "shipped" },
    { text: "Ordered", align: "center", value: "max" },
    { text: "Quantity", align: "center", value: "quantity" },
    { text: "Price", align: "center", value: "price" },
  ];
  public fulfilment: IFulfilment | undefined;
  public selected: [] = [];
  public orderLines: IOrderLine[] = [];

  public loading: boolean = false;
  public mainPreLoading: boolean = false;
  public modalDeliveryDatePicker: boolean = false;

  public deliverAt: string = "";


  decrement(item: IOrderLine) {
    if ('newQuantity' in item) {
      item.newQuantity -= 1;
      this.quantityWarnMessage(item);
    }
  }

  increment(item: IOrderLine) {
    if ('newQuantity' in item) {
      item.newQuantity += 1;
      this.quantityWarnMessage(item);
    }
  }

  updateQuantity(value: number, item: IOrderLine) {
    item.newQuantity = value;
    this.quantityWarnMessage(item);
  }

  quantityWarnMessage(item: IOrderLine) {
    if (parseInt(item.newQuantity) > item.max || parseInt(item.newQuantity) < 1) {
      this.$parent.$refs.toast.show({
        message: "quantity can't be more than ordered value or negative",
        color: 'red'
      });
      item.newQuantity = item.max
    }
  }

  async createDelivery() {
    const defaultErrorMessage = 'An error occurred, please contact the administrator';
    this.loading = true;
    let orderLine: { quantity: number; id: any }[] = [];

    if (this.selected.length) {
      this.selected.forEach((item) => {
        orderLine.push({
          id: item.id,
          quantity: item.newQuantity,
        });
      });
    }

    let deliveryPayload = {
      id: this.orderId,
      orderLine,
      transporter: this.selectedTransporter,
      deliverAt: this.deliverAt,
    };
    try {
      let response = await postOrderDelivery(deliveryPayload);
      if (response && response.success) {
        this.$parent.$refs.toast.show({
          message: response?.message,
          color: 'green'
        });
      } else {
        this.$parent.$refs.toast.show({
          message: response?.response?.data?.message,
          color: 'red'
        });
      }
      this.closeDialog();
      this.$emit("order:update");
    } catch (error) {
      this.$parent.$refs.toast.show({
        message: defaultErrorMessage,
        color: "red",
        icon: "mdi-close-circle",
      });
    }
    this.loading = false;
  }

  closeDialog() {
    this.showDialog = false;
  }

  get getShippingAddress() {
    return this.orderData && this.orderData.order.shippingaddress;
  }

  async fetchData() {
    if (this.orderId) {
      this.mainPreLoading = true;
      this.orderData = await getOrderDetails(this.orderId);
      let orderLine = await getOrderLines({ order: this.orderId });
      this.fulfilment = (await getFulfilment({ order: this.orderId }))[0];
      let fulfilmentsLine: any[] = this.fulfilment
        ? await getFulfilmentOrderLines({ fulfilment: this.fulfilment.id })
        : [];
      this.transports = await getFulfilmentTransports();
      this.orderLines = this.preparingOrderLine(orderLine, fulfilmentsLine);
      this.fulfilment = (await getFulfilment({ order: this.orderId }))[0];
      this.mainPreLoading = false;
    }
  }

  async mounted() {
    await this.fetchData();
  }
}
