
import { Prop, Component, Vue } from "vue-property-decorator";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";

@Component({
  components: {
    FileUploader,
    PazTooltipButton
  },
})
export default class CardDetailsFileUploader extends Vue {
  @Prop({ default: "" }) inputClass?: string;
  @Prop() acceptTypes!: string;
  @Prop({ default: "" }) inputPlaceholder?: string;
  @Prop({ default: false }) toBase64?: boolean;
  @Prop({ default: false }) hasPreview?: boolean;
  @Prop({ default: 240 }) imageWidth?: number;
  @Prop({ default: "" }) imgHref?: string;
  @Prop({ default: false}) deleteBtn?: boolean;
}
