import { ITablePaginationOptions, QueryStringParams } from "src/models/base";
import { TransactionApiInterface, TransactionsInterface } from "src/models/contracts.model";
import { Mixins, Component, Watch } from "vue-property-decorator";
import { getTransactions } from "../../../services/contracts-service";
import TransactionsFiltersMixin from "./transactions-filters.mixin";
import { formatPrice } from "../../../helpers/format";

@Component({
  methods: {
    formatPrice
  }
})
export default class TransactionsListingMixin extends Mixins(
  TransactionsFiltersMixin
) {

  public loading: boolean = false;
  public search: string = "";
  public transactionsArray = {} as TransactionsInterface;
  public footerProps = {
    itemsPerPageOptions: [50, 100, 200, -1],
  };

  private _timerId: ReturnType<typeof setTimeout> | null = null;
  public totalItems = 1;
  public paginationOptions: ITablePaginationOptions = { itemsPerPage: 200 };

  public defaultQueryPaginationOpt = {
    pagination: 1,
    perPage: 200,
    page: 1,
    expand: 'contract,contractAsset,account,transactionSubject',
  };

  public dataTablesHeaders = [
    {
      text: "Company",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "company"
    },
    {
      text: "Contract",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "contract"
    },
    {
      text: "Transaction",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "transactionSubject.nameTech"
    },
    {
      text: "Start date",
      align: "left",
      class: "cell-truncate",
      sortable: true,
      value: "startDate"
    },
    {
      text: "End date",
      align: "left",
      class: "cell-truncate",
      sortable: true,
      value: "endDate"
    },
    {
      text: "Status",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "status"
    },
    {
      text: "Quantity",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "quantity"
    },
    {
      text: "Total",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "total"
    },

  ];

  createSearchString(search: string) {
    return search.length > 0 ? { like: `%${this.search}%` } : null;
  }


  getTransactionCompany(item: TransactionApiInterface) {
    if (item && item.hasOwnProperty('account'))
        return item?.account?.business
    //@ts-ignore
    else (item.transactionSubject?.contractAsset?.length > 0 ) 
      && item.transactionSubject?.contractAsset[0].contract?.name
  }

  //NOTE: Search
  onSearch() {
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const searchString = this.createSearchString(this.search);
      // TODO: Add search fields.
      const queryParams = searchString
        ? {
          filter: JSON.stringify({
            operator: "AND",
            isDeleted: 0,
            multi: {
            operator: "OR",
            id: searchString,
            status: searchString,
            quantity: searchString
          }})
        } : {}

      const currentOrder = this.createOrderParams();
      await this.getDefaultTransactions(queryParams, false, currentOrder);
    }, 800);
  }

  //NOTE: Create params from orderBy
  createOrderParams(): { [key: string]: "DESC" | "ASC" } | undefined {
    let orderBy: { [key: string]: "DESC" | "ASC" } | undefined = undefined;
    // @ts-ignore
    this.paginationOptions?.sortBy.length > 0 &&
      (orderBy = {
        [this.paginationOptions?.sortBy[0]]: this.paginationOptions.sortDesc[0]
          ? "DESC"
          : "ASC",
      });
    return orderBy || undefined;
  }

  onChangeGroupFilter() {
    const filters = this.createFiltersObject();
    const currentOrder = this.createOrderParams();

    this.getDefaultTransactions(
      { filter: JSON.stringify(filters), ...this.defaultQueryPaginationOpt },
      false,
      currentOrder
    )
  }

  //NOTE: MAIN request
  async getDefaultTransactions(
    params?: QueryStringParams,
    isAllRecords?: boolean,
    order?: { [key: string]: "ASC" | "DESC" }
  ) {
    let reqParams = {} as QueryStringParams;
    params && (reqParams = params);
    if (reqParams && !reqParams.hasOwnProperty("filter")) {
      reqParams = Object.assign(reqParams, {
        filter: JSON.stringify({ isDeleted: 0 }),
      });
    }

    if (!reqParams.hasOwnProperty('paginataion'))
      Object.assign(reqParams, this.defaultQueryPaginationOpt)

    order && Object.assign(reqParams, { orderBy: JSON.stringify(order) });
    this.loading = true;
    let response;
    try {
      response = await getTransactions({ ...reqParams });
    } catch (error) {
      this.loading = false;
    }

    if (response) {
      this.transactionsArray = response;
    }
    this.loading = false
  }//

  goToContract(item: TransactionApiInterface) {
    this.$router.push({ name: 'contracts', query: { fromTransactions: item.id.toString() } });
  }


  get getTableDataHeaders() {
    return this.dataTablesHeaders;
  }

  get getTransactionsArray() {
    return this.transactionsArray && this.transactionsArray._data;
  }

   companyFilterOptions(): { label: string, value: number }[] {
    const sortedAccounts = this.getTransactionsArray &&
      this.getTransactionsArray.map((t) => {
        const accountObj = (t.transactionSubject?.contractAsset) && [0].contract?.account;
        return accountObj && ('id' in accountObj) && { label: accountObj.business, value: accountObj.id }
      })
    return sortedAccounts
  }

   statusesFilterOptions() {
    return (
      this.getTransactionsArray &&
      this.getTransactionsArray.map(
        (t) => t.status && {
          label: t.status,
          value: t.status
        }
      )
    );
  }


  fetchData() {
    this.getDefaultTransactions(this.defaultQueryPaginationOpt, false, undefined);
  }


  // NOTE: FROM Pagination
  @Watch("paginationOptions")
  onUpdatePagination(val, oldVal) {
    if (
      val.itemsPerPage !== oldVal.itemsPerPage ||
      val.page !== oldVal.page ||
      val.sortBy.length > 0
    ) {
      let pagiantionParams;
      let orderBy;
      if (val.itemsPerPage > 0)
        pagiantionParams = {
          pagination: 1,
          perPage: val.itemsPerPage,
          page: val.page
        };
      else {
        pagiantionParams = this.defaultQueryPaginationOpt;
        pagiantionParams.perPage = 200;
      }
      //filters = this.createFiltersObject();
      //filters && Object.assign(pagiantionParams, {filter: JSON.stringify(filters)});
      this.getDefaultTransactions(pagiantionParams, val.itemsPerPage === -1, orderBy);
    }
  }

}//
