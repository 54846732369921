
import { Component, Mixins, VModel, Prop } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import PazOrderProductionRecords from "../order/_common/PazOrderProductionRecords.vue";
import { IOrder } from "../../models/order";
import OrderDialogMixin from "./mixins/ordersDialogMixin";
import { formatPrice } from "../../helpers/format";
import { DataTableHeader } from "vuetify";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";

@Component({
  components: {
    PazLoader,
    PazOrderProductionRecords,
    PazTooltipButton
  },
  methods: {
    formatPrice,
  },
})
export default class PazProductionDialog extends Mixins(OrderDialogMixin) {
  @VModel({
    default: false,
  })
  public showDialog!: boolean;

  @Prop({ default: null })
  readonly orderId: number | null | undefined;

  @Prop({ default: null })
  public order: IOrder | null | undefined;

  public headers: DataTableHeader[] = [
    { text: "Description", align: "center", value: "description" },
    { text: "Length", align: "center", value: "length" },
    { text: "Width", align: "center", value: "width" },
    { text: "Quantity", align: "center", value: "quantity" },
    { text: "Price", align: "center", value: "price" },
    { text: "production type", align: "center", value: "extra" },
    { text: "line notes", align: "center", value: "lineNotes" },
  ];

  public cardFooterStyle: string = `position: sticky;
     bottom: 0;
     background: #d6d6d6;
     z-index: 10;`;

  public valid: boolean = false;
  public loading: boolean = false;
  public modalStartDatePicker: boolean = false;
  public deliverAt: string = "";
  public selectedTransporter = "default";

  goToOrderDetails() {
    this.showDialog = false;
    this.$emit('openOrderDetails', this.order)
  }


  async mounted() {
    await this.fetchData();
  }
}
