
import { IOrderLine } from "../../../../models/order";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatPrice } from "../../../../helpers/format";
@Component({
  methods: {
    formatPrice,
  }
})
export default class TableEditableFiled extends Vue {
  @Prop() editAble!: boolean;
  @Prop() item!: IOrderLine;
  @Prop() itemEditable!: boolean;
  @Prop() fieldKey!: keyof IOrderLine;

  get getItemKey() {
    return this.item[this.fieldKey];
  }

}
