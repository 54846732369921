
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
//
//
/**
 * example usage:
 *
 * template: <PazConfirmation ref="confirm"></PazConfirmation>
 *
 * code:
 * const confirm = await this.$refs.confirm.openConfirmDialog('Delete', 'Are you sure you want to delete `' + user.name + '?');
 * if (confirm) {
 *  deleteUser();
 * }
 */
@Component
export default class PazConfirmation extends Vue {

  public dialog: boolean = false;
  public title: string = 'default title';
  public message: string = 'default message';

  @Prop({default: 'primary'})
  color!: string;
  @Prop({default: 290})
  width!: number;
  @Prop({default: 200})
  zIndex!: number;

  @Prop({default: 'Yes'})
  textYes!: string;
  @Prop({default: 'No'})
  textNo!: string;

  public resolve: Function = () => {
  };
  public reject: Function = () => {
  };

  public created() {
    this.dialog = false;
  }

  agree() {
    this.resolve(true)
    this.dialog = false
  }

  cancel() {
    this.resolve(false)
    this.dialog = false
  }

  public async openConfirmDialog(title: string, message: string): Promise<boolean> {
    this.dialog = true;
    this.title = title;
    this.message = message;
    return new Promise<boolean>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  get getTitle() {
    return this.title;
  }

  get getMessage() {
    return this.message.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }

  get getIsActive() {
    return this.dialog;
  }

  get getTextYes() {
    return this.textYes;

  }

  get getTextNo() {
    return this.textNo;
  }
}
