import { Component, Mixins, Prop } from "vue-property-decorator";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import DossierDocsMixin from "../../../views/dossier/mixins/dossier-docs.mixin";
import AccountMixin from "../../../mixins/account.mixin";
import { IDossier, IDossierFile } from "../../../models/dossier.model";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import {
  getDossierAccount,
  updateDossierProperties,
  createDossierApi,
  uploadDossierFile,
  deleteDossierFile,
  shareDossier
} from "../../../services/dossier-service";
import * as dayjs from 'dayjs'


@Component({
  components: {
    PazTooltipButton,
    PazLoader
  },
  methods: {
      formatDateTime
  }
})
export default class DossierEditorMixin extends Mixins(
  DossierDocsMixin,
  AccountMixin
) {
  $refs!: {
    dossierForm: any
    toast: any,
    confirmDeleteDocDossier: any
  };

  @Prop() public selectedDossierProp?: IDossier | undefined;
  @Prop() public dossiers: IDossier[] | undefined;
  @Prop({ default: false }) public IsTop: boolean;
  @Prop({ default: null }) public parentId?: number;

  public dossierList = [];
  public mainPreLoading: boolean = false;
  public tablePreLoading: boolean = false;
  public formValid: boolean = false;
  public newFile: File | null = null;
  public newFileDescription: string = '';
  public newFileVersion: string = '';
  public currentDossierId: number;
  private defaultDossierInstance = {
    name: null,
    description: "",
    status: false,
    validations: {
      name: [(value) => !!value || "Required."]
    },
    dossier: null,
    parentId: null,
    isAutoreset: false,
    autoresetAt: dayjs().add(6, 'M')
  }
  

  public dossierInstance = this.defaultDossierInstance;

  async dossierRequestHandler(response: IDossier | boolean, liveModal?: boolean) {
    if (response) {
      this.showCourseDetails = liveModal;
      this.$emit('onUpdateDossier');
      this.mainPreLoading = false;
      this.tablePreLoading = false;
    }
  }


  async getFlatDossiersList() {
    const dossierList = await getDossierAccount({ flat: true });
    if (dossierList) this.dossierList = dossierList;
  };

  async createDossier() {
    this.$refs.dossierForm.validate();
    if (!this.formValid) return;
    const postData = this.dossierInstance;
    delete postData.validations;
    //invert property for creting nested dossier
    if (postData.hasOwnProperty('parentId')) postData.dossier = postData.parentId
    const response: IDossier = await createDossierApi(postData)
    this.dossierRequestHandler(response)
  }

  updateExpirationDate(date: Date) {
    this.dossierInstance.autoresetAt = date;
    this.updateDossierProp('autoresetAt', date);
  }

  async updateDossierProp(key: string, value: string | number | Date) {
    if (!this.selectedDossierProp) return
    const putData = { [key]: value };
    const response = await updateDossierProperties(this.selectedDossierProp?.id, putData);
    this.dossierRequestHandler(response, true);
  }

  async updateDossierParent(parentId: number) {
    if (!this.selectedDossierProp) return
    const putData = { dossier: parentId }
    const response = await updateDossierProperties(this.selectedDossierProp?.id, putData);
    this.dossierRequestHandler(response, true);
  }


  async deleteFile(fileId: number, docName: string) {
    const confirm = await this.$refs.confirmDeleteDocDossier.openConfirmDialog(
      "Delete",
      `Do you want to delete ${docName}?`
    );
    if (!confirm) return;
    const response = await deleteDossierFile(fileId);
    if (response) await this.getDossierDocument(this.selectedDossierProp?.id);
  }


  getUsersOperationsMessage(action: string, positive: boolean, serverMess?: string | undefined) {
    const baseMess = 'Users';
    return baseMess + 
        (positive ? ' has been' : ' hans\'t been') + (action === 'add' ? ' added ' : ' removed ') 
      + '\d' + (serverMess ? serverMess : '');
  }

  async shareDossierUsers(usersData: { dossierId: number, users: any, action: string }) {
    if (!usersData.users || !usersData.users.length) {
      return
    }
    try {
      this.tablePreLoading = true;
      usersData.users && usersData.users.forEach(async (user: any) => {
        const res = await shareDossier(
          usersData.dossierId, { userId: user.id, action: usersData.action }
        );
        if (res && res.success) {
          this.showOperationToast({
            message: this.getUsersOperationsMessage(usersData.action, true),
            color: 'success',
            icon: 'mdi-check',
          });
          this.$refs.usersBlock.getDossierExtraInfo();
          this.dossierRequestHandler(true, true);
          this.tablePreLoading = false;
        } else {
          this.tablePreLoading = false;
          const serverMess = res.response?.data?.detail;
          this.showOperationToast({
            message: this.getUsersOperationsMessage(usersData.action, false, serverMess),
            color: 'orange',
            icon: 'mdi-exclamation-thick',
          });
          return
        }
      });
    } catch (error: unknown) {
      this.showOperationToast({
        message: this.getUsersOperationsMessage(usersData.action, false),
        color: 'orange',
        icon: 'mdi-exclamation-thick',
      });
      this.tablePreLoading = false;
    }
  }//

  /** Upload files */
  async onFileSelect(file: File, id: number, description: string) {
    this.currentDossierId = id;
    this.newFile = file;
  }

  showOperationToast(params: { message: string, color: string, icon: string }) {
    this.$parent.$refs.toast.show(params);
  }

  async uploadFile(doc: IDossierFile) {

    let response;

    if (!doc) {
      response = await uploadDossierFile(
        this.currentDossierId || this.selectedDossierProp?.id,
        this.newFile,
        this.newFileDescription,
        this.newFileVersion,
        0
      );
    }
    else {
      response = await uploadDossierFile(
        this.selectedDossierProp?.id,
        this.newFile,
        doc.description,
        doc.fileVersion,
        doc.id
      )
    }
    if (response) {
      this.showOperationToast({
        message: 'File has been uploaded',
        color: 'green',
        icon: 'done',
      });
      await this.getDossierDocument(this.selectedDossierProp?.id);
      this.dossierRequestHandler(response, true);
    } else {
      this.showOperationToast({
        message: 'File hasn\'t been uploaded!',
        color: 'error',
        icon: 'mdi-close-circle',
      });
    }

  }

  get dossiersListSorted() {
    return this.selectedDossierProp ?
      this.dossierList && this.dossierList?.filter((item: IDossier) => item.id !== this.selectedDossierProp?.id) :
      this.dossierList;
  }

  getFormatDate(date: Date | null) {
    return date && formatDateTime(date, null, "YYYY-MM-DD");
  }

  mounted() {
    if (this.selectedDossierProp) {
      Object.assign(this.dossierInstance, this.selectedDossierProp);
      this.getDossierDocument(this.selectedDossierProp?.id);
    }
    this.getFlatDossiersList();  
    this.dossierInstance.parentId = this.parentId;
  }

  beforeDestroy() {
    this.dossierInstance.parentId = null;
    this.$emit('onCloseDialog');
  }
}//