
import { IUserLisApiModel } from "../../../../models/user-models";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { fetchFilesByUser } from "../../../../services/dossier-service";
import DossierDocumentViewer from "../../parts/DossierDocumentViewer.vue";

@Component({
  components: { DossierDocumentViewer },
})
export default class DossierUserFilesPanel extends Vue {
  @Prop({ default: null }) nestedUser!: IUserLisApiModel | null;
  @Prop({ default: null }) selectedUser!: IUserLisApiModel | null;
  public docLoading: boolean = false;
  public userPersonalFiles: null | [] = null;

  async getFilesByUser() {
    this.docLoading = true;
    const response = await fetchFilesByUser(this.nestedUser.id);
    if (response && response.hasOwnProperty("files")) {
      this.userPersonalFiles = response.files;
    } 
    this.docLoading = false;
  }

  mounted() {
    if (
      !this.userPersonalFiles &&
      this.nestedUser &&
      this.nestedUser.hasOwnProperty("id")
    ) {
      this.getFilesByUser();
    }   
  }

} //
