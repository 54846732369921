
import {Component, Prop, VModel, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class TestComponent extends Vue {
  @Prop({default: false})
  public category: string;

  @Prop({default: false})
  public namespace: string;

  @VModel({
    default: () => {
    }
  })
  public item: any;


  mounted() {
    console.log('current mounted the following: ')
    console.log(this.namespace)
    console.log()
    console.log('this is the supposed item:')
    console.log(this.item)
  }

  // private getLocal

  //name: string, path: string
  private generatePath() {
    return this.category + ' ' + this.namespace;
  }

  private checkIfHasChildren() {
    return Object.keys(this.item.children).length > 1
  }

}
