
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { Component, Prop, VModel, Mixins, Watch } from "vue-property-decorator";
import ProgressArrow from "../../assets/ProgressArrow.vue";
import { IOrderLine } from "../../models/order";
import PazProductPicker from "../picker/PazProductPicker.vue";
import {
  getProductById,
  updateOrderLine,
} from "../../services/catalog-service";
import { formatPrice } from "../../helpers/format";
import { IApiProduct, IproductTypesModel } from "../../models/product";
import { PatchPricing } from "../dialog/mixins/patchPricingMixin"
@Component({
  components: {
    ProgressArrow,
    PazLoader,
    PazProductPicker,
  },
  methods: {
    formatPrice,
  },
})
export default class PazOrderLineEditor extends Mixins(
  PatchPricing
) {
  @VModel({
    default: false,
  })
  public showDialog: boolean;

  @Prop({
    default: () => {
      return { product: { id: 0 } };
    },
  })
  public line!: IOrderLine;

  @Prop() productTypes!: IproductTypesModel[] | [];

  public loading: boolean = false;
  public valid: boolean = true;
  public lineFormObj = {} as IOrderLine;

  @Watch("showDialog")
  opened(isOpen: boolean) {
    if (!isOpen) {
      return;
    }
  }

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  @Watch("line.product.id")
  async productChanged(newProduct?: number, oldProduct: number) {
    if (newProduct !== oldProduct && newProduct != null) {
      const pdata = await getProductById(newProduct);
      this.updateProduct(pdata);
    }
  }

  updateProduct(p: IApiProduct) {
    this.loading = true;
    this.line.product.id = p.product_catalog_product__id;
    this.line.description = p.product_catalog_product__name;
    this.line.price = p.product_catalog_product__price;
    this.lineFormObj.isDimensionedLine =
      this.isdimensionedType(p.product_catalog_product__product_type, this.productTypes, true);
    if (!this.lineFormObj.isDimensionedLine) {
      this.line.width = 1;
      this.line.length = 1;
      if (this.$measureCalculationUnit == "mm") {
        this.line.width = 1000;
        this.line.length = 1000;
      } else {
        this.line.width = 1;
        this.line.length = 1;
      }
    }
    if (this.line.description === null) {
      this.line.description = p.product_catalog_product__sku;
    }
    this.loading = false;
    this.updateTotal(null);
  }


  // NOTE: Update line params
  updateTotal(key: string | null) {
    if (!this.$orderlineMeasureCalculation || !this.lineFormObj.isDimensionedLine) {
      this.lineFormObj.total = Math.round((parseFloat(this.lineFormObj.price) * this.lineFormObj.quantity + Number.EPSILON) * 100) / 100;
    } else {
      let totalSquare = this.lineFormObj.width * this.lineFormObj.length;
      if (this.$measureCalculationUnit == "mm") totalSquare = (totalSquare / 1000000);

      if (key === 'price') {
        this.lineFormObj.price = Math.round((parseFloat(this.lineFormObj.price) + Number.EPSILON) * 100) / 100;
      } else {
        this.lineFormObj.price = Math.round((parseFloat(this.lineFormObj.product.price) * totalSquare + Number.EPSILON) * 100) / 100;
      }
      this.lineFormObj.total = Math.round(((this.lineFormObj.quantity * this.lineFormObj.price) + Number.EPSILON) * 100) / 100;
    }
  }//


  // NOTE: Save line
  async saveOrderLine() {
    this.loading = true;
    await updateOrderLine(this.line.id, this.lineFormObj);
    this.showDialog = false;
    this.$emit("update-data");
  }

  mounted() {
    this.lineFormObj = { ...this.line };
  }

}//
