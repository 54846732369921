
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

@Component({})
export default class BoolInput extends Vue {
  @Prop({})
  public properties!: { name: string, class: string, id: string, label: string };

  @VModel({})
  public selected!: string;

  //@ts-ignore
  public localItems: string[] = [];
  public defaultOption: string = 'Select a value';

  mounted() {
    if (!!this.selected) {
      if (this.selected === '1' || this.selected === '0') {
        this.selected = Boolean(this.selected).toString();
      }

      this.localItems = ['true', 'false']
    } else {
      this.selected = this.defaultOption;
      this.localItems = [this.defaultOption, 'true', 'false']
    }

    console.log('result of type bool: ')
    console.log(this.localItems)
    console.log('this is the selected item/ v-model:')
    console.log(this.selected)
  }
}
