
import {Vue, Component, Prop} from "vue-property-decorator";

@Component({})
export default class SelectPackage extends Vue {
  @Prop({
    default: () => {
      []
    }
  })
  public packages!: object[];

  @Prop({
    default: () => {
      'none'
    }
  })
  public currentPackage!: string | object;

  public selectedPackage: string | object = this.currentPackage;

  public onChange(change: string) {
    this.$emit("change", change);
  }
}
