import {IDate} from "../models/base";

export function formatPrice(price?: number|string) {
    if(typeof price == 'string'){
        price = Number(price);
    }
    if (price == null || isNaN(price)) {
        return '-';
    }
    if (isNaN(price)) {
        return Number(0).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'});
    } else {
        return Number(price).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'});
    }
}

export function timestampToYMD(timestamp?: number) {
    if (timestamp == null) {
        return '';
    }
    let dt = new Date();
    dt.setTime(timestamp);
    return dt.toISOString().split('T')[0];
}

export function formatDateYMD(date?: IDate) {
    if (date == null) {
        return '';
    }
    return new Date(date.date.replace(/ /g, "T")).toISOString().split('T')[0];
}

export function extractDate(datetime?: string) {
    if (!datetime)
        return '';
    return datetime.split(' ')[0];
}
export function formatDate(date?: IDate) {
    if (date == null) {
        return '';
    }
    return new Date(date.date.replace(/ /g, "T")).toLocaleString('nl-NL');
}
