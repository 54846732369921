
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import ContractDetailsLines from "./parts/ContractDetailsLines.vue"
import AssetDetailsModal from "./parts/AssetDetailsModal.vue";
import {
  ContractApiInterface,
  ContractAsset,
  ContractDetailsInterface,
  ContractLine,
  newContractLine,
  TransactionSubject,
  ReminderTypes,
ContractLineFrequency
} from "../../../models/contracts.model";
import { Component, Vue, Prop, VModel, Mixins } from "vue-property-decorator";
import {
  createContractLine,
  deleteContractAsset,
  getClLineFrequencies,
  getContracts,
  getRemindersTypes,
  updateContractLine
} from "../../../services/contracts-service";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import {
  getAccounts
} from "../../../services/catalog-service";
import * as dayjs from 'dayjs'
import ContractLinesMixin from "../mixins/contracts-line.mixin";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";

@Component({
  components: {
    PazLoader,
    PazToast,
    PazMenuDatePicker,
    PazTooltipButton,
    ContractDetailsLines,
    AssetDetailsModal,
  },
  methods: {
    formatDateTime
  }
})
export default class ContractDetailsModal extends Mixins(
  ContractLinesMixin
) {
  @VModel({
    default: false,
  })
  public showContractDialog!: boolean;

  @Prop() selectedContract!: ContractApiInterface | null;
  @Prop({ default: [] }) transactionSubjects!: TransactionSubject[];

  public loading: boolean = false;
  public contractFormModel: boolean = false;
  public accountItems = [];
  public addressesItem = [];
  public showAssetsDetailsModal: boolean = false;
  public selectedAsset: null | {} = null;
  public remindersTypes = {} as ReminderTypes;
  public frequencyTypes = [] as ContractLineFrequency[];
  public contractObject = {
    name: '',
    account: 0,
    startDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    endDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    location: '',
    reference: '',
    contractNumber: '',
    status: '',
    reminderInterval: null
  } as ContractDetailsInterface;
  public externalRequestField = {
    expand:
      "contractLine,contractAsset,assetType,transactionSubject,contractLineFrequency,product,tax",
  };

  /* SECTION assets headers */
  public getContractAssetsHeaders = [
    {
      text: "Type",
      align: "left",
      cellClass: "nowrap",
      value: "assetType.nameTech",
    },
    {
      text: "Name",
      align: "left",
      cellClass: "nowrap",
      value: "name",
    },
    {
      text: "Serial number",
      align: "left",
      cellClass: "nowrap",
      value: "serialNumber",
    },
    {
      text: "Location",
      align: "left",
      cellClass: "nowrap",
      value: "location",
    },
    {
      text: "actions",
      align: "right",
      cellClass: "nowrap",
      value: "actions",
    },
  ];


  async getSupportInstances() {
    this.loading = true;
    this.accountItems = await getAccounts();
    this.remindersTypes = await getRemindersTypes();
    this.frequencyTypes = await getClLineFrequencies();
    this.loading = false;
  }

  async getOneContract() {
    this.loading = true;
    const response = await getContracts(
      this.externalRequestField,
      this.selectedContract?.id
    );
    response && (this.contractObject = response);
    this.loading = false;
  }

  setObjectData<T extends keyof ContractDetailsInterface>(value: any, key: T) {
    this.contractObject[key] = value;
  }

  /**
   * NOTE: CRUD CONTRACT LINE
   */
 
  async updateContractLine(item: ContractLine) {
    let response = null;
    let lineData = {} as ContractLine;
    if ("id" in item) {
      lineData = this.preparingLineFields(item, true);
      response = await updateContractLine(item.id, lineData);
    } else {
      lineData = this.preparingLineFields(item, false);
      response = await createContractLine(lineData);
    }
    if (response && "id" in response) {
      //this.showContractDialog = false;
      this.getOneContract();
     // this.$emit("onUpdateContract");
    }
  }

  /**
   * NOTE: CRUD CONTRACT ASSET
   */

  onAssetsEdit(asset?: ContractAsset) {
    this.showAssetsDetailsModal = true;
    asset && (this.selectedAsset = asset);  
  }

  async deleteAssetLine(item: any) {
    const confirm =
      await this.$parent.$refs.confirmDeleteContract.openConfirmDialog(
        "Delete",
        `Do you want to delete contract asset: ${item.name}?`
      );
    confirm && deleteContractAsset(item.id);
  }


  getFormatDate(date: Date|null) {
    return date && formatDateTime(date, null, "YYYY-MM-DD");
  }


  get hasNewLine() {
    return this.contractObject?.contractLine?.find(line => line.hasOwnProperty('indexId'));
  }

  get getContractAssetsItems() {
    return this.contractObject?.contractAsset ?? [];
  }

  get getContractLine() {
    return this.contractObject?.contractLine?.filter( line => {return !line.isDeleted}) ?? [];
  }

  get getRemindersTypes() {
   return this.remindersTypes && Object.keys(this.remindersTypes).map(el => {
      return {
        key: el, value: this.remindersTypes[el]   
      }
   });
  }



  mounted() {
    this.getSupportInstances();
    if (this.selectedContract) this.getOneContract();
    else this.contractObject.account = 0;
  }

  // ANCHOR NESTED_COMPONENT
  /**
   * LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/contracts/contract-details/parts/ContractDetailsLines.vue
   */

} //
