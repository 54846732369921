import { IcardFieldTypes } from '../models/member_card.model';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class CardAttributesOperationsMixin extends Vue {

  /**
   * @param attrTypes object {fieldName: {hasOptions: false}}
   * @returns modifyAttrArray [{name: fieldName, hasOptions: false}]
   */
  createFieldTypesArray(attrTypes: IcardFieldTypes) { 
    let modifyAttrArray = Object.entries(attrTypes).map(([name, value]) => 
      ({ name, hasOptions: value.hasOptions }));
    return modifyAttrArray;
  }

}