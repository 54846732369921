import { deleteRequest, getRequest, putRequest, postRequest } from '@pazion/pazion-core/src/services/auth-service';
import { stringifyUrl } from 'query-string';
import { IApiProduct } from "../models/product";
import { IWarehouse } from "../models/warehouse";
import { IApiCategory } from "../models/category";

const basePath = 'catalog';

/**
Products API methods
 */
export async function getViewProducts(filter?: any, limit?: number, pagination?: boolean) {
    let prodFilter = filter || {};
    if (filter || pagination) {
        const url = stringifyUrl({
            url: 'catalog/view-products',
            query: {
                filter: JSON.stringify(prodFilter),
                limit: limit,
            }
        });
        return (await getRequest(url)).data;

    }
    return (await getRequest('catalog/view-products')).data;
}

export async function getProductType(filter?: any) {
    let prodFilter = filter || {};
    if (filter) {
        const url = stringifyUrl({
            url: 'catalog/product-types',
            query: {
                filter: JSON.stringify(prodFilter)
            }
        });
        return (await getRequest(url)).data;

    }
    return (await getRequest('catalog/product-types')).data;
}

export async function getViewProductsPaginated(queryParams?: any) {
    if (queryParams) {
        const url = stringifyUrl({
            url: 'catalog/view-products',
            query: {
                ...queryParams
            }
        });
        return (await getRequest(url)).data;

    }
    return (await getRequest('catalog/view-products')).data;
}

export async function getProductById(productId: number): Promise<IApiProduct> {
    return (await getRequest('catalog/view-products/' + productId)).data;
}

export async function getAllProducts() {
    return (await getRequest('catalog/products')).data;
}
export async function getProduct(id: number, queryParams?: any) {
    const url = stringifyUrl({
        url: `catalog/products/${id}`,
        query: {
            ...queryParams
        }
    });
    return (await getRequest(url)).data;
}
export async function createProduct(data: object) {
    return (await postRequest('catalog/products', data)).data;
}
export async function updateProduct(data: object, id: number) {
    return (await putRequest(`catalog/products/${id}`, data)).data;
}
export async function deleteProduct(id: number) {
    return (await deleteRequest(`catalog/products/${id}`)).data;
}

/**
 * Import products
 * @returns status
 */
export default async function bulkPorductsImport(file: File) {
    let formData = new FormData();
    formData.append('file', file);
    return (await postRequest(`${basePath}/products/upload`, formData)).data;
}


//NOTE: Products attributes
export async function getProductAttributes() {
    return (await getRequest(`${basePath}/product-attributes?expand=productAttributeType`)).data;
}

export async function getProductAttributeValues(filter?: object) {
    const url = stringifyUrl({
        url: `${basePath}/product-attribute-values?expand=productAttribute,productAttributeType`,
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url)).data;
}
export async function setProductAttrValues(data: any) {
    return (await postRequest(`${basePath}/product-attribute-values`, data)).data;
}

export async function updateProductAttrValue(id: number, data: object) {
    return (await putRequest(`${basePath}/product-attribute-values/${id}`, data)).data;
}
/**/

export async function getStockTransactionTypes() {
    return (await getRequest('catalog/stock-transaction-types')).data;
}

export async function getStock(filter?: any, limit?: number) {
    filter = filter || {};
    if (filter) {
        const url = stringifyUrl({
            url: 'stock-mutations',
            query: {
                filter: JSON.stringify(filter),
                limit: limit
            }
        });
        return (await getRequest(url)).data;

    }
    return (await getRequest('stock-mutations')).data;
}

export async function createStockMutation(data: object) {
    return (await postRequest('mutate-stock', data)).data;
}

export async function getCategoriesProduct(): Promise<Array<IApiCategory>> {
    const url = stringifyUrl({
        url: 'catalog/categories-products',
    });
    return (await getRequest(url)).data;
}


export async function getOrderLines(filter?: object) {
    filter = filter || {};
    const url = stringifyUrl({
        url: 'catalog/order-lines?expandStock=true&excludeSystemProducts=true',
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url ? url : 'catalog/order-lines?expandStock=true&excludeSystemProducts=true')).data;
}

export async function getFulfilment(filter?: object) {
    filter = filter || {};
    const url = stringifyUrl({
        url: 'catalog/fulfilments',
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url ? url : 'catalog/fulfilments')).data;
}
export async function getFulfilmentOrderLines(filter?: object) {
    filter = filter || {};
    const url = stringifyUrl({
        url: 'catalog/fulfilment-lines',
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url ? url : 'catalog/fulfilment-lines')).data;
}
export async function getFulfilmentTransports() {
    return (await getRequest('fulfilment-transports')).data;
}
export async function createOrder(reference: string) {
    return (await postRequest('catalog/orders/0', { reference })).data;
}

export async function getOrdersOnly(filter?: object, hasProduction?: boolean, hasOrderFiles?: boolean) {

    filter = filter || {};
    const url = stringifyUrl({
        url: 'catalog/orders',
        query: {
            filter: JSON.stringify(filter),
            productionManagement: hasProduction,
            orderDossierManagement: hasOrderFiles || false
        }
    });

    return (await getRequest(url)).data;
}

export async function getOrderlinesIncludingOrderFields(hasProduction?: boolean, hasOrderFiles?: boolean, filter?: object) {
    const url = stringifyUrl({
        url: 'catalog/order-lines-combined',
        query: {
            filter: JSON.stringify(filter),
            productionManagement: hasProduction,
            orderDossierManagement: hasOrderFiles || false
        }
    });
    return (await getRequest(url)).data;
}

export async function createOrderWithData(data: object) {
    return (await postRequest('catalog/orders/0', data)).data;
}

export async function updateOrder(orderId: number, data: object) {
    if (orderId > 0) {
        return (await putRequest('catalog/orders/' + orderId, data)).data;
    }
    return (await postRequest('catalog/orders/' + orderId, data)).data;
}

export async function getOrderDetails(orderId: number, hasProduction: boolean) {
    const url = stringifyUrl({
        url: 'catalog/orders/' + orderId,
        query: {
            productionManagement: hasProduction
        }
    });
    return (await getRequest(url)).data;
}

export async function getOrderAttributes() {
    return (await getRequest('catalog/order-attributes')).data;
}
export async function getOrderLineAttributes() {
    return (await getRequest('catalog/order-line-attributes')).data;
}


export async function getOrderAttrValue(filter?: object) {
    const url = stringifyUrl({
        url: 'catalog/order-attribute-values',
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url)).data;
}

export async function getOrderLineAttrValues(filter?: object) {
    const url = stringifyUrl({
        url: 'catalog/order-line-attribute-values',
        query: {
            filter: JSON.stringify(filter)
        }
    });
    return (await getRequest(url)).data;
}

// NOTE: Create: add attribute to order
export async function setOrdeAttrValues(data: any) {
    return (await postRequest(`catalog/order-attribute-values`, data)).data;
}
// NOTE: Create: add attribute to orderLine
export async function setOrderLineAttrValues(data: any) {
    return (await postRequest(`catalog/order-line-attribute-values`, data)).data;
}
// NOTE: UPDATE: orderLine or order, product attributes
export async function setAttributeValue(type: string, id: number, data: object) {
    let typeUrl;
    switch (type) {
        case 'order':
            typeUrl = 'order-attribute-values/';
            break;
        case 'orderLine':
            typeUrl = 'order-line-attribute-values/';
            break;
        case 'product':
            typeUrl = 'product-attribute-values/';
            break;
        default:
            return {};
    }
    return (await putRequest('catalog/' + typeUrl + id, data)).data;
}

export async function deleteAttributesValue(instanceId: number, instanceType: string) {

    let typeUrl;
    switch (instanceType) {
        case 'order':
            typeUrl = 'order-attribute-values/';
            break;
        case 'orderLine':
            typeUrl = 'order-line-attribute-values/';
            break;
        case 'product':
            typeUrl = 'product-attribute-values/';
            break;
        default:
            return {};
    }

    const fullUrl = stringifyUrl({
        url: 'catalog/' + typeUrl + instanceId,
        query: {
            hard: true
        }
    })

    return (await deleteRequest(fullUrl)).data;
}

export async function getMarketplaces(query?: object) {
    const fullUrl = stringifyUrl({
        url: 'catalog/marketplaces',
        query
    })
    return (await getRequest(fullUrl)).data;
}

export async function deleteOrderById(orderId: number) {
    return (await deleteRequest('catalog/orders/' + orderId)).data;
}
export async function deleteOrderLine(orderLineId: number) {
    return (await deleteRequest('catalog/order-lines/' + orderLineId)).data;
}

export async function deleteFulfilment(fulfilmentId: number) {
    return (await deleteRequest('catalog/fulfilments/' + fulfilmentId)).data;
}

export async function updateOrderLine(orderLineId: number, data?: any) {
    if (orderLineId > 0) {
        return (await putRequest('catalog/order-lines/' + orderLineId, data)).data;
    }
    return (await postRequest('catalog/order-lines', data)).data;
}

export async function getCatalogDashboardDetails() {
    return (await getRequest('catalog-dashboard')).data;
}

export async function getWarehouses(query?: object): Promise<IWarehouse[]> {
    const fullUrl = stringifyUrl({
        url: 'catalog/warehouses',
        query
    })
    return (await getRequest(fullUrl)).data;
}

// NOTE: Accounts API
export async function getAccounts(filter = {}, search: string = '') {

    filter = filter || {};
    let url = stringifyUrl({
        url: 'catalog/accounts',
        query: {
            filter: JSON.stringify(filter),
        }
    });

    return (await getRequest(url)).data;
}

export async function getAccountById(accountId?: number) {
    return (await getRequest('catalog/accounts/' + accountId)).data;
}

export async function deleteAccount(accountId?: number, hard?: boolean) {
    const url = stringifyUrl({
        url: 'catalog/accounts/' + accountId,
        query: {
            hard: hard
        }
    })
    return (await deleteRequest(url)).data;
}

export async function updateAccount(accountId: number, data: any) {
    data.id = accountId;
    if (accountId == 0) {
        return (await (postRequest('catalog/accounts/0', data))).data;
    }
    return (await (putRequest('catalog/accounts/' + accountId, data))).data;
}

export async function getAdressById(addressId?: number) {
    return (await getRequest('catalog/addresses/' + addressId)).data;
}

export async function updateAdress(addressId: number, accountId: number, data: any) {
    data.accountId = accountId;
    data.id = addressId;
    if (addressId == 0) {
        return (await (postRequest('catalog/addresses/0', data))).data;
    }
    return (await (putRequest('catalog/addresses/' + addressId, data))).data;
}

export async function deleteAdress(addressId: number) {
    return (await deleteRequest('catalog/addresses/' + addressId)).data;
}


export async function getAdressForAccount(accountId: number, type?: number | string) {
    const url = stringifyUrl({
        url: 'catalog/addresses',
        query: {
            filter: JSON.stringify({ account: accountId, addressType: type })
        }
    });
    return (await getRequest(url)).data;
}
export async function getAddressesTypes() {
    return (await getRequest(`${basePath}/addresses-types`)).data;
}

export async function getBolSettings() {
    return (await getRequest('settings-bol')).data;
}

export async function postOrderDelivery(item: any) {
    return (await postRequest('order-delivery', item)).data;
}

export async function getInvoices() {
    return (await getRequest(`${basePath}-invoices?expand=status`)).data;
}

export async function updateInvoice(data: any) {
    return (await putRequest(`${basePath}/invoices/${data.id}`, data)).data;
}
export async function getOrderStatuses() {
    return (await getRequest(`orders-statuses`)).data;
}

/**
 * NOTE: Production lines API
 * */
export async function getProductionLines(queryParams: object) {
    const fullUrl = stringifyUrl({
        url: `${basePath}/production-lines`,
        query: {
            ...queryParams
        }
    });
    return (await getRequest(fullUrl)).data;
}


/**
 * NOTE: product-account-price
 */
export async function getProductAccountPrice(queryParams: object) {
    const fullUrl = stringifyUrl({
        url: `${basePath}/view-account-product-prices`,
        query: {
            ...queryParams
        }
    });
    return (await getRequest(fullUrl)).data;
}
