

import PazLoader from '@pazion/pazion-core/src/components/utils/PazLoader.vue'
import {Component, Emit, VModel, Vue} from 'vue-property-decorator';
import {createOrder} from "../../services/catalog-service";

@Component({
  components: {
    PazLoader
  }
})
export default class PazOrderCreator extends Vue {
  @VModel({
    default: false
  })
  public showDialog!: boolean;

  public loading: boolean = false;

  public orderReference:string = '';

  created() {
  }

  async createOrder() {
    this.loading = true;
    const rsp = await createOrder(this.orderReference);
    if(rsp.orderId && rsp.orderId > 0){
      this.newOrderCreated(rsp.orderId);
      this.orderReference = '';
    }
    this.loading = false;
  }
  @Emit()
  public newOrderCreated(orderId: number){
    return orderId;
  }
}
