
import {Component, Prop, Vue} from 'vue-property-decorator';
import ProgressArrow from "../../assets/ProgressArrow.vue";
import {IOrderStatus, ORDER_STATUS} from "../../models/order";

@Component({
  components: {
    ProgressArrow
  }
})
export default class PazOrderStatus extends Vue {
  @Prop({default: ''})
  public orderStatus!: string;

  @Prop({default: 'sales'})
  public orderType!: string;

  @Prop({default: true})
  public displayInvoiceStatus!: boolean;

  public orderStatusList: IOrderStatus[] = ORDER_STATUS;

  get orderStatusDescription() {
    let orderStatus       = this.orderStatus;
    let orderStatusFilter = this.orderStatusList.find(function(elem) {      
      return elem.nameTech == orderStatus;
    });
    if(orderStatusFilter)
      orderStatus = orderStatusFilter.label;

    return orderStatus;
  }

  get cartColor() {
    switch (this.orderStatus) {
      case 'CANCELLED':
        return 'grey';
      case 'ERROR':
        return 'red';
    }

    return 'green';
  }

  get warehouseColor() {
    switch (this.orderStatus) {
      case 'NEW':
        return 'grey';
      case 'IN_PRODUCTION':
      case 'READY_TO_SHIP':
      case 'SHIPPED_PARTIAL':
        return 'orange';
      case 'READY_TO_INVOICE':
      case 'INVOICED':
      case 'PROCESSED':
      case 'SHIPPING':
      case 'SHIPPED':
      case 'AT_WAREHOUSE':
        return 'green';
      case 'ACTION_REQUIRED':
        return 'red'

    }    
    return 'grey';
  }

  get shippedColor() {
    switch (this.orderStatus) {
      case 'NEW':
        return 'grey';
      case 'SHIPPED_PARTIAL':
      case 'SHIPPING':
        return 'orange';
      case 'READY_TO_INVOICE':
      case 'INVOICED':
      case 'PROCESSED':
      case 'SHIPPED':
        return 'green';
    }
    return 'grey';
  }

  get invoiceColor() {
    switch (this.orderStatus) {
      case 'READY_TO_INVOICE':
      case 'SHIPPED':
        return 'orange';
      case 'INVOICED':
      case 'PROCESSED':
        return 'green';
    }
    return 'grey';
  }

  get completedColor() {
    switch (this.orderStatus) {
      case 'NEW':
        return 'grey';
      case 'INVOICED':
      case 'PROCESSED':
        return 'green';
      case 'SHIPPED':
        if(this.displayInvoiceStatus)
          return 'grey';
        else
          return 'green';
    }
    return 'grey';
  }

}
