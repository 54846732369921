
import {Vue, Component, Prop, VModel} from "vue-property-decorator";

@Component({})
export default class SelectInput extends Vue {
  @Prop({})
  public itemValue!: any;

  @Prop({
    default: () => {
    }
  })
  public properties!: { name: string, class: string, id: string, label: string };


  @VModel({})
  public selected!: string;
  public localItems: string[] = [];
  public defaultSelectOption: string = 'Select a value';

  mounted() {
    if (!!this.selected) {
      this.localItems = this.itemValue;
    } else {
      this.selected = this.defaultSelectOption;
      //@ts-ignore
      this.localItems = [this.defaultSelectOption, ...this.itemValue];
    }


    console.log('result of type select: ')
    console.log(this.localItems)
    console.log('this is the selected item/ v-model:')
    console.log(this.selected)
    console.log('this is the passed options array: ')
    console.log(this.itemValue)
    console.log(typeof this.itemValue)
    console.log('thse are the props: ')
    console.log(this.properties)
  }
}
