
import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class PazDateField extends Vue {
  @VModel()
  public date: any;

  @Prop() public editable: boolean;
  @Prop({ default: "date" })
  public label!: string;
  public menu: boolean = false;
  public activePicker: string = "DATE";
}
