
import { Component, Mixins } from "vue-property-decorator";
import TransactionsListingMixin from "./mixins/transactions-listing.mixin";
import TransactionsFiltersMixin from "./mixins/transactions-filters.mixin";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";

@Component({
  components: {
    PazTooltipButton,
    PazRefreshButton,
    PazMenuDatePicker,
  },
})
export default class TransactionsListing extends Mixins(
  TransactionsListingMixin,
  TransactionsFiltersMixin
) {
  /**
   * LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/contracts/mixins/transactions-listing.mixin.ts
   **/
  /**
   * LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/contracts/mixins/transactions-filters.mixin.ts
   **/
}
