import { render, staticRenderFns } from "./pazDossierDocViewer.vue?vue&type=template&id=30534f62&scoped=true&"
import script from "./pazDossierDocViewer.vue?vue&type=script&lang=ts&"
export * from "./pazDossierDocViewer.vue?vue&type=script&lang=ts&"
import style0 from "./pazDossierDocViewer.vue?vue&type=style&index=0&id=30534f62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30534f62",
  null
  
)

export default component.exports