

import { Component, Vue, Prop } from "vue-property-decorator";
import { IOrderLine } from "../../../models/order";
import ProductionDocs from '../../../views/orders/parts/ProductionDocs.vue'
import { getProductionRecords } from "../../../services/order-service";


@Component({
  components: { ProductionDocs },
})
export default class PazOrderProductionRecords extends Vue {
  @Prop({ default: null })
  readonly order!: IOrderLine;

  private productionRecords = [];


  get getProductionRecord() {
    return this.productionRecords && this.productionRecords;
  }

  async mounted() {
    this.productionRecords = await getProductionRecords(this.order.id);
  }

}
