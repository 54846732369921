
import {Component, Vue} from 'vue-property-decorator';
import {authentication} from "../../store";

@Component({
  components: {}
})
export default class ActivateAccount extends Vue {
  private user: any = {email: '', password: '', password2: ''};
  private loading: boolean = false;
  private message: string = '';

  public validate() {
    return false;
  }

  get canSubmit() {
    return this.user.email.length > 0 && this.user.password.length > 0 && this.user.password2.length > 0;
  }

  handleInput() {
    this.loading = true;
    this.message = '';
    if (!this.canSubmit) {
      this.loading = false;
      return;
    }
  }
}
