

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PazLoader extends Vue {

  @Prop({ default: false })
  loading!: boolean;

  get isPazionApp() {
    return process.env.VUE_APP_NAME === 'Pazion App';
  }
}
