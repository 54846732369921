
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component
export default class PazRefreshButton extends Vue {

  @Prop({default: 'refresh!'})
  public title!: string;

  @Prop({default: false})
  public loading!: boolean;

  @Prop({default: ''})
  public className!: string;

}
