
import { Component, Mixins } from "vue-property-decorator";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import ContractListingMixin from "./mixins/contracts-listing.mixin";
import ContractDetailsModal from "./contract-details/ContractDetailsModal.vue";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";
import {extractDate} from "../../helpers/format";

@Component({
  methods: {extractDate},
  components: {
    PazTooltipButton,
    PazToast,
    FileUploader,
    PazRefreshButton,
    PazConfirmation,
    ContractDetailsModal,
  },
})
export default class ContractListing extends Mixins(ContractListingMixin) {
  refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
  };
  /**
  * LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/contracts/mixins/contracts-listing.mixin.ts
  **/
} //
