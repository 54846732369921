
import { Component, Mixins } from "vue-property-decorator";
import DossierListTable from "./parts/DossierListTable.vue";
import DossierListApp from "./dossierApp/DossierListApp.vue";
import DossierPersonalListApp from "./dossierApp/DossierPersonalListApp.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import DossierEditDialog from "../../components/dialog/pazDossierEditDialog.vue";
import BaseMixin from "../../mixins/base.mixin";
import AccountMixin from "../../mixins/account.mixin";
import {
  getDossierAccount,
  deleteDossier,
} from "../../services/dossier-service";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import { IDossier } from "../../models/dossier.model";
import PazRefreshButton from "../../components/controls/PazRefreshButton.vue";

@Component({
  components: {
    DossierListTable,
    PazToast,
    PazTooltipButton,
    DossierEditDialog,
    PazConfirmation,
    DossierListApp,
    DossierPersonalListApp,
    PazRefreshButton
  },
})
export default class DossierListingPage extends Mixins(
  BaseMixin,
  AccountMixin
) {
  public dossierAccount: IDossier | null = null;
  public loading: boolean = false;
  public showDossierEditDialog: boolean = false;
  public IsTop: boolean = false;
  public selectedDossier: null | IDossier = null;
  public parentId: number | null = null;
  public dossierActiveTab: string | null = 'all-dossiers';
  public $refs!: {
    toast: PazToast,
    confirmDeleteProductDossier: PazConfirmation
  };
  createNewDossier() {
    this.showDossierEditDialog = true;
  }

  hasDossier(response) {
    return response && response.hasOwnProperty("length");
  }

  createFromLine(parentId: number) {
    this.showDossierEditDialog = true;
    this.parentId = parentId;
  }

  async getDossierAccount() {
    try {
      this.loading = true;
      const dossierAccount = await getDossierAccount({ flat: false });
      this.dossierAccount = dossierAccount;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.showDefaultServerErrorToast(true);
    }
  }

  onDossierEdit(data: { item: IDossier, isTop: boolean | undefined }) {
    this.IsTop = data.isTop || false;
    this.selectedDossier = data.item;
    this.showDossierEditDialog = true;
  }

  async onDossierDelete(item: IDossier) {
    const confirm =
      await this.$refs.confirmDeleteProductDossier.openConfirmDialog(
        "Delete",
        `Do you want to delete dossier: ${item.name}?`
      );
    if (!confirm) return;
    this.loading = true;
    try {
      const response = await deleteDossier(item.id);
      if (response) {
        this.$refs.toast.show({ message: `${item.name} has been deleted!` });
        this.getDossierAccount();
      } else {
        this.showDefaultServerErrorToast(true);
      }
    } catch (error) {
      console.log("error", error);
      this.showDefaultServerErrorToast(true);
    }
    this.loading = false;
  }

  onCloseDialog() {
    this.selectedDossier = null;
    this.showDossierEditDialog = false;
    this.parentId = null;
  }

  mounted() {
    this.getDossierAccount();
  }
}
