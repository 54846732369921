import { IproductTypesModel } from "../../../models/product";
import { Component, Vue } from "vue-property-decorator";

@Component
export class PatchPricing extends Vue {
  /**
   * NOTE: convert price to measuring and units
   * @param price: number 
   * @param square : number
   */
  patchCalculatedPrice(price: number, item: IOrderLine) {
    const square = (item.length && item.width) ? parseFloat(item.length * item.width) : 1;
    if (!this.$orderlineMeasureCalculation)
      return price
    else {
      const relativePrice = price * square;
      return (this.$measureCalculationUnit == "mm") ? (relativePrice / 1000000) : relativePrice;
    }
  }  

  /**
   * NOTE: Is Product has dimensions
   * @param productType 
   * @param existingProductTypes 
   * @returns true | false
   */
  isdimensionedType(productType: number, existingProductTypes: IproductTypesModel[], byName?: boolean) {
    const typeKey = !byName ? 'id' : 'nameTech';
    const prodType = existingProductTypes 
    && existingProductTypes.find((t: IproductTypesModel) => t[typeKey] === productType);
    return prodType && (prodType.nameTech === 'dimensioned');
  }

}//