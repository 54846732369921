
import UserListing from "../../../views/user/UserListing.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { fetchExtraDossierInfo } from "../../../services/dossier-service";
import { getUsers } from "../../../services/auth-service";
import AccountMixin from "../../../mixins/account.mixin";
import BaseMixin from "../../../mixins/base.mixin";
import { DossierUsers } from "../../../models/dossier.model";

@Component({
  components: {
     UserListing
  }
})
export default class EditDossierUsers extends Mixins(
  AccountMixin,
  BaseMixin
) {
  @Prop() public dossierInstance!: object;
  @Prop({default: false}) public tablePreLoading: boolean;
  public dossierUsers = [];
  public usersArray = [];
  public dossierExtraInfo: {} | null = null;
  public usersLoad: boolean = false;

  async fetchUsers() {
    this.usersLoad = true;
    const response = await getUsers();
    if (response)
      this.usersArray = response.filter(
        (user) => user.id !== this.currentUser.id 
      );
    this.usersLoad = false;
  }


  async getDossierExtraInfo() {
    try {
      const response = await fetchExtraDossierInfo(this.dossierInstance?.id);
      if (response) this.dossierExtraInfo = response;
      this.dossierUsers = [];
    } catch (error) {
      this.showDefaultServerErrorToast();
    }
  }

  deleteUserFromDossier(user: DossierUsers) {
    this.$emit("onShareDossierUsers", {
      dossierId: this.dossierInstance?.id,
      users: [user],
      action: "remove",
    });
    this.dossierUsers = [];
  }

 get getFilteredUSers() {
    return this.usersArray.filter(user => {
      return user && !this.dossierExtraInfo.users?.map(el => el.id).includes(user.id);
    })
  }

  mounted() {
    this.fetchUsers();
    this.getDossierExtraInfo();
  }
}
