import {Action, Module, MutationAction, VuexModule} from 'vuex-module-decorators'
import {RouteConfig} from 'vue-router'
import {getAllRoutes} from '../router'
import {authentication} from "./index";
import {DEFAULT_NAV_SORT_VALUE} from "../config";

const hasPermission = (roles: string[] | null, route: RouteConfig) => {
    if (!route.meta || !route.meta['show-in-nav']) {
        return false;
    }
    if (route.meta.roles) {
        if (roles == null) {
            return false;
        }
        return roles.some(role => route.meta?.roles.includes(role))
    } else {
        return true
    }
}

export const filterAsyncRoutes = (routes: RouteConfig[], roles: string[] | null): RouteConfig[] => {
    const res: RouteConfig[] = [];
    if (routes === null || routes === undefined || routes.length == 0) {
        return res;
    }
    routes.forEach(route => {
        const r = {...route}
        if (hasPermission(roles, r)) {
            if (r.children) {
                r.children = filterAsyncRoutes(r.children, roles);
            }
            res.push(r)
        }
    });
    res.sort((route1, route2) => {
        return route1.meta?.sort ?? DEFAULT_NAV_SORT_VALUE - route2.meta?.sort ?? DEFAULT_NAV_SORT_VALUE;
    });
    return res
}

export interface IPermissionState {
    routes: RouteConfig[]
    routesWithPermission: RouteConfig[]
}

@Module({name: 'permission'})
export default class Permission extends VuexModule implements IPermissionState {
    public routes: RouteConfig[] = [];
    public routesWithPermission: RouteConfig[] = [];


    @MutationAction({mutate: ['routes']})
    public async GenerateRoutes() {

        let roles: string[] | null = authentication.getProfile?.roles || null;
        let routes: RouteConfig[] = filterAsyncRoutes(getAllRoutes(), roles);

        return {routes: routes };
    }

    @Action
    async updateRoutes() {
        await this.GenerateRoutes();
    }

    get getroutesWithPermissions() {
        let roles: string[] | null = authentication.getProfile?.roles || null;
        return filterAsyncRoutes(this.routes, roles);
    }
}
