import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({ profile: (state) => state.authentication.profile })
  }
})
export default class AccountMixin extends Vue {
  
  get isUserAdmin() {   
    return this.profile?.roles?.includes("administrator") || false;
  }

  get isUserPublic() {
    return this.profile.email === 'public'
  }

  get currentUser() {
    return this.profile;
  }

}
