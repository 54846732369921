
import Component from "vue-class-component";
import Vue from "vue";

export interface IToast {
  xPosition?: string;
  yPosition?: string;
  message?: string;
  color?: string;
  timer?: number;
  icon?: string;
}

/**
 * <paz-toast ref="toast"/>
 *
 *
 * this.$refs.vtoast.show(IToast)
 */

@Component({})
export default class PazToast extends Vue {
  showSnackbar: boolean = false;
  message: string = '';
  color: string = 'success';
  icon: string = 'mdi-check';
  timer: number = 8000;
  xPosition: string = 'right';
  yPosition: string = 'bottom';

  show(data: IToast) {
    this.showSnackbar = true;
    this.message = data.message || 'missing "message".';
    this.color = data.color || 'success';
    this.timer = data.timer || 8000;
    this.icon = data.icon || 'mdi-check';
    this.yPosition = data.yPosition;
  }

   getVerticalPosition(pos: string) {        
    return pos === this.yPosition
  }
}
