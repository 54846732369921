import _Vue from 'vue';
import Rollbar from 'rollbar';

const RollbarPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue: typeof _Vue) {
    Vue.prototype.$rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: true,
      checkIgnore: function (isUncaught, args, payload) {
        return window.location.hostname === 'localhost'
      },
      payload: {
        environment: process.env.VUE_APP_NAME,
        client: {
          javascript: {
            code_version: '1.0'
          }
        }
      }
    });
    Vue.config.errorHandler = (err, vm, info) => {
      //@ts-ignore
      vm.$rollbar.error(err);
      throw err; // rethrow
    };
  }
}

export default RollbarPlugin;