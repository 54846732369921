
import { Component, Mixins } from "vue-property-decorator";
import AccountsListMixin from "./mixins/accounts-list.mixin";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazAccountDialog from "../../components/dialog/PazAccountDialog.vue";

@Component({
  components: {
    PazTooltipButton,
    PazRefreshButton,
    PazToast,
    PazConfirmation,
    PazAccountDialog
  },
})
export default class AccountsListing extends Mixins(AccountsListMixin) {
  // LINK: frontendPackages/pazion-catalog/src/views/accounts/mixins/accounts-list.mixin.ts
}
