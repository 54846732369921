/**
 * SECTION OrderListing filters panel logic
 */
import { IOrder } from "src/models/order";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class orderListingFilterMixin extends Vue {

  //NOTE: variable for elastic search
  private _timerId: number | null = null;


  // NOTE: filter items by all select filter
  get filteredItems() {
    let filteredItems = this.items;

    const statusInstanceKey = this.isCombinedOrderListing ? 'orderLineStatus' : 'status' 

    if (this.selectedOrderStatus.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        return this.selectedOrderStatus.includes(item[statusInstanceKey]);
      });
    }

    if (this.selectedOrderType.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        return this.selectedOrderType.includes(item.orderType);
      });
    }

    if (this.selectInstancesMarketPlace.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        return this.selectInstancesMarketPlace.includes(item.marketplaceInstance);
      });
    }

    return filteredItems;
  }
  
  // NOTE: get order statuses
   getAvailableStatuses() {
    return (
      this.orderStatus.length &&
      this.orderStatus
        .map((st) => {
          if (
            this.filteredItems &&
            this.filteredItems.flatMap((el: IOrder) => el.status).includes(st.nameTech)
          )
            return st;
        })
        .filter((item) => item !== undefined)
    );
  }
  // NOTE: get order types
  getAvailableOrderTypes() {
    return (
      (this.filteredItems && [
        ...new Map(
          this.filteredItems.map((v) => [JSON.stringify([v.orderType]), v])
        ).values(),
      ]) ||
      []
    );
  }
  // NOTE: get order marketplaces
  getAvailableOrderMarketplaces() {
    return (
      (this.marketplaces.length &&
        this.marketplaces
          .map((st) => {
            if (
              this.filteredItems &&
              this.filteredItems
                .flatMap((el: IOrder) => el.marketplace)
                .includes(st.id)
            )
              return st;
          })
          .filter((item) => item !== undefined)) ||
      []
    );
  }

  // NOte: get marketplaceInstance
  get getAvailableMarketplaceInstances() {
    const currentMarketplace = this.availableOrderMarketplaces
      .filter((mp) => mp && this.selectedOrderMarketPlace === mp.id)
      .shift();
    if (currentMarketplace && currentMarketplace.marketplaceInstance.length === 1)
        this.selectInstancesMarketPlace = [currentMarketplace.marketplaceInstance[0].id];
    else this.selectInstancesMarketPlace = [];    
    return currentMarketplace ? currentMarketplace.marketplaceInstance : []
  }


  // NOTE: config search
  searchByReferenceFields(search: string, IsCombo?: boolean) {
    let refSearchJson = {
      operator: "AND",
      multi: {
        operator: "OR",
        reference: { like: `%${search}%` },
        description: { like: `%${search}%` },
        contact: { like: `%${search}%` },
        notes: { like: `%${search}%` },
        status: { like: `%${search}%` }
      }
    }
    if (IsCombo) {
      refSearchJson = {
        operator: "AND",
        multi: {
          operator: "OR",
          "o.reference": { like: `%${search}%` },
          "o.description": { like: `%${search}%` },
          "o.status": { like: `%${search}%` },
          "o.notes": { like: `%${search}%` },
          "o.contact": { like: `%${search}%` },
          "e.description": { like: `%${search}%` },
          "e.notes": { like: `%${search}%` },
          "e.status": { like: `%${search}%` }
        }
      }
    }
    return refSearchJson
  }


  // NOTE: Search and filters
  onRefSearch(isCombo?: boolean) {
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const searchString =
        this.searchByReference.length > 0
          ? this.searchByReferenceFields(this.searchByReference, isCombo || false)
          : null;
      await this.fetchData(searchString);
    }, 1000);
  }

  get isCombinedOrderListing(): boolean {
    return this.$orderListingType == "combo";
  }

}//


