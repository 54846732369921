
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import {
  getOrdersOnly,
  getOrderStatuses,
  createOrderWithData,
  createOrder,
  updateOrder,
  getMarketplaces,
} from "../../services/catalog-service";
import Fulfilments from "./order-detail/Fulfilments.vue";
import PazOrderDialog from "../../components/dialog/PazOrderDialog.vue";
import PazOrderDeliveryDialog from "../../components/dialog/PazOrderDeliveryDialog.vue";
import { formatPrice } from "../../helpers/format";
import PazOrderStatus from "../../components/order/PazOrderStatus.vue";
import { IOrder, IOrderStatus, IOrderStatusesApi, ORDER_STATUS } from "../../models/order";
import PazListFilterSelector from "@pazion/pazion-core/src/components/utils/PazListFilterSelector.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazOrderCreator from "../../components/order/PazOrderCreator.vue";
import PazOrderProductionDialog from "../../components/dialog/pazOrderProductionDialog.vue";
import OrderListingMixin from "./mixins/orderListingMixin";
import orderListingFilterMixin from "./mixins/orderListingFilterMixin";
import PazOrderFilesDialog from "../../components/dialog/PazOrderFilesDialog.vue";

import {
  postOrderDelivery,
  deleteOrderById,
} from "../../services/catalog-service";
import { AxiosError } from "axios";
import { IMarketplace } from "../../models/base";
import { MarketplaceInstanceEntity } from "src/models/offers.model";


@Component({
  components: {
    PazOrderDeliveryDialog,
    Fulfilments,
    PazOrderDialog,
    PazRefreshButton,
    PazOrderStatus,
    PazListFilterSelector,
    PazTooltipButton,
    PazToast,
    PazConfirmation,
    PazOrderCreator,
    PazOrderProductionDialog,
    PazOrderFilesDialog,
  },
  methods: {
    formatPrice,
  },
})
export default class OrderListingOrdersOnly extends Mixins(
  OrderListingMixin,
  orderListingFilterMixin
) {
  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
    confirmDeleteOrder: PazConfirmation;
  };
  public orderStatus: IOrderStatus[] = ORDER_STATUS;
  public selectedOrderStatus = [];
  public selectedOrderType = [];
  public selectedOrderMarketPlace: number | null = null;
  public selectInstancesMarketPlace = [] as MarketplaceInstanceEntity[];
  public marketplaces: IMarketplace[] = [];
  public loading: boolean = false;
  public search: string = "";
  public searchByReference: string = "";
  public items = [];
  public orderDetailsData: any;
  public result: any;
  public expanded: [] = [];
  public footer: object = {
    "items-per-page-options": [50, 100, 200],
  };
  public showOrderEditDialog: boolean = false;
  public showOrderDeliveryDialog: boolean = false;
  public selectedOrder: number = 0;
  public dialog: object = {
    show: false,
    info: [],
  };
  public showOrderProductDialog: boolean = false;
  public orderStatuses = {} as IOrderStatusesApi;

  public availableStatusesItems = [];
  public availableOrderTypes = [];
  public availableOrderMarketplaces = [];
  public availableMarketplaceInstances = [];
  public fromFulfilment: boolean | undefined = false;

  @Prop({
    default: () => {
      return [];
    },
  })
  public tableHeaders!: object[];

  public headers: object = {
    main: [
      {
        text: "Order",
        align: "left",
        class: "cell-truncate nowrap",
        sortable: true,
        cellClass: "nowrap",
        value: "id",
      },
      {
        text: "Type",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "orderType",
        groupable: false,
      },
      {
        text: "Reference",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "reference",
        groupable: false,
      },
      {
        text: "Description",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "description",
        groupable: false,
      },
      // {
      //   text: 'Attributes',
      //   align: 'left',
      //   class: 'cell-truncate',
      //   sortable: true,
      //   value: 'attributeValue',
      //   groupable: false,
      // },
      {
        text: "Contact",
        class: "cell-truncate",
        sortable: true,
        value: "contact",
        groupable: false,
      },
      {
        text: "Total",
        align: "right",
        class: "cell-truncate text-right 	mx-0",
        sortable: true,
        value: "total",
        groupable: false,
      },
      /*{
        text: 'Status',
        align: 'right',
        class: 'cell-truncate text-right',
        sortable: true,
        value: 'orderLineStatus',
        groupable: false,
      },*/
      {
        text: "Status",
        align: "right",
        class: "cell-truncate text-right",
        sortable: true,
        value: "status",
        groupable: false,
      },
      {
        text: "Order date",
        align: "right",
        class: "cell-truncate text-right",
        sortable: true,
        value: "orderDate",
        groupable: false,
      },
      {
        text: "Actions",
        class: "cell-truncate text-right mx-5",
        value: "actions",
        groupable: false,
      },
    ],
  };

  /*
   Order operations
   */
  // async newOrderCreated(orderId: number) {
  //   this.orderReference = `order-${Date.now()}`;
  //   this.loading = true;
  //   const rsp = await createOrder(this.orderReference);
  //   if (rsp.orderId && rsp.orderId > 0) {
  //     this.orderReference = "";
  //     this.showOrderEditDialog = true;
  //     this.selectedOrder = rsp.orderId;
  //     await this.fetchData();
  //   }
  //   this.loading = false;
  // }

  async deleteOrder(item: IOrder) {
    const confirm = await this.$refs.confirmDeleteOrder.openConfirmDialog(
      "Delete",
      `Do you want to delete order #${item.id}?`
    );
    if (!confirm) {
      return;
    }
    let response = await deleteOrderById(item.id);
    if (response.success) {
      this.$refs.toast.show({ message: response.message });
      this.fetchData();
      return;
    }
    this.$refs.toast.show({
      message: "An error occurred, please contact the administrator",
      icon: "mdi-close-circle",
      color: "red",
    });
  }
  async duplicateOrder(items: object) {
    const orderId = items[0].orderId;
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Duplicate",
      "Are you sure you want to duplicate order  `" + orderId + "`?"
    );
    if (confirm) {
      const r = await createOrderWithData({ copyId: orderId });
      if (r.orderId && r.orderId > 0) {
        this.$refs.toast.show({ message: `Order #${r.orderId} created.` });
        this.showOrderEditDialog = true;
        this.selectedOrder = r.orderId;
        await this.fetchData();
      }
    }
  }

  public updateNotes(newText: string) {
    this.orderDetailsData.notes = newText;
  }

  updateShowOrderEditDialog(value: boolean) {
    this.showOrderEditDialog = value;
  }

  updateShowDeliveryDialog(value: boolean) {
    this.showOrderDeliveryDialog = value;
  }

  /* Support operations*/
  async createDelivery(item: IOrder) {
    try {
      let response = null;
      this.selectedOrder = item.id;
      let deliveryPayload = {
        id: item.id,
        orderLine: [],
        deliverAt: "",
      };

      if (this.canShowDeliveryDialog) {
        this.showOrderDeliveryDialog = true;
      } else {
        response = await postOrderDelivery(deliveryPayload);
        this.$refs.toast.show({ message: response.message });
        await this.fetchData();
      }
    } catch (error) {
      let response = (error as AxiosError).response;

      if (response && response.status !== 500) {
        this.$refs.toast.show({
          message: response.data.message,
          color: "red",
          icon: "mdi-close-circle",
        });
        return;
      }
      this.$refs.toast.show({
        message: "An error occurred, please contact the administrator",
        icon: "mdi-close-circle",
        color: "red",
      });
    }
  }

  async showOrderProduction(item: IOrder) {
    this.selectedOrder = item.id;
    this.showOrderProductDialog = true;
  }

  isEmpty(item: any) {
    return item.extra.length == 0 || item.extra == "[]";
  }

  async orderStateRetry(item: any) {
    const rsp = await updateOrder(item.id, { status: "NEW" });

    if (rsp.success) {
      this.$refs.toast.show({ message: rsp.message });
      await this.fetchData();
    }
  }

  canDoProd(status: string) {
    return !["INVOICED", "PROCESSED", "SHIPPED"].includes(status);
  }

  get canOrderEditableWithStatus() {
    if (this.cSelectedOrder) {
      return (
        this.cSelectedOrder &&
        !["SHIPPING", "READY_TO_INVOICE", "PROCESSED", "SHIPPED"].includes(
          this.cSelectedOrder.status
        )
      );
    }
    return false;
  }

  get canEditOrder() {
    return this.$editOrder != undefined && this.$editOrder;
  }

  get cSelectedOrder() {
    return this.items.find((item) => {
      return item.id === this.selectedOrder;
    });
  }

  get canShowOrderDetails() {
    return this.$orderDetails != undefined && this.$orderDetails;
  }

  /*Fetch main Data*/
  async fetchData(filterData?: object, clearRef?: boolean) {
    this.loading = true;
    let filter: { reference?: object; isDeleted: number; operator?: "AND" } = {
      isDeleted: 0,
    };

    this.orderStatuses = await getOrderStatuses();

    if(clearRef) { 
      (this.searchByReference = "") 
      this.$router.replace({query: null}).catch(err => {});
    }

    if (!filterData && this.searchByReference.length > 0) {
      const refString = this.searchByReferenceFields(this.searchByReference);
      Object.assign(filter, refString);
    }

    if (filterData) Object.assign(filter, filterData);

    const response = await getOrdersOnly(
      filter,
      this.$productionManagement,
      this.$orderDossierManagement
    );

    if (!response) {
      this.items = [];
      return;
    }
    this.items = response;
    this.availableStatusesItems = this.getAvailableStatuses();
    this.availableStatusesItems = this.getAvailableStatuses();
    this.availableOrderTypes = this.getAvailableOrderTypes();
    this.availableOrderMarketplaces = this.getAvailableOrderMarketplaces();
    this.loading = false;
  }

  openOrderDetails(item: IOrder) {
    this.selectedOrder = item.id;
    this.showOrderEditDialog = true;
  }

  showOrderDetails(item: any, fromFulfilment?: boolean) {
    this.fromFulfilment = fromFulfilment;
    this.showOrderEditDialog = true;
    this.selectedOrder = item.id;
  }

  async mounted() {
    this.searchByReference =
      ( this.$route.query && this.$route.query.orderReference as string) || "";
    this.fetchData();
    this.marketplaces = await getMarketplaces();
  }
}
