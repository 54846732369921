

import Vue from 'vue';
import SettingsEntry from './SettingsEntry.vue';
import {Component} from 'vue-property-decorator';
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import {getAllPackageWithSystemApi, getSettings} from "../local_services";
import {postRequest} from "@pazion/pazion-core/src/services/auth-service";

//inputs
import TextInput from './Settings/Form/Input/TextInput.vue';
import SelectPackage from './Settings/SelectPackage.vue';
import TabContent from './Settings/TabContent.vue';
import BaseInput from './Settings/Form/Input/BaseInput.vue';
import TestComponent from './Settings/Form/TestComponent.vue';

//extra
import Popup from './Settings/Popup.vue';

@Component({
  components: {
    SettingsEntry,
    PazLoader,
    TextInput,
    SelectPackage,
    TabContent,
    BaseInput,
    Popup,
    TestComponent,
  }
})

export default class Settings extends Vue {
  public data: object = {};
  public isWelcomePage: boolean = true;
  public isLoading: boolean = false;

  public packages: object[] = [];
  public selectedPackage: string = 'none';
  public packageData: object[] = [];
  public tabs: number = 0;
  public messages: object[] = [];
  public displayError: object = {};

  public errorList: object[] = [];
  public successList: object[] = [];

  public created() {
    this.fillMenu();
  }

  //fetches all api packages and stores them in usable variable
  private async fillMenu() {
    let data = await getAllPackageWithSystemApi();
    Object.keys(data).forEach((current, index) => {
      this.packages.push({
        'text': current,
        'id': index
      })
    })
    console.log(data)
    console.log(this.packages)
  }

  public clearStatus() {
    this.messages = [];
    this.packageData = [];
    this.selectedPackage = 'none';
  }


  public clearErrorAndSuccess(): void {
    this.errorList = [];
    this.successList = [];
  }

  public submitForm() {
    //clear all previous messages and errors/ success
    this.messages = [];
    this.clearErrorAndSuccess();

    //do actual update
    this.doUpateRequest()
  }

  private async doUpateRequest() {
    let url = 'base/settings-api-packages/' + this.selectedPackage
    let response = (await postRequest(url, this.packageData)).data
    let responseDetails = 'request' in response ? JSON.parse(response.request.response) : response;

    //create general error message
    this.messages.push({
      title: responseDetails.title,
      type: responseDetails.type,
      message: responseDetails.message ?? responseDetails.detail,
      popup: responseDetails.popup ?? 'info',
    });

    //add each failed field to list of error fields: this.errorList
    if (responseDetails.status === 400) {
      //something went wrong
      if ('validation' in responseDetails) {
        //count all error(s) / failed field(s)
        let countOfFailedFields = responseDetails.validation.length ?? 0;


        console.log(countOfFailedFields);

        //errors during validation
        for (let [key, value] of Object.entries(responseDetails.validation)) {
          this.errorList.push({
            key: key,
            value: value
          })
        }
      }
    }

    if (responseDetails.status === '200') {
      //find all fields with id of items in success array and set
      //success

      console.log('response 200')
      responseDetails.success.forEach((id: string) => {
        this.successList.push({
          key: id,
        })
      })
      console.log('pushed all success fields to array')

    }
  }

  //gets specific api configuration
  private async fetchPackage(newPackage: string) {
    console.log('this is the current package which will be fetched: ' + newPackage)
    this.isLoading = true;
    this.isWelcomePage = false;
    this.selectedPackage = newPackage;
    let response = await getSettings(newPackage);

    let test = response;
    console.log(response)

    this.packageData = test;

    // console.log('packageData object:')


    this.isLoading = false;
  }

  public cancelPackageUpdate() {
    //set all values to 0
    this.isLoading = true;

    this.data = {};
    this.selectedPackage = 'none';
    this.packageData = [];
    this.tabs = 0;
    this.isWelcomePage = true;


    this.isLoading = false;
  }

  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
}
