
import Vue from "vue";

import { Component } from "vue-property-decorator";
import { getInvoices, updateInvoice } from "../../services/catalog-service";
import { DataTableHeader } from "vuetify";
import { IInvoice } from "../../models/invoice";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { AxiosError } from "axios";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";

@Component({
  components: {
    PazTooltipButton,
    PazToast,
  },
})
export default class InvoicesListing extends Vue {
  $refs!: {
    toast: PazToast;
  };
  public search: string = "";
  public headers: DataTableHeader[] = [
    {
      text: "id",
      align: "start",
      sortable: true,
      value: "id",
    },
    {
      text: "Order",
      align: "start",
      sortable: true,
      value: "orderId",
    },
    {
      text: "invoice number",
      align: "center",
      sortable: true,
      value: "invoiceNumber",
    },
    {
      text: "reference",
      align: "center",
      sortable: true,
      value: "reference",
    },
    {
      text: "description",
      align: "center",
      sortable: true,
      value: "description",
    },
    {
      text: "customer",
      align: "center",
      sortable: true,
      value: "customer",
    },
    {
      text: "invoice date",
      align: "center",
      sortable: true,
      value: "invoiceDate",
    },
    {
      text: "tax",
      align: "center",
      sortable: true,
      value: "tax",
    },
    {
      text: "total",
      align: "center",
      sortable: true,
      value: "total",
    },
    {
      text: "status",
      align: "center",
      sortable: true,
      value: "status",
    },
    {
      text: "Actions",
      align: "center",
      sortable: false,
      value: "actions",
    },
  ];
  public items: IInvoice[] = [];
  public loading: boolean = true;
  public dialog: boolean = false;

  linkToOrder(orderReference: string) {
    this.$router.push({name: 'orders', query: {'orderReference': orderReference}});
  }

  mounted() {
    this.fetchData();
  }

  async fetchData() {
    this.items = await getInvoices();
    this.loading = false;
  }

  async onRetryClick(invoice: IInvoice) {
    try {
      this.loading = true;
      let data = {
        id: invoice.id,
        status: "NEW",
      };
      const response = await this.updateInvoice(data);
      this.$refs.toast.show({ message: `Invoice #${response.id} retried` });
      this.fetchData();
    } catch (error) {
      let response = (error as AxiosError).response;
      if (response && response.status !== 500) {
        this.$refs.toast.show({
          message: response.data.message,
          color: "red",
          icon: "mdi-close-circle",
        });
        this.loading = false;
        return;
      }
      this.loading = false;
      this.$refs.toast.show({
        message: "An error occurred, please contact the administrator",
        icon: "mdi-close-circle",
        color: "red",
      });
    }
  }

  isInvoiceStatusNotFailed(invoice: IInvoice): boolean {
    return invoice.status === "FAILED";
  }
  async updateInvoice(data: any) {
    return await updateInvoice(data);
  }
  formatDate(date: string): string {
    if (date == null) {
      return "";
    }
    return new Date(date).toLocaleDateString("nl-nl", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  get getTableHeaders() {
    return !this.$contractManagement ? this.headers.filter(h => h && !['customer','tax','total'].includes(h.value)) : this.headers
  }

}
