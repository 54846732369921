
import Vue from "vue";
import { Component } from "vue-property-decorator";
import QueueDataTable from "./components/QueueDataTable.vue";
import PazTextSearch from "../../components/controls/PazTextSearch.vue";
import PazRefreshButton from "../../components/controls/PazRefreshButton.vue";
import { ALL_STATES, IQueueJobState } from "./components/QueueJobState.vue";
import {
  executeCommand,
  getCommandList,
  getQueueJobs,
} from "../../services/auth-service";
import PazCommandSelector from "./components/PazCommandSelector.vue";
import PazListFilterSelector from "../../components/utils/PazListFilterSelector.vue";
import PazTooltipButton from "../../components/controls/PazTooltipButton.vue";
import QueueTableFilterPanel from './components/QueueTableFiltersPanel.vue'

interface ICache {
  [key: string]: boolean;
}

interface IBulkOption {
  action: string;
  option: number;
  isRecurringOption: string;
}

export const retryMap: ICache = {};
export const cancelMap: ICache = {};
export const inProgressMap: ICache = {};

ALL_STATES.forEach((state) => {
  retryMap[state.nameTech] = state.retry;
  cancelMap[state.nameTech] = state.cancel;
  inProgressMap[state.nameTech] = state.inProgress ?? false;
});

@Component({
  components: {
    PazRefreshButton,
    QueueDataTable,
    PazTextSearch,
    PazCommandSelector,
    PazListFilterSelector,
    PazTooltipButton,
    QueueTableFilterPanel
  },
})
export default class QueueListing extends Vue {
  public selectedStatus: IQueueJobState[] = [];
  public bulkOptions: IBulkOption[] = [
    { action: "Retry", option: 1, isRecurringOption: "Both" },
    { action: "Cancel", option: 2, isRecurringOption: "No" },
    { action: "Pause", option: 2, isRecurringOption: "Yes" },
  ];

  $refs!: {
    nonRecurringItems: QueueDataTable;
    recurringItems: QueueDataTable;
  };

  public nonRecurringJobsTab: boolean = true;
  public search: string = "";
  public items: object[] = [];
  public loading: boolean = true;
  public showcommandDialog: boolean = false;

  public selectedWorker: String[]  = [];
  public selectedDelegate: String[] = [];

  async fetchData() {
    this.loading = true;
    this.items = await getQueueJobs();
    
    this.loading = false;
  }

  created() {
    this.fetchData();
  }

  check(action: string) {
    if (this.nonRecurringJobsTab) {
      this.$refs.nonRecurringItems.checkSelection(action);
    }
    this.$refs.recurringItems.checkSelection(action);
  }

  toggle() {
    this.$nextTick(() => {
      if (this.allSelected) {
        this.selectedStatus = [];
      } else {
        this.selectedStatus = ALL_STATES.slice();
      }
    });
  }

  async showCommandList() {
    this.showcommandDialog = true;
  }

  setFilter(filter: {key: string, value: any}) {
    this[filter.key] = filter.value;
  }

  get nothingSelected() {
    if (this.loading) {
      return this.loading;
    }

    if (this.nonRecurringJobsTab) {
      return (this.$refs.nonRecurringItems?.getSelectedItems.length || 0) == 0;
    }
    return (this.$refs.recurringItems?.getSelectedItems.length || 0) == 0;
  }

  get allSelected(): boolean {
    return this.selectedStatus && this.selectedStatus.length === ALL_STATES.length;
  }

  get someSelected(): boolean {
    return (
      (this.selectedStatus.length > 0 || this.selectedWorker.length > 0) &&
      !this.allSelected
    );
  }

  get dropdownIcon(): string {
    if (this.allSelected) {
      return "mdi-close-box";
    }
    if (this.someSelected) {
      return "mdi-minus-box";
    }
    return "mdi-checkbox-blank-outline";
  }

  get nonRecurringItems() {
    let items = this.items.filter((item) => !item.isRecurring);

    if (this.selectedStatus.length > 0) {
      items = items.filter((item) => this.selectedStatus.includes(item.status));
    }

    if (this.selectedWorker.length > 0) {
      items = items.filter((item) => this.selectedWorker.includes(item.worker));
    }

    if(this.selectedDelegate.length > 0) {
     items = items.filter((item) => this.selectedDelegate.includes(item.delegate));
    }
    return items;
  }

  get recurringItems() {
    let items = this.items.filter((item) => item.isRecurring);

    if (this.selectedStatus.length > 0) {
      items = items.filter((item) => this.selectedStatus.includes(item.status));
    }

    if (this.selectedWorker.length > 0) {
      items = items.filter((item) => this.selectedWorker.includes(item.worker));
    }

    if(this.selectedDelegate.length > 0) {
     items = items.filter((item) => this.selectedDelegate.includes(item.delegate));
    }
    return items;
  }

  get getJobworkers() {
    return (
      this.items &&
      this.items.map((j: any) => {
        return j.worker
          && { nameTech: j.worker, label: j.worker }
      })
    );
  }

  get getDelegateOptions() {
    return (
      this.items &&
      this.items.map((j: any) => {
        return { nameTech: j.delegate, label: j.delegate };
      })
    );
  }

  get filteredNonRecurringOptions() {
    return ALL_STATES.filter(
      (item) =>
        item.isRecurringOption === "No" || item.isRecurringOption === "Both"
    );
  }

  get filteredRecurringOptions() {
    return ALL_STATES.filter(
      (item) =>
        item.isRecurringOption === "Yes" || item.isRecurringOption === "Both"
    );
  }

  get filteredBulkOptions() {
    if (this.nonRecurringJobsTab) {
      return this.bulkOptions.filter(
        (item) =>
          item.isRecurringOption === "No" || item.isRecurringOption === "Both"
      );
    }
    return this.bulkOptions.filter(
      (item) =>
        item.isRecurringOption === "Yes" || item.isRecurringOption === "Both"
    );
  }
}
