
import { Component, Mixins, Prop } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { formatDateTime } from "../../../services/base-service";
import { IDossierOneFile } from "../../../models/dossier.model";
import DossierDocsMixin from "../mixins/dossier-docs.mixin";

@Component({
  components: {
    PazLoader,
  },
})
export default class DossierDocumentItem extends Mixins(DossierDocsMixin) {
  @Prop() public doc!: IDossierOneFile;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: false }) public isEdit: boolean;
  @Prop() public getFullInfo?: boolean;
  @Prop() public hasPreview?: boolean;

  public showDocPreview: boolean = false;
  public fileData: string | null = null;

  actionView() {
    this.showDocPreview = true;
  }

  actionDownLoad() {
    window.location.href = this.docFullUrl;
  }

  get docFullUrl() {
    return this.fileData;
  }

  get getFileIcon() {
    switch (this.doc.fileExtension) {
      case "pdf":
        return "mdi-file-pdf-box";
      case "doc":
        return "mdi-microsoft-word";
    }
    return "mdi-file-document-outline";
  }

  get getUploadedDate() {
    return formatDateTime(
      this.doc.createdAt.date,
      this.doc.createdAt.timezone,
      "YYYY-MM-DD, HH:mm"
    );
  }

  /**
   * if getFullInfo - file data(base64) fetch immediately on component mount
   * else by click icon preparing file
   */
  preparingFileData(docId: number) {
    this.fetchFileData(docId);
  }

  async fetchFileData(docId: number) {
    this.fileData = await this.getFileData(this.doc.id);
    this.$nextTick(() => {
      this.$refs.referral && this.$refs.referral.click();
    });
  }

  mounted() {
    if (this.getFullInfo) this.fetchFileData();
  }
}
