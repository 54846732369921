
import { Component, Vue, Prop } from "vue-property-decorator";
import { getUsers } from "../../../services/auth-service";
import { IUserLisApiModel } from "../../../models/user-models";
import DossierUserFilesPanel from "./parts/DossierUserFilesPanel.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import FileUploader from "../../../components/common/FileUploader.vue";
import {
  uploadDossierFile,
  deleteDossierFile,
} from "../../../services/dossier-service";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import { IDossierOneFile } from "../../../models/dossier.model";

@Component({
  components: {
    DossierUserFilesPanel,
    PazTooltipButton,
    FileUploader,
    PazToast,
    PazConfirmation,
  },
})
export default class DossierPersonalListApp extends Vue {
  public $refs!: {
    toast: PazToast;
    personalListAppConfirmation: PazConfirmation;
  };

  @Prop({ default: false }) isUserAdmin!: boolean;
  @Prop({ default: null }) currentUser!: object;

  public expandPanel = [];
  public users = [] as IUserLisApiModel[];
  public loading: boolean = false;
  public selectedUser: IUserLisApiModel | null = null;
  public acceptedDocFormats: string =
    ".xlsx, .xls, .doc, .docx, application/msword, application/vnd.ms-excel,text/plain, application/pdf";
  public rules = [
    (value) =>
      !value || value.size < 20000000 || "File size should be less than 20 MB!",
  ];
  public search: string = "";

  updateExpandUser(user: IUserLisApiModel) {
    this.selectedUser = user;
  }

  async deleteDossierFile(file: IDossierOneFile) {
    const confirm = await this.$refs.personalListAppConfirmation.openConfirmDialog(
      "Delete",
      `Do you want to delete file: ${file.fileName}?`
    );
    if (!confirm) return;
    const result = await deleteDossierFile(file?.id);
    if (!result) {
      this.$refs.toast.show({
        message: "File hasn't been deleted",
        color: "red",
      });
      return;
    }
    this.getUsers();
  }

  async uploadFile(fileData: File, user: IUserLisApiModel) {
    const response = await uploadDossierFile(
      undefined,
      fileData,
      "",
      "",
      0,
      user.id
    );
    if (response && response.success) {
      this.$refs.toast.show({
        message: "Success! The file has been uploaded",
        color: "green",
      });
      // this.$refs.userFilesComponent.getFilesByUser();
      this.updateExpandUser(user);
      this.getUsers();
    } else {
      this.$refs.toast.show({
        message: response?.response?.data?.detail,
        color: "red",
      });
    }
  }

  async getUsers() {
    if (!this.isUserAdmin) return;
    this.loading = true;
    const queryParams = { filter: JSON.stringify({ isDeleted: 0 }) };
    this.users = await getUsers(queryParams, undefined);
    this.loading = false;
  }

  get usersArray() {
    return this.users && this.users?.length > 0 && this.search.length > 0
      ? this.users.filter((u) => {
          return u ? u.name.toLowerCase().includes(this.search) : undefined;
        })
      : this.users;
  }

  mounted() {
    this.getUsers();
  }
} //
