import { getConfigSettings } from '../services/base-service';
import { removeRouteByName, resetRouter } from '../router';
import _Vue from 'vue';
import {addChildRoute} from "@pazion/pazion-core/src/router";

import SettingsPage from "@pazion/pazion-core/src/views/settings/SettingsPage.vue"
import { IProjectSettings } from '@pazion/pazion-core/src/models/config_variables';
import { setConfigLocal } from '../models/config_variables';


function setProtoVariables(settings: IProjectSettings, Vue: typeof _Vue) {

  if (settings && 'config' in settings) {
    localStorage.setItem('projectSettings', JSON.stringify(settings));
    for (let option in settings.featureFlags) {
      Vue.prototype[`$${option}`] = settings.featureFlags[option];
    }
    for (let option in settings.config) {
      Vue.prototype[`$${option}`] = settings.config[option]
    }
    deleteRoutersPerSettings(Vue);

    Vue.prototype.apiPackage = {};
    for (let option in settings.apiPackage) {
      Vue.prototype.apiPackage[`$${option}`] = settings.apiPackage[option]
    }
    addApiPackageSettings(Vue);

  }
}

function deleteRoutersPerSettings(Vue: typeof _Vue) {

  if (Vue.prototype.$stockManagement != true)
    removeRouteByName('stock');

  if (Vue.prototype.$productManagement != true)
    removeRouteByName('products');

  if (Vue.prototype.$dossierManagement != true)
    removeRouteByName('dossier');

  if (Vue.prototype.$accountManagement != true)
    removeRouteByName('accounts');

  if (Vue.prototype.$invoiceManagement != true)
    removeRouteByName('invoices');

  if (Vue.prototype.$orderManagement != true)
    removeRouteByName('orders');    

  if (Vue.prototype.$contractManagement != true)
    removeRouteByName('contracts');

  if (Vue.prototype.$transactionManagement != true)
    removeRouteByName('transactions');

  if (Vue.prototype.$productionManagement != true)
    removeRouteByName('productions');

  if (Vue.prototype.$offerManagement != true)
    removeRouteByName('offers');

  if (Vue.prototype.$returnManagement != true)
    removeRouteByName("returns");

  if (Vue.prototype.$logManagement != true)
    removeRouteByName('logs');

  if (Vue.prototype.$queueManagement != true)
    removeRouteByName('queue');

  if (Vue.prototype.$orderManagement != true)
    removeRouteByName('orders');

  if (Vue.prototype.$membersCardManagement != true)
    removeRouteByName('cards');

  if (Vue.prototype.$membersCardManagement != true)
    removeRouteByName('card members');

  if (Vue.prototype.$membersCardManagement != true)
    removeRouteByName('card design');
  
  //rewrite our route array
  resetRouter();
}

function addApiPackageSettings(Vue: typeof _Vue) {

    // todo move to vue frontend pazion-catalog package module-config.ts.
    // check local Settings.vue ( import)
    // be able to add extra settings menu items ?
    /*
    addChildRoute("settings",    {
            path: "/api-package-settings",
            name: "API-packages",
            component: Settings,
	    meta: {
	    'hasDrawer': true,
                "show-in-nav": true,
                roles: ["user"],
                sort: 800
            }
        }
    );*/

    //TEMP backend routes, unitl we have generic app-api-setting
    // ( maybe make loop and make more generic?! )
    if (Vue.prototype.apiPackage.$amazonSettings == true){
        addChildRoute("settings", {
            path: "amazon/settings",
            name: "amazon",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }

    if (Vue.prototype.apiPackage.$boldotcomSettings == true){
        addChildRoute("settings", {
            path: "boldotcom/settings",
            name: "boldotcom",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$dynamicsSettings == true){
        addChildRoute("settings", {
            path: "dynamics/settings",
            name: "dynamics",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$exactonlineSettings == true){
        addChildRoute("settings", {
            path: "exactonline/settings",
            name: "exactonline",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$opencartSettings == true){
        addChildRoute("settings", {
            path: "opencart/settings",
            name: "opencart",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$woocommerceSettings == true){
        addChildRoute("settings", {
            path: "woocommerce/settings",
            name: "woocommerce",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$bumbalSettings == true){
        addChildRoute("settings", {
            path: "bumbal/settings",
            name: "bumbal",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"]
            }
        });
    }
    if (Vue.prototype.apiPackage.$postnlSettings == true){
        addChildRoute("settings", {
            path: "postnl/settings",
            name: "postnl",
            component: SettingsPage,
            meta: {
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"],
                sort: 800
            }
        });
    }
    if (Vue.prototype.apiPackage.$devPackage == true){
        addChildRoute("settings", {
            path: "dev/doc",
            name: "docs",
            component: SettingsPage,
	    meta: {
                "icon": 'mdi-book-open-page-variant',
                "show-in-nav": true,
                "redirect-to-backend": true,
                roles: ["user"],
                sort: 900
            }
        });
    }

}

export async function setConfigSettings(Vue: typeof _Vue):Promise<void> {
  setConfigLocal(Vue)
  let cacheSettings = localStorage.getItem('projectSettings');
  if (cacheSettings) {
    setProtoVariables(JSON.parse(cacheSettings), Vue);
    return
  } else {
    const serverSettings = await getConfigSettings();
    setProtoVariables(serverSettings, Vue);
  }
};

export function clearSettings () {
  localStorage.removeItem('projectSettings');
};
