
import { Component, Mixins, Prop } from "vue-property-decorator";
import DossierDocPreview from "../../../components/dialog/pazDossierDocViewer.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { formatDateTime } from "../../../services/base-service";
import { IDossierOneFile } from "../../../models/dossier.model";
import DossierDocsMixin from "../../dossier/mixins/dossier-docs.mixin";

@Component({
  components: {
    DossierDocPreview,
    PazLoader,
  },
})
export default class DossierDocumentViewer extends Mixins(DossierDocsMixin) {
  @Prop() public doc!: IDossierOneFile;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: false }) public isEdit: boolean;
  @Prop() public getFullInfo?: boolean;
  @Prop() public hasPreview?: boolean;
  @Prop({default: false}) public hasDeleteFile?: boolean;

  public showDocPreview: boolean = false;
  public fileData: string | null = null;

  actionView() {
    this.showDocPreview = true;
  }

  actionDownLoad() {
    window.location.href = this.docFullUrl;
  }

  actionDelete() {
    this.$emit('onDeleteFile', this.doc);
  }

  get docFullUrl() {
    return this.fileData;
  }

  get getFileIcon() {
    switch (this.doc.fileExtension) {
      case "pdf":
        return "mdi-file-pdf-box";
      case "doc":
        return "mdi-microsoft-word";
    }
    return "mdi-file-document-outline";
  }

  get getUploadedDate() {
    return formatDateTime(
      this.doc.createdAt.date,
      this.doc.createdAt.timezone,
      "YYYY-MM-DD, HH:mm"
    );
  }

  get hasDescription() {
    return this.doc?.description.length > 0
  }

  async fetchFileData() {
    this.fileData = await this.getFileData(this.doc.id);
  }

  mounted() {
    if (this.getFullInfo) this.fetchFileData();
  }
}
