
import Vue from 'vue';

import {Component} from 'vue-property-decorator';
import {getCatalogDashboardDetails} from "../../services/catalog-service";
import LineChart from "../../components/chart/LineChart.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";

export interface IDashboardinfo {
  orderDayCount: string
  orderMonthCount: string
  shipmentsMonthCount: string
  returnsMonthPercent: string
  salesLastWeekLabel: string
  salesThisWeekLabel: string
  shipmentsCountEnabled: boolean
  returnsCountEnabled: boolean
  salesThisWeekLegend: Array<string>
  salesThisWeekValues: Array<string>
  salesLastWeekValues: Array<string>
  salesCount: Array<string>
}

@Component({
  components: {LineChart, PazRefreshButton}
})
export default class CatalogDashboard extends Vue {

  public data: object = {};
  public loading: boolean = true;
  public ordersTodayCount: number = 0;
  public orderMonthCount: number = 0;
  public shipmentsCountEnabled: boolean = false;
  public returnsCountEnabled: boolean = false;
  public shipmentsMonthCount: number = 0;
  public returnsMonthPercent: number = 0;
  public salesCount: Array<string> = [];

  async fetchData() {
    this.loading = true;
    const info: IDashboardinfo = await getCatalogDashboardDetails();

    this.ordersTodayCount = parseInt(info.orderDayCount);
    this.orderMonthCount = parseInt(info.orderMonthCount);
    this.shipmentsMonthCount = parseInt(info.shipmentsMonthCount);
    this.returnsMonthPercent = parseInt(info.returnsMonthPercent);
    this.shipmentsCountEnabled = info.shipmentsCountEnabled;
    this.returnsCountEnabled = info.returnsCountEnabled;

    this.salesCount = info.salesCount;

    this.data = {
      type: 'line',
      data: {
        labels: info.salesThisWeekLegend,
        datasets: [{
          label: info.salesThisWeekLabel,
          backgroundColor: '#01aff0',
          borderColor: '#01aff0',
          data: info.salesThisWeekValues,
          fill: false,
        }, {
          label: info.salesLastWeekLabel,
          backgroundColor: '#4eab77',
          borderColor: '#4eab77',
          data: info.salesLastWeekValues,
          fill: false,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Orderregels per dag afgelopen 7 dagen & de vorige week'
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Week'
            }
          },
          y: {
            min: 0,
            title: {
              display: true,
              text: 'Omzet in euro\'s'
            },
            ticks: {
              precision: 2
            }
          }
        }
      }
    }
    this.loading = false;
  }

  get monthName() {

    const objDate = new Date();
    objDate.toLocaleDateString();
    const locale = navigator.language || "nl-nl";
    return objDate.toLocaleString(locale, {month: "long"});
  }

  mounted() {
    this.fetchData();
  }

  drawChart() {

  }
}
