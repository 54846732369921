import {getRequest} from "@pazion/pazion-core/src/services/auth-service";

export async function getAllPackageWithSystemApi() {
    return (await getRequest('base/settings-api-packages')).data;
}

export async function getSettings(module?: string) {

    //doe hier een try catch als je desperate bent -maoro
    let url = "base/settings-api-packages";
    if (module) {
        url += '/' + module;
    }
    return (await getRequest(url)).data;

}