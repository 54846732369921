
import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";

@Component({
  components: {
    PazTooltipButton
  }
})
export default class PazInputConfirmTextField extends Vue {


  public selectedValue: string = "";
  @Prop({default: ''})
  public originalValue!: string;

  @Prop({default: false})
  public loading!: boolean;

  @Emit()
  public async saveItem() {
    return this.selectedValue;
  }

  get isDisabled() {
    return this.selectedValue === this.originalValue;
  }

  @Watch('originalValue')
  updateSelectedValue(val: string, old: string) {
    if (val !== old) {
      this.selectedValue = val;
    }
    else{
    }
  }
  mounted(){
    this.selectedValue = this.originalValue;
  }

}
