import { deleteRequest, getRequest, putRequest, postRequest, patchRequest } from '@pazion/pazion-core/src/services/auth-service';
import { stringifyUrl } from 'query-string';
import { ContractLineFrequency, IBlancInvoice, ContractRegistration } from '../models/contracts.model';

const basePath = 'catalog';

/** SECTION: CONTRACTS **/
/** Get contracts
 * @queryParams { filter: {id: 1, isDeleted":0}, expand: ''
 * @parm contractId: 267
 */
export async function getContracts(queryParams?: object, contractId?: number) {
  const fullUrl = !contractId ? `${basePath}/contracts` : `${basePath}/contracts/${contractId}`;
  const ParamsUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(ParamsUrl)).data;
}


  export async function getRemindersTypes() {
    return (await getRequest('contract-reminders')).data;
  }

/** Create contract
 * @param data
 */
export async function createContract(data: object) {
  return (await postRequest(`${basePath}/contracts`, data)).data;
}

export async function updateContract(id:number, data: object) {
  return (await putRequest(`${basePath}/contracts/${id}`, data)).data;
}

/** Delete contract
 * @param contractId: 267
*/
export async function deleteContract(contractId: number) {
  return (await deleteRequest(`${basePath}/contracts/${contractId}`)).data;
}

/** SECTION: CONTRACT LINE **/

/** Create contract line
 * @param data: object
 */
export async function createContractLine(data: object) {
  return (await postRequest(`${basePath}/contract-lines`, data)).data;
}

/** Update contract line
 * @param contractAssetId: 2
 */
export async function updateContractLine(contractAssetId: number, data: object) {
  return (await putRequest(`${basePath}/contract-lines/${contractAssetId}`, data)).data;
}

/** Delete contract line
 * @param contractAssetId: 2
 */
export async function deleteContractLine(contractAssetId?: number) {
  return (await deleteRequest(`${basePath}/contract-lines/${contractAssetId}`)).data;
}

export async function getClLineFrequencies(): Promise<ContractLineFrequency[]> {
  return (await getRequest(`${basePath}/contract-line-frequencies`)).data;
}

/** SECTION: CONTRACT ASSETS **/
/**
 * Get contract asset details
 * @param assetId: number
 */
export async function getAssetDetails(queryParam: object, assetId?: number) {
  const fullUrl = !assetId ? `${basePath}/contract-assets` : `${basePath}/contract-assets/${assetId}`
  const ParamsUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParam
    }
  })
  return (await getRequest(ParamsUrl)).data;
}

/**
 * Get Contract assets types
 */
export async function getContractAssetTypes() {
  return (await getRequest(`${basePath}/contract-asset-types`)).data;
}

/** Create contract asset
 * @param data: object
 */
export async function createContractAsset(data: object) {
  return (await postRequest(`${basePath}/contract-assets`, data)).data;
}

/** Update contract asset
 * @param contractAssetId: 2
 */
export async function updateContractAsset(contractAssetId: number, data: object) {
  return (await putRequest(`${basePath}/contract-assets/${contractAssetId}`, data)).data;
}

/** Delete contract asset
 * @param contractAssetId: 2
 */
export async function deleteContractAsset(contractAssetId?: number) {
  return (await deleteRequest(`${basePath}/contract-assets/${contractAssetId}`)).data;
}

/**!SECTION Transactions */
/**
 * Get Transactions table
*/
export async function getTransactions(queryParam: object) {
  const fullUrl = `${basePath}/transactions`;
  const ParamsUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParam
    }
  })
  return (await getRequest(ParamsUrl)).data;
} 
/** 
 * GET Transaction subject 
 */
export async function getTransactionSubject() {
  return (await getRequest(`${basePath}/transaction-subjects`)).data;
}

/** 
 * GET Transaction mutations
 */
export async function getTransactionMutations(queryParam: object) {
  const fullUrl = `${basePath}/transaction-mutations`;
  const ParamsUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParam
    }
  })
  return (await getRequest(ParamsUrl)).data;
}

/**
 * Mutate start count per subject into Asset 
 * @Param data: ContractRegistration
 * */
export async function setSubjectCountMutation(id: number, data: object) {
  return (await putRequest(`${basePath}/contract-assets/${id}`, data)).data;
}


/** SECTION: Invoices */
/**
 * @returns Get invoices table
 */
export async function getInvoicesTable() {
  return (await getRequest(`/generate-invoices`)).data;
}

/**
 * Generate invoices
 */
export async function createInvoices(data: IBlancInvoice) {
  return (await postRequest(`/generate-invoices`, data)).data;
}



















