
import {Component, Prop} from "vue-property-decorator";
import DataDialog from "../../../components/utils/DataDialog.vue";
import {retryMap, inProgressMap, cancelMap} from "../QueueListing.vue";
import Vue from "vue";
import QueueJobState from "./QueueJobState.vue";
import {patchRequest} from "../../../services/auth-service";
import PazTooltipButton from "../../../components/controls/PazTooltipButton.vue";
import {DataTableHeader} from "vuetify";
import {IQueue} from "../../../models/queue";

@Component({
  components: {
    DataDialog,
    QueueJobState,
    PazTooltipButton
  }
})

export default class QueueDataTable extends Vue {

  @Prop()
  public search = '';

  public extraInfo: object = {};
  public dialog: boolean = false;
  public response = '';
  public selected: IQueue[] = [];
  public retryJobCheck: boolean = false;
  public confirmationDialog: boolean = false;
  public confirmationInfoShow: boolean = false;
  public confirmationInfoCheck: IQueue[] = [];
  public confirmationInfoOption: string = '';
  public confirmationInfoStatus: string = '';
  public optionSelected: number = 1;
  public bulkSelect: IQueue[] = [];
  public headers: DataTableHeader[] = [

    {
      text: 'id',
      align: 'start',
      value: 'id',
      sortable: true,
      width: '5%',
      class: 'nowrap',
    },
    {
      text: 'type',
      align: 'start',
      value: 'delegate',
      sortable: true,
      width: '15%',
      class: 'nowrap',
    },
    {
      text: 'worker',
      align: 'start',
      value: 'worker',
      sortable: true,
      width: '10%',
      class: 'nowrap',
    },
    {
      text: 'status',
      align: 'start',
      value: 'status',
      sortable: true,
      width: '10%',
      class: 'nowrap',
    },
    {
      text: 'description',
      class: 'nowrap',
      cellClass: 'elipse',
      align: 'start',
      value: 'notes',
      sortable: true,
      width: '30%',
    },
    {
      text: 'last run',
      align: 'start',
      value: 'lastStartAt',
      class: 'nowrap',
      sortable: true,
      width: '15%',
    },
    {
        text: 'next run',
        align: 'start',
        value: 'formatted.nextRun',
        sortable: true,
        width: '10%',
    },
    {
      text: 'created at',
      align: 'start',
      value: 'createdAt',
      class: 'nowrap',
      sortable: true,
      width: '15%',
    },
    {
      text: 'execution',
      class: 'nowrap',
      align: 'end',
      cellClass: 'nowrap',
      value: 'execution',
      sortable: true,
      width: '15%',
    },
    {
      text: '',
      class: 'nowrap',
      cellClass: 'nowrap px-0',
      align: 'end',
      value: 'action',
      sortable: true,
      width: '10%',
    }
  ];
  public jobsNonRecurring: DataTableHeader[] = [
    {
      text: 'id',
      align: 'start',
      value: 'id',
      sortable: true,
      width: '5%',
    },
    {
      text: 'type',
      align: 'start',
      value: 'type',
      sortable: true,
      width: '15%',
    },
    {
      text: 'worker',
      align: 'start',
      value: 'worker',
      sortable: true,
      width: '10%',
    },
    {
      text: 'status',
      align: 'start',
      value: 'status',
      sortable: true,
      width: '10%',
    },
    {
      text: 'description',
      cellClass: 'elipse',
      align: 'start',
      value: 'notes',
      sortable: true,
      width: '30%',
    },
    {
      text: 'last run',
      align: 'start',
      value: 'lastStartAt',
      sortable: true,
      width: '15%',
    },
    {
      text: 'created at',
      align: 'start',
      value: 'createdAt',
      sortable: true,
      width: '15%',
    },
    {
      text: 'Execution time',
      class: 'nowrap',
      align: 'end',
      cellClass: 'nowrap',
      value: 'execution',
      sortable: true,
      width: '15%',
    },
    {
      text: '',
      class: 'nowrap',
      cellClass: 'nowrap',
      align: 'end',
      value: 'action',
      sortable: true,
      width: '10%',
    },
  ];

  public jobsRecurring: DataTableHeader[] = [
    {
      text: 'id',
      align: 'start',
      value: 'id',
      sortable: true,
      width: '5%',
    },
    {
      text: 'worker',
      align: 'start',
      value: 'worker',
      sortable: true,
      width: '10%',
    },
    {
      text: 'interval',
      align: 'start',
      value: 'interval',
      sortable: true,
      width: '10%',
    },
    {
      text: 'status',
      align: 'start',
      value: 'status',
      sortable: true,
      width: '10%',
    },
    {
      text: 'description',
      align: 'start',
      value: 'notes',
      sortable: true,
      width: '35%',
    },
    {
      text: 'last run',
      class: 'nowrap',
      cellClass: 'nowrap',
      align: 'start',
      value: 'lastStartAt',
      sortable: true,
      width: '10%',
    },
    {
      text: 'next run',
      class: 'nowrap',
      cellClass: 'nowrap',
      align: 'start',
      value: 'nextRun',
      sortable: true,
      width: '10%',
    },
    {
      text: '',
      class: 'nowrap',
      cellClass: 'nowrap',
      align: 'end',
      value: 'action',
      sortable: true,
      width: '10%',
    },
  ];

  @Prop({
    default: () => {
    }
  })
  public items!: object[];

  @Prop({
    default: false
  })
  public isRecurring!: boolean;

  @Prop({default: true})
  public loading!: boolean;
  public internalLoading: boolean = false;

  get getHeaders() {
    if (this.isRecurring) {
      return this.jobsRecurring;
    }
    return this.jobsNonRecurring;
  }

  formatDelegate(item: IQueue) {
    return item.delegate.split('\\').pop();
  }

  isEmpty(item: IQueue) {
    return item.jsonInput == undefined || Array.isArray(item.jsonInput);
  }
  showInfo(item: IQueue) {
    if (Array.isArray(item.jsonInput) || item.jsonInput === null) {
        return;
    }
    this.extraInfo = {
      'id': item.id,
      'notes': item.notes,
      'worker': item.worker,
      'input': item.jsonInput,
      'output': item.jsonOutput,
      'status': item.status
    };
    this.dialog = true;
  }

  checkRetryDisabled(item: IQueue) {
    if (retryMap.hasOwnProperty(item.status)) {
      return retryMap[item.status];
    }
  }

  checkCancelDisabled(item: IQueue) {
    if (cancelMap.hasOwnProperty(item.status)) {
      return cancelMap[item.status];
    }
  }

  async changeStatus(selected) {
    this.internalLoading = true;
    this.response = await patchRequest('job-status', selected);
    this.statusUpdated(this.response);
    this.internalLoading = false;
  }

  checkJobStatus(selection: IQueue[], option: number) {

    if (!Array.isArray(selection) || !selection.length) {
      return;
    }

    if (selection.find(element => inProgressMap[element.status] === true)) {
      this.confirmationInfoShow = true;
      this.confirmationInfoCheck = selection;
      this.confirmationInfoOption = option;
      this.confirmationDialog = true;
    } else {
      this.changeJobStatus(selection, option);
    }

  }

  get getSelectedItems() {
    return this.selected;
  }

  checkSelection(action: string) {

    if (!Array.isArray(this.bulkSelect) || !this.bulkSelect.length) {
      return;
    }

    if (action === 'Retry') {
      if (this.bulkSelect.find(element => retryMap[element.status] === false)) {
        this.confirmationInfoShow = true;
        this.confirmationDialog = true;
        this.retryJobCheck = true;
        this.confirmationInfoStatus = 'Retry';
      } else {
        this.checkJobStatus(this.bulkSelect, 1);
      }
    } else if (action === 'Cancel' || action === 'Pause') {
      if (this.bulkSelect.find(element => cancelMap[element.status] === false)) {
        this.confirmationInfoShow = true;
        this.confirmationDialog = true;
        this.retryJobCheck = true;
        this.confirmationInfoStatus = 'Cancel';
      } else {
        this.checkJobStatus(this.bulkSelect, 2);
      }
    }
  }

  changeJobStatus(selected: IQueue[], option) {

    this.confirmationDialog = false;

    selected.forEach(element => this.setJobStatus(element, option));

    this.changeStatus(selected);

    this.optionSelected = option;
    this.selected = [];
    this.retryJobCheck = false;

    return true;

  }

  setJobStatus(item, option) {

    item.status = 'START__QUEUED_FOR_RETRY';

    if (option === 2) {
      item.status = 'ERROR__CANCELED';
    }

  }

  statusUpdated(response) {
    for (var i = 0; i < response.length; i++) {
      let index = this.items.indexOf(
          this.items.find(
              element => element.id === response[i].id));

      this.items[index] = response[i];
    }

  }

  selectedJobs(item: IQueue[]) {
    this.bulkSelect = item;
  }

  calculateExecutionTime(item: IQueue) {

    if (item.modifiedAt === null) {
        return '';
    }
    if (item.lastStartAt === null) {
        return '';
    }
    const modObj = new Date(item.modifiedAt.replace(/ /g, "T")).getTime();

    const lastRunObj = new Date(item.lastStartAt.replace(/ /g, "T")).getTime();
    let seconds = (modObj - lastRunObj) / 1000;
    let str = "";

    if (seconds > 3600) {
      str += Math.floor(seconds / 3600) + " hours, ";
      seconds = seconds % 3600;
    }

    if (seconds > 60) {
      str += Math.floor(seconds / 60) + " min, ";
      seconds = seconds % 60;
    }

    return `${str} ${seconds < 10 ? String.fromCharCode(160) : ''}${seconds} sec`;
  }

}
