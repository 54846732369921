
import { Component, VModel, Prop, Mixins } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import {
  ICardMemberModel,
  ICardModel,
} from "../../../models/member_card.model";
import { addCardMember, editCardMember, getCardMembers, getCards } from "../../../services/cards-service";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import CardAttributesController from "../atoms/CardAttributesController.vue";
import { getCardAttributes, getCardAttributeValues } from "../../../services/cards-service";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import CardOperationsMixin from "../mixins/card-operations";

@Component({
  components: {
    PazLoader,
    PazMenuDatePicker,
    CardAttributesController,
  },
    methods: {
    formatDateTime
  }
})
export default class CardMemberDetailsModal extends Mixins(
  CardOperationsMixin
) {

  refs!: {
    memberForm: HTMLFormElement;
  }

  @VModel({
    default: false,
  })
  showCardMemberModal!: boolean;

  @Prop({ default: null }) selectedMember!: ICardMemberModel | null;

  public loading: boolean = false;
  public memberFormModel: boolean = false;
  public memberCardModel = {
    isActive: false,
    cardNumber: null,
    card: { timePeriod: null, id: null },
    validUntil: null,
    dynamicField: null,
    name: null,
    street: null,
    familyName: null,
    housenumber: null,
    birthday: null,
    city: null,
    isDeleted: false,
  } as ICardMemberModel;
  // TODO: add types!
  public memberAttributes = [];
  private cardItems = [] as ICardModel[];

  setObjectData<
    T extends keyof ICardMemberModel,
    K extends keyof (ICardMemberModel[T] & ICardModel)
  >(value: any, key: T, altKey?: K) {
    if (!altKey) this.memberCardModel[key] = value;
    else this.memberCardModel[altKey][key] = value;   
  }

  async saveMember(isNew: boolean) {
    const postData = {...this.memberCardModel};
    postData.card = postData?.card?.id;
    delete postData.attributeValue;
    delete postData.attribute;
    await this.$refs.memberForm.validate();
    if (!this.memberFormModel) return;

    this.loading = true;
    const rez = isNew ? await addCardMember(postData) :
      await editCardMember(this.selectedMember?.id, postData);
      if (!rez || !rez.id) {
          this.handleSaveError(rez, 'Member', 'added');
        return
    }
    this.$emit('onClose', true);
    this.loading = false;
  }

  //TODO: actually this external method
  async getMemberById() {
    await getCardMembers(null, this.selectedMember?.id);
  }

  getFormatDate(date: Date|null) {
    return date && formatDateTime(date, null, "YYYY-MM-DD");
  }

  get getDynamicFiledOptions() {
    if (this.selectedMember?.dynamicField) {
      const cardListAttr = JSON.parse(this.selectedMember?.dynamicField);
      const convertedArray = Object.keys(cardListAttr).map((key) => [
        cardListAttr[key],
      ]);
      return convertedArray;
    } else[];
  }

  async getMembersAttrValues() {
    this.loading = true;
    const attrQueryParams = {
      filter: JSON.stringify(
      {isActive: 1, isDeleted: 0, isUserAttribute: 1, card: this.getCurrentMemberCardId})
      };
    const attrValuesQueryParams = {
      filter: JSON.stringify({cardMember: this.selectedMember?.id, card: this.getCurrentMemberCardId})
      };
    const attrs = await getCardAttributes(attrQueryParams);
    const attrValues = await getCardAttributeValues(attrValuesQueryParams);

    const attritemsArray = attrs?.map( (attr:any) => {
     const attrValue = attrValues?.find(attrVal => attrVal && attrVal.cardAttribute === attr.id);
     if(attrValue) {
        attr.value = attrValue.value;
        attr.valueId = attrValue.id;
        attr.cardMember = attrValue.cardMember;
     } else
      attr.value = null;
      return attr;
    })
    if (!attrs) return;
    this.memberAttributes = attritemsArray;
    this.loading = false;
  }

  async getCardsList() {
    this.cardItems = await getCards({filter: JSON.stringify({isDeleted: 0})});
  }

  get getCurrentMemberCardId() {
    return this.memberCardModel?.card?.id;
  }

  get getCards() {
    return this.cardItems;
  }

  mounted() {
    this.loading = true;
    if (this.selectedMember) {
      this.memberCardModel = this.selectedMember;
      this.getMembersAttrValues();
    }
    this.getCardsList();
    this.loading = false;
  }
} //
