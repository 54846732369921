import { stringifyUrl } from 'query-string';
import dayjs from 'dayjs';
import {getRequest,postRequest} from "./auth-service";

export async function getCountries(filter?: any, limit?: number) {
  filter = filter || {};
  if (filter) {
    const url = stringifyUrl({
      url: 'base/country',
      query: {
        filter: JSON.stringify(filter),
        limit: limit
      }
    });
    return (await getRequest(url)).data;

  }
  return (await getRequest('catalog/country')).data;
}

/**
 *
 * @param fileType the file type [#todo, ability to retrieve a list of valid types]
 * @param file the file to upload
 */
export async function uploadFlowFileToQueue(fileType: string, file: File){
  let formData = new FormData();
  formData.append('file', file);
  formData.append('type', fileType)

  return (await postRequest(`/import-flow-file`, formData, {})).data;
}

export function formatDateTime(value: string, branchLocalTime: string, format: string, notValidate: boolean): string {
  /**
   *  Format and validate string with date (iso8601) with format str and timezone
   @param value - date string, in format ISO8601 (2021-12-20T12:00:10)
   @param format - string format
   @returns format string with date
   */
  //@ts-ignore
  const formattedDate = dayjs.tz(value, branchLocalTime).format(format);
  const validFormattedDate = !notValidate ? dayjs(formattedDate, format).isValid() : true;
  if (!value) return '';
   //@ts-ignore
  return validFormattedDate ? formattedDate : dayjs(value).tz(branchLocalTime).format(format);
}


/**
 * Get Project settings
 * @returns {config: {}, featuresFlags: {}}
 */
export async function getConfigSettings() {
  return (await getRequest('settings/frontend')).data;
}