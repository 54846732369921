import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { LoginResponse, UserLoginForm, UserProfile } from '../models/user-models';
import {
    fetchUserData,
    loginUser,
    logoutUser
} from '../services/auth-service';
import router from "../router";
import { permission } from "./index";
import { clearSettings } from "@pazion/pazion-core/src/services/settings_config-service";

export interface IAuthenticationState {
    user: LoginResponse | null;
    authenticated: boolean;
}


@Module({ name: 'authentication', namespaced: true })
export default class AuthenticationModule extends VuexModule implements IAuthenticationState {

    public authenticated = false;
    public user: LoginResponse | null = null;
    private profile: UserProfile | null = null;


    @Mutation
    public loginSuccess(user: LoginResponse): void {
        this.authenticated = true;
        this.user = user;
    }

    @Mutation
    public logout(): void {
        this.authenticated = false;
        this.user = null;
        clearSettings();
    }

    @Action({ rawError: true })
    public afterLogout() {
        if (router.currentRoute.meta?.guest === true || router.currentRoute.name === 'login') {
            return;
        }
        router.push({ name: 'login' });
    }

    get isAuthenticated(): boolean {
        return this.authenticated;
    }


    @Action
    public async verifyUser() {
        const ud = fetchUserData(this.user?.token);
        if (ud === null || this.user === null || this.user.refreshToken === null) {
            this.context.commit("logout");
            this.afterLogout();
            return;
        }
        await this.context.commit("updateUserProfile", ud);
        await this.updateRoutes();
    }

    @Mutation
    public updateUserProfile(profile: UserProfile) {
        this.profile = profile;
        if (this.profile.roles.includes("administrator")) {
            this.profile.roles.push("user");
        }
    }

    @Action({ commit: 'updateUserProfile' })
    public async loadProfile() {
        await this.verifyUser();
        if (!this.isUserLoggedIn || this.user === null) {
            return;
        }
        return fetchUserData(this.user.token);
    }

    @Action
    public async updateRoutes() {
        if (!this.profile) {
            return;
        }
        await permission.updateRoutes();
    }

    @MutationAction({ mutate: ['user', 'authenticated'] })
    public async login(data: UserLoginForm) {
        const user = await loginUser(data);
        let authenticated = false;
        if (user.token !== undefined) {
            authenticated = !!user.token;
        }
        return { user, authenticated };
    }

    @Action
    public signOut(): void {
        logoutUser();
        this.context.commit('logout');
    }

    get isUserLoggedIn() {
        return this.authenticated;
    }

    get profileLoaded() {
        return this.getProfile !== null;
    }

    get getProfile(): UserProfile | null {
        return this.profile;
    }
}
