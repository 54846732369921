

import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class PazClipboardButton extends Vue {
  active = false;
  error = false;
  @Prop({default: ''})
  text!: string;
  public supportsCB = false;

  get getRightColor() {
    if (this.error) {
      return 'red';
    }
    if (this.active) {
      return 'green';
    }
    return 'blue';
  }

  get getRightIcon() {
    if (this.active) {
      return 'mdi-check';
    }
    return 'mdi-content-copy';
  }

  created() {
    if (navigator.clipboard) {
      this.supportsCB = true;
    }
  }

  toClipboard() {
    let dis = this;
    if (!this.supportsCB) {
      dis.error = true;
      return;
    }
    navigator.clipboard.writeText(this.text).then(function() {
      dis.active = true;
    }, function(err) {
      dis.error = true;
    });
    setTimeout(function() {
      dis.active = false;
    }, 600);
  }
}
