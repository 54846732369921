
import { Component, Mixins } from "vue-property-decorator";

import CardDetailsConfigModal from "./modals/CardDetailsConfig.vue";
import CardDetailsAttributes from "./modals/CardDetailsAttributes.vue";

import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import { ICardModel } from "../../models/member_card.model";
import CardOperationsMixin from "./mixins/card-operations";
import { deleteCard, getCards } from "../../services/cards-service";

@Component({
  components: {
    CardDetailsConfigModal,
    PazTooltipButton,
    PazRefreshButton,
    PazConfirmation,
    PazToast,
    CardDetailsAttributes
  },
})
export default class CardsListing extends Mixins(CardOperationsMixin) {

  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
  };


  public loading: boolean = false;
  public showCardDetails: boolean = false;
  public showAttrModal: boolean = false;
  public search: string = "";
  public cardItems = [] as ICardModel[];
  public selectedCard: ICardModel | null = null;

  //NOTE: modals states
  public showConfigModal: boolean = false;
  public showAttributeModal: boolean = false;

  onSearch(search: string) {
    this.search = search;
  }

  showCardConfigModal(card: ICardModel | null, status: boolean) {
    this.showConfigModal = status;
    if (!card) this.selectedCard = null;
    else this.selectedCard = card;
  }

  showCardAttributesModal(card: ICardModel, status: boolean) {
    this.showAttrModal = status;
    this.selectedCard = card;
  }

  showCardDesignModal(cardId: number) {
    this.$router.push({path: `/card-design/${cardId}`});
  }

  async deleteCard(card: ICardModel) {
    const confirm = await this.$refs.confirm
      .openConfirmDialog('Delete', `Do you want to delete card: ${card.name}?`);
    if (confirm) {
      const result = await deleteCard(card.id);
      if(!result || (result && !result.id))
        this.$refs.toast.show({
        message: result?.response?.data?.detail || "An error occurred, card hasn't been delete",
        icon: "mdi-close-circle",
        color: "red",
      });
      else
        this.fetchData();
    }
  }

  async getDefaultCards() {
    this.loading = true;
    this.cardItems = await getCards({excludeResources: 1, filter: JSON.stringify({ isDeleted: 0 })});
    this.loading = false;
  }

  async fetchData() {
     this.getDefaultCards();
  }

  mounted() {
    this.fetchData();
  }
} //
