

import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, PropSync, VModel, Watch} from 'vue-property-decorator';
import PazClipboardButton from "../controls/PazClipboardButton.vue";

const BIG_DATA_THRESHOLD_SIZE = 50;
@Component({
  components: {PazClipboardButton}
})
export default class DataDialog extends Vue {

  @VModel({
    default: false
  })
  public dialog!: boolean;

  @Prop({default: 'Extra info'})
  public title!: string;

  @Prop({
    default: () => {
    }
  })
  public item!: object;

  hasLotsofData(data: any) {
    if (typeof data === 'object') {
      return true;
    }
    return data.length > BIG_DATA_THRESHOLD_SIZE;
  }

  displayData(data: any): string {
    if (typeof data === 'object') {
      return JSON.stringify(data, null, 2);
    }
    return data;
  }
}
