
import Vue from "vue";
import { Component } from "vue-property-decorator";
import OrderLineGroupByListing from "./OrderLineGroupByListing.vue";
import OrderListingOrdersOnly from "./OrderListingOrdersOnly.vue";

@Component({
  components: {
    OrderListingOrdersOnly,
    OrderLineGroupByListing,
  },
})
export default class OrderListDisplayWrapper extends Vue {
  private $orderListingType: string | undefined;

  get isCombinedOrderListing(): boolean {
    return (
      this.$orderListingType == undefined || this.$orderListingType == "combo"
    );
  }

  get isOrdersOnlyListing(): boolean {
    return (
      this.$orderListingType != undefined && this.$orderListingType == "order"
    );
  }

  get isOrderLineOnlyListing(): boolean {
    return (
      this.$orderListingType != undefined &&
      this.$orderListingType == "orderline"
    );
  }
}
