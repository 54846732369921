import Vue from 'vue';
import ProductionsListingPage from "./views/productions/ProductionsListing.vue";
import InvoicesListing from "./views/invoices/InvoicesListing.vue";
import ReturnsListing from "./views/returns/ReturnsListing.vue";
import CatalogDashboard from "./views/home/CatalogDashboard.vue";
import OrderListDisplayWrapper from "./views/orders/OrderListDisplayWrapper.vue";
import Stock from "./views/stock/Stock.vue";
import AccountsListing from "./views/accounts/AccountsListing.vue";
import OffersListing from "./views/offers/OffersListing.vue";
import ContractsListing from "./views/contracts/ContractsListing.vue";
import ProductListingPage from "./views/product/ProductListingPage.vue";
import TransactionsListing from "./views/contracts/TransactionsListing.vue";
import InvoicesGenerator from "./views/contracts/InvoiceGenerator.vue"
import CardListing from "./views/cards/CardsListing.vue";
import MembersCardDesign from "./views/cards/parts/CardDesignConstructor.vue";
import CardMembersListing from "./views/cards/CardMembersListing.vue";
import MembershipCard from "./views/cards/MembershipCard.vue";


export function getCatalogRoutes() {

    const routes = [
        {
            path: '/',
            name: 'Home',
            component: CatalogDashboard,
            children: [
                { path: 'admin', component: CatalogDashboard }
            ],
            meta: {
                'show-in-nav': false,
                roles: ['user'],
                sort: -100,
            }
        }, {
            path: '/products',
            name: 'products',
            component: ProductListingPage,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -70
            },
        }, {
            path: '/orders',
            name: 'orders',
            component: OrderListDisplayWrapper,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -60
            },
        }, {
            path: '/invoices',
            name: 'invoices',
            component: InvoicesListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -85
            },
        }, {
            path: '/returns',
            name: 'returns',
            component: ReturnsListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -40
            },
        }, {
            path: '/stock',
            name: 'stock',
            component: Stock,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -45
            }
        },

        {
            path: '/accounts',
            name: 'accounts',
            component: AccountsListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -40
            },
        },
        {
            path: '/offers',
            name: 'offers',
            component: OffersListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -65
            },
        },
        {
            path: '/contracts',
            name: 'contracts',
            component: ContractsListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -75
            },
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: TransactionsListing,
            meta: {
                'show-in-nav': false,
                roles: ['user'],
                sort: -80
            },
        },
        {
            path: '/invoices-generator',
            name: 'invoicesGenerator',
            component: InvoicesGenerator,
            meta: {
                'show-in-nav': false,
                roles: ['user'],
                sort: -80
            },
        },
        {
            path: '/productions',
            name: 'productions',
            component: ProductionsListingPage,
            meta: {
                'show-in-nav': true,
                roles: ['user', 'production'],
                sort: -64
            },
        },
        {
            path: '/cards',
            name: 'cards',
            component: CardListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -55
            },
        },
        {
            path: '/card-members',
            name: 'card members',
            component: CardMembersListing,
            meta: {
                'show-in-nav': true,
                roles: ['user'],
                sort: -55
            },
        },
        {
            path: '/card-design/:cardId',
            name: 'card design',
            component: MembersCardDesign,
            props: true,
            meta: {
                'show-in-nav': false,
                roles: ['user'],
                sort: -55
            },
        },
        {
            path: '/member-login',
            name: 'membership pass',
            alias: '/member',
            component: MembershipCard,
            meta: {
                'show-in-nav': false,
                guest: true,
                roles: ['user'],
                hideHeader: true
            }
        },


    ];

    return routes;
}

