
import { Component, VModel, Mixins } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { ICardModel } from "../../../models/member_card.model";
import { exportCardMembers, getCards } from "../../../services/cards-service";
import CardOperationsMixin from "../mixins/card-operations";

@Component({
  components: {
    PazLoader
  },
})
export default class CardMemberImportModal extends Mixins(
  CardOperationsMixin
) {
  @VModel({
    default: false,
  })
  public showExportModal!: boolean;
  public loading: boolean = false;
  public cardItems = [] as ICardModel[];
  public selectedCardId: number | null = null;
  public exportedFileData = null;
  private filteXLsType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  async exportMembers() {
    this.loading = true;
    try {
      const data = await exportCardMembers(
        { filter: JSON.stringify({ card: this.selectedCardId}) });
      if (data && data instanceof Blob) {
        const blob = new Blob([data], { type: this.filteXLsType });
        const url = window.URL.createObjectURL(blob);
        this.exportedFileData = url;
        this.$nextTick(() => {
          this.$refs.referral && this.$refs.referral.click();
        })
      } else {
        this.undeccessFileDownload(data)
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
        this.undeccessFileDownload(error)
    }
  }

  undeccessFileDownload(response) {
    this.handleSaveError(response, 'File', 'downloaded');
  }

  async fetchData() {
    this.loading = true;
    try {
      this.cardItems = await getCards(
        { excludeResources: 1, filter: JSON.stringify({ isDeleted: 0 }) }
      );
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  mounted() {
    this.fetchData();
  }
} //
