
import {Component, Vue, VModel, Prop} from "vue-property-decorator";
import pdf from "vue-pdf";
import { IDossierFile } from "../../models/dossier.model";

@Component({
  components: {
    pdf
  }
})
export default class PazDossierDocksViewer extends Vue {

  @VModel({default: false}) showDialog: boolean;

  @Prop() document!: IDossierFile;
  @Prop() docRef!: string;

  public cardFooterStyle: string = `position: sticky;
     bottom: 0;
     background: #546E7A;
     color: #fff;
     z-index: 10;`;
}
