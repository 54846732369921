
import { Component, Mixins, Prop } from "vue-property-decorator";
import { CardAttributeModel, IAttributeValueInstance, ICardMemberModel } from "../../../models/member_card.model";
import { postCardAttributeValues, putCardAttributeValues } from "../../../services/cards-service";
import CardAttrValueEditor from "../parts/CardAttrValueEditor.vue";
import CardAttributesDynamicValueEditor from "../parts/CardAttributesDynamicValueEditor.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import BaseMixin from "../../../mixins/base.mixin";
import { AxiosError } from "axios";

@Component({
  components: {
    CardAttrValueEditor,
    CardAttributesDynamicValueEditor,
    PazToast
  }
})
export default class CardAttributesController extends Mixins(
  BaseMixin
) {

  refs!: {
    toast: PazToast;
  }

  @Prop({ default: [] }) attributeItems!: CardAttributeModel;
  @Prop({ default: '' }) constructorFormClass?: string;
  @Prop({ default: null }) memberId?: number;


  public cardAttrFormModel = false;
  public selectedMember: number | null = null;


  async setDynamicItemValue(arrayValues, field: CardAttributeModel) {
    const itemObject = { ...field };
  
    const stringArrValues = arrayValues && JSON.stringify(arrayValues);
    itemObject.value = stringArrValues;
  
    const attrDataValue = {
      attrData: itemObject,
      valueStatus: !!field.value,
      cardMember: itemObject.cardMember
    };
  
    await this.updateAttributeValue(attrDataValue);
  }

  async updateAttributeValue(attribute: IAttributeValueInstance) {
    const { attrData, valueStatus, cardMember } = attribute;
    let res = undefined;
    const postData = {
      card: attrData.card,
      value: attrData.value,
      cardAttribute: attrData.id,
    }
    this.memberId && (postData.cardMember = this.memberId);
    !attrData.isUserAttribute && delete postData.cardMember;
    const attrValueId = attrData.hasOwnProperty('valueId') ? attrData.valueId : attrData.id
    try {
      if (!valueStatus) {
        res = await postCardAttributeValues(postData);
      } else {
        res = await putCardAttributeValues(attrValueId, {
          value: postData.value,
          cardMember: postData.cardMember
        });
      }
      this.fireErrorMessage(res, false);
    } catch (error) {
      this.fireErrorMessage(res, true);
    }
  }

  fireErrorMessage(res: AxiosError, server?: boolean) {
    if (!res || !res.id) {
      this.chainServerError('added', false,
        (!server && res?.response?.data?.detail) || 'An attribute hasn\'t been updated!',
        'value', true)
    }
  }

  saveAttrValue() {
    this.$emit('onSaveValue')
  }

  getFieldOptions(field: CardAttributeModel): { key: string, value: CardAttributeModel }[] {
    const fields = (field?.fieldOptions && field?.fieldOptions.length > 0) && JSON.parse(field?.fieldOptions);
    if (fields && fields.length)
      return fields ? fields.map((f: CardAttributeModel) => ({ key: f, value: null })) : null;
  }


}//
