
import { Component, Vue, Prop } from "vue-property-decorator";
import { CardAttributeModel, IcardFieldTypesConverted } from "../../../models/member_card.model";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";

@Component({
  components: {
    PazTooltipButton,
  },
})
export default class CardAttributesConstructor extends Vue {
  @Prop({ default: () => { } }) cardAttrItem!: CardAttributeModel;
  @Prop({ default: 0 }) index!: number;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: () => [] }) attributeTypes: IcardFieldTypesConverted;

  $refs!: {
    attributesForm: HTMLFormElement
  }

  public cardAttrLineItem = {} as CardAttributeModel;
  public attributesFormModel = false;

  setAttrChanges() {
    this.cardAttrLineItem.isEdit = true;
  }

  async onAttrLineChange() {
    await this.$refs.attributesForm.validate();
    if (!this.attributesFormModel) return;
    if (typeof this.cardAttrLineItem.fieldType === 'object'
      && (this.cardAttrLineItem.fieldOptions && typeof this.cardAttrLineItem.fieldOptions === 'object')) {
      this.cardAttrLineItem.fieldType.hasOptions && (this.cardAttrLineItem.fieldType = this.cardAttrLineItem.fieldType.name);
      this.cardAttrLineItem.fieldOptions = JSON.stringify(this.cardAttrLineItem.fieldOptions);
    } else if (typeof this.cardAttrLineItem.fieldType === 'object' && !this.cardAttrLineItem.fieldOptions) {
      this.cardAttrLineItem.fieldType.hasOwnProperty('name') &&
        (this.cardAttrLineItem.fieldType = this.cardAttrLineItem.fieldType.name);
      delete this.cardAttrLineItem.fieldOptions
    }
    this.$emit("onLineUpdate", {
      index: this.index,
      attrLine: this.cardAttrLineItem,
    });
    this.cardAttrLineItem.isEdit = false;
  }

  deletAttrItem() {
    if (this.cardAttrItem.id === 0) this.$emit("onAttrLineDelete", this.index);
    else this.$emit("onAttrDbDelete", this.cardAttrItem);
  }

  get reletiveFieldOptions() {
    return this.cardAttrLineItem.fieldOptions ? this.cardAttrLineItem.fieldOptions : [];
  }

  get dynamicFieldTypes() {
    const dynTypes = typeof this.cardAttrLineItem.fieldType === 'object' &&
      this.cardAttrLineItem.fieldType.hasOptions;
    return dynTypes
  }

  mounted() {
    this.cardAttrLineItem = this.cardAttrItem;
    if (this.cardAttrLineItem.fieldOptions && this.cardAttrLineItem.fieldOptions.length > 0) {
      this.cardAttrLineItem.fieldOptions = JSON.parse(this.cardAttrLineItem.fieldOptions);
    }

  }
}
