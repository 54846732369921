
//TODO: Add types for ProductObject
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { Component, VModel, Mixins, Prop, Emit } from "vue-property-decorator";
import ProgressArrow from "../../assets/ProgressArrow.vue";
import { IApiProduct } from "@/models/product";
import {
  createProduct,
  getProduct,
  getProductType,
  getWarehouses,
  updateProduct,
} from "../../services/catalog-service";
import BaseMixin from "../../mixins/base.mixin";
import { baseRegexp } from "../../helpers/constants";
import PazProductAsyncPicker from "../picker/PazProductAsyncPicker.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { IWarehouse } from "../../models/warehouse";

@Component({
  components: {
    ProgressArrow,
    PazLoader,
    PazProductAsyncPicker,
    PazTooltipButton,
  },
})
export default class PazProductDialog extends Mixins(BaseMixin) {
  $refs!: {
    productForm: any;
  };

  @VModel({
    default: false,
  })
  public showDialog!: boolean;
  @Prop() productId?: number;
  public loading: boolean = false;
  public bundleLoading: boolean = false;
  public valid: boolean = false;
  public ProductObject = {
    validation: {
      name: [(value: string) => !!value || "Required."],
      sku: [(value: string) => !!value || "Required."],
      price: [
        (value: string) => !!value || "Required.",
        (value: string) => baseRegexp.priceRegex.test(value) || "Wrong format",
      ],
      purchasePrice: [
        (value: string) => !!value || "Required.",
        (value: string) => baseRegexp.priceRegex.test(value) || "Wrong format",
      ],
      ean: [
        (n: string) =>
          (n && (/^[0-9]*$/.test(n) || "Wrong format")) || "Wrong format",
      ],
    },
    value: {
      name: null,
      sku: null,
      price: null,
      purchasePrice: "0",
      isStock: false,
      isActive: false,
      catalog: null,
      productType: null,
      tax: null,
      ean: null,
      defaultWarehouse: null,
      productgroup: [],
      quantity: 1,
    },
  };
  public newProductToBundle: any = null;

  /**
   * Hardcode variables
   */
  public catalogOptions = [{ id: 1, value: "default" }];
  public taxOptions = [{ id: 1, value: 21 }];
  public productTypeOptions:
    | { name: string; nameTech: string; id: number }[]
    | [] = [{ id: 1, nameTech: "default", name: "default" }];
  public warehouses: IWarehouse[] | [] = [];
  //

  public async getOneProduct() {
    if (this.productId) {
      this.loading = true;
      try {
        const response = await getProduct(this.productId, {
          expand: "productType,productgroup",
        });
        if (response) {
          this.ProductObject.value = response;
          this.ProductObject.value.productType =
            response.productType && response.productType.id;
          !response.purchasePrice &&
            (this.ProductObject.value.purchasePrice = "0");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    } else {
      if (this.taxOptions.length && this.taxOptions.length === 1)
        this.ProductObject.value.tax = this.taxOptions[0].id;
      if (this.catalogOptions.length && this.catalogOptions.length === 1)
        this.ProductObject.value.catalog = this.catalogOptions[0].id;
      if (
        this.productTypeOptions.length &&
        this.productTypeOptions.length === 1
      )
        this.ProductObject.value.productType = this.productTypeOptions[0].id;
    }

    this.productTypeOptions = await getProductType();
  }

  /**
   * Add/Edit Product handler
   */
  public async productUpdateHandler(productId: number | null) {
    this.$refs.productForm.validate();
    if (!this.valid) return;
    let response = null;
    this.loading = true;
    this.ProductObject.value.productType !== 2 &&
      (this.ProductObject.value.productgroup = []);
    let productGroupArray = this.ProductObject.value.productgroup;
    if (productGroupArray.length > 0) {
      this.ProductObject.value.productgroup = productGroupArray.map(
        (g) => g.id
      );
    } else {
      delete this.ProductObject.value.productgroup;
    }
    try {
      response = productId
        ? await updateProduct(this.ProductObject.value, productId)
        : await createProduct(this.ProductObject.value);
      if (
        (response && response.hasOwnProperty("productId")) ||
        response.hasOwnProperty("id")
      ) {
        this.showDialog = false;
        this.onProductUpdate(response);
      } else {
        const serverError = response?.response?.statusText;
        serverError &&
          this.chainServerError(
            productId ? "updated" : "created",
            false,
            serverError || null,
            "product"
          );
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.showDefaultServerErrorToast(this);
    }
  }

  async addNewProductToBundle() {
    if (this.newProductToBundle) {
      this.bundleLoading = true;
      const preparingProduct = await getProduct(this.newProductToBundle, {
        expand: "productType,productgroup",
      });
      if (preparingProduct && preparingProduct.id !== this.productId) {
        this.changeBundleArray(0, preparingProduct, true);
        this.newProductToBundle = null;
      } else {
        this.$parent.$refs.toast.show({
          message:
            "Unable to add, the product is the same as which you are editing",
          color: "warning",
        });
      }
      this.bundleLoading = false;
    }
  }

  deleteFromBundle(index: number) {
    this.bundleLoading = true;
    this.ProductObject.value.productgroup.splice(index, 1);
    setTimeout(() => (this.bundleLoading = false), 100);
  }

  changeBundleArray(index: number, item?: any, addNew?: boolean) {
    if (addNew && item) {
      this.ProductObject.value.productgroup.push(item);
    }
    if (!addNew && index)
      this.ProductObject.value.productgroup.splice(index, 1, item);
  }

  get getProdcutBundelArray() {
    return this.ProductObject.value.productgroup;
  }

  @Emit()
  public onProductUpdate(item: IApiProduct) {
    return item;
  }

  async mounted() {
    await this.getOneProduct();
    this.warehouses = await getWarehouses();
  }
}
