
import { Component, Vue } from 'vue-property-decorator';
import { ICardAttrValuesModel, ICardMemberModel, ICardModel } from '../../models/member_card.model';
import { getCardMembers, getCards, loginMember } from '../../services/cards-service';
import CardDesignAttributeBlock from './atoms/CardDesignAttributeBlock.vue';
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import dayjs from "dayjs";
import { createDownloadPwaLink } from "../../services/base-service";

@Component({
  components: {
    CardDesignAttributeBlock,
    PazLoader
  }
})
export default class MembershipCard extends Vue {

  public loading: boolean = false;
  public currentMember: ICardMemberModel | null = null;
  public currentCard = {} as ICardModel;
  public isMobilePlot = true;
  public memberFormModel = false;
  public emailModel: string | null = null;
  public emailRules = [
    v => !!v || 'E-mail is required',
    v => /.+@.+/.test(v) || 'E-mail must be valid',
  ];
  public waterMarkState: string = '';
  public loginError: string | null = null;
  private loginDefaultError: string = ''
  private memberAttrValues: ICardAttrValuesModel[] | null = null;
  private globalAttrValues: ICardAttrValuesModel[] | null = null;
  private succesEmailMessage = 'Check your email to get an access token'
  private unsuccessGetDataMessage = 'We were unable to retrieve member data. Please try again or contact the administrator for assistance.'

  public deferredPrompt = null;


  // TODO: Dublicate code.
  get getCardStyle() {
    // TODO: create style creator;
    let cardBackground: { (key: string): string | null };
    if (this.currentCard.background) {
      cardBackground = { 'background-color': this.currentCard.background }
    }
    if (this.currentCard.backgroundImage)
      cardBackground = { ...cardBackground, 'background-image': `url(${this.currentCard.backgroundImage})` }
    return cardBackground
  }

  createElementStyle(el: any) {
    return `transform: ${el.transform};width:${el.width}px;height:${el.height}px;${el.style}`
  }

  async getCardBySubDomain() {
    const checkedDomain = window.location.hostname;
    const parsedCardSubdomain = checkedDomain.match(/[^.]*/);
    const cardSubdomain = parsedCardSubdomain ? parsedCardSubdomain[0] : null;
    const cardRes = await getCards(null, cardSubdomain);
    if (cardRes && cardRes.hasOwnProperty("id"))
      this.currentCard = cardRes;
  }

  setWatermarkState() {
    setInterval(() => {
      this.waterMarkState = dayjs().format('YYYY-DD-MM HH:mm:ss');
    }, 1000)
  }

  async loginMemberhandler(postData: { crardId: number, email?: string, token?: string }, withToken: boolean) {
    const resUser = await loginMember(postData);
    if (!resUser || (!withToken && resUser && !resUser.success) || (withToken && resUser && !resUser.hasOwnProperty('id'))) {
      this.loginError = resUser?.message || this.loginDefaultError;
      return
    }
    return resUser;
  }

  //NOTE: Send login form
  async onContinue(e: Event) {
    e.preventDefault();
    await this.$refs.memberCardForm.validate();
    if (!this.memberFormModel) return;
    const postData: { crardId: number, email?: string, token?: string } = {
      cardId: this.currentCard.id,
      email: this.emailModel
    }
    try {
      this.loading = true;
      const token = await this.loginMemberhandler(postData, false);
      if (token) {
        this.loginError = token.hasOwnProperty('message') ? token.message : this.succesEmailMessage
      }
      this.loading = false;
    } catch (error) {
      this.loginError = this.loginDefaultError;
    }
  }

  // NOTE: Login from Token
  async getUserByToken() {
    this.loading = true;
    const memberToken = this.$route.query?.token;
    const cachedMember = localStorage.getItem('cardMember');
    let postData: { cardId: number, token?: string, email?: string } = {
      cardId: this.currentCard.id,
    }
    if (cachedMember) {
      const cardCachedMember = JSON.parse(cachedMember);
      if (cardCachedMember) {
        this.currentMember = cardCachedMember
        await this.$router.push({ path: '/member' }).catch(() => { });
        this.loading = false;
      }
      return
    } else if (memberToken) {
      postData.token = memberToken;
      const memberFromToken = await this.loginMemberhandler(postData, true);
      if (memberFromToken && memberFromToken.hasOwnProperty('id')) {
        this.currentMember = memberFromToken;
        localStorage.setItem('cardMember', JSON.stringify(memberFromToken));
        await this.$router.push({ path: '/member' }).catch(() => { });
      } else {
        localStorage.removeItem('memberCardToken');
        this.redirectTologin(true);
      }
    }
    this.redirectTologin(false);
    this.loading = false;
  }

  // TODO: Add member request by token.
  async getMemberAttrValues() {
    if (this.currentMember) {
      this.loading = true;
      const userToken = this.currentMember && this.currentMember.token;
      try {
        if(userToken) {
          const fullMember = await getCardMembers({expand: 'attributeValue,card'}, null, userToken);
          if(fullMember && fullMember.hasOwnProperty('id')) {           
            this.currentMember = fullMember
            const memberAttrsValues = fullMember?.attributeValue;
            if(memberAttrsValues && memberAttrsValues.length)
              this.memberAttrValues = memberAttrsValues;

            const globalAttr  = fullMember?.card.attributeValue;
            if(globalAttr && globalAttr.length)  
              this.globalAttrValues = globalAttr
          }
        }
        this.loading = false;
      } catch (err) {
        this.loginError = this.unsuccessGetDataMessage;
        this.loading = false;
      }
    }
  }

  redirectTologin(delQuery?: boolean) {
    if (delQuery)
      this.$router.replace({ query: null })
    else if (this.$route.path !== "/member-login")
      this.$router.push({ path: '/member-login', query: null })
  }

  memberLogout() {
    this.currentMember = null;
    localStorage.removeItem('memberCardToken');
    localStorage.removeItem('cardMember');
    this.loginError = null;
    this.redirectTologin();
  }

  checkAgeCurrentMember() {
    if (this.currentMember) {
      const birthDate = this.currentMember.birthday;
      const diff = dayjs().diff(birthDate, 'millisecond');
      const millisecondsInYear = 365 * 24 * 60 * 60 * 1000;
      const eighteenYearsInMilliseconds = 18 * millisecondsInYear;
      const isUser18Plus = diff >= eighteenYearsInMilliseconds;
      return isUser18Plus;
    } else return false;
  }

  get getTemplateElements() {
    const currentLayout = this.currentCard.layout && JSON.parse(this.currentCard.layout);
    const mapedLayout = currentLayout && currentLayout.map(el => {

      if(el.system === '_qrcode' || el.system === '_barcode')
        el.use = false

      if(el.system === 'logo' && this.currentCard.hasOwnProperty('logo')) {
        el.value = this.currentCard.logo;
        el.use = true  
      }
        

      if (el.hasOwnProperty('isMemberAttr') && el.isMemberAttr && this.currentMember?.cardNumber) {
        if (el.system === '_qrcode' || el.system === '_barcode') {
          el.value = this.currentMember?.cardNumber.toString();
          el.use = true;
        } 
        else if (el.system === '_ageCheck' ) {
          const isUser18Plus = this.checkAgeCurrentMember();
          !isUser18Plus ? (el.use = false) : 
          (el.value = this.currentCard.ageCheckImage);
        } 
        else if(this.currentMember && this.currentMember.hasOwnProperty(el.text)) {
          el.value = this.currentMember[el.text]
        }  
      } else if(this.currentCard && this.currentMember && this.currentCard.hasOwnProperty(el.text)) {
        if(el.system !== '_ageCheck')
          el.value = this.currentCard[el.text]
      }

      //Render member attrs
      if (this.currentMember && this.memberAttrValues && this.memberAttrValues.length > 0) {
        const memberValue = this.memberAttrValues.find(mv => mv.cardAttribute === el.cardAttributeId);
        memberValue && memberValue.hasOwnProperty('value') && (el.value = memberValue.value);      
      }

       //Render card attrs
      if (this.currentMember && this.globalAttrValues && this.globalAttrValues.length > 0) {
        const globalValue = this.globalAttrValues.find(mv => mv.cardAttribute === el.cardAttributeId);   
        globalValue && globalValue.hasOwnProperty('value') && (el.value = globalValue.value);      
      }
      return el

    }).filter(el => el && el)
    return (mapedLayout && mapedLayout.length > 0) ? mapedLayout : currentLayout;
  }

  get getVersion() {
    return process.env.VUE_APP_VERSION;
  }

  get getAppName() {
    return process.env.VUE_APP_NAME;
  }


  async mounted() {
    this.setWatermarkState();
    await this.getCardBySubDomain();
    await this.getUserByToken();
    await this.getMemberAttrValues();
  }

  created() {
    this.createDownloadLink();
  }

  // register link to app
  createDownloadLink() {
    this.deferredPrompt = createDownloadPwaLink();
  }


  handleInstallClick() {
    if (this.deferredPrompt && this.deferredPrompt.hasOwnProperty('promptEvent')) {
      this.deferredPrompt.status = false;
      this.deferredPrompt.promptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }



}//
