
import { Component, Mixins } from "vue-property-decorator";
import ProductionsListingMixin from "./mixins/production-listing.mixin";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";

@Component({
  components: {
    PazTooltipButton,
    PazRefreshButton,
    PazToast,
    PazMenuDatePicker
  },
  methods: {
    formatDateTime
  }
})
export default class ProductionsListingPage extends Mixins(
  ProductionsListingMixin
) {
  //LINK: CatalogAppFrontendPackages/pazion-catalog/src/views/productions/mixins/production-listing.mixin.ts
}
