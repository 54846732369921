
import { Component, Vue, Watch } from "vue-property-decorator";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazOfferDialog from "../../components/dialog/pazOfferDialog.vue";
import PazOfferImportDialog from "../../components/dialog/PazOfferImportDailog.vue";
import { formatPrice } from "../../helpers/format";
import {
  createOffer,
  deleteOffer,
  getOffers,
  updateOffer,
} from "../../services/offers-service";
import {
  offerDetailsModel,
  offersModel,
  IOffersPaginateModel,
  marketplaceModel,
  MarketplaceInstanceEntity,
} from "../../models/offers.model";
import { ITablePaginationOptions } from "../../models/base";
import PazListFilterSelector from "@pazion/pazion-core/src/components/utils/PazListFilterSelector.vue";
import { getMarketplaces } from "../../services/catalog-service";

@Component({
  components: {
    PazTooltipButton,
    PazToast,
    PazConfirmation,
    PazRefreshButton,
    PazOfferDialog,
    PazOfferImportDialog,
    PazListFilterSelector,
  },
  methods: {
    formatPrice,
  },
})
export default class OfferListing extends Vue {
  refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
  };

  public loading: boolean = false;
  public showOfferDialog: boolean = false;
  public selectedOffer: offersModel | null = null;
  public offersArray: IOffersPaginateModel | {} = {};
  public showOfferImportDialog: boolean = false;
  public withoutProduct: boolean | null = null;
  public needsUpdateFilter: boolean | null = null;
  public marketplaces: marketplaceModel[] | [] = [];
  public filterWarehouseError: boolean | null = null;
  public marketplaceErrorCode: number | null = null;
  public marketplaceInstance: number | null = null;
  public isActive: boolean | null = null;
  public repriceActiveFilter: boolean | null = null;
  public search: string = "";
  public showFilters: boolean = false;
  public tableHeaders = [
    {
      text: "ID",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: true,
      cellClass: "nowrap",
      value: "id",
    },
    // {
    //   text: "Catalog",
    //   align: "left",
    //   class: "cell-truncate",
    //   sortable: true,
    //   value: "catalog",
    //   groupable: false,
    // },
    // {
    //   text: "Supplier",
    //   align: "left",
    //   class: "cell-truncate",
    //   sortable: true,
    //   value: "supplier",
    //   groupable: false,
    // },
    {
      text: "Product",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "product",
      groupable: false,
      cellClass: "productimage",
    },
    {
      text: "Marketplace",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      groupable: false,
      value: "marketplace",
    },
    {
      text: "Marketplace Instance",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "marketplaceInstance",
      groupable: false,
    },
    {
      text: "Warehouse error",
      align: "center",
      class: "cell-truncate",
      sortable: true,
      value: "warehouseError",
      groupable: false,
    },
    {
      text: "Marketplace error",
      align: "center",
      class: "cell-truncate",
      sortable: true,
      value: "marketplaceErrorCode",
      groupable: false,
    },
    {
      text: "Purchase Price",
      class: "cell-truncate",
      sortable: true,
      value: "purchasePrice",
      groupable: false,
    },
    {
      text: "Calculated Price",
      align: "center",
      class: "cell-truncate",
      sortable: true,
      value: "calculatedPrice",
      groupable: false,
    },
    {
      text: "Current Price",
      align: "center",
      class: "cell-truncate",
      sortable: true,
      value: "currentPrice",
      groupable: false,
    },
    {
      text: "Updated",
      align: "center",
      class: "cell-truncate text-right",
      sortable: true,
      value: "changedAt",
      groupable: false,
    },
    {
      text: "Status",
      align: "center",
      class: "cell-truncate text-right",
      sortable: true,
      value: "isActive",
      groupable: false,
    },

    {
      text: "Actions",
      class: "cell-truncate text-right mx-5",
      value: "actions",
      groupable: false,
      sortable: false,
    },
  ];

  /** NOTE: Paginations **/
  public totalItems = 1;
  public paginationOptions: ITablePaginationOptions = { itemsPerPage: 200 };
  public footerProps = {
    itemsPerPageOptions: [50, 100, 200, -1],
  };
  public defaultQueryPaginationsOpt = {
    pagination: 1,
    perPage: 200,
    page: 1,
    expand: "product,productLocale,media,marketplace,marketplaceInstance",
  };
  public groupFilterOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
    { value: null, label: "All" },
  ];

  createNewOffer() {
    this.showOfferDialog = true;
  }

  showOfferDetials(item: offersModel) {
    this.showOfferDialog = true;
    this.selectedOffer = item;
  }

  importOffer() {
    this.showOfferImportDialog = true;
  }

  goToProduct(item: offersModel) {
    const fromOfferId = item?.product?.id;
    fromOfferId &&
      this.$router.push({
        name: "products",
        query: { fromOffer: fromOfferId },
      });
  }

  chainOfferError(type: string, result?: boolean, message?: string) {
    const fullMessage: string = message
      ? message
      : `An error occurred, Offer hasn't been ${type}`;
    this.$refs.toast.show({
      message: fullMessage,
      icon: !result ? "mdi-close-circle" : "mdi-checkbox-marked-circle-outline",
      color: !result ? "red" : "green",
      yPosition: "top",
    });
  }

  async createOffer(offer: offerDetailsModel) {
    try {
      const response = await createOffer(offer);
      if (
        !response ||
        (response.hasOwnProperty("response") &&
          response.response.hasOwnProperty("data"))
      ) {
        this.chainOfferError(
          "created",
          false,
          response?.response?.data?.detail || null
        );
        return;
      }
      this.showOfferDialog = false;
    } catch (error) {
      this.chainOfferError("created", false);
    }
  }

  async updateOffer(offer: offerDetailsModel) {
    this.loading = true;
    try {
      const systemFields = [
        "id",
        "competitionLowestPrice",
        "competitionHighestPrice",
        "competitionCompetitionCount",
        "currentDate",
        "currentPosition",
        "isDeleted",
        "deletedAt",
        "changedAt",
        "createdAt",
        "pricingStrategy",
        "attributeValue",
        "offerArchive",
        "minimalPrice",
      ];
      const clearOffer = { ...offer };
      systemFields.forEach((f) => {
        delete clearOffer[f];
      });
      const response = await updateOffer(clearOffer, offer.id);
      if (
        !response ||
        (response.hasOwnProperty("response") &&
          response.response.hasOwnProperty("data"))
      ) {
        this.chainOfferError(
          "updated",
          false,
          response?.response?.data?.detail || null
        );
        this.loading = false;
        return;
      }
      this.getDefaultOffers(this.defaultQueryPaginationsOpt);
      this.loading = false;
      this.showOfferDialog = false;
    } catch (error) {
      this.loading = false;
      this.chainOfferError("updated");
    }
  }

  onImportOffers(status: boolean) {
    const mess: string = status
      ? "Import is added to the queue"
      : "Import failed";
    this.chainOfferError(undefined, status, mess);
    this.showOfferImportDialog = false;
    status && this.getDefaultOffers(this.defaultQueryPaginationsOpt);
  }

  async deleteOffer(item: offersModel) {
    const confirm = await this.$refs.confirmDeleteOffer.openConfirmDialog(
      "Delete",
      `Do you want to delete offer #${item.id}?`
    );
    if (!confirm) return;
    const response = await deleteOffer(item.id);
    if (!response || !response.success) {
      this.$refs.toast.show({
        message: "An error occurred, Offer hasn't been deleted",
        icon: "mdi-close-circle",
        color: "red",
        yPosition: "top",
      });
      return;
    }
    this.fetchData();
  }

  row_classes() {
    return "v-row-group__subrow";
  }

  getProductMedia(item: offersModel) {
    //@ts-ignore
    return item.product?.productLocale?.length > 0
      ? item.product.productLocale[0]?.media[0]?.path
      : null;
  }

  createSearchString(search: string) {
    return search.length > 0 ? { like: `%${this.search}%` } : null;
  }

  //NOTE: Create params from filters
  createFiltersObject() {
    let filter: {
      product?: null;
      marketplaceNeedsUpdate?: null | boolean;
      marketplaceErrorCode?: null | number;
      isDeleted?: number;
      marketplaceInstance?: number | null;
      isActive?: null | boolean;
      filterWarehouseError?: null | boolean;
      repriceActive?: null | boolean;
    } = {
      product: null,
      marketplaceNeedsUpdate: null,
      marketplaceErrorCode: null,
      isDeleted: 0,
      marketplaceInstance: null,
      isActive: null,
      filterWarehouseError: null,
      repriceActive: null,
    };
    this.withoutProduct !== null && this.withoutProduct
      ? Object.assign(filter, { product: null })
      : delete filter.product;
    this.needsUpdateFilter !== null
      ? Object.assign(filter, {
        marketplaceNeedsUpdate: this.needsUpdateFilter,
      })
      : delete filter.marketplaceNeedsUpdate;
    this.marketplaceErrorCode !== null
      ? Object.assign(filter, {
        marketplaceErrorCode: this.marketplaceErrorCode,
      })
      : delete filter.marketplaceErrorCode;

    this.marketplaceInstance !== null
      ? Object.assign(filter, {
        marketplaceInstance: this.marketplaceInstance,
      })
      : delete filter.marketplaceInstance;

    this.isActive !== null
      ? Object.assign(filter, {
        isActive: this.isActive,
      })
      : delete filter.isActive;

    this.filterWarehouseError !== null
      ? Object.assign(filter, {
        filterWarehouseError: this.filterWarehouseError,
      })
      : delete filter.filterWarehouseError;

    this.repriceActiveFilter !== null
      ? Object.assign(filter, {
        repriceActive: this.repriceActiveFilter,
      })
      : delete filter.repriceActive;

    const fromProductId = this.$route.query && this.$route.query.productId;

    fromProductId &&
      Object.assign(filter, {
        product: fromProductId,
      });

    return filter;
  }

  //NOTE: Create params from orderBy
  createOrderParams(): { [key: string]: "DESC" | "ASC" } | undefined {
    let orderBy: { [key: string]: "DESC" | "ASC" } | undefined = undefined;
    // @ts-ignore
    this.paginationOptions?.sortBy.length > 0 &&
      (orderBy = {
        [this.paginationOptions?.sortBy[0]]: this.paginationOptions.sortDesc[0]
          ? "DESC"
          : "ASC",
      });
    return orderBy || undefined;
  }

  // NOTE: FROM SEARCH ++
  onSearch() {
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const searchString = this.createSearchString(this.search);
      const filters = this.createFiltersObject();

      const queryParams = searchString
        ? {
          ...this.defaultQueryPaginationsOpt,
          filter: JSON.stringify({
            operator: "AND",
            ...filters,
            multi: {
              operator: "OR",
              id: searchString,
              marketplaceEan: searchString,
              deliveryClassification: searchString,
              fulfilmentMethod: searchString,
              calculatedPrice: searchString,
              currentPrice: searchString,
            },
          }),
        }
        : {
          ...this.defaultQueryPaginationsOpt,
          filter: JSON.stringify({ ...filters }),
        };
      const currentOrder = this.createOrderParams();
      await this.getDefaultOffers(queryParams, false, currentOrder);
    }, 800);
  }

  // NOTE: FROM FILTER
  onChangeGroupFilter() {
    const filters = this.createFiltersObject();
    const currentOrder = this.createOrderParams();
    this.search = "";
    this.getDefaultOffers(
      { filter: JSON.stringify(filters), ...this.defaultQueryPaginationsOpt },
      false,
      currentOrder
    );
  }

  //NOTE: MAIN request
  async getDefaultOffers(
    params?: { [key: string]: any | { [key: string]: string } } | {},
    isAllRecords?: boolean,
    order?: { [key: string]: "ASC" | "DESC" }
  ) {
    const fromProductId = this.$route.query && this.$route.query.productId;
    let reqParams;
    if (params) reqParams = params;
    if (reqParams && !reqParams.hasOwnProperty("filter")) {
      if (!fromProductId) {
        reqParams = Object.assign(reqParams, {
          filter: JSON.stringify({ isDeleted: 0 }),
        });
      } else {
        reqParams = Object.assign(reqParams, {
          filter: JSON.stringify({ isDeleted: 0, product: fromProductId }),
        });
      }
    }

    order && Object.assign(reqParams, { orderBy: JSON.stringify(order) });
    fromProductId;
    this.loading = true;
    const response = await getOffers({ ...reqParams });
    if (response) {
      this.offersArray = response;
      this.totalItems = response._pagination._total;
      Object.assign(this.paginationOptions, {
        page: response._pagination._page,
        itemsPerPage:
          isAllRecords || fromProductId ? -1 : response._pagination._per_page,
      });
    }
    this.loading = false;
  }

  // NOTE: FROM Paginastion
  @Watch("paginationOptions")
  onUpdatePaginations(val, oldVal) {
    if (
      val.itemsPerPage !== oldVal.itemsPerPage ||
      val.page !== oldVal.page ||
      val.sortBy.length > 0
    ) {
      let pagiantionParams;
      let orderBy;
      if (val.itemsPerPage > 0)
        pagiantionParams = {
          pagination: 1,
          perPage: val.itemsPerPage,
          page: val.page,
          expand: "product,productLocale,media,marketplace,marketplaceInstance",
        };
      else {
        pagiantionParams = this.defaultQueryPaginationsOpt;
        pagiantionParams.perPage = 200;
      }
      orderBy = this.createOrderParams();
      let filters = this.createFiltersObject();
      filters &&
        Object.assign(pagiantionParams, { filter: JSON.stringify(filters) });
      this.getDefaultOffers(pagiantionParams, val.itemsPerPage === -1, orderBy);
    }
  }

  onCloseOfferModal() {
    this.showOfferDialog = false;
    this.selectedOffer = null;
  }

  fetchData(clearProduct?: boolean) {
    clearProduct &&
      this.$route.query &&
      this.$router.replace({ query: null }).catch(() => { });

    const filters = this.createFiltersObject();
    const currentOrder = this.createOrderParams();

    this.getDefaultOffers(
      { filter: JSON.stringify(filters), ...this.defaultQueryPaginationsOpt },
      false,
      currentOrder
    );
  }

  get marketplaceErrorsCode() {
    return (
      this.getOffersArray &&
      this.getOffersArray.map(
        (v: offersModel) =>
          v.marketplaceErrorCode && {
            label: v.marketplaceErrorCode,
            value: v.marketplaceErrorCode,
          }
      )
    );
  }

  get getOffersArray() {
    return this.offersArray && this.offersArray?._data;
  }

  get canEditOffer() {
    return true;
  }

  get getHeaders() {
    return this.tableHeaders && this.tableHeaders;
  }

  get getMarketplaceInstances() {
    return this.marketplaces
      .flatMap((inst: marketplaceModel) => {
        if (
          "marketplaceInstance" in inst &&
          inst.marketplaceInstance.length > 0
        ) {
          return inst.marketplaceInstance;
        } else return [];
      })
      .filter(
        (item: MarketplaceInstanceEntity | undefined) => item !== undefined
      );
  }

  async mounted() {
    this.marketplaces = await getMarketplaces({
      filter: JSON.stringify({ isActive: true }),
    });
  }
} //
