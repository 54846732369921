
import { Component, Vue } from 'vue-property-decorator';
import { permission } from '@pazion/pazion-core/src/store';
import { RouteConfig } from 'vue-router';

@Component({})
export default class PazSettingsDrawer extends Vue {

  getActiveItem(item: RouteConfig) {
    return item?.path === this.$route.path;
  }

  getMenuIcon(item: RouteConfig) {
    return item.path.includes('settings') ? 'mdi-cog' : (item?.meta.icon || 'mdi-menu-right');
  }

  get navRoutes() {
    const children = permission.getroutesWithPermissions.filter(r => r.children && r.children);
    return children && children.shift();
  }

}//
