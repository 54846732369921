
import { Prop, Vue, Component } from "vue-property-decorator";
import PazEditUserModal from "./modals/pazEditUserModal.vue";

@Component({
  components: {
    PazEditUserModal,
  },
})
export default class UserEdit extends Vue {
  @Prop({ default: null }) id!: number;
  public loading: boolean = false;
  public showEditUserDialog = true;
} //
