import { getRequest, postRequest, putRequest } from '@pazion/pazion-core/src/services/auth-service';

export async function getProductionWorktypes () {
  return (await getRequest('production-worktypes')).data;
}

export async function getProductionRecords(orderId: number) {
  return (await getRequest(`/catalog/orders/${orderId}/production`)).data;
}

export async function getProductionOrder( data: any) {
  return (await postRequest('order-production', data)).data;
}

export async function generateProductionsCut(data: Object) {
  return (await postRequest('production-csv', data)).data
} 

export async function changeLineStatus(id: number, data: Object) {
  return (await putRequest(`catalog/production-lines/${id}` , data)).data
} 

