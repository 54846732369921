import { Component, Vue, Watch } from "vue-property-decorator";
import { IOrder, IOrderStatus, ORDER_STATUS } from "../../../models/order";
import {
createOrder
} from "../../../services/catalog-service";
import { executeCommand } from '@pazion/pazion-core/src/services/auth-service';


@Component({})
export default class orderListingMixin extends Vue {
  public showOrderCreator: boolean = false;
  public showOrderFilesModal: boolean = false;

  //
  row_classes() {
    return "v-row-group__subrow";
  }

  isOrderHasNotes(order: IOrder) {
    return order.notes;
  }

  createNewOrder() {
    if(!this.$orderAutoReference)this.showOrderCreator = true;
    else this.newAutoOrderCreator();
  }

  newOrderCreated(orderId: number) {
    this.showOrderCreator = false;
    this.showOrderEditDialog = true;
    this.selectedOrder = orderId;
    this.fetchData();
  }

  async newAutoOrderCreator() {
    this.orderReference = `order-${Date.now()}`;
    this.loading = true;
    const rsp = await createOrder(this.orderReference);
    if (rsp.orderId && rsp.orderId > 0) {
      this.orderReference = "";
      this.showOrderEditDialog = true;
      this.selectedOrder = rsp.orderId;
      await this.fetchData();
    }
    this.loading = false;
  }

  // Show order files
  showOrderFiles(item: IOrder, fromGroup?: boolean) {
    this.selectedOrder = !fromGroup ? item.id : item.orderId;
    this.showOrderFilesModal = true;
  }

  isStateRetryable(state: any) {
    switch (state) {
      case "ERROR":
      case "ERROR_TO_SHIPMENT":
      case "ERROR_SHIPMENT":
      case "ERROR_INVOICE":
        return true;
    }
    return false;
  }
  

  async addQueJobCommand(orderId: number) {
    const orderCommand = {
      command: this.hasOrderCommand,
      material: orderId
    };
    this.loading = true;
    const rsp = await executeCommand(orderCommand);
    if (!rsp || !rsp.success) {
      this.$refs.toast.show({
        message: "Failed to add the requested queue job",
        icon: "mdi-close-circle",
        color: "red",
      }); 
    }  else {
      this.$refs.toast.show({
        message: "Succesfully added the requested queue job",
        color: 'green'
      }); 
    }
    this.loading = false;
  }


  canDoProd(status: string) {
    return !["INVOICED", "PROCESSED", "SHIPPED"].includes(status);
  }

  get hasOrderCommand() {
    return this.$queJobAction && 
      this.$queJobAction.hasOwnProperty('orderListing') && this.$queJobAction.orderListing
  }

  get canCreateDelivery() {
    return this.$createDelivery != undefined && !!this.$createDelivery;
  }

  get canShowDeliveryDialog() {
    return this.$showDeliveryDialog != undefined && this.$showDeliveryDialog;
  }

  //Get table-data headers
  get getHeaders() {
    if (this.tableHeaders.length > 0) {
      return this.tableHeaders;
    }
    return this.headers.main;
  }
      
}//


