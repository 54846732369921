
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CardAttributeModel } from '../../../models/member_card.model';
import CardDetailsFileUploader from "../atoms/CardDetailsFileUploader.vue";

@Component({
  components: {
    CardDetailsFileUploader
  }
})
export default class CardAttributesDynamicValueEditor extends Vue {

  @Prop() dynamicItem!: { key: string, value: CardAttributeModel };
  @Prop() itemIndex!: number;
  @Prop({ default: null }) field!: CardAttributeModel;

  public currentItem: { key: string, value: CardAttributeModel } | null = null;
  public fillOptions = [];

  setItemValue(value: string) {
    this.fillOptions[this.itemIndex].value = value;
    this.$emit('onItemChange', this.fillOptions)
  }

  getPreviousOptionsValue() {
    this.currentItem = { ...this.dynamicItem };
    const parsedOpt = JSON.parse(this.field.fieldOptions);
    const previousValue = this.field.value && 
      JSON.parse(this.field.value) || parsedOpt.map(opt => ({key: opt, value: null}));
    //NOTE: Set current item value
    this.currentItem = previousValue[this.itemIndex];
    //NOTE: Set filed options
    this.fillOptions = previousValue;
  }

  mounted() {
    this.getPreviousOptionsValue();
  }

}//
