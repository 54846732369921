
import { IDossier, IDossierOneFile } from "../../../../models/dossier.model";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import PazTooltipButton from "../../../../components/controls/PazTooltipButton.vue";
import DossierDocsMixin from "../../../../views/dossier/mixins/dossier-docs.mixin";
import DossierDocumentsItem from "../../../../views/dossier/parts/DossierDocumentsItem.vue";
import DossierDocumentsList from "../../../../views/dossier/parts/DossierDocumentsList.vue";
import {formatDateTime} from "../../../../services/base-service";

@Component({
  components: {
    PazTooltipButton,
    DossierDocumentsItem,
    DossierDocumentsList,
  },
  methods: {
    formatDateTime
  }
})
export default class ListAppItem extends Mixins(DossierDocsMixin) {
  @Prop() public item!: IDossier;
  @Prop() public isUserAdmin!: boolean;
  @Prop() public selectedGroupItem?: IDossier;

  public docs = { files: [] };
  public loadDoc: boolean = false;
  // listStyleFiles: files display, like a list else file type
  public listStyleFiles: boolean = true;
  public fileData: any = null;
  public visibleFiles: boolean = false;

  getStatusString(status: boolean) {
    return status ? "active" : "inactive";
  }

  async fetchFiles() {
    await this.getDossierDocument(this.item.id);
  }

  async showFiles() {
    if (!this.dossierFiles.hasOwnProperty("files")) {
      this.fetchFiles();
    }
  }

  clickFileStop(e: Event) {
    e.stopImmediatePropagation();
  }

  downloadFile(file: { data: string; doc: IDossierOneFile }) {
    this.fileData = file;
    this.$nextTick(() => {
      this.$refs.referral && this.$refs.referral.click();
    });
  }

  showItemFiles() {
    this.visibleFiles = !this.visibleFiles
  }

  get fileCount() {
    return this.item.hasOwnProperty("_fileCount") ? this.item._fileCount : "0";
  }

  mounted() {
    this.showFiles();
  }
}
