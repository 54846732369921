
import { Component, Vue, Watch } from "vue-property-decorator";
import { authentication } from "../../store";
import { setConfigSettings } from "@pazion/pazion-core/src/services/settings_config-service";
import { UserLoginForm } from "../../models/user-models";

@Component
export default class Login extends Vue {
  private user: UserLoginForm = { email: "", password: "", twoFactorCode: "" };
  public loading: boolean = false;
  private $enforceTwoFactor: boolean | undefined;
  private showPassword: boolean = false;
  private autofilled: boolean = false;
  private message: string = "";

  created() {
    if (authentication.isUserLoggedIn) {
      this.$router.push("/profile");
    }
  }

  get canSubmit() {
    if (this.autofilled) return true;
    return this.user.password.length > 0;
  }

  mounted() {
    this.intervalDetectAutofill = setInterval(() => {
      if (
        document.querySelectorAll('input[type="password"]:-webkit-autofill')
          .length > 0
      ) {
        this.$refs.username.$el
          .querySelector("label")
          .classList.add("v-label--active");
        this.$refs.password.$el
          .querySelector("label")
          .classList.add("v-label--active");

        this.autofilled = true;
        clearInterval(this.intervalDetectAutofill);
      }
    }, 100);

    // clean interval if needed after 3s
    setTimeout(() => {
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill);
        this.intervalDetectAutofill = null;
      }
    }, 3000);
  }

  get showTwoFactor(): boolean {
    if (this.$enforceTwoFactor == undefined) {
      return false;
    }
    return this.$enforceTwoFactor;
  }

  /**
   * Check if user is admin and hasn't configure 2FA;
   */

  isUserHasRole(role: string) {
    return authentication?.getProfile.roles.includes(role);
  }
  async redirectToUserProfile(currentUserId: number): Promise<boolean> {
    const isUseradmin = this.isUserHasRole('administrator');
    return isUseradmin && !(authentication?.user?.hasTwoFactor);
  }

  async handleLogin() {
    this.loading = true;
    this.message = "";
    if (!this.canSubmit) {
      this.loading = false;
      return;
    }
    try {
      await authentication.login(this.user);
      await authentication.verifyUser();
      if (authentication.getProfile === null) {
        this.loading = false;
        this.message = "Login server unreachable";
        return;
      } else {
        setConfigSettings(Vue);

        const currentUserId = authentication?.getProfile?.id;
        if (this.showTwoFactor && await this.redirectToUserProfile(currentUserId)) {
          await this.$router.push({ path: `/users/${currentUserId}` });
        }
        else if( this.isUserHasRole('production') ) {
          await this.$router.push({name: 'productions'})
        } 
        else await this.$router.push({ name: 'Home' });
      }
    } catch (error) {
      this.loading = false;
      const responseError = error?.response?.data?.error;
      if (responseError && responseError.length > 0) {
        this.message = responseError;
      }
      else if (error.response.status == 403) {
        this.message = "Invalid login credentials";
      } else if (error.response.status == 400 || error.response.status == 404) {
        this.message = "Login server unreachable";
      } else {
        this.message = "Could not authenticate";
      }
    }
  }
} //
