
import { Vue, Component, Emit, Prop, Watch } from "vue-property-decorator";
import {
  IOrderAttribute,
  IOrderAttributeName,
  IOrderLineAttribute,
} from "../../../models/order";
import {
  getOrderLineAttrValues,
  setAttributeValue,
  setOrderLineAttrValues,
  getOrderAttrValue,
  setOrdeAttrValues,
  deleteAttributesValue,
  getProductAttributeValues,
  setProductAttrValues
} from "../../../services/catalog-service";
import PazInputConfirmTextField from "@pazion/pazion-core/src/components/input/PazInputConfirmTextField.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { IproductAttributes } from "../../../models/product";

@Component({
  components: {
    PazInputConfirmTextField,
    PazTooltipButton,
    PazLoader,
  },
  methods: {
    formatDateTime,
  },
})
export default class AttributesTab extends Vue {
  @Prop({ default: true }) withInstance!: boolean;
  @Prop() orderAttributeNames!: IOrderAttributeName[];
  @Prop() attributeType!: string;
  @Prop({ default: true }) editable?: boolean;
  @Prop() instanceId!: number;

  public saving: boolean = false;
  public showAddAttributeForm: boolean = false;
  public loading: boolean = false;
  public selectedAttribute: string = null;
  public attributeValue: string = "";
  public attributeInstance: string = "";
  public instanceAttributes: IOrderLineAttribute[] | IOrderAttribute[] | [] =
    [];

  //NOTE: POST: Create attribute
  async addAttributeToOrder() {
    let data = {
      value: this.attributeValue,
      instance: this.attributeInstance ? this.attributeInstance : null,
    };
    let response: any;
    if (this.attributeType === 'product') {
      response = await setProductAttrValues({
        ...data,
        productAttribute: this.selectedAttribute,
        product: this.instanceId
      })
    }
    else if (!this.isOrderType) {
      response = await setOrderLineAttrValues({
        ...data,
        orderLineAttribute: this.selectedAttribute,
        orderLine: this.instanceId,
      });
    } else if (this.isOrderType) {
      response = await setOrdeAttrValues({
        ...data,
        orderAttribute: this.selectedAttribute,
        order: this.instanceId,
      });
    }
    this.$emit("updateData");
    if (response) await this.getAttributeesValues();
    this.showAddAttributeForm = false;
  }

  //NOTE: PUT: Update attribute
  async saveItem(value: string, id: number) {
    this.saving = true;    
    await setAttributeValue(this.attributeType, id, {
      value,
    });
    await this.getAttributeesValues();
    this.saving = false;
    this.$emit("updateData");
  }

  //NOTE: DELETE: Update attribute
  async deleteAttribute(attrId: number) {
    this.loading = true;
    const response = await deleteAttributesValue(attrId, this.attributeType);
    if (response) {
      await this.getAttributeesValues();
      this.$emit("updateData");
    } else this.loading = true;
  }

  reset() {
    this.selectedAttribute = "";
  }

  async fetchOrderAttrValues() {
    const filter = !this.isOrderType
      ? { orderLine: this.instanceId }
      : { order: this.instanceId };
    this.instanceAttributes = !this.isOrderType
      ? await getOrderLineAttrValues(filter)
      : await getOrderAttrValue(filter);      
  }

  async fetchProductAttributes() {
    const filter = { product: this.instanceId };
    const response = await getProductAttributeValues(filter);
    this.instanceAttributes = response && response.map( (r:IproductAttributes) => ({...r.productAttribute, ...r}));    
  }

  async getAttributeesValues() { 
    this.loading = true;
    switch (this.attributeType) {
      case "order":
        this.fetchOrderAttrValues();
        break;
      case 'orderLine':
        this.fetchOrderAttrValues();
        break;
      case "product":
        this.fetchProductAttributes();
        break;
    }
    this.loading = false;
    this.attributeValue = "";
  }

  get selectedAttributeHasInstance() {
    if (!this.selectedAttribute) return false;
    let attributeName = this.orderAttributeNames.find(
      (attributeName) => attributeName.id === this.selectedAttribute
    );
    return attributeName ? attributeName.isInstanceSpecific : false;
  }

  get getAttributes() {
    return this.instanceAttributes;
  }

  get isOrderType() {
    return this.attributeType === "order";
  }

  @Watch("showAddAttributeForm")
  onChangeShowAddAttributeForm(value: boolean) {
    !value && this.reset();
  }
  @Emit()
  updateData() {
    return true;
  }

  mounted() {
    this.getAttributeesValues();
  }
} //
