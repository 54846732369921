import Vue from 'vue';
import Vuex from 'vuex';
import PermissionModule,{IPermissionState} from "./permissionModule";
import AuthenticationModule, {IAuthenticationState} from './authenticationModule';
import VuexPersistence from 'vuex-persist';
import {getModule} from 'vuex-module-decorators';

export interface IRootState {
  authentication: IAuthenticationState;
  permission: IPermissionState;
}

Vue.use(Vuex);
const vuexLocal = new VuexPersistence<IRootState>({
  storage: window.localStorage,
  reducer(state) {
    return {
      authentication: {
        authenticated: state.authentication.authenticated,
        user: state.authentication.user,
      },
    };
  },
});

const store = new Vuex.Store<IRootState>({
  mutations: {},
  actions: {},
  modules: {
    permission: PermissionModule,
    authentication: AuthenticationModule,
  },
  plugins: [vuexLocal.plugin],
});
export default store;
export const authentication = getModule(AuthenticationModule, store);
export const permission = getModule(PermissionModule, store);
