import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import {ApiUser, LoginResponse, UserLoginForm, UserProfile} from '../models/user-models';
import {authentication} from '../store';
import { stringifyUrl } from 'query-string';
// import qs from 'qs';

export const authApi = axios.create({
    baseURL: `${process.env.VUE_APP_BACKEND_API_ROOT}`,
    headers: {'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json'},
    // headers: {'Content-Type': 'application/json'},
});
const API_ENDPOINT_TOKEN_REFRESH: string = 'token-refresh';
const API_ENDPOINT_LOGIN: string = 'token-refresh';
const API_ENDPOINT_LOGOUT: string = 'token-refresh';
const TOKEN_SAFETY_SECONDS: number = 60;


authApi.interceptors.request.use(
    async config => {
        if (!authentication.isAuthenticated) {
            return config;
        }
        if (config.url == API_ENDPOINT_TOKEN_REFRESH || config.url == API_ENDPOINT_LOGIN || config.url == API_ENDPOINT_LOGOUT) {
            return config;
        }
        if (needNewToken()) {
            const ud = fetchUserData(authentication.user?.token);
            if (ud == null) {
                authentication.logout();
                return config;
            }
            const refreshToken = authentication.user?.refreshToken ?? '';

            try {

                const newTokens = await getNewTokens(refreshToken, ud.id);
                if (newTokens != null) {
                    authentication.loginSuccess(newTokens);
                    // getSessionId();
                } else {
                    authentication.logout();
                    authentication.afterLogout();
                }
            } catch (e) {
                if (e.response.status === 403) {
                    authentication.logout();
                    authentication.afterLogout();
                }
            }
        }
        if (process.env.NODE_ENV == 'development' && config.url != undefined  && document.cookie.includes("XDEBUG")) {
            let sessionName = process.env.VUE_APP_DEBUG_SESSION_NAME != undefined ? process.env.VUE_APP_DEBUG_SESSION_NAME : 'PHPSTORM';
            if (config.url.includes('?')) {
                config.url += "&XDEBUG_SESSION_START=" + sessionName;
            } else {
                config.url += "?XDEBUG_SESSION_START=" + sessionName;
            }
        }
        config.headers.Authorization = `Bearer ${authentication.user?.token}`;
        return config;
    },
    error => {
        Promise.reject(error)
    });

function tokenIsExpired(): boolean {
    let expiration: number | undefined = fetchUserData(authentication.user?.token)?.exp;
    if (expiration === undefined) {
        return true;
    }

    return expiration < (Date.now() / 1000 + TOKEN_SAFETY_SECONDS);
}

export async function getRequest(url: string, config?: AxiosRequestConfig) {
    return authApi.get(url, config);
}

export async function postRequest(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = authApi.post(url, data, config).catch((error: Error) => {
        return {data: error}
    });
    return response
}

export async function patchRequest(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = authApi.patch(url, data, config).catch((error: Error) => {
        return { data: error }
    });
    return response
}

export async function putRequest(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = authApi.put(url, data, config).catch((error: Error) => {
        return { data: error }
    });
    return response
}

export async function deleteRequest(url: string, config?: AxiosRequestConfig) {
    const response = authApi.delete(url, config).catch((error: Error) => {
        return { data: error }
    });
    return response
}

function needNewToken(): boolean {
    return authentication.isAuthenticated && tokenIsExpired();
}

export async function loginUser(user: UserLoginForm): Promise<LoginResponse> {

    const bodyFormData = new FormData();
    bodyFormData.append('username', user.email);
    bodyFormData.append('password', user.password);
    //@ts-ignore
    if (user.hasOwnProperty('twoFactorCode') && user.twoFactorCode && user.twoFactorCode.length > 0) {
         //@ts-ignore
        bodyFormData.append('twoFactorCode', user.twoFactorCode);
    }
    const response = await authApi.post('login',
        bodyFormData,
        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    const rsp = response.data as LoginResponse;
    if (rsp.sessionId !== undefined && rsp.sessionId != '') {
        document.cookie = "PHPSESSID=" + rsp.sessionId + ";path=/";
    }
    return rsp;
}

export async function getSessionId() {
    const response = await authApi.get('/session-id');
    if (response.data.sessionId !== undefined && response.data.sessionId > 0) {
        document.cookie = "PHPSESSID=" + response.data.sessionId + ";path=/";
    }
    return response.data;
}


export async function requestPasswordReset(email: string, token?: string) {
    const response = await authApi.post('/reset-password', {email, token});
    return response.data;
}
export async function requestNewPassword(token: string, newPassword: string) {
    const response = await authApi.post('/reset-password', {newPassword, token});
    return response.data;
}

export async function getNewTokens(refreshToken: string, userId: number): Promise<LoginResponse | null> {
    const response = (await authApi.post(API_ENDPOINT_TOKEN_REFRESH, {refreshToken, userId})).data as LoginResponse;
    if (response.sessionId !== undefined && response.sessionId != '') {
        document.cookie = "PHPSESSID=" + response.sessionId + ";path=/";
    }
    return response;
}

export function fetchUserData(token?: string): UserProfile | null {
    if (token == null || token.length < 1) {
        return null;
    }
    const parts = token.split('.');
    // p[0] = token info
    // p[1] = userInfo;
    if (parts.length >= 2) {
        return JSON.parse(atob(parts[1])) as UserProfile;
    }
    return null;
}

export async function getLogs() {
    // const queryparams = qs.stringify({
    //     filter: {
    //         id: {
    //             gt: 1100,
    //         },
    //     },
    //     limit: 200,
    //     orderBy: 'id.desc',
    // });
    // return (await getRequest('catalog/logs?' + queryparams)).data;
    return (await getRequest('catalog/logs')).data;
}

export async function getUsers(queryParam?: {filter: string}, userId?: number ) {
    let baseUsersUrl = 'catalog/users';
    userId && (baseUsersUrl = `${baseUsersUrl}/${userId}`);
    let fullUrl = {
        url: baseUsersUrl,
        query: queryParam
    }
   const urlWithParam =  stringifyUrl(fullUrl)

    return (await getRequest(urlWithParam)).data;
}
export async function generateNewApiToken(userId: number){
    return (await postRequest('generate-edi-token', {user: userId})).data;
}
export async function fetchRoles() {
    return (await getRequest('catalog/roles')).data;
}

export async function fetchCountries() {
    return (await getRequest('catalog/countries')).data;
}

export async function getUserById(id: number): Promise<ApiUser | null> {
    return (await getRequest('catalog/users/' + id)).data;
}

export async function updateUser(id: number, data: any): Promise<ApiUser | AxiosError | null> {
    if (id > 0) {
        return (await putRequest('catalog/users/' + id, data)).data;
    }
    return (await postRequest('catalog/users/' + id, data)).data;
}

export async function deleteUser(id: number): Promise<ApiUser | null> {
    return (await deleteRequest('catalog/users/' + id)).data;
}


export async function getPackageVersions() {
    return (await getRequest('package-version')).data;
}

export async function getQueueJobs() {
    return (await getRequest('catalog/queue-job?orderBy=isRecurring.desc,id.desc&limit=2000')).data;
}

export async function getCommandList() {
    return (await getRequest('command-list')).data;
}

export async function executeCommand(command: object) {
    return (await postRequest('command-list', command)).data;
}


export async function logoutUser(): Promise<number> {
    const response = await authApi.post('logout');
    return response.status;
}

export async function getAccount(id:number) {
    return (await getRequest(`catalog/users/${id}`)).data;
}
