
import "../../../config";
import { Vue, Component, Prop, VModel, Watch } from "vue-property-decorator";
import {
  fetchCountries,
  fetchRoles,
  getUserById,
  updateUser,
} from "../../../services/auth-service";
import { ApiUser, IUserPostData } from "../../../models/user-models";
import PazTooltipButton from "../../../components/controls/PazTooltipButton.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import QRCode from "qrcode";

@Component({
  components: {
    PazTooltipButton,
    PazLoader,
  },
})
export default class pazUserEditDialog extends Vue {
  $refs!: {
    userForm: HTMLElement;
    qrcanvasElement: Element;
  };
  @VModel({
    default: false,
  })
  public showEditUserDialog!: boolean;

  @Prop({ default: null }) userId!: number | null;
  @Prop({ default: false }) isPage?: boolean;

  public roles: object[] = [];
  public countries: object[] = [];
  public loading: boolean = false;
  public userFormModel: boolean = false;

  public feedback: string = "";
  public email: string = "";
  public name: string = "";
  public country: number = 150;
  public role: number = 1;
  public password: string = "";
  public password_confirmation: string = "";
  public isActiveUser: boolean = false;
  public isPublicUser: boolean = false;
  public twoFactorCode: string = "";
  public twoFactorSecret: string = "";
  public hasTwoFactor: boolean = false;
  public two_factor_string: string = "";

  created() {
    this.loading = true;
    this._getCountries();
    this._getRoles();
  }

  async handleSubmit() {
    this.$refs.userForm.validate();
    if (!this.userFormModel) return;
    this.loading = true;
    const userId = (this.userId && this.userId) || 0;
    let userData: IUserPostData = {
      username: this.email,
      name: this.name,
      role: this.role,
      country: this.country,
      password: this.password,
      is_activated: this.isActiveUser,
      password_confirmation: this.password_confirmation,
      isPublic: this.isPublicUser,
      twoFactorCode: this.twoFactorCode,
      twoFactorSecret: this.twoFactorSecret,
    };
    if (this.isPublicUser) delete userData.username;
    const rsp = await updateUser(userId, userData);

    if (rsp.success) {
      if(!this.isPage) {
        this.showEditUserDialog = false;
        this.$emit("onUserChange");
        return 
      } 
      this.loading = false;
      await this.fetchData();
       if ( this.canCloseModal() )
          this.$router.push({name: 'Home'})
    } else {
      if (rsp && !rsp.success && rsp.hasOwnProperty("message")) {
        this.feedback = rsp.message;
      } else {
        this.feedback = "Unknown error, see the logs";
      }
      this.loading = false;
    }
  }

  canCloseModal() {
    return (!this.$enforceTwoFactor || !this.isPage) || (this.$enforceTwoFactor && this.hasTwoFactor);
  }

  emailMatchError() {
    return [
      () => (true ? !!this.email || this.isPublicUser : "Email is required"),
    ];
  }

  mounted() {
    this.fetchData();
  }

  async _getCountries() {
    this.countries = await fetchCountries();
  }

  get getCountries() {
    return this.countries;
  }

  setData(obj: ApiUser) {
    this.name = obj.name;
    this.email = obj.username;
    this.country = obj.country;
    this.role = obj.role;
    this.isActiveUser = obj.isActivated;
    this.isPublicUser = obj.isPublic;
    this.twoFactorCode = "";
    this.hasTwoFactor = obj.hasTwoFactor;
    this.twoFactorSecret = obj.new_twofactor;
    this.two_factor_string = obj.two_factor_string;
  }

  loadCanvas() {
    setTimeout(() => {
      const canvasElement = this.$refs.qrcanvasElement;
      if (canvasElement) {
        QRCode.toCanvas(
          canvasElement,
          this.two_factor_string,
          function (error) {
            if (error) console.error(error);
          }
        );
      }
    }, 100);
  }

  async fetchData() {
    if (this.userId) {
      const rsp = await getUserById(this.userId);
      if (rsp != null) {
        this.setData(rsp);
        this.loadCanvas();
      }
    }
    this.loading = false;
  }

  get getRoles() {
    return this.roles;
  }

  get isLoading() {
    return this.loading;
  }

  async _getRoles() {
    this.roles = await fetchRoles();
  }

  @Watch("isPublicUser")
  publicUserChange(oldVal: string | null, newVal: string | null) {
    if (oldVal !== newVal && this.$refs.hasOwnProperty("userForm")) {
      this.$refs.userForm.resetValidation();
      this.email = "";
    }
  }
} //
