
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class ProgressArrow extends Vue {

  @Prop({default: 'blue'})
  public type!: string;
  @Prop({default: 'mdi-cart'})
  public icon!: string;
  @Prop({default: ''})
  public tooltip!: string;

  @Prop({default: false})
  public first!: boolean;
  @Prop({default: false})
  public last!: boolean;

  get firstCords() {
    if (!this.first) {
      return '7.5789';
    }
    return '0';
  }

  get lastCords() {
    if (!this.last) {
      return '28.4211';
    }
    return '36';
  }

  get fillColor() {
    switch (this.type) {
      case 'red':
        return '#FF5252';
      case 'green':
        return '#4EAB76';
      case 'grey':
        return '#CBCBCB';
      case 'orange':
        return '#f7942e';
    }
    return '#01AEF0';
  }
}
