import { Component, Prop, Mixins } from "vue-property-decorator";
import BaseMixin from "../../../mixins/base.mixin";
import { IFulfilment } from "../../../models/fulfilment";
import { IOrder, IOrderLine } from "../../../models/order";
import { formatDate, formatDateYMD, formatPrice } from "../../../helpers/format";
import { getFulfilment, getFulfilmentOrderLines, getOrderLines, updateOrderLine, getProductById, getFulfilmentTransports, getProductAccountPrice } from "../../../services/catalog-service";
import { getProductionWorktypes, getProductionOrder } from "../../../services/order-service";
import { PatchPricing } from "./patchPricingMixin";
import * as dayjs from 'dayjs';

@Component({
  methods: {
    formatDate,
    formatDateYMD,
    formatPrice
  }
})
export class OrdersDialogMixin extends Mixins(BaseMixin, PatchPricing) {

  @Prop({ default: null })
  readonly order!: IOrder | null | undefined;

  public selected = [];
  public fulfilment: IFulfilment | undefined;
  public mainPreLoading: boolean = false;
  public orderLines: IOrderLine[] | [] = [];
  public productionType: string = '';
  public manufacturID: string | null = null;
  public productionTypes: { key: string[]; val: any; }[] = [];
  public quantity: number | null = null;
  public productionNotes: string = "";
  private _timerId: ReturnType<typeof setTimeout> | undefined = undefined;
  public selectedTransporter = "default";
  public transports: { [k: string]: any } = {};

  //Don't calculate stocks
  private unlimitedStocks: boolean = true
  public preloadOrderLine: boolean = false;
  public cashNewFastLine: number = 0;
  public currentProdType: string = '';

  onSelectAll(data: { items: IOrderLine[]; value: boolean }, isProduction?: boolean) {
    if (isProduction) return;
    data.items.forEach((item: IOrderLine) => {
      item.newQuantity = item.max
    });
  }

  onItemSelected(data: any, isProduction?: boolean) {
    if (isProduction) return;
    data.item.newQuantity = data.item.max;
  }


  selectItemByIncrementDecrement(item: IOrderLine, actionFlag: null | string = null) {
    if (
      actionFlag === "unselect" &&
      this.selected.length !== 0 &&
      item.newQuantity >= 0
    ) {
      this.selected = this.selected.filter(
        (selectedItem) => selectedItem === item.id
      );
      return;
    }

    if (
      (this.selected.length === 0 && actionFlag !== "unselect") ||
      (!this.selected.map((sItem: IOrderLine) => sItem.id).includes(item.id) &&
        actionFlag !== "unselect")
    ) {
      this.selected.push(item);
    }
  }

  showQuantityWarningToast(message: string) {
    const toastElement = this.$parent.$refs.toast || this.$refs.toast;
    toastElement.show({
      message: message,
      color: "orange",
      icon: "mdi-alert-circle",
    });
  }

  increment(item: any, isOrder?: boolean, hasDimensions?:boolean) {
    let index = this.orderLines.findIndex((line) => line.id === item.id);
    const currentQuantity = this.orderLines[index].newQuantity;
    if (typeof currentQuantity === 'number' && !isNaN(currentQuantity)) {
      this.selectItemByIncrementDecrement(item);
      if (currentQuantity >= 0) {
        if (this.orderLines[index].newQuantity < item.max || this.unlimitedStocks) {
          this.orderLines[index].newQuantity += 1;
          isOrder && this.successUpdatedQuantity(this.orderLines[index], null, hasDimensions);
          return;

        }
        this.showQuantityWarningToast(`Should be equal or less than ${item.max} .`)
      }
    }
  }

  decrement(item: any, isOrder?: boolean, hasDimensions?: boolean) {
    let index = this.orderLines.findIndex((line) => line.id === item.id);
    const currentQuantity = this.orderLines[index].newQuantity;
    if (typeof currentQuantity === 'number' && !isNaN(currentQuantity)) {
      if (this.orderLines[index].newQuantity === 1) {
        this.selectItemByIncrementDecrement(item, "unselect");
      }
      if (currentQuantity > 0) {
        this.orderLines[index].newQuantity -= 1;
        isOrder && this.successUpdatedQuantity(this.orderLines[index], null, hasDimensions);
        return;
      }
      this.showQuantityWarningToast(`Should be equal or more than 1 .`);
    }
  }


  updatePricingField(value: string, item: IOrderLine, fieldKey: string, hasDimensions?:boolean) {
    this.updateSquare(value, item, fieldKey, hasDimensions);
  }

  updateQuantity(value: any, item: IOrderLine, hasDimensions?: boolean) {
    if (!this.preloadOrderLine && value) {
      let index = this.orderLines.findIndex((line) => line.id === item.id)
      if (parseInt(value) === 0) {
        this.selectItemByIncrementDecrement(item, "unselect");
        this.orderLines[index].newQuantity = parseFloat(value);
        this.orderLines[index].quantity = parseFloat(value);
        return;
      }
      if (parseFloat(value) > parseFloat(this.orderLines[index].max) && !this.unlimitedStocks) {
        value = this.orderLines[index].max;
        this.showQuantityWarningToast(`Should be equal or less than ${this.orderLines[index].max} .`);
        return
      }
      if (parseInt(value) < 0) {
        value = 1;
        this.showQuantityWarningToast(`Should be equal or more than 1 .`);
        return
      }

      this.selectItemByIncrementDecrement(item);
      this.orderLines[index].newQuantity = parseFloat(value);
      this.orderLines[index].quantity = parseFloat(value);
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        this.successUpdatedQuantity(this.orderLines[index], null, hasDimensions);
      }, 1000);
    }
  }//

  updateSquare(value: any, item: IOrderLine, key: string, hasDimensions?: boolean) {
    let index = this.orderLines.findIndex((line) => line.id === item.id);
    if (value) {
      //@ts-ignore
      this.orderLines[index][key] = value;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        this.successUpdatedQuantity(this.orderLines[index], key, hasDimensions);
      }, 1300);
    }
  }

  calculateSquare(item: IOrderLine) {
    let index = this.orderLines.findIndex((line) => line.id === item.id);
    //@ts-ignore
    const length = parseFloat(this.orderLines[index].length);
    //@ts-ignore
    const width = parseFloat(this.orderLines[index].width);
    let total = 0;

    if (length && width) {
      total = length * width;

      if (this.$measureCalculationUnit == "mm") {
        total = (total / 1000000);
      }

      this.orderLines[index].calculatedSquare = total;
    } else {
      this.orderLines[index].calculatedSquare = 0;
    }
  }

  async successUpdatedQuantity(line: IOrderLine, key?: string | null, hasDimensions?:boolean) {
    this.preloadOrderLine = true;
    let completeLine = line;
    completeLine.quantity = line.newQuantity;
 
    if (this.$orderlineMeasureCalculation && (hasDimensions === undefined || hasDimensions)) {
      //run calculation
      this.calculateSquare(completeLine);
      let calculatedPrice = parseFloat(line.price) / (line.sourceSquare || 1);

  
      if (this.$measureCalculationUnit == "mm") {
        calculatedPrice = (calculatedPrice * 1000000);
      }
      //@ts-ignore
      completeLine.price = Math.round((calculatedPrice * completeLine.calculatedSquare + Number.EPSILON) * 100) / 100;
      
    }
    else
      //@ts-ignore
      completeLine.price = Math.round((parseFloat(line.price) + Number.EPSILON) * 100) / 100;

    if (key !== 'tax')
      delete completeLine.tax

    if (key === 'total') {
      //@ts-ignore
      completeLine.total = Math.round((parseFloat(line.total) + Number.EPSILON) * 100) / 100;
      completeLine.price = completeLine.total;
    } else {
      //@ts-ignore
      delete completeLine.total

    }

    delete completeLine.newQuantity;
    delete completeLine.calculatedSquare;
    delete completeLine.editField


    const response = await updateOrderLine(line.id, completeLine);
    if (response && response.success) {
      this.fetchData(line.order);
      this.$emit("update-data");
    }
    this.preloadOrderLine = false;
  }

  /**
   * NOTE: Get SP by account and productId
   */
  async getSpByProductId(customerId: number, productId: number) {
    return await getProductAccountPrice(
      {
        filter: JSON.stringify({
          operator: 'AND',
          product_catalog_pricelist_account__account_id: customerId,
          product_catalog_product_pricelist__product_id: productId
        })
      }
    )
  }

  async changeItemProduct(id: number, item: IOrderLine, orderDetailsData?: IOrder, hasDimensions?: boolean) {
    if (id) {
      const newProduct = await getProductById(id);
      if (newProduct) {
        let newLineProd: IOrderLine = item;
        Object.assign(newLineProd, item);
        const specialPrice = 
          await this.getSpByProductId(orderDetailsData?.customer?.id, newProduct.product_catalog_product__id)
        newLineProd.product.id = newProduct.product_catalog_product__id;
        newLineProd.product.sku = newProduct.product_catalog_product__sku;
        newLineProd.description = newProduct.product_catalog_product__name;
        if (!hasDimensions) {
          if (this.$measureCalculationUnit == "mm") {
            newLineProd.length = 1000;
            newLineProd.width = 1000;
          } else {
            newLineProd.length = 1;
            newLineProd.width = 1;
          }

        }
        if (item.product && Object.keys(item.product).length === 2) {
          newLineProd.price = item.price;
        }
        else if (item.product) {
          if (specialPrice?.length > 0) {
            item.specialPrice = specialPrice.shift();
            const sp = item.specialPrice?.product_catalog_product_pricelist__price;
            newLineProd.price = this.patchCalculatedPrice(parseFloat(sp), newLineProd);
          } else {
            newLineProd.price = 
              this.patchCalculatedPrice(parseFloat(newProduct.product_catalog_product__price), hasDimensions ? newLineProd : item); 
          }
        }
        if (newLineProd.description === null) {
          newLineProd.description = newProduct.product_catalog_product__sku;
        }    
        await this.successUpdatedQuantity(newLineProd, null, hasDimensions);
      }
    }
  }

  isItemSelected(item: any) {
    return !this.selected.map((item) => item.id).includes(item.id);
  }


  onChangeProdType(item: Event, data: any, key: string) {
    data[key] = item;
  }

  formatDate(date: string) {
    if (date == null) {
      return "";
    }
    return new Date(date).toLocaleDateString("nl-nl", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  onPickDate() {
    this.$refs.deliveryDialogPicker.save(this.deliverAt);
  }


  get cTransports() {
    let availableTransports: string[] = [];
    for (const key in this.transports) {
      if (this.transports[key]) availableTransports.push(key);
    }
    return availableTransports;
  }

  get cOrderStatus() {
    return this.order && this.order.hasOwnProperty('status') ? this.order.status.replace(/_/, " ") : '';
  }

  get cOrderDate() {
    if (this.order && this.order.orderDate) {
      return this.order.orderDate //this.formatDate(this.order.orderDate)
    }
    return '';
  }

  get cTotal() {
    let initValue = 0;
    let total = this.selected.reduce((accumulator: number, currentValue: IOrderLine) => {
      if (!currentValue.price || !currentValue.newQuantity) return accumulator;
      return accumulator + parseFloat(currentValue.price) * currentValue.newQuantity
    }
      , initValue);
    return formatPrice(total ? total : '');
  }


  get orderHasProduction() {
    return this.order && this.order.production > 0;
  }


  preparingOrderLine(orderLine: IOrderLine[], fulfilmentsLine, isOrderModal: boolean) {
    return orderLine.map((line: IOrderLine) => {
      //TODO: Set stocks count, (it was: let max = line.stockCount)
      let max = line.quantity;
      let ordered = 0;
      let relatedFulfilment = null;

      if (this.cashNewFastLine === line.id) {
        line.fromQuickMenu = true;
      }

      if (fulfilmentsLine.length !== 0) {
        relatedFulfilment = fulfilmentsLine.find((fLine: object) => {
          return fLine.orderLine === line.id;
        });
      }

      if (relatedFulfilment && relatedFulfilment.hasOwnProperty('quantity')) {
        ordered = parseFloat(relatedFulfilment.quantity);
        max = max - ordered;
      }

      // if product doesn't exist set to default
      if (!line.product) line.product = { id: 0 }

      if (!line.shipped) line.shipped = ordered;
      if (!line.max) line.max = max;
      //@ts-ignore
      if (!line.newQuantity) line.newQuantity = isOrderModal && line.quantity ? parseFloat(line.quantity) : 0;

      if (this.$orderlineMeasureCalculation) {
        line.width = line.width ? line.width : 0;
        line.length = line.length ? line.length : 0;
        line.sourceSquare = parseFloat(line.width * line.length);     
      }

      return line;
    })
  }

  async fetchData(orderId?: number, fromQuickMenu?: boolean) {

    this.cashNewFastLine = 0;
    const currentOrder = orderId || this.orderId;
    if (currentOrder) {
      this.mainPreLoading = true;
      if (!this.orderHasProduction) {
        this.fulfilment = (await getFulfilment({ order: currentOrder }))[0];
        let fulfilmentsLine: any[] = this.fulfilment
          ? await getFulfilmentOrderLines({ fulfilment: this.fulfilment.id })
          : [];
        // TODO: add conditional for us  with several modals
        this.transports = await getFulfilmentTransports();
        let prodTypeObject = await getProductionWorktypes();
        this.productionTypes = prodTypeObject;
        //@ts-ignore
        let orderLine = this.orderDetailsData ? this.orderDetailsData.orderlines : await getOrderLines({ order: currentOrder })
        this.orderLines = this.preparingOrderLine(orderLine, fulfilmentsLine, !!orderId);
        if (fromQuickMenu) {
          this.orderLines.sort((a, b) => {
            return dayjs(b.changedAt ? b.changedAt : b.createdAt).unix() - dayjs(a.changedAt ? a.changedAt : a.createdAt).unix();
          });
          this.cashNewFastLine = this.orderLines[0].id;
        }
        this.fulfilment = (await getFulfilment({ order: currentOrder }))[0];
      }
      this.mainPreLoading = false;
    }
  }

  updateAfterSuccessProduction(response: any) {
    this.loading = false;
    this.$parent.$refs.toast.show({ message: response.message, color: 'green' });
    this.$emit("order:update");
    this.showDialog = false;
  }

  /**
   * Create order production record
   */
  async startProduction() {
    this.loading = true;

    let orderLine: { quantity: number; id: any }[] = [];

    if (this.selected.length) {
      this.selected.forEach((item) => {
        orderLine.push({
          id: item.id,
          quantity: item.newQuantity,
          worktype: item.prodType,
          notes: item.notes
        });
      });
    }

    const postData = {
      id: this.orderId,
      deliverAt: this.deliverAt,
      orderLine,
      transporter: this.selectedTransporter,
      notes: this.productionNotes,
    };

    try {
      const prodRecord = await getProductionOrder(postData);
      if (prodRecord && prodRecord.success) {
        this.updateAfterSuccessProduction(prodRecord);
      } else {
        this.$parent.$refs.toast.show({
          message: prodRecord?.response?.data?.message,
          color: 'red'
        });
      }
      this.loading = false;
    } catch (error) {
      this.showDefaultServerErrorToast();
      this.loading = false;
    }
  }

  get lineWorkTypes() {
    const prodTypesArray = Object.values(this.productionTypes);
    let defaultType = prodTypesArray.find(pr => pr && pr.default);
    defaultType && (this.currentProdType = defaultType.label);
    return prodTypesArray;
  }

  get canCreateRecord() {
    return this.deliverAt && this.selected.length > 0;
  }

}//
export default OrdersDialogMixin;
