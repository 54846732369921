
import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import PazDossierCoursePage from "@pazion/pazion-core/src/views/dossier/parts/pazDossierCoursePage.vue";

@Component({
  components: { PazDossierCoursePage },
})
export default class PazOrderFilesDialog extends Vue {
  @Prop() orderId!: number;

  @VModel({
    default: false,
  })
  public showOrderFilesModal!: boolean;

}
