
import { Component, Prop, Mixins } from "vue-property-decorator";
import { formatPrice } from "../../../helpers/format";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import { IAccountProductPrice, IOrder, IOrderAttributeName, IOrderLine } from "../../../models/order";
import { DataTableHeader } from "vuetify";
import {
  deleteOrderLine,
  getCategoriesProduct,
} from "../../../services/catalog-service";
import PazOrderLineEditor from "../../editor/PazOrderLineEditor.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazOrderLineRow from "../../editor/PazOrderLineRow.vue";
import {
  updateOrderLine,
  getOrderLineAttributes
} from "../../../services/catalog-service";
import { IApiCategory } from "../../../models/category";
import { IProduct, IproductTypesModel } from "../../../models/product";
import OrdersDialogMixin from "../mixins/ordersDialogMixin";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazProductAsyncPicker from "../../picker/PazProductAsyncPicker.vue";
import QuickMenu from "../../custom/QuickMenu.vue";
import PazOrderAttributesDialog from "../PazOrderAttributeDialog.vue";
import AttributesTab from "../tabs/AttributesTab.vue";
import TableEditableField from "./components/TableEditableField.vue";
import { PatchPricing } from "../mixins/patchPricingMixin";

const NEW_ITEM = {
  id: 0,
  product: { id: 0, sku: "" },
} as IOrderLine;

@Component({
  components: {
    PazOrderLineRow,
    PazConfirmation,
    PazOrderLineEditor,
    PazTooltipButton,
    PazToast,
    PazProductAsyncPicker,
    QuickMenu,
    PazOrderAttributesDialog,
    AttributesTab,
    TableEditableField
  },
  methods: {
    formatPrice,
  },
})
export default class OrderItemsTab extends Mixins(
  OrdersDialogMixin,
  PatchPricing
) {
  private NEW_ITEM: IOrderLine = NEW_ITEM;
  $refs!: {
    confirm: PazConfirmation;
  };
  @Prop() public orderDetailsData!: IOrder;
  @Prop() public orderLineStatuses: object;
  @Prop() public editAble!: boolean;
  @Prop() public editProduct!: boolean;
  @Prop() editableStatus!: boolean;
  @Prop() productTypes!: IproductTypesModel[] | [];

  public orderLineDetailsHeaders: DataTableHeader[] = [
    {
      text: "sku",
      align: "start",
      class: "nowrap",
      sortable: true,
      value: "sku",
    },
    {
      text: "Description",
      align: "start",
      class: "nowrap",
      sortable: true,
      value: "description",
    },
    {
      text: "Quantity",
      align: "center",
      class: "nowrap",
      sortable: true,
      value: "quantity",
    },
    {
      text: "Status",
      align: "center",
      class: "nowrap",
      sortable: true,
      value: "status",
      width: "20%",
    },
    {
      text: "Length",
      align: "center",
      class: "hasMeasuring",
      sortable: false,
      value: "length",
    },
    {
      text: "Width",
      align: "center",
      class: "hasMeasuring",
      sortable: false,
      value: "width",
    },

    {
      text: "Price",
      align: "center",
      class: "nowrap",
      sortable: true,
      value: "price",
      cellClass: "nowrap",
    },
    {
      text: "Tax",
      align: "center",
      class: "nowrap",
      sortable: true,
      value: "tax",
      cellClass: "nowrap",
    },
    {
      text: "Total",
      align: "end",
      class: "nowrap",
      sortable: true,
      value: "total",
      cellClass: "nowrap",
    },
    {
      text: "Actions",
      align: "start",
      class: "nowrap",
      sortable: false,
      value: "action",
      cellClass: "nowrap",
    },
  ];

  public loadOrderItems: boolean = false;
  public addNewOrderLine: boolean = false;
  public categoriesProducts: IApiCategory[] = [];
  public selectedOrderLine: IOrderLine = { product: { id: 0 } };
  public showOrderLineEditor: boolean = false;
  public lineProduct = null;
  public orderAttributeNames: IOrderAttributeName[] = [];
  public selectedOrderLineAttr: IOrderLine | undefined;
  public showAttributesModal: boolean = false;
  public rowToBlink = null;

  get orderLinesArray() {
    return this.orderLines;
  }

  get computedNotes() {
    return this.orderDetailsData.notes;
  }

  get getDiscount() {
    return this.orderDetailsData?.order?.discount || 0;
  }

  get getTax() {
    return this.orderDetailsData?.order?.tax;
  }

  get getSubTotal() {
    return this.orderDetailsData?.order?.total - this.orderDetailsData?.order?.tax;
  }

  get canEditOrderLineStatus() {
    return this.$editOrderLineStatus != undefined && this.$editOrderLineStatus;
  }

  get selectedOL() {
    return this.selectedOrderLine;
  }


  markLine(item: IOrderLine) {
    return this.cashNewFastLine === item.id ? 'blink' : '';
  }

  getOrderAttributesCount(item: IOrderLine) {
    return item.attributeValue?.length;
  }

  async openOrderLineAttributeModal(item: IOrderLine) {
    this.selectedOrderLineAttr = item;
    this.showAttributesModal = true;
  }

  getAvailableOrderLineStatuses(item: IOrderLine): any[] {
    let itemStatus: string = item.status;
    if (!itemStatus && !this.orderLineStatuses) {
      return [];
    }
    if (!this.orderLineStatuses.hasOwnProperty(itemStatus)) {
      return [];
    }
    let selectableStatuses: string[] = this.orderLineStatuses[itemStatus];
    if (
      selectableStatuses.length !== 0 &&
      !selectableStatuses.includes(itemStatus)
    )
      selectableStatuses.push(itemStatus);
    if (!selectableStatuses) {
      return [];
    }
    return selectableStatuses.map((status: string) => {
      return { status, label: status.replaceAll("_", " ") };
    });
  }

  async handleOnStatusLineChange(value: any, item: any) {
    let data = {
      order: parseInt(this.orderDetailsData.order.id),
      status: value,
    };
    const rsp = await updateOrderLine(item.id, data);
    if (rsp.success) {
      this.$emit("update-data");
    }
  }

  public result: any;

  async fetchDataOrder() {
    this.loadOrderItems = true;
    await this.fetchData(this.orderDetailsData.id);
    this.orderAttributeNames = await getOrderLineAttributes();
    if (this.$orderQuickMenu)
      this.categoriesProducts = await getCategoriesProduct();
    this.loadOrderItems = false;
  }

  bubbleUpdate() {
    this.$emit("update-data");
    this.fetchDataOrder();
  }

  async handleOnClickQuickMenuProductButton(product: IProduct) {
    this.loadOrderItems = true;
    let data = {
      order: parseInt(this.orderDetailsData.order.id),
      quantity: 1,
      //      notes: product.descriptionShort,
      price: parseFloat(product.price),
      total: parseFloat(product.price),
      product: { id: product.id },
    };

    const orderLines: any[] = this.orderDetailsData?.orderlines;

    let createdOrder = orderLines.find((orderLine: any) => {
      return orderLine.product.id === product.id;
    });
    const orderLineId: number = createdOrder ? createdOrder.id : -1;
    if (createdOrder) {
      data.quantity += parseFloat(createdOrder.quantity);
      data.total = data.quantity * data.total;
    }

    const rsp = await updateOrderLine(orderLineId, data);
    if (rsp.success) {
      this.$emit("update-data");
      this.fetchData(parseInt(this.orderDetailsData.order.id), true);
      this.resortToNewLine = 'changedAt';
    }

    this.loadOrderItems = false;
  }

  async deleteOrderLine(item: IOrderLine) {
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Delete",
      `Are you sure you want to delete ${item.quantity} x ${item.product.sku} ${item.description}?`
    );
    if (confirm) {
      await updateOrderLine(item.id, { total: 0, price: 0, tax: 0 });
      const rsp = await deleteOrderLine(item.id);
      if (rsp) {
        this.$emit("update-data");
        this.fetchData(item.id);
      }
    }
  }

  newOrderLineV2() {
    this.addNewOrderLine = true;
  }

  closeOrderLineForm() {
    this.addNewOrderLine = false;
  }

  newOrderLine() {
    const clone = Object.assign({}, this.NEW_ITEM);
    clone.order = this.orderDetailsData.id;
    clone.quantity = 1;
    this.selectedOrderLine = clone;
    this.showOrderLineEditor = true;
  }

  editOrderLine(item: IOrderLine) {
    // NOTE: PLACE
    this.selectedOrderLine = item;
    this.selectedOrderLine.isDimensionedLine = this.isdimensionedType(item?.product?.productType, this.productTypes);
    this.showOrderLineEditor = true;
  }

  editFieldStatus(item: IOrderLine, status: boolean, field?: string | null) {
    if (this.editAble || (this.editableStatus && (field === 'product' || field === 'lineTrigger'))) {
      this.mainPreLoading = true;
      const index = this.orderLines.findIndex((line) => line.id === item.id);
      if (!field || field === 'product' || field === 'lineTrigger')
        this.orderLines[index].editField = status;
      else this.orderLines[index][field].editField = status;
      this.mainPreLoading = false;
    }
  }

  get getOrderLineHeaders() {
    //@ts-ignore
    if (!this.$orderlineMeasureCalculation)
      return this.orderLineDetailsHeaders.filter(
        (item) => item && !item.class.includes("hasMeasuring")
      );
    return this.orderLineDetailsHeaders;
  }

  mounted() {
    this.fetchDataOrder();
  }
} //
