
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import {getViewProducts} from "../../services/catalog-service";
import {IApiProduct} from "../../models/product";
import {formatPrice} from "../../helpers/format";

@Component({
  methods: {
    formatPrice
  }
})
export default class PazProductPicker extends Vue {

  public loading: boolean = true;
  public errorMessage: string = '';
  public items: IApiProduct[] = [];
  public MaxtextLength = 50;

  @VModel()
  public productId?: number;

  @Prop()
  public label!: string;

  async fetchData() {
    this.loading = true;
    this.items = await getViewProducts(
    {product_catalog_product__is_deleted: 0},50000);
    this.loading = false;
  }
  shorten(shorten?: string){
    if(shorten == undefined){
      return '';
    }
    if(shorten.length > this.MaxtextLength){
        return shorten.substring(0, this.MaxtextLength) + '..';
    }
    return shorten;
  }

  get nothingSelected() {
    return this.productId === undefined;
  }

  mounted() {
    this.fetchData();
  }

  filterfunc(item: IApiProduct, queryText: string, itemText: string) {
    return (item.product_catalog_product__name != null && item.product_catalog_product__name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
        ||
        item.product_catalog_product__sku.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
  }
}
