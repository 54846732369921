<template>
    <v-row>
        <v-col>
          <v-data-table
              class="elevation-1 order-line-details-table log-data-table"
              :items="orderDetailsData.fulfilmentOrderLines"
              :headers="headers.fulfilmentOrderLines"
              no-data-text="No fulfilments found."
              no-results-text="No matching fulfillments found."
              ref="fulfilmentsDataTable"
              :expanded.sync="expandAnimations.fulfilmentsDataExpanded"
              item-key="id"
              group-by="fulfilmentsId"
              group-desc="true"
              :footer-props="footer"
              show-expand
          >
            <!-- fulfilments group header -->
            <template slot="group.header" slot-scope="{ group,headers,items,item}">
              <td :colspan="headers.length - 1" style="height:inherit" class="pa-2">
                FUL-{{ group }}
              </td>
              <td class="text-right" style="height:inherit">
                                            <span class="cell-truncate">
                                                <v-icon
                                                    class="mr-2"
                                                    @click="fulfilmentOperator.detailsDialog = true"
                                                >
                                                    mdi-information
                                                </v-icon>
                                                <v-icon
                                                    @click=
                                                        "{{
                                                            fulfilmentOperator.transportsDialog = true
                                                            getItemGroupById
                                                            (
                                                                orderDetailsData.transports,
                                                                'fulfilmentsId',
                                                                group,
                                                                'fulfilmentOperator.transportsItemsById'
                                                            )
                                                        }}"
                                                >
                                                    mdi-truck-delivery
                                                </v-icon>
                                            </span>
                <v-dialog
                    v-model="fulfilmentOperator.transportsDialog"
                    max-width="500px"
                >
                  <v-card>
                    <v-card-title class="pa-0">
                      <v-toolbar
                          dark
                          color="primary"
                          flat
                          per
                          dense
                      >
                        <v-btn
                            icon
                            dark
                            small
                            @click="fulfilmentOperator.transportsDialog = false;"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Transports Details # {{ group }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <!--
                          <v-btn
                              dark
                              text
                              x-large
                              @click=""
                          >
                            Save
                          </v-btn>
                          -->
                        </v-toolbar-items>
                      </v-toolbar>
                    </v-card-title>
                    <v-card-text>
                    </v-card-text>

                    <v-card-actions>
                      <v-container class="text-center">
                        <v-btn
                            class="text-center"
                            color="blue darken-1"
                            text
                            @click="fulfilmentOperator.transportsDialog = false"
                        >
                          Ok
                        </v-btn>
                      </v-container>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="fulfilmentOperator.detailsDialog" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this orderline ?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="orderlineCloseDelete()">Cancel</v-btn>
                      <v-btn color="blue darken-1" text @click="orderlineDeleteItemConfirm()">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </template>

            <!-- fulfilments top content -->
            <template v-slot:top>
              <v-toolbar
                  flat
                  max-height="55px"
              >
                <v-icon class="mr-2">mdi-order-bool-ascending-variant</v-icon>
                <v-toolbar-title class="headline">
                  Fulfillment details
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <!-- fulfilments orderline expanded buttons -->
            <template v-slot:item.data-table-expand="props">
              <v-icon
                  :class="{
                                                          'v-data-table__expand-icon': true,
                                                          'v-data-table__expand-icon--active': props.isExpanded && expandAnimations.transitioned [
                                                               props.item.id
                                                          ]
                                                        }"
                  @click="toggleExpand(props,props.item.id)"
              >
                mdi-chevron-down
              </v-icon>
            </template>

            <!-- fulfilments expanded content -->
            <template v-slot:expanded-item="{headers, item}">
              <td
                  class="elevation-1"
                  :colspan="headers.length"
                  :class="{'ma-0 pa-0': true, 'expanded-closing': !expandAnimations.transitioned [
                                                            item.id
                                                        ]}"
                  style="height: auto;"
              >
                <v-expand-transition v-show="expandAnimations.transitioned[item.id]">
                    <v-card>
                      <v-card-tex>
                      </v-card-tex>
                    </v-card>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip>
                        {{
                          item.changedAt
                              ? toEuropeanDateWithTimeString(item.changedAt)
                              : item.createdAt
                              ? toEuropeanDateWithTimeString(item.createdAt) : ""
                        }}
                      </v-chip>
                    </template>
                    {{ item }}
                </v-expand-transition>
              </td>
            </template>

            <!-- fulfilments orderline id remodel -->
            <template slot="item.id" slot-scope="{ item }">
              <span class="cell-truncate">{{ 'ITEM-' +  item.id }}</span>
            </template>

            <!-- fulfilments orderline created/changed date remodel -->
            <template v-slot:item.date="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{
                      item.changedAt
                          ? toEuropeanDateWithTimeString(item.changedAt)
                          :item.orderLine.createdAt
                          ? toEuropeanDateWithTimeString(item.createdAt) : ""
                    }}
                  </v-chip>
                </template>
                <span>
                                                {{
                    item.changedAt
                        ?  "Changed at date"
                        :item.createdAt
                        ? "Created at date" : ""
                  }}
                                            </span>
              </v-tooltip>
            </template>

            <!-- actions buttons -->
            <template v-slot:item.actions="{ item }">
            </template>
          </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "Fulfilments"
    }
</script>
