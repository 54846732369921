import { getProductionLines } from "../../../services/catalog-service";
import { Vue, Component } from "vue-property-decorator";
import { getProductionWorktypes, generateProductionsCut, changeLineStatus } from "../../../services/order-service";
import { IProductionRecord, IWorktype } from "../../../models/production.model";
import * as dayjs from 'dayjs';


@Component({})
export default class ProductionsListingMixin extends Vue {

  public loading: boolean = false;
  public search: string = "";
  public productionsList = [] as IProductionRecord[];
  public productionLinesData = [];

  public lineStatus: string | null = null;
  public lineWorkType: IWorktype | null = null;
  public deliverAtDefaultDate: string | null = null;
  private defaultRequestParams = { expand: 'orderLine,product,production' };
  private productionWorkTypes = {} as IWorktype;
  public currentProdCut: string | null = null;
  public selectedGroup: string = '';
  private recordsLineStatuses = [
    { label: 'New', value: 'NEW' },
    { label: 'Processed', value: 'PROCESSED' },
  ]
  private filePlaiExtension: string = "data:text/plain;charset=utf-8,";

  public footerProps = {
    itemsPerPageOptions: [50, 100, 200, -1],
  };

  public tableHeaders = [
    {
      text: "Production ",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: true,
      cellClass: "nowrap",
      value: "production.id",
      groupable: false,
    },
    {
      text: "SKU",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: false,
      cellClass: "nowrap",
      value: "SKU",
      groupable: true
    },
    {
      text: "Description",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "description",
      groupable: false,
      width: "25%"
    },
    {
      text: "Length",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: false,
      cellClass: "nowrap",
      value: "length",
      groupable: false
    },
    {
      text: "Width",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: false,
      cellClass: "nowrap",
      value: "width",
      groupable: false
    },
    {
      text: "Quantity",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: true,
      cellClass: "nowrap",
      value: "quantity",
      groupable: false
    },
    {
      text: "Notes",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "notes",
      groupable: false,
      width: "25%"
    },
    {
      text: "Deliver at",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: true,
      cellClass: "nowrap",
      value: "deliverAt",
      groupable: false
    },
    {
      text: "Worktype",
      align: "left",
      class: "cell-truncate nowrap",
      sortable: true,
      cellClass: "nowrap",
      value: "worktype",
      groupable: false
    },
    {
      text: "Actions",
      align: "right",
      value: "actions",
      groupable: false
    }

  ];

  async getDefaultProductionRecords(queryParams: { expand: string }) {
    this.loading = true;
    this.productionsList = await getProductionLines(queryParams);
    this.loading = false;
  }


  setDeliverDateFilter(selectedDate: Date) {
    this.deliverAtDefaultDate = selectedDate;
    this.onChangeGroupFilter();
  }

  createFiltersObject() {
    let filter: {
      status?: string | null,
      worktype?: string | null,
      "production.deliverAt"?: {} | null
    } = {
      status: null,
      worktype: null,
      "production.deliverAt": null
    };

    this.lineStatus !== null && this.lineStatus
      ? Object.assign(filter, { status: this.lineStatus })
      : delete filter.status;

    this.lineWorkType !== null && this.lineWorkTypes
      ? Object.assign(filter, { worktype: this.lineWorkType })
      : delete filter.worktype;


    if (this.deliverAtDefaultDate !== null && this.deliverAtDefaultDate) {
      const filterField = {
        "production.deliverAt": { "like": this.deliverAtDefaultDate }
      };
      Object.assign(filter, filterField)
    } else {
      delete filter["production.deliverAt"];
    }
    return filter;
  }

  // NOTE: FROM FILTER
  onChangeGroupFilter() {
    const filters = this.createFiltersObject();
    const fullFilter = {
      ...this.defaultRequestParams,
      filter: JSON.stringify(filters)
    }
    this.getDefaultProductionRecords(fullFilter);
  }

  async onChangeLineStatus(item: any, status: string) {
    if (status) {
      try {
        const response = await changeLineStatus(item.id, { status: status });
        if (response && response.hasOwnProperty('id'))
          this.fetchData();
        else {
          this.$refs.toast.show({
            message: response.response?.data?.detail,
            color: "red"
          });
        }
      } catch (error) {
        this.$refs.toast.show({
          message: "Status hasn't been changed",
          color: "red"
        });
      }

    }
  }

  createCsvFile(data: string) {
    const fullData = this.filePlaiExtension + data;
    this.currentProdCut = encodeURI(fullData);
    this.$nextTick(() => {
      this.$refs.referralLink as HTMLLinkElement && this.$refs.referralLink.click();
    });
    this.$refs.toast.show({
      message: 'File has been downloaded',
      color: 'green'
    });
    this.productionLinesData = [];
    //this.getDefaultProductionRecords(this.defaultRequestParams);
    this.onChangeGroupFilter();
  }


  async generateProductionCut() {
    const cutData = { productionLines: this.productionLinesData.map((line: IProductionRecord) => ({ id: line.id })) };
    const response = await generateProductionsCut(cutData);
    if (!response || !response.success) {
      this.$refs.toast.show({
        message: response.response.data.message,
        color: 'red'
      });
      return
    }
    this.createCsvFile(response.data);
  }

  async getInstances() {
    this.productionWorkTypes = await getProductionWorktypes();
  }

  onSelectGroup(group: string, items: any, status?: boolean) {
    if (!status) {
      this.selectedGroup = group;
      this.productionLinesData = items;
    } else {
      this.selectedGroup = '';
      this.productionLinesData = [];
    }
  }

  fetchData() {
    this.lineStatus = this.recordsLineStatuses[0].value
    this.onChangeGroupFilter();
    this.getInstances();
  }

  get getHeaders() {
    return this.tableHeaders;
  }

  get getProductionRecords() {
    return this.productionsList.map((pl: IProductionRecord) => {
      pl.SKU = pl.orderLine.product.sku;
      pl.status = pl.hasOwnProperty('status') ? pl.status : null;
      return pl
    })
  }

  get lineStatuses() {
    return this.recordsLineStatuses;
  }

  get lineWorkTypes() {
    const prodTypesArray = Object.values(this.productionWorkTypes);
    return prodTypesArray
  }

  get canCreateCut() {
    return this.productionLinesData?.length > 0;
  }

  mounted() {
    this.fetchData();
  }

}//