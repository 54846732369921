
import { Component, Vue, VModel, Prop, Watch } from "vue-property-decorator";
import { formatPrice } from "../../helpers/format";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import PazProductAsyncPicker from "../picker/PazProductAsyncPicker.vue";
import {
  getOffers,
  getShippingSize,
  getShippingMethods,
  getOffersHistory,
  getMarginsValue,
} from "../../services/offers-service";
import { getMarketplaces } from "../../services/catalog-service";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { newOfferModel, ShippingSisesModel } from "../../models/offers.model";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import dayjs from "dayjs";
import { IApiProduct } from "../../models/product";

@Component({
  components: {
    PazMenuDatePicker,
    PazProductAsyncPicker,
    PazLoader,
  },
  methods: {
    formatPrice,
    formatDateTime,
  },
})
export default class PazOfferDialog extends Vue {
  @VModel({
    default: false,
  })
  public showOfferDialog!: boolean;

  @Prop() selectedOffer!: any;

  public loading: boolean = false;
  public historyTableHeaders = [
    {
      text: "date",
      align: "left",
      cellClass: "nowrap",
      value: "currentDate",
    },
    {
      text: "price",
      align: "left",
      cellClass: "nowrap",
      value: "currentPrice",
    },
    {
      text: "position",
      align: "left",
      cellClass: "nowrap",
      value: "currentPosition",
    },
    {
      text: "lowest price",
      align: "left",
      cellClass: "nowrap",
      value: "competitionLowestPrice",
    },
    {
      text: "Highest price",
      align: "left",
      cellClass: "nowrap",
      value: "competitionHighestPrice",
    },
    {
      text: "Shipping fee",
      align: "left",
      cellClass: "nowrap",
      value: "shipmentFee",
    },
  ];

  offerHistory = [];

  public offerObject: newOfferModel = {
    product: null,
    marketplace: null,
    marketplaceInstance: null,
    fulfilmentMethod: null,
    marketplaceEan: "",
    shipmentSize: "",
    quantity: 1,
    minMarge: null,
    defaultMarge: null,
    shipmentMethod: null,
    shipmentFee: 0,
    startDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    endDate: null,
    isActive: false,
    purchasePrice: 0,
    minimalPrice: null,
    currentPrice: null,
    calculatedPrice: null,
    notes: "",
    calculatedPriceNeedsUpdate: false,
    repriceActive: true,
  };
  public offerFormModel: boolean = false;
  public products = [];
  public marketplaces = [];
  public shippingMethods: { [key: string]: string } = {};
  public updateStrategies = [];
  public startDatePicker: boolean = false;
  public endDatePicker: boolean = false;
  public startDate: string = "";
  public endDate: string = "";

  public shippingSizes: ShippingSisesModel[] | null = null;

  setObjectData<T extends keyof newOfferModel>(value: any, key: T) {
    this.offerObject[key] = value;
  }

  onPickDate<T extends keyof newOfferModel>(val: any, key: T) {
    this.offerObject[key] = val;
  }

  get isRepricerActive(): boolean {
    if (!this.$repriceManagement == undefined) {
      return false;
    }
    return this.$repriceManagement;
  }

  async saveOffer(isEdit?: boolean) {
    await this.$refs.offerForm.validate();
    if (!this.offerFormModel) {
      const el = document.querySelector(".error--text:first-of-type");
      el && el.scrollIntoView({ block: "end", behavior: "smooth" });
      return;
    }
    const operationType = !isEdit ? "onCreateOffer" : "onUpdateOffer";
    this.$emit(operationType, this.offerObject);
  }

  async getShipMethods(product: IApiProduct) {
    if (product && product.hasOwnProperty("product_catalog_product__id")) {
      if (!this.selectedOffer) {
        this.offerObject.purchasePrice = parseFloat(
          product.product_catalog_product__price
        );
        this.offerObject.currentPrice = parseFloat(
          product.product_catalog_product__price
        );
        this.offerObject.calculatedPrice = parseFloat(
          product.product_catalog_product__price
        );
        this.offerObject.product = product.product_catalog_product__id;
      } else if (this.selectedOffer)  {
        this.shippingMethods = await getShippingMethods(this.selectedOffer.id);
      }
    }
  }

  //@Watch()

  async getSelectedOffer() {
    if (this.selectedOffer) {
      const offer = await getOffers(undefined, this.selectedOffer.id);
      offer && Object.assign(this.offerObject, offer);
      this.shippingMethods = await getShippingMethods(this.selectedOffer.id);
      this.offerHistory = await getOffersHistory({
        offer: this.selectedOffer.id,
      });
    } else {
      const margins = await getMarginsValue();
      if (margins) {
        this.offerObject.defaultMarge = margins.default;
        this.offerObject.minMarge = margins.minimal;
      }
    }
  }

  async fetchData() {
    this.loading = true;
    await this.getSelectedOffer();
    this.shippingSizes = await getShippingSize();
    this.marketplaces = await getMarketplaces({
      filter: JSON.stringify({ isActive: true }),
    });
    this.loading = false;
  }

  get getHistoryTableHeaders() {
    return this.historyTableHeaders;
  }

  get getShippingSize() {
    if (this.shippingSizes) {
      return Object.entries(this.shippingSizes).map((s) => ({
        name: s[1],
        value: s[0],
      }));
    } else return [];
  }

  get getShippingMethods() {
    if (this.shippingMethods) {
      return Object.entries(this.shippingMethods).map((s) => ({
        name: s[1],
        id: s[0].toLowerCase(),
      }));
    } else return [];
  }

  get getMarketplaceInstances() {
    const currentMarketplace = this.marketplaces
      .filter((mp) => mp && this.offerObject.marketplace === mp.id)
      .shift();
    return currentMarketplace && currentMarketplace.marketplaceInstance;
  }

  get getDeliveryClassifications() {
    const currentMarketplace = this.marketplaces
      .filter((mp) => mp && this.offerObject.marketplace === mp.id)
      .shift();

    return (
      currentMarketplace &&
      Object.entries(currentMarketplace.deliveryClassifications).map((s) => ({
        id: s[0],
        name: s[1],
      }))
    );
  }

  get getFulfilmentMethods() {
    const currentMarketplace = this.marketplaces
      .filter((mp) => mp && this.offerObject.marketplace === mp.id)
      .shift();
    return (
      currentMarketplace &&
      Object.entries(currentMarketplace.fulfilmentMethods).map((s) => ({
        id: s[0],
        name: s[1],
      }))
    );
  }

  get getToday() {
    return dayjs().format("DD-MM-YYYY");
  }

  mounted() {
    this.fetchData();
  }
} //
