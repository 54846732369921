
import Vue from 'vue';

import {getPackageVersions} from "../../services/auth-service";
import {Component} from "vue-property-decorator";

@Component({
  components: {}
})

export default class PackagesVersionPage extends Vue {

  public items: object[] = [];
  public headers: object[] = [
    {
      text: 'name',
      align: 'left',
      sortable: true,
      value: 'name',
    },
    {
      text: 'version',
      align: 'left',
      sortable: true,
      value: 'version',
    }
  ];

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.items = await getPackageVersions();
  }

}
