
import { Component, Mixins, VModel } from "vue-property-decorator";
import DossierEditor from "./mixins/dossier-editor.mixin";
import DossierDocumentViewer from "../../views/dossier/parts/DossierDocumentViewer.vue";
import FileUploader from "../common/FileUploader.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import EditDossierUsers from "./parts/EditDossierUsers.vue";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";

@Component({
  components: {
    DossierDocumentViewer,
    FileUploader,
    PazConfirmation,
    EditDossierUsers,
    PazMenuDatePicker
  }
})
export default class DossierEditDialog extends Mixins(DossierEditor) {
  @VModel() public showCourseDetails: boolean;

  public EditorTab = null;
  public showDocPreview: boolean = true;
  public dossierUsers = null;
  public acceptedDocFormats: string =
    ".xlsx, .xls, .doc, .docx, application/msword, application/vnd.ms-excel,text/plain, application/pdf";

  public rules = [
    (value) =>
      !value || value.size < 20000000 || "File size should be less than 20 MB!",
  ];

  get canUploadFiles() {
    return (
      this.selectedDossierProp && this.selectedDossierProp.hasOwnProperty("id")
    );
  }
}
/**
 * Logic put into dossier-editor.mixin
 * LINK: frontendPackages/pazion-core/src/components/dialog/mixins/dossier-editor.mixin.ts
 */
