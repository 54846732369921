
import Vue from 'vue';

import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class SettingsEntry extends Vue {

    @Prop()
    public title;
    @Prop()
    public value;

    isArray(config): boolean {

        if(config == null){
            return false;
        }

        if (typeof config == 'object' || typeof config == 'array' ) {
            return Object.keys(config).length > 0;
        }

        return false;
    }

    isEmpty(value) {

        if(Array.isArray(value)){
            value = null;
        }

        if(value === undefined || value === null){
            value = '---';
        }

        return value;
    }
}
