
import { Component, Vue, Prop, VModel } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { getCardAttributes, getCardAttributeValues } from "../../../services/cards-service";
import CardAttributesController from "../atoms/CardAttributesController.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import { ICardModel } from "../../../models/member_card.model";

@Component({
  components: {
    PazLoader,
    CardAttributesController,
    PazToast
  },
})
export default class CardDetailsAttributes extends Vue {

  refs!: {
      toast: PazToast;
  }

  @VModel({
    default: false,
  })
  showAttrModal!: boolean;

  public loading: boolean = false;
  public cardConfigFormModel: boolean = false;
  // TODO: add types!
  public attributeItems = [];

  @Prop() cardId!: number;
  @Prop() cardData!: ICardModel;

  saveAttrValue() {
    this.getAttributesByCard(this.cardId);
  }


  async getAttributesByCard(cardId: number) {
    this.loading = true;
    const attrQueryParams = {filter: JSON.stringify({card: cardId, isActive: 1, isDeleted: 0, isUserAttribute: 0})};
    const attrValuesQueryParams = {filter: JSON.stringify({card: cardId})};
    
    const attrs = await getCardAttributes(attrQueryParams);
    const attrValues = await getCardAttributeValues(attrValuesQueryParams);

    const attritemsArray = attrs?.map( (attr:any) => {
     const attrValue = attrValues?.find(attrVal => attrVal && attrVal.cardAttribute === attr.id);
     if(attrValue) {
        attr.value = attrValue.value;
        attr.valueId = attrValue.id;
        attr.cardMember = attrValue.cardMember;
     } else
      attr.value = null;
      return attr;
    })
    if (!attrs) return;
    this.attributeItems = attritemsArray;
    this.loading = false;
  }


  mounted() {
    this.getAttributesByCard(this.cardId);  
  }
} //
