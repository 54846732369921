
import { Component, Vue, Prop } from "vue-property-decorator";
import dayjs from "dayjs";

@Component
export default class PazDatePicker extends Vue {

refs!: {
  menuRef: HTMLBodyElement
}
  @Prop() menuRef!:string;
  @Prop() pickeLabel?:string;
  @Prop() pickedValue?:string;
  @Prop({default: false}) hasManualInput?:boolean;
  @Prop({default: false}) isClearable?:boolean;
  
  public pickerMenu: boolean = false;
  public validateMess: string | null = null;
  private defaulValidateMess: string = "date format doesn't match: 'YYYY-MM-DD'";

  changeDate(date:Date) {
    if(date && !dayjs(date, 'YYYY-MM-DD', true).isValid()) {
        this.validateMess = this.defaulValidateMess;
    } else  {
        this.validateMess = null;
        this.$emit('onPickDate', date);
    }
  }

}
