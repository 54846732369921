
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { getViewProducts } from "../../services/catalog-service";
import { IApiProduct } from "../../models/product";
import { formatPrice } from "../../helpers/format";
import PazTooltipButton from '@pazion/pazion-core/src/components/controls/PazTooltipButton.vue';

@Component({
  methods: {
    formatPrice,
  },
  components: {
    PazTooltipButton
  }
})
export default class PazProductAsyncPicker extends Vue {
  public loading: boolean = false;
  public errorMessage: string = "";
  public items: IApiProduct[] = [];
  public MaxtextLength = 50;
  public search: string = "";

  @VModel()
  public productId?: number;

  @Prop()
  public label!: string;

  @Prop() fieldName: string;

  @Prop({ default: false }) returnObject: boolean;

  @Prop({ default: false }) showProductName: boolean;

  @Prop({ default: false }) copySku?: boolean;

  public _timer: number | undefined;

  async fetchData(query: any) {
    this.loading = true;
    this.items = await getViewProducts(query, 50000);
    this.loading = false;
  }
  shorten(shorten?: string) {
    if (shorten == undefined) {
      return "";
    }
    if (shorten.length > this.MaxtextLength) {
      return shorten.substring(0, this.MaxtextLength) + "..";
    }
    return shorten;
  }

  onChangeProduct(event: IApiProduct) {
    this.$emit('onChangeProduct', event);
  }


  copySkuToClipBoard() {
    let textToCopy = this.$refs.productSelect;
    if (textToCopy) {
      textToCopy?.select();
      document.execCommand("Copy");
    };
  }

  get nothingSelected() {
    return this.productId === undefined;
  }

  @Watch("search")
  async filterFunc(queryText: string) {
    if (!this.loading && queryText) {
      clearTimeout(this._timer);
      this._timer = setTimeout(async () => {
        await this.fetchData({
          operator: 'AND',
          product_catalog_product__is_deleted: 0,
          multi: {
            operator: 'OR',
            product_catalog_product__name: { like: `%${queryText}%` },
            product_catalog_product__sku: { like: `%${queryText}%` },
            product_catalog_product__ean: { like: `%${queryText}%` }
          }
        });
      }, 600);
    }
  }

  mounted() {
    this.productId &&
      this.fetchData({
        product_catalog_product__id: { like: `%${this.productId}%` },
      });
  }

}
