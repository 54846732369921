import { deleteRequest, getRequest, putRequest, postRequest } from '@pazion/pazion-core/src/services/auth-service';
import { stringifyUrl } from 'query-string';


/** SECTION CARDS */

/**
 * GET Cards array or one by id
 * @param queryParams? object;
 * @param cardId: number;
 */
export async function getCards(queryParams?: object, cardId?: number | string) {
  const baseUrl = 'catalog/cards';
  const fullUrl = cardId ? `${baseUrl}/${cardId}` : baseUrl;
  const url = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(url)).data;
}

export async function createCard(data: object) {
  const baseUrl = 'catalog/cards';
  return (await postRequest(baseUrl, data)).data;
}

export async function updateCard(cardId: number, data: object) {
  const baseUrl = 'catalog/cards';
  const fullUrl = cardId ? `${baseUrl}/${cardId}` : baseUrl;
  return (await putRequest(fullUrl, data)).data;
}


/**
 * Delete card
 * @param cardId
 */
export async function deleteCard(cardId?: number) {
  const baseUrl = `catalog/cards/${cardId}`;
  return (await deleteRequest(baseUrl)).data;
}

// SECTION: ATTRIBUTES

/**
 * GET Cards attributes
 * @queryParams
 * @param cardId 
 * @returns 
 */
export async function getCardAttributes(queryParams?: object, attrId?: number) {
  const fullUrl = attrId ? `/catalog/card-attributes/${attrId}` : '/catalog/card-attributes'
  const paramUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(paramUrl)).data;
}


/**
 * GET attr field types
 * @returns typesArray
 */
export async function getCardAttributeTypes() {
  const fullUrl = 'card-attribute-field-types';
  return (await getRequest(fullUrl)).data;
}

/**
 * GET Cards attributes values
 * @param cardId 
 * @returns 
 */
export async function getCardAttributeValues(queryParams?: object,) {
  const fullUrl = `/catalog/card-attribute-values`
  const paramUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(paramUrl)).data;
}

/**
 * Put Cards attributes values
 * @param value: any
 * @returns 
 */
export async function postCardAttributeValues(value: any) {
  const fullUrl = '/catalog/card-attribute-values';
  return (await postRequest(fullUrl, value)).data;
}


/**
 * Put Cards attributes values
 * @param attrValueId: number
 * @param value: any
 * @returns 
 */
export async function putCardAttributeValues(attrValueId: number, value: any) {
  const fullUrl = `/catalog/card-attribute-values/${attrValueId}`;
  return (await putRequest(fullUrl, value)).data;
}


/**
 * POST Cards attributes
 * @queryParams data
 */
export async function postCardAttribute(data: any) {
  return (await postRequest(`/catalog/card-attributes`, data)).data;
}

/**
 * PUT Cards attributes
 * @queryParams data
 */
export async function putCardAttribute(attrId: number, data: any) {
  return (await putRequest(`/catalog/card-attributes/${attrId}`, data)).data;
}

/** 
 * Delete attributes 
 */
export async function cardDeleteAttrubute(attrId: number) {
  return (await deleteRequest(`/catalog/card-attributes/${attrId}`)).data;
}

// SECTION: MEMBERS
/**
 * GET Card members
 * @param queryParams?: object
 * @param memberId?: number
 * @param token?: string
 */
export async function getCardMembers(queryParams?: object, memberId?: number, token?: string) {
  const baseUrl = '/catalog/card-members';
  let fullUrl = baseUrl;

  if (memberId)
    fullUrl = `${baseUrl}/${memberId}`;
  else if (token)
    fullUrl = `${baseUrl}/${token}`;
  else
    fullUrl = baseUrl;

  const paramUrl = stringifyUrl({
    url: fullUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(paramUrl)).data;
}

/**
 * Add member
 */
export async function addCardMember(data: any) {
  return (await postRequest('/catalog/card-members', data)).data;
}

/**
 * Edit member
 */
export async function editCardMember(memberId: number, data: any) {
  return (await putRequest(`/catalog/card-members/${memberId}`, data)).data;
}

/**
 * Delete Member
 */
export async function deleteCardMember(memberId?: number) {
  const baseUrl = `/catalog/card-members/${memberId}`;
  return (await deleteRequest(baseUrl)).data;
}



/**
 * [Export] member
 * @param queryParams
 * @returns 
 */
export async function exportCardMembers(queryParams?: object) {
  const baseUrl = '/catalog/card-members/download';
  const paramUrl = stringifyUrl({
    url: baseUrl,
    query: {
      ...queryParams
    }
  });
  return (await getRequest(paramUrl, { responseType: 'blob' })).data;
}

//[IMPORT] Download sample file
export async function uploadExample() {
  return (await getRequest('catalog/card-members/upload', { responseType: 'blob' })).data;
}

/**
 * [IMPORT] Uplod members from file
 */
export async function uploadCardMembers(queryParams: object, file: File) {
  let formData = new FormData();
  formData.append('file', file);

  const baseUrl = '/catalog/card-members/upload';
  const paramUrl = stringifyUrl({
    url: baseUrl,
    query: {
      ...queryParams
    }
  });

  return (await postRequest(paramUrl,
    formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })).data;
}

//SECTION: Membership card (Production view) 
/**
 * Login user to the Membership card
 * @param data: {email: string, cardId?: number, token?: string}
 * @returns 
 */
export async function loginMember(data: { email?: string, cardId: number, token?: string }) {
  return (await postRequest('/card-member-login', data)).data;
}

