import { Component, Vue } from "vue-property-decorator";
import { ContractDetailsInterface, ContractLine, newContractLine, TransactionSubject } from "../../../models/contracts.model";
import { deleteContractLine } from "../../../services/contracts-service"

@Component({})
export default class ContractLinesMixin extends Vue {

/** 
* NOTE: add new contract line objects/operations
*/
  public newLineObject = {
    indexId: 0,
    name: "",
    transactionSubject: { name: "New line", id: 0 },
    contractLineFrequency: { name: "Monthly", id: 0 },
    location: "",
    serialNumber: "",
    quantity: 1,
    price: 0
  };


  addNewLine() {
    this.newLineObject.transactionSubject.id = this.transactionSubjects.length > 0 ?
      this.transactionSubjects[0].id : 0
    this.newLineObject.contractLineFrequency.id = this.frequencyTypes.length > 0 ?
      this.frequencyTypes[0] : { name: "Select frequency", id: 0 }
    this.contractObject?.contractLine?.push(this.newLineObject);
  }

  // get getNewLineObject() {
  //   return Object.assign(this.newLineObject, {
  //     transactionSubject: this.transactionSubjects.length > 0 ?
  //       this.transactionSubjects[0] : { name: "New line", id: 0 },
  //     contractLineFrequency: this.frequencyTypes.length > 0 ?
  //       this.frequencyTypes[0] : { name: "Select frequency", id: 0 }
  //   })
  // }

  preparingLineFields(item: ContractLine, isUpdate?: boolean): ContractLine {
    let contractLineFields = {
      name: item.name,
      contract: isUpdate ? item.contract : this.selectedContract?.id,
      price: item.price,
      priceExtra: item.priceExtra,
      quantity: item.quantity,
      tax: isUpdate ? item.tax : 1,
      contractLineFrequency: item.contractLineFrequency
        ? item.contractLineFrequency.id
        : null,
      transactionSubject: item.transactionSubject
        ? item.transactionSubject.id
        : null,
    };
    //@ts-ignore
    return contractLineFields;
  }

  async delContractLine(
    { item, index }: { item: newContractLine | ContractDetailsInterface, index: number }
  ) {
    if ("id" in item) {
      const confirm =
        await this.$parent.$refs.confirmDeleteContract?.openConfirmDialog(
          "Delete",
          `Do you want to delete contract line: ${item.name}?`
        );
      if (confirm) {
        const response = await deleteContractLine(item.id);
      }
    } else this.contractObject?.contractLine?.splice(index, 1);
  }

}//