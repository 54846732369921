
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import OrderAdressComponent from "./components/OrderAdressComponent.vue";
import CustomerPicker from "../../picker/CustomerPicker.vue";
import {updateOrder} from "../../../services/catalog-service";

@Component({
  components: {
    OrderAdressComponent,
    CustomerPicker
  }
})
export default class OrderCustomerTab extends Vue {

  @Prop()
  public orderDetailsData!: object;

  @Prop({default: true})
  public editAble!: boolean;

  public showCustomerPicker: boolean = false;

  async updateShippingAdress(adressId: number) {
    await updateOrder(this.orderDetailsData.id, {shippingAddressId: adressId});
    this.$emit('update-data');
  }

  async updateBillingAdress(adressId: number) {
    await updateOrder(this.orderDetailsData.id, {billingAddressId: adressId})
    this.$emit('update-data');
  }

  async updateAccount(accountId: number) {
    await updateOrder(this.orderDetailsData.id, {accountId: accountId});
    this.$emit('update-data');
  }
  get isCustomerSelected() {
    return this.orderDetailsData.hasOwnProperty('customer') && this.orderDetailsData.customer.id;
  }

  openCustomerPicker() {
    this.showCustomerPicker = true;
  }

  public result: any;

  toEuropeanDateString(dateHook: any) {
    if (dateHook == null) {
      return '-';
    }
    return this.format(dateHook.date, 'dd-MM-yyyy');
  }

  isValidDate(value: any) {
    return !isNaN(new Date(value).getDate());
  }

  toEuropeanDateWithTimeString(dateHook: any) {
    return this.format(dateHook.date, 'dd-MM-yyyy hh:mm:ss');
  }

  format = function date2str(x: any, y: any) {
    x = new Date(x)
    if (x.toString() !== 'Invalid Date') {
      var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
      };

      y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v: string) {
        return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
      });

      return y.replace(/(y+)/g, function (v: string | any[]) {
        return x.getFullYear().toString().slice(-v.length)
      });
    }
  }

}

