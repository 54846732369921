import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import Login from './views/user/Login.vue';
import Logout from './views/user/Logout.vue';
import Profile from './views/user/Profile.vue';
import LogsPage from './views/logs/LogsPage.vue';
import QueueListing from "./views/queue/QueueListing.vue";
import UserListing from "./views/user/UserListing.vue";
import SettingsPage from "./views/settings/SettingsPage.vue";
import UserEdit from "./views/user/UserEdit.vue";
import ForgotPassword from "./views/user/PasswordForget.vue";
import ActivateAccount from "./views/user/ActivateAccount.vue";
import PackagesVersionPage from "./views/packages/PackagesVersionPage.vue";
import DossierListingPage from "./views/dossier/DossierListingPage.vue"
import DossierCourseDialog from "./views/dossier/parts/pazDossierCoursePage.vue";

Vue.use(VueRouter);

export const baseRoutes: RouteConfig[] = [
    {
        path: '/',
        name: 'Home',
        component: Dashboard,
        meta: {
            'show-in-nav': false,
            roles: ['user'],
            sort: -100
        },
    }, {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
        }
    }, {
        path: '/forgot-password/:token?',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            guest: true,
        }
    }, {
        path: '/activate-account/:token?',
        name: 'activate-account',
        component: ActivateAccount,
        meta: {
            guest: true,
        }
    },
    {
        path: '/dossier',
        name: 'dossier',
        component: DossierListingPage,
        meta: {
            'show-in-nav': true,
            roles: ['user','customer'],
            sort: 700
        },
        children: [

        ]
    },
    {
        path: '/dossier/:dossierId',
        name: 'dossier-course-page',
        component: DossierCourseDialog,
        props: true,
        meta: {
            'show-in-nav': false,
            roles: ['user']
        },
    },
    {
        path: '/dossier/order',
        name: 'DossierOrderFiles',
        component: DossierCourseDialog,
        meta: {
            'show-in-nav': false,
            roles: ['user','customer']
        },
    },
    {
        path: '/queue',
        name: 'queue',
        component: QueueListing,
        meta: {
            'show-in-nav': true,
            roles: ['user'],
            sort: 800
        },
    }, {
        path: '/logs',
        name: 'logs',
        component: LogsPage,
        meta: {
            'show-in-nav': true,
            roles: ['user'],
            sort: 900
        },
    }, {
        path: '',
        name: 'settings',
        component: SettingsPage,
        meta: {
            'show-in-nav': true,
            sort: 1000
        },
        children: [
            {
                path: '/profile',
                name: 'profile',
                component: Profile,
                meta: {
                    'show-in-nav': true,
                    'hasDrawer': true,
                    'icon': 'mdi-account',
                    roles: ['user', 'customer', 'production'],
                    sort: 100
                },
            },
            {
                path: '/users',
                name: 'users',
                component: UserListing,
                meta: {
                    'hasDrawer': true,
		    'show-in-nav': true,
		    'icon': 'mdi-account-multiple',		    
                    roles: ['administrator'],
                },
            },
            {
                path: '/users/:id',
                name: 'user-edit',
                component: UserEdit,
                props: true
            }, {
                path: '/package-versions',
                name: 'versions',
                component: PackagesVersionPage,
                meta: {
                    'show-in-nav': true,
                    'hasDrawer': true,
                    'icon': 'mdi-book-open',
                    roles: ['user'],
                    sort: 3999
                },
            }
        ],
    }, 
    {
        path: '/logout',
        name: '<i class="mdi mdi-logout"></i>',
        component: Logout,
        meta: {
            'class': 'menuLogout',
            'style': 'font-size: 150%;',
            'show-in-nav': true,
            'icon': 'mdi-logout',
            sort: 4000
       },
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ './views/misc/About.vue'),
    },
];

export function getAllRoutes() {
    return baseRoutes;
}

export function removeRouteByName(routeName: string): boolean {
    baseRoutes.forEach((item, index) => {
        if (item.name === routeName) {
            baseRoutes.splice(index, 1);
            return true;
        }
    });
    return false;
}

export function redirectRouteToBackend(routeName: string, add: boolean) {
    const route = baseRoutes.find(value => value.name === routeName);
    if (route === undefined) {
        return;
    }
    if (!route.meta) {
        route.meta = {};
    }
    if (add) {
        route.meta['redirect-to-backend'] = true;
    } else {
        delete route.meta['redirect-to-backend'];
    }
    const index = baseRoutes.findIndex((value => value.name === routeName));
    baseRoutes[index] = route;
    resetRouter();
}

export function addChildRoute(parentName: string, route: RouteConfig) {
    const routeExists = baseRoutes.find(value => value.name === parentName);
    if (routeExists === undefined) {
        return;
    }
    if (routeExists.children === undefined) {
        return;
    }
    const childRoute = routeExists.children.find(value => value.name === route.name);
    if (childRoute === undefined) {
        routeExists.children.push(route);
    } else {
        const index = routeExists.children.findIndex((value => value.name === route.name));
        routeExists.children[index] = route;
    }
    resetRouter();
}

export function removeChildRoute(parentName: string, routeName: string) {
    const routeExists = baseRoutes.find(value => value.name === parentName);
    if (routeExists === undefined) {
        return;
    }
    if (routeExists.children === undefined) {
        return;
    }
    const childRoute = routeExists.children.find(value => value.name === routeName);
    if (childRoute !== undefined) {
        const index = routeExists.children.findIndex((value => value.name === routeName));
        routeExists.children.splice(index, 1);
    }
    resetRouter();
}


export function addRoutes(routes: RouteConfig[]) {
    if (routes === null || routes.length == 0) {
        return;
    }
    routes.forEach(r => addRoute(r, true));
    resetRouter();
}

export function addRoute(route: RouteConfig, bulk?: boolean) {
    const routeExists = baseRoutes.find(value => value.name === route.name);
    if (routeExists === undefined) {
        baseRoutes.push(route);
        if (!bulk) {
            resetRouter();
        }
        return;
    }
    const index = baseRoutes.findIndex((value => value.name === route.name));
    baseRoutes[index] = route;
    if (!bulk) {
        resetRouter();
    }
}

const createRouter = () => new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: baseRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.beforeResolve((to, from, next) => {
        if (Vue.prototype.$membersCardManagement && !window.location.hostname.includes('admin') && to.name !== 'membership pass') {
            console.log('Router step', Vue.prototype.$membersCardManagement, !window.location.hostname.includes('admin'), to.name !== 'membership pass');
            next({ name: 'membership pass' })
        }
         
        if (to.meta && to.meta['redirect-to-backend']) {
            location.href = `${process.env.VUE_APP_BACKEND_BASE_URL}${to.path.slice(1)}`;
            return;
        }
        next();
    });
    (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router;
