
import { Component, Vue, VModel } from "vue-property-decorator";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { getCards, uploadCardMembers, uploadExample } from "../../../services/cards-service";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";
import { ICardModel } from "../../../models/member_card.model";

@Component({
  components: {
    PazLoader,
    FileUploader
  },
})
export default class CardMemberImportModal extends Vue {
  @VModel({
    default: false,
  })
  public showImportModal!: boolean;
  public loading: boolean = false;
  public cardItems = [] as ICardModel[];
  public sampleFile: any = null;
  public downloadedFile: File | null = null;
  public filteXLsType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  public selectedCardId:number|null = null;

  public importOption: string = 'update'
  public actionOptions = [
    {
      label: 'Update existing members',
      value: 'update',
      disabled: false
    },
    {
      label: 'Update existing and create new members',
      value: 'create_update',
      disabled: false
    },
    {
      label: 'Delete members from import list',
      value: 'delete',
      disabled: false
    },
    {
      label: 'Remove absent members (not available at this time)',
      value: 'delete_missing',
      disabled: true
    }
  ]





  //update, create_update and delete
  //upload?action=update&criteria={"card":2}

  async downloadSample() {
    const data = await uploadExample();
    if (data && data instanceof Blob) {
      const blob = new Blob([data], { type: this.filteXLsType });
      const url = window.URL.createObjectURL(blob);
      this.sampleFile = url;
    }
  }


  onFileSelect(file: any) {
    this.downloadedFile = file;
  }

  // {
  //     "success": true,
  //     "message": "file uploaded successfully and is waiting in queue to be processed",
  //     "type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     "error": 0
  // }

  async importMember() {
    const queryImportData = {
      action: this.importOption,
      criteria: JSON.stringify({ card: this.selectedCardId })
    }

    const res = await uploadCardMembers(queryImportData, this.downloadedFile);
    if (res && res.hasOwnProperty('success')) {
      this.$parent.$refs.toast.show({
        message: res.message,
        color: res.success ? 'success' : 'error'
      });
    }
    this.$emit('onClose');
  }

  async fetchData() {
    this.loading = true;
    try {
      this.cardItems = await getCards(
        { excludeResources: 1, filter: JSON.stringify({ isDeleted: 0 }) }
      );
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  mounted() {
    this.downloadSample();
    this.fetchData();
  }
} //
