import { Component, Vue } from "vue-property-decorator";

@Component
export default class BaseMixin extends Vue {

  showDefaultServerErrorToast(fromContext?: boolean) {
    const instance =  fromContext ?  this.$refs.toast : this.$parent.$refs.toast;
    instance.show({
      message: 'An error occurred, please contact the administrator',
      icon: 'mdi-close-circle',
      color: 'red'
    });
  }
  
}
