
import {Component, Prop} from "vue-property-decorator";
import PazTooltipButton from '@pazion/pazion-core/src/components/controls/PazTooltipButton.vue';
import Vue from "vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import {IFulfilment, IFulfilmentOrderLine, ITransportLine} from "../../../models/fulfilment";
import {formatDate} from "../../../helpers/format";
import {deleteFulfilment} from "../../../services/catalog-service";

@Component({
  components: {
    PazTooltipButton,
    PazConfirmation
  },
  methods: {
    formatDate
  }
})
export default class FulfilmentTab extends Vue {

  $refs!: {
    confirm: PazConfirmation,
  };
  @Prop()
  public fulfilments!: IFulfilment[];

  @Prop()
  public fulfilmentOrderLines!: IFulfilmentOrderLine[];

  @Prop()
  public transportLines!: ITransportLine[];

  public selectedPanels: number = 0;

  mounted() {
    // console.log(this.getTransporterById(this.fulfilments[0].id));
  }

  getFulfilmentsById(id: number) {
    return this.fulfilmentOrderLines.filter((i) => {
      return id == i.fulfilmentsId;
    });
  }

  async deleteFulfilment(id: number) {
    const confirm = await this.$refs.confirm.openConfirmDialog('Delete', `Do you want to delete this fulfilment?`);
    if (confirm) {
      const rsp = await deleteFulfilment(id);
      if (rsp.success) {
        this.$emit('update-data');
      }
    }
  }

  isFulfillmentHasPaklijstPdf(fulfilment: IFulfilment) {
      if (fulfilment.attributes === null) {
          return false;
      }
      return fulfilment.attributes.hasOwnProperty('paklijst_pdffile');
  }

  isFulfillmentHasPakbonPdf(fulfilment: IFulfilment) {
      if (fulfilment.attributes === null) {
          return false;
      }
      return fulfilment.attributes.hasOwnProperty('pakbon_pdffile');
  }

  getTransporterName(id: number) {
    const tr = this.getTransporterById(id);
    if (tr === undefined) {
      return '';
    }
    return tr.code;
  }

  getUrl(part: string) {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}${part}`;
  }

  getTransporterTrackTrace(id: number) {
    const tr = this.getTransporterById(id);
    if (tr === undefined) {
      return '';
    }
    return tr.code;
  }

  getTransporterById(id: number) {
    return this.transportLines.find((i) => {
      return id == i.fulfilmentsId;
    });
  }

}

