
import {Vue, Component, Prop, VModel} from "vue-property-decorator";

@Component({})
export default class ListInput extends Vue {
  @VModel({
    default: () => {
      {
      }
    }
  })
  public itemValue!: object[];

  @Prop({})
  public properties!: { name: string, class: string, id: string, label: string };


  //local var(s)
  public newListItemTitle: string = '';
  public newListItemValue: string = '';

  public removeListItem(itemIndex: number) {
    let localItem = this.itemValue[itemIndex];
    if (!confirm('Do you want to delete the following item?: ' + Object.keys(localItem)[0])) {
      return;
    }

    //remove item from arr
    this.itemValue.splice(itemIndex, 1);

    return alert('Deleted ' + Object.keys(localItem)[0] + ' from the list');
  }

  public addListItem() {
    //check if item is longer then 5 characters/ not empty
    if (this.newListItemTitle.length < 5) {
      return alert('Please fill title field with a word 5 characters long adding new item');
    }


    //check if item with title exists in arr
    let doesTitleExist = false;
    this.itemValue.forEach((e) => {
      if (Object.keys(e).includes(this.newListItemTitle)) {
        doesTitleExist = true;
        return
      }
    });

    if (doesTitleExist) {
      return alert(this.newListItemTitle.charAt(0).toUpperCase() + this.newListItemTitle.slice(1) + ' is already in the list. Give it another title')
    }

    //push the new object into array of objects
    this.itemValue.push({[this.newListItemTitle]: this.newListItemValue});

    return alert('Added ' + this.newListItemTitle + ' to the list.')
  }
}
