import { IDate, IEntityAttribute } from "./base";
import { IExtendedProduct } from "./product";


export interface IOrder {
    orderId?: number;
    orderType?: string;
    orderLineId?: string;
    notes: string;
    id: number;
    status: string;
    orderContact?: object;
    orderTotal?: string;
    orderStatus?: string;
    orderReference?: string;
    orderDate?: string;
    orderLineDescription?: string;
    orderLineAttributes?: IOrderAttribute[];
    orderLineAttributesLine?: IOrderLine[];
    orderLineQuantity?: number;
    orderLineTotal?: number;
    orderLineStatus?: string;
    orderLineStatusDescription?: object;
    marketplace?: number;
    production?: number;

}

export interface IOrderLine {
    shipped?: number;
    attributeValue: object;// {}
    changedAt?: IDate;// {date: "2021-06-22 14:55:45.000000"; timezone_type: 3, timezone: "Europe/Amsterdam"}
    createdAt: IDate;//{date: "2021-06-22 14:54:54.000000", timezone_type: 3, timezone: "Europe/Amsterdam"}
    description: string | null;//"4gardenz® Comfortabel Ventilerend Opvouw Ligbed 190x57x28 cm - Grijs"
    discount: string;//
    id: number;
    notes?: string;
    order?: object;
    price: string;
    product: IExtendedProduct;
    quantity?: number;
    status: string;
    orderLineStatus?: string;
    statusDescription?: string;
    tax?: number;
    total: string;
    weight?: string;
    newQuantity?: number;
    stockCount?: number;
    length?: number;
    width?: number;
    calculatedSquare?: number;
    editField: undefined | boolean;
    fromQuickMenu?: boolean;
    //TODO: add correct types
    ledgerCode: any;
    fulfilmentline: any,
    specialPrice?: IAccountProductPrice;
    max: string;
    sourceSquare?: number;
    isDimensionedLine?: boolean;
}

export interface IOrderAttribute {
    changedAt?: IDate;
    createdAt: IDate;
    id: number;
    instance?: string;
    orderAttribute: IEntityAttribute;
    value: string;
}

export interface IOrderLineAttribute {
    changedAt?: IDate;
    createdAt: IDate;
    id: number;
    instance?: string;
    orderLine: number
    orderLineAttribute: number
    value: string
}

export interface IOrderStatus {
    nameTech: string;
    label: string;
    color: string;
    icon: string;
}

// TODO remove this and make dynamic based on backend api data...
export const ORDER_STATUS: IOrderStatus[] = [
    { nameTech: 'DRAFT', label: 'Draft', color: '', icon: 'mdi-cart' },
    { nameTech: 'NEW', label: 'New', color: '', icon: 'mdi-cart' },
    { nameTech: 'OPEN', label: 'Open', color: '', icon: 'mdi-cart' }, //@deprecated.
    { nameTech: 'READY_TO_SHIP', label: 'Ready to ship', color: '', icon: 'mdi-truck' },
    { nameTech: 'READY_TO_PRODUCE', label: 'Ready to produce', color: '', icon: 'mdi-warehouse' },
    { nameTech: 'IN_PRODUCTION', label: 'In production', color: '', icon: 'mdi-warehouse' },
    { nameTech: 'AT_WAREHOUSE', label: 'At warehouse', color: '', icon: 'mdi-warehouse' },
    { nameTech: 'ACTION_REQUIRED', label: 'Action required', color: '', icon: 'mdi-warehouse' },
    { nameTech: 'SHIPMENT_PENDING', label: 'Shipment pending', color: '', icon: 'mdi-truck-delivery' },
    { nameTech: 'SHIPPING', label: 'Shipping', color: '', icon: 'mdi-truck-delivery' },
    { nameTech: 'SHIPPED_PARTIAL', label: 'Shipping (Partial)', color: '', icon: 'mdi-truck-delivery' },
    { nameTech: 'READY_TO_INVOICE', label: 'Ready to invoice', color: '', icon: 'mdi-receipt' },
    { nameTech: 'INVOICED', label: 'Invoiced', color: 'success', icon: 'mdi-receipt' },
    { nameTech: 'CANCEL_REQ', label: 'Cancel request', color: 'warning', icon: 'mdi-cancel' },
    { nameTech: 'CANCELLED', label: 'Cancelled', color: 'warning', icon: 'mdi-cancel' },
    { nameTech: 'ERROR', label: 'Error', color: 'error', icon: 'mdi-alert-circle' },
    { nameTech: 'FAILED', label: 'Failed', color: 'error', icon: 'mdi-alert-circle' },
    { nameTech: 'CLOSED', label: 'Closed', color: 'error', icon: 'mdi-alert-circle' },
    { nameTech: 'PROCESSED', label: 'Processed', color: 'success', icon: 'mdi-alert-circle' },
    { nameTech: 'ACCEPTANCE_ERROR', label: 'Acceptance error', color: 'error', icon: 'mdi-message-alert' },
    { nameTech: 'ERROR_TO_SHIPMENT', label: 'Error to shipment', color: 'error', icon: 'mdi-truck-delivery' },
    { nameTech: 'ERROR_SHIPMENT', label: 'Error shipment', color: 'error', icon: 'mdi-truck-delivery' },
    { nameTech: 'ERROR_INVOICE', label: 'Invoice error', color: 'error', icon: 'mdi-receipt' },
]

export interface IOrderAttributeName {
    "id": number,
    "nameTech": string,
    "isInstanceSpecific": boolean
}


export interface IOrderOne {
    customer: Customer;
    orderAttributes: any[];
    id: number;
    order: IOrder;
    orderlines: IOrderLine[];
    fulfilments: any[];
    fulfilmentOrderLines: any[];
    transports: any[];
    notes: null;
}


export interface ChangedAt {
    date: Date;
    timezone_type: number;
    timezone: string;
}

export interface Customer {
    id: number;
    business: string | null;
    vat: string | null;
    isVatValidated: boolean | null;
    kvk: boolean | null;
    contact: string;
    email: string;
    phone: string;
    isCustomer: number;
    isDeleted: boolean | null;
    reference: boolean | null;
    notes: string;
    changedAt: ChangedAt;
    createdAt: ChangedAt;
    accountType: any;
    attributeValue: {};
    accountAddresses?: {};
    accountUsers?: {};
    pricelist?: {};
}


/**
 * NOTE: Product account special price
 */
export interface IAccountProductPrice {
    product_catalog_product_pricelist__product_id: number;
    product_catalog_pricelist_account__pricelist_id: number;
    product_catalog_pricelist_account__account_id: number;
    product_catalog_pricelist_account__user_id: number | null;
    product_catalog_pricelist_account__order: number;
    product_catalog_product_pricelist__price: string;
}

/**
 * SECTION: Order statuses API model
 */
export interface IOrderStatusesApi {
    orderStatuses: IOrderStatusesField;
    orderLineStatuses: IOrderLineStatusesField;
}
export interface IOrderStatusesField {
    DRAFT?: (string)[] | null;
    NEW?: (string)[] | null;
    ACTION_REQUIRED?: (string)[] | null;
    READY_TO_PRODUCE?: (string)[] | null;
    READY_TO_SHIP?: (string)[] | null;
    AT_WAREHOUSE?: (string)[] | null;
    IN_PRODUCTION?: (string)[] | null;
    CANCEL_REQ_AT_WAREHOUSE?: (string)[] | null;
    CANCEL_REQ?: (string)[] | null;
    ACCEPTANCE_SENT?: (string)[] | null;
    READY_TO_INVOICE?: (string)[] | null;
    SHIPPING?: (string)[] | null;
    SHIPPED_PARTIAL?: (string)[] | null;
    SHIPPED?: (string)[] | null;
    PROCESSED?: (null)[] | null;
    FAILED?: (string)[] | null;
    ERROR?: (string)[] | null;
    CANCELLED?: (null)[] | null;
}
export interface IOrderLineStatusesField {
    NEW?: (string)[] | null;
    ACTION_REQUIRED?: (string)[] | null;
    READY_TO_SHIP?: (string)[] | null;
    AT_WAREHOUSE?: (string)[] | null;
    READY_TO_INVOICE?: (string)[] | null;
    SHIPPING?: (string)[] | null;
    SHIPPED?: (string)[] | null;
    SHIPPED_PARTIAL?: (string)[] | null;
    PROCESSED?: (string)[] | null;
    FAILED?: (string)[] | null;
    CANCELLED?: (string)[] | null;
    ERROR?: (string)[] | null;
}
