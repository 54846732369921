
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PazTextSearch from "../../../components/controls/PazTextSearch.vue";
import PazListFilterSelector from "../../../components/utils/PazListFilterSelector.vue";
import { IQueueJobState } from "../components/QueueJobState.vue";

@Component({
  components: {
    PazTextSearch,
    PazListFilterSelector,
  },
})
export default class QueueTableFiltersPanel extends Vue {
  @Prop({ default: [] }) public items!: object[];
  @Prop({ default: false }) public isRecaring!: boolean;

  @Prop({default: () => []}) public delegateOptions!: [];
  @Prop({default: () => []}) public filteredStatusOptions!: [];
  @Prop({default: () => []}) public jobWorkers!: [];

  public search: string = "";
  public selectedWorker: String[] = [];
  public selectedDelegate: String[] = [];
  public selectedStatus: IQueueJobState[] = [];


  @Watch("search")
  onChangeSearch(value: string) {
    this.$emit("onFillFilter", { key: "search", value });
  }

  @Watch("selectedDelegate")
  onChangeDelegate(value: string) {
    this.$emit("onFillFilter", { key: "selectedDelegate", value });
  }

  @Watch("selectedWorker")
  onChangeWorker(value: string) {
    this.$emit("onFillFilter", { key: "selectedWorker", value });
  }

  @Watch("selectedStatus")
    onChangeStatus(value: string) {
    this.$emit("onFillFilter", { key: "selectedStatus", value });
  }


} //
