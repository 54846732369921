
import Vue from 'vue';

import {Component, Watch} from 'vue-property-decorator';
import {authentication} from "../store";

@Component({})
export default class Dashboard extends Vue {

  userDetails() {
    authentication.loadProfile().then((e) =>
        console.log(authentication.getProfile)
    );
  }


  get isLoggedIn() {
    return authentication.isUserLoggedIn;
  }

  @Watch('isLoggedIn')
  listenToLogin(newVal: boolean, oldval: boolean) {
    if (!newVal) {
      console.log('to login from @Watch');
      
      this.$router.push({name: 'login'});
    }
  }

}
