import { createInvoices } from "../../../services/contracts-service";
import { Vue, Component, } from "vue-property-decorator";
import { IBlancInvoice } from "../../../models/contracts.model";
import { TransactionApiInterface } from "../../../models/contracts.model";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";

@Component({})
export default class InvoicesGeneratorMixin extends Vue {

  $refs!: {
    toast: PazToast;
  }

  public invoicesArray = [];
  public expanded:unknown[] = [];
  public expandPanel = null;
  public selectedTransactionsRow = [];
  private defaultErrorMessage = "Error: invoice hasn't been generated";

  public generateInvoicesTableHeaders = {
    subHeaders: [
      {
        text: "Transaction",
        align: "left",
        class: "cell-truncate",
        value: "contractLine.name"
      },
      {
        text: "Transaction type",
        align: "left",
        class: "cell-truncate",
        value: "transactionSubject.name"
      },
      {
        text: "Type",
        align: "left",
        class: "cell-truncate nowrap",
        value: "transactionType"
      },
      {
        text: "Start date",
        align: "left",
        class: "cell-truncate nowrap",
        value: "startDate"
      },
      {
        text: "End date",
        align: "left",
        class: "cell-truncate nowrap",
        value: "endDate"
      },
      {
        text: "Count / Contract count",
        align: "left",
        class: "cell-truncate nowrap",
        value: "quantity"
      },
      {
        text: "Months",
        align: "right",
        class: "cell-truncate nowrap",
        value: "multiplier"
      },
      {
        text: "Contract price / extra",
        align: "right",
        class: "cell-truncate nowrap",
        value: "contractLine.price"
      },
      {
        text: "Subtotal",
        align: "right",
        class: "cell-truncate nowrap",
        value: "subtotal"
      },
      {
        text: "Total",
        align: "right",
        class: "cell-truncate nowrap",
        value: "total"
      },
    ],
    headersChild: [
      {
        text: "",
        align: "left",
        value: "name",
        sortable: false
      },
      {
        text: "",
        align: "right",
        value: "startDate",
        sortable: false
      },
      {
        text: "",
        align: "left",
        value: "endDate",
        sortable: false
      },
    ],
    headersParent: [

      {
        text: 'Name',
        align: 'left',
        value: 'category'
      }
    ]
  }

  public footerProps = {
    itemsPerPageOptions: [50, 100, 200, -1]
  };


  // onOpenPanel(item) {
  //   const contract = Object.values(item.contracts);
  //   this.expanded = [...contract];
  // }

  getInvoiceTotal(item: TransactionApiInterface) {
    return this.getInvoiceBase(item) + this.getInvoiceExtra(item);
  }

  getPricePerUnitForType(item: TransactionApiInterface) {
    if (item.transactionType == "DYNAMIC") {
      return this.getInvoiceExtra(item);
    }
    return this.getInvoiceBase(item);
  }

  getUnitsForType(item: TransactionApiInterface) {
    if (item.transactionType == "DYNAMIC") {
      return this.getExtraAmount(item);
    }
    return 1;
  }

  getPriceForType(item: TransactionApiInterface) {
    if (item.transactionType == "DYNAMIC") {
      return this.getInvoiceExtra(item);
    }
    return this.getInvoiceBase(item);
  }

  getInvoiceBase(item: TransactionApiInterface) {
    if (typeof item.contractLine == "number") {
      return 9999999;
    }
    return item.contractLine.price * item.multiplier;
  }

  getExtraAmount(item: TransactionApiInterface) {
    if (typeof item.contractLine == "number") {
      return 0;
    }
    return Math.max(0, item.quantity - item.contractLine.quantity * item.multiplier)
  }

  getInvoiceExtra(item: TransactionApiInterface) {
    if (typeof item.contractLine == "number") {
      return 0;
    }
    if (item.quantity <= item.contractLine.quantity * item.multiplier) {
      return 0;
    }
    return (item.contractLine.priceExtra ?? 0) * (item.quantity - item.contractLine.quantity * item.multiplier);
  }

  getNestedInstances(item: any, key: string) {
    return (item && key in item) && Object.values(item[key]);
  }

  changeTotal(value: string, item: TransactionApiInterface) {
    item.total = value
  }

  preparingInvoiceFields(blankInvoice: any): IBlancInvoice {
    return {
      transactions: blankInvoice.map((inv: TransactionApiInterface) => ({
        transactionsId: inv.id,
        selected: true,
        total: inv.total ? parseFloat(inv.total) : this.getPriceForType(inv)
      }))
    }
  }

  async generateInvoice() {
    this.loading = true;
    const invoicesBuiltArray = this.preparingInvoiceFields(this.selectedTransactionsRow)
    const response = await createInvoices(invoicesBuiltArray);
    if (response && !response.success) {
      this.$refs.toast.show({ message: response?.message || this.defaultErrorMessage, color: 'red' });
      this.loading = false;
    } else {
      this.$refs.toast.show({ message: "Success: " + response?.message, color: 'green' });
      this.selectedTransactionsRow = [];
      await this.getDefaultInvoicesTable();

    }
  }

  get canGenerateInvoices() {
    return this.selectedTransactionsRow.length > 0
  }


}//
