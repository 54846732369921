
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { authentication, permission } from "../../store";
import { RouteConfig } from "vue-router";
import { DEFAULT_NAV_SORT_VALUE } from "../../config";

@Component
export default class PazNavigation extends Vue {
  public left: object[] = [];
  public right: object[] = [];
  public routes: object[] = [];
  public showDrawer: boolean = false;
  active: string = "/";
  @Prop({ default: "home" })
  public homeName!: string;
  @Prop({ default: "/assets/logo.png" })
  public image!: string;

  @Prop({
    default: () => ({ name: "Home" }),
  })
  public routerLink!: object;

  created() {}

  sortedChildren(routes: RouteConfig[]) {
    return routes.sort((route1, route2) => {
      const sort1 = route1.meta?.sort || DEFAULT_NAV_SORT_VALUE;
      const sort2 = route2.meta?.sort || DEFAULT_NAV_SORT_VALUE;
      return sort1 - sort2;
    });
  }

  get navRoutes() {
    return permission.getroutesWithPermissions;
  }

  get userRoles() {
    return authentication.profileLoaded;
  }

  get getHomeName() {
    return this.homeName;
  }

  get isSettingsActive() {
    return (this.$route.matched && this.$route.matched.some((value) => value.name === "settings")
    );
  }

  @Watch("userRoles")
  updateRoles() {
    authentication.updateRoutes();
  }
}
