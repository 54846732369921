
import { Component, Prop, Watch, Mixins } from "vue-property-decorator";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import {
  createOrderWithData,
  deleteOrderById,
  getMarketplaces,
  getOrderlinesIncludingOrderFields,
  getOrderStatuses,
  postOrderDelivery,
  updateOrder,
} from "../../services/catalog-service";
import Fulfilments from "./order-detail/Fulfilments.vue";
import PazOrderDialog from "../../components/dialog/PazOrderDialog.vue";
import { formatPrice } from "../../helpers/format";
import PazOrderStatus from "../../components/order/PazOrderStatus.vue";
import { IOrder, IOrderStatus, IOrderStatusesApi, ORDER_STATUS } from "../../models/order";
import PazListFilterSelector from "@pazion/pazion-core/src/components/utils/PazListFilterSelector.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazOrderCreator from "../../components/order/PazOrderCreator.vue";
import PazOrderDeliveryDialog from "../../components/dialog/PazOrderDeliveryDialog.vue";
import { AxiosError } from "axios";
import OrderListingMixin from "./mixins/orderListingMixin";
import orderListingFilterMixin from "./mixins/orderListingFilterMixin";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import PazOrderFilesDialog from "../../components/dialog/PazOrderFilesDialog.vue";
import { IMarketplace } from "../../models/base";
import PazOrderProductionDialog from "../../components/dialog/pazOrderProductionDialog.vue";
import { MarketplaceInstanceEntity } from "../../models/offers.model";

@Component({
  components: {
    Fulfilments,
    PazOrderDialog,
    PazRefreshButton,
    PazOrderStatus,
    PazListFilterSelector,
    PazTooltipButton,
    PazToast,
    PazConfirmation,
    PazOrderCreator,
    PazOrderDeliveryDialog,
    PazOrderFilesDialog,
    PazOrderProductionDialog
  },
  methods: {
    formatPrice,
  },
})
export default class OrderLineGroupByListing extends Mixins(
  OrderListingMixin,
  orderListingFilterMixin
) {
  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
    confirmDeleteOrder: PazConfirmation;
  };
  public orderStatus: IOrderStatus[] = ORDER_STATUS;
  public selectedOrderStatus = [];
  public loading: boolean = false;
  //public search: string = "";
  public searchByReference: string = "";
  public selectedOrderType = [];
  public selectedOrderMarketPlace = [];
  public lastSearchByReference: string = "";
  public items = [];
  public orderDetailsData: any;
  public result: any;
  public expanded: [] = [];
  // private refreshTimer: NodeJS.Timeout | null = null;
  public footer: object = {
    "items-per-page-options": [50, 100, 200],
  };
  public showOrderEditDialog: boolean = false;
  public avaliableOrderStatuses: [] = [];
  public showOrderCreator: boolean = false;
  public selectedOrder: number = 0;
  public marketplaces: IMarketplace[] = [];
  public showOrderDeliveryDialog: boolean = false;
  public dialog: object = {
    show: false,
    info: [],
  };

  public orderStatuses = {} as IOrderStatusesApi;
  public availableStatusesItems = [];
  public availableOrderTypes = [];
  public availableOrderMarketplaces = [];
  public fromFulfilment: boolean | undefined = false;
  public showOrderProductDialog: boolean = false;
  public selectInstancesMarketPlace = [] as MarketplaceInstanceEntity[];

  @Prop({
    default: () => {
      return [];
    },
  })
  public tableHeaders!: object[];

  public headers: object = {
    main: [
      {
        text: "Order",
        align: "left d-none d-md-table-cell",
        class: "cell-truncate nowrap",
        sortable: true,
        cellClass: "nowrap",
        value: "orderId",
      },
      {
        text: "Orderline",
        align: "left",
        class: "cell-truncate",
        sortable: true,
        value: "orderLineId",
        cellClass: "nowrap",
        groupable: false,
      },
      {
        text: "Type",
        align: "left d-none d-sm-table-cell",
        class: "cell-truncate",
        sortable: true,
        value: null,
        groupable: false,
      },
      {
        text: "Description",
        align:
          "left maxcol  d-none d-xs-table-cell  d-md-table-cell  d-lg-table-cell",
        class: "cell-truncate",
        sortable: true,
        value: "orderLineDescription",
        groupable: false,
      },
      {
        text: "Attributes",
        align: "left d-none d-lg-table-cell",
        class: "cell-truncate",
        sortable: true,
        value: "orderLineAttributesLine",
        groupable: false,
      },
      {
        text: "Quantity",
        align: "right d-none d-lg-table-cell",
        class: "cell-truncate  d-none d-lg-table-cell",
        sortable: true,
        value: "orderLineQuantity",
        groupable: false,
      },
      {
        text: "Total",
        align: "left d-none d-xs-table-cell  d-lg-table-cell",
        class: "cell-truncate text-right	mx-0",
        sortable: true,
        value: "orderTotal",
      },
      {
        text: "Status",
        align: "right",
        class: "cell-truncate text-right",
        sortable: true,
        value: "orderLineStatus",
        groupable: false,
      },
      {
        text: "Order date",
        align: "right maxcol100",
        class: "cell-truncate text-right",
        sortable: true,
        value: "",
        groupable: false,
      },
      {
        text: "Actions",
        align: "right",
        class: "cell-truncate text-right mx-5",
        sortable: false,
        value: "actions",
        groupable: false,
      },
    ],
  };

  async duplicateOrderAction(items: any) {
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Duplicate",
      "Are you sure you want to duplicate order  `" + items[0].orderId + "`?"
    );
    if (confirm) {
      await this.duplicateOrder(items[0].orderId);
    }
  }

  async duplicateOrder(orderId?: number) {
    const r = await createOrderWithData({ copyId: orderId });
    if (r.orderId && r.orderId > 0) {
      this.$refs.toast.show({
        message: `Order #${r.orderId} created. Duplicated from #${orderId}`,
      });
      this.showOrderEditDialog = true;
      this.selectedOrder = r.orderId;
      await this.fetchData();
    }
  }

  get cSelectedOrder() {
    let index = this.items.findIndex((item) => {
      return item.orderId == this.selectedOrder;
    });

    if (index === -1) {
      return {};
    }
    return {
      id: this.items[index].orderId,
      total: this.items[index].orderTotal,
      description: this.items[index].orderLineDescription,
      orderDate: this.items[index].orderDate,
      status: this.items[index].orderStatus,
      production: this.items[index].production
    };
  }

  isEmpty(item: any) {
    return item.extra.length == 0 || item.extra == "[]";
  }

  openOrderDetails(item: IOrder) {
    this.selectedOrder = item.id;
    this.showOrderEditDialog = true;
  }

  async createDelivery(items: any) {
    try {
      let response = null;
      let orderLine = items.map(
        (item: { id: number; orderLineQuantity: number }) => {
          return { id: item.id, quantity: item.orderLineQuantity };
        }
      );
      this.selectedOrder = items[0].orderId;
      let deliveryPayload = {
        id: items[0].orderId,
        orderLine,
        deliverAt: "",
      };

      if (this.canShowDeliveryDialog) {
        this.showOrderDeliveryDialog = true;
      } else {
        response = await postOrderDelivery(deliveryPayload);
        this.$refs.toast.show({ message: response.message });
        this.fetchData();
      }
    } catch (error) {
      let response = (error as AxiosError).response;
      if (response && response.status !== 500) {
        this.$refs.toast.show({
          message: response.data.message,
          color: "red",
          icon: "mdi-close-circle",
        });
        return;
      }
      this.$refs.toast.show({
        message: "An error occurred, please contact the administrator",
        icon: "mdi-close-circle",
        color: "red",
      });
    }
  }

  get canOrderEditableWithStatus() {
    if (this.cSelectedOrder) {
      return (
        this.cSelectedOrder &&
        !["SHIPPING", "READY_TO_INVOICE", "PROCESSED", "SHIPPED"].includes(
          this.cSelectedOrder.status
        )
      );
    }
    return false;
  }
  get canCopyOrder() {
    return this.$copyOrder != undefined && this.$copyOrder;
  }

  get canEditOrder() {
    return this.$editOrder != undefined && this.$editOrder;
  }

  get canShowOrderDetails() {
    return this.$orderDetails != undefined && this.$orderDetails;
  }

  async orderStateRetry(item: any) {
    const rsp = await updateOrder(item.orderId, { status: "NEW" });
    if (rsp.success) {
      this.$refs.toast.show({ message: rsp.message });
      await this.fetchData();
    }
  }

  async showOrderProduction(item: IOrder) {
    this.selectedOrder = item.orderId || item.id;
    this.showOrderProductDialog = true;
  }

  getAvailableStatuses() {
    return (
      this.orderStatus.length &&
      this.orderStatus
        .map((st) => {
          if (
            this.filteredItems &&
            this.filteredItems
              .flatMap((el) => el.orderLineStatus)
              .includes(st.nameTech)
          ) {
            return st;
          }
        })
        .filter((item) => item !== undefined)
    );
  }

  async fetchData(filterData?: object, clearRef?: boolean) {
    this.loading = true;
    let filter: {
      reference?: object;
      operator?: "AND";
      "o.isDeleted"?: number;
    } = { "o.isDeleted": 0 };

    this.orderStatuses = await getOrderStatuses();

    clearRef && (this.searchByReference = "");

    if (!filterData && this.searchByReference.length > 0) {
      const refString = this.searchByReferenceFields(
        this.searchByReference,
        true
      );
      Object.assign(filter, refString);
    }

    if (filterData) Object.assign(filter, filterData);
    //@ts-ignore
    const response = await getOrderlinesIncludingOrderFields(
      this.$productionManagement,
      this.$orderDossierManagement,
      filter
    );

    if (this.items == undefined) {
      this.items = [];
    }
    this.items = response;
    this.availableStatusesItems = this.getAvailableStatuses();
    this.availableOrderTypes = this.getAvailableOrderTypes();
    this.availableOrderMarketplaces = this.getAvailableOrderMarketplaces();

    this.lastSearchByReference = this.searchByReference;
    this.loading = false;
  }

  public updateNotes(newText: string) {
    this.orderDetailsData.notes = newText;
  }

  getAvailableOrderMarketplaces() {
    return (
      (this.marketplaces.length &&
        this.marketplaces
          .map((st) => {
            if (
              this.filteredItems &&
              this.filteredItems
                .flatMap((el: IOrder) => el.marketplace)
                .includes(st.name)
            )
              return st;
          })
          .filter((item) => item !== undefined)) ||
      []
    );
  }

  formatDate(date: string) {
    if (date == null) {
      return "";
    } else {
      return formatDateTime(date, null, "YYYY-MM-DD");
    }
  }

  showOrderDetails(item: any, fromFulfilment?: boolean) {
    this.fromFulfilment = fromFulfilment;
    this.showOrderEditDialog = true;
    this.selectedOrder = item[0].orderId;
  }

  async deleteOrder(item: any) {
    const confirm = await this.$refs.confirmDeleteOrder.openConfirmDialog(
      "Delete",
      `Do you want to delete order #${item.id}?`
    );
    if (!confirm) {
      return;
    }
    let response = await deleteOrderById(item.orderId);

    if (response.success) {
      this.$refs.toast.show({ message: response.message });
      this.fetchData();
      return;
    }
    this.$refs.toast.show({
      message: "An error occurred, please contact the administrator",
      icon: "mdi-close-circle",
      color: "red",
    });
  }

  async mounted() {
    this.searchByReference =
      (this.$route.query && <string>this.$route.query.orderReference) || "";
    this.fetchData();
    this.marketplaces = await getMarketplaces();
  }
} //
