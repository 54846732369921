import {addChildRoute, addRoutes} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import Settings from "./views/Settings.vue";
import Logout from "@pazion/pazion-core/src/views/user/Logout.vue";

addRoutes(getCatalogRoutes());

/* customize...
redirectRouteToBackend('invoices', true);
removeRouteByName('invoices');
redirectRouteToBackend('products', true);
addRoute(  {
  path: '/invoices',
  name: 'invoices',
  component: AppOrderList,
  meta: {
    'show-in-nav': true,
    roles: ['user'],
  },
  });
*/

addChildRoute('settings', {
    path: '/apisetting',
    name: 'Api configuration',
    component: Settings,
    meta: {
        'show-in-nav': true,
        'hasDrawer': true,
        'icon': 'mdi-account',
        roles: ['user'],
    },
});