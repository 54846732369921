
import {Component, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import TextInput from "./Form/Input/TextInput.vue";
import SelectInput from "./Form/Input/SelectInput.vue";
import ListInput from "./Form/Input/ListInput.vue";
import BoolInput from './Form/Input/BoolInput.vue';
import BaseInput from "./Form/Input/BaseInput.vue";
import SecretInput from "./Form/Input/SecretInput.vue";

@Component({
  components: {
    SecretInput,
    BaseInput,
    TabContent,
    TextInput,
    SelectInput,
    ListInput,
    BoolInput,
  }
})
export default class TabContent extends Vue {
  @Prop({
    default: () => {
      []
    }
  })
  public children!: object[];
  @Prop()
  public parentName!: string;

  @Prop()
  public errorList!: object[];

  // @Watch('errorList')
  // public WatcherForErrorList() {
  //   console.log('main logger for error list for ' + this.parentName)
  //   // console.log(this.errorList)
  //   this.childrenNames.forEach((name: string) => {
  //     this.checkIfDisplayChange(name, this.successList)
  //   })
  // }

  @Prop()
  public successList!: object[];

  // @Watch('successList')
  // public WatcherForSuccessList() {
  //   console.log('main logger for success list for ' + this.parentName)
  //   // console.log(this.successList)
  //
  //   this.childrenNames.forEach((name: string) => {
  //     this.checkIfDisplayChange(name, this.successList)
  //   })
  // }

  @VModel({
    default: () => {
    }
  })
  public singleItem!: object;

  public childrenList: object[];

  public repeatBlock: string = '';

  // mounted() {
  //   if (this.children.length > 1) {
  //     this.children.forEach((child) => {
  //       this.childrenList.push({
  //         //path: value
  //         [this.generatePathForChild(child.name, child.path)]: false
  //       });
  //     })
  //   } else {
  //     this.childrenList.push({[this.generatePathForChild(this.singleItem.name, this.singleItem.path)]: false});
  //   }
  // }

  //local component var(s)
  public defaultSelectOption: string = 'Select a value';

  public generatePathForChild(name: string, path: string): string {
    let newPath: string;
    newPath = path ? path + '.' + name : name;

    return newPath;
  }

  public createNewChildElement() {
    //in container, get all fields under repeat
    let cloneOfRepeat = Object.assign({}, this.children['repeat']);

    console.log(cloneOfRepeat);

    //reset values
    cloneOfRepeat = this.changeValuesOfChildren(cloneOfRepeat);

    console.log('after all changes')
    console.log(cloneOfRepeat)

    //finally, add clone to list of children
    this.children.push(cloneOfRepeat)
  }

  private changeValuesOfChildren(item: object) {
    //strip the repeat of the name(s) and path
    let name: string;
    let path: string;

    name = item.name.replaceAll('repeat', this.repeatBlock);
    console.log('new name is equal to: ' + name)
    item.name = name;

    path = item.path.replaceAll('repeat', this.repeatBlock);
    console.log('newpath is equal to: ' + path)
    item.path = path;

    //reset value
    item.value = null;

    //check if children exist, if so do the same foreach child
    if (item.children.length > 1) {
      item.children.forEach((child) => {
        item.children[child] = this.changeValuesOfChildren(child);
      })
    }

    return item;
  }


  // public checkIfDisplayChange(ID: string, array: object[]) {
  //   let displayList: object[] = [];
  //
  //   //foreach child in current container
  //   //check if it exists in error or success list.
  //   //if so send true to value
  //
  //
  //   array.forEach((item: { key: string, value: object }) => {
  //     if (item.key === ID) {
  //       displayList.push(item.key)
  //       if ('value' in item) {
  //         for (let [key, message] of Object.entries(item.value)) {
  //           this.displayList.push(message.toString())
  //         }
  //       }
  //     }
  //   })
  //
  //   if (!inArray) {
  //     this.localErrorMessages = [];
  //   }
  //
  //   return inArray;
  // }
}
