
import PazTooltipButton from '@pazion/pazion-core/src/components/controls/PazTooltipButton.vue';
import { ContractApiInterface, ContractLine, ContractLineFrequency, TransactionSubject } from '../../../../models/contracts.model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VDataTable, VTextField, VSelect } from 'vuetify/lib';
import { baseRegexp } from "../../../../helpers/constants";
import { watch } from 'fs';



@Component({
  components: { PazTooltipButton }
})
export default class extends Vue {

  @Prop() selectedContract?: ContractApiInterface | null;
  @Prop() contractLines?: ContractLine[] | [];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: [] }) transactionSubjects!: TransactionSubject[];
  @Prop({ default: [] }) frequencyTypes!: ContractLineFrequency[];

  public baseRegexpObject = baseRegexp;


  /* SECTION Line headers */
  public getContractLinesHeaders = [
    {
      text: "Name",
      align: "left",
      cellClass: "nowrap",
      value: "name",
    },
    {
      text: "Subject",
      align: "left",
      cellClass: "nowrap",
      value: "transactionSubject",
    },
    {
      text: "Quantity",
      align: "left",
      cellClass: "nowrap",
      value: "quantity",
    },
    {
      text: "Price - Monthly",
      align: "left",
      cellClass: "nowrap",
      value: "price",
    },
    {
      text: "Extra Price - Monthly",
      align: "left",
      cellClass: "nowrap",
      value: "priceExtra",
    },
    {
      text: "Invoice Frequency",
      align: "left",
      cellClass: "nowrap",
      value: "contractLineFrequency",
    },

    {
      text: "",
      align: "right",
      cellClass: "nowrap",
      value: "actions",
    },
  ];

  onDeleteContractLine(item: ContractLine, index: number | undefined) {
    this.$emit('delContractLine', { item, index });
  }

  onLineChange(item: any) {
    item.hasUpdate = true;
  }

  get getContractLines(): ContractLine[] | undefined {
    return this.contractLines;
  }

}
