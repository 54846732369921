
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { IOrderAdress } from "../../../../models/adress";
import PazAdressPicker from "../../../picker/PazAdressPicker.vue";

@Component({
  components: {
    PazAdressPicker,
  },
})
export default class OrderAdressComponent extends Vue {
  @Prop()
  public adress!: IOrderAdress;

  @Prop({ default: "Billing details" })
  public title!: string;
  @Prop({ default: "mdi-receipt" })
  public icon!: string;
  @Prop({ default: true })
  public editAble!: boolean;
  @Prop({ default: "unknown" })
  public addressType!: string;
  @Prop({ default: -1 })
  public accountId!: boolean;

  private showAdressPicker: boolean = false;
  private targetAdress: string = "";
  public myEmitter: string = "";

  async updateAdress() {}

  openAdressPicker() {
    this.showAdressPicker = true;
  }
  async newAdressPicked(v: any) {
    this.$emit("picked-value", v);
    // await updateOrderDetails(orderDetailsData.id, {adress: v});
  }
}
