import {Vue, Component} from 'vue-property-decorator';
import { AxiosError } from "axios";

@Component
export default class CardOperationsMixin extends Vue {

  public headers = [
    {
      text: "id",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "id",
    },
    {
      text: "Name",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "name",
    },
    {
      text: "Account",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "client",
    },
    {
      text: "SubDomain",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "subdomain",
    },
    {
      text: "Active",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "isActive",
    },
    {
      text: "Modified",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "changedAt",
    },
    {
      text: "Actions",
      class: "nowrap",
      align: "right",
      sortable: false,
      value: "actions",
    }
  ]


  handleSaveError(res: AxiosError | Response, instance?: string, operation?: string) {
    const currentInstance = instance ? instance : 'card';
    const currentOperation = operation ? operation : 'saved';
    this.$parent.$refs.toast.show({
      message: res?.response?.data?.detail || `An error occurred, ${currentInstance} hasn't been ${currentOperation}`,
      icon: "mdi-close-circle",
      color: "red",
    });
    this.loading = false;
  }

}//