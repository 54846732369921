
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import ProgressArrow from "../../assets/ProgressArrow.vue";
import { IAccountProductPrice, IOrderLine } from "../../models/order";
import PazProductPicker from "../picker/PazProductPicker.vue";
import {
  getProductAccountPrice,
  getProductById,
  updateOrderLine,
} from "../../services/catalog-service";
import { formatPrice } from "../../helpers/format";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import { IApiProduct } from "../../models/product";

@Component({
  components: {
    ProgressArrow,
    PazLoader,
    PazTooltipButton,
    PazProductPicker,
  },
  methods: {
    formatPrice,
  },
})
export default class PazOrderLineRow extends Vue {
  @VModel({
    default: false,
  })

  public showDialog!: boolean;
  public selectedProductId: number = 0;
  public selectedProduct: object | null = null;
  public selectedDescription: string | null = null;
  public selectedQuantity: number = 1;
  public selectedPrice: number | null = 0;
  public productPrice: number | null = 0;
  public selectedSumPrice: string | null = null;
  public widthParam: number = 0;
  public lengthParam: number = 0;

  @Prop({
    default: () => {
      return { product: { id: 0 } };
    },
  })
  public line!: IOrderLine;

  @Prop({ default: 0 })
  public orderId!: number | string;

  @Prop() orderDetailsData!: object;

  public loading: boolean = false;
  public valid: boolean = true;
  public manualPrice: boolean = false;
  public isSpecialPrice: boolean = false;
  public selectedProductType: string | null = null;

  async saveOrderLine() {
    this.loading = true;
    const rsp = await updateOrderLine(this.line.id, {
      order: this.orderId,
      quantity: this.selectedQuantity,
      width: this.widthParam,
      length: this.lengthParam,
      //      notes: this.selectedDescription,
      price: this.selectedPrice,
      total: null,
      product: { id: this.selectedProductId },
    });
    this.showDialog = false;
    if (rsp.success) {
      this.$emit("update-data");
      this.closeForm();
    }
  }

  public resetForm() {
    this.selectedProductId = 0;
    this.selectedProduct = null;
    this.selectedDescription = null;
    this.selectedQuantity = 1;
    this.selectedPrice = 0;
    this.productPrice = 0;
    this.loading = false;
    this.selectedSumPrice = null;
    this.lengthParam = 0;
    this.widthParam = 0;
    this.manualPrice = false;
  }

  public closeForm() {
    this.resetForm();
    this.$emit("close-form");
  }

  async updateProduct(p: IApiProduct) {
    this.loading = true;
    this.widthParam = 0;
    this.lengthParam = 0;
    this.selectedProductId = p?.product_catalog_product__id;
    this.selectedDescription =
      p?.product_catalog_product__name;
    this.selectedProductType = p?.product_catalog_product__product_type;

    let finalPrice = null;
    if (this.$pricelistManagement) {
      const specialPrice = await getProductAccountPrice(
        {
          filter: JSON.stringify({
            operator: 'AND',
            product_catalog_pricelist_account__account_id: this.orderDetailsData?.customer?.id,
            product_catalog_product_pricelist__product_id: p.product_catalog_product__id
          })
        }
      )
      if (specialPrice && specialPrice.length > 0) {
        finalPrice = parseFloat(specialPrice.shift()?.product_catalog_product_pricelist__price);
        this.isSpecialPrice = true;
      } else
        finalPrice = parseFloat(p.product_catalog_product__price);
    } else {
      finalPrice = parseFloat(p.product_catalog_product__price);
    }
    this.selectedPrice = finalPrice;
    this.productPrice = finalPrice;

    if (this.line.description === null) {
      this.line.description = p.product_catalog_product__sku;
    }
    this.updateTotal();
    this.loading = false;
  }


  get getLinePrice() {
    return this.selectedPrice;
  }

  get productHasdimensions() {
    return this.selectedProductType &&
        this.selectedProductType === "dimensioned";
  }

  @Watch("showDialog")
  opened(isOpen: boolean) {
    if (!isOpen) {
      return;
    }
  }

  @Watch("selectedProduct")
  async productChanged(newProduct: number, oldProduct: number) {
    if (newProduct !== oldProduct && newProduct != null) {
      const pdata = await getProductById(newProduct);
      this.updateProduct(pdata);
    }
  }

  @Watch("selectedQuantity")
  triggerQuantity() {
    this.updateTotal();
  }

  updateTotal(manualPrice?: boolean) {
    let selectedSumPrice = 0;

    if (manualPrice) {
      this.manualPrice = manualPrice;
      this.productPrice = this.selectedPrice;
    }

    if (!this.$orderlineMeasureCalculation || !this.productHasdimensions) {
      selectedSumPrice =
        Math.round(
          (this.productPrice * this.selectedQuantity + Number.EPSILON) * 100
        ) / 100;
    } else {
      if (this.lengthParam * this.widthParam > 0 && !this.manualPrice) {
        this.selectedPrice =
          Math.round(
            (this.productPrice * this.lengthParam * this.widthParam +
              Number.EPSILON) *
            100
          ) / 100;
        selectedSumPrice =
          Math.round(
            (this.productPrice *
              (this.selectedQuantity * (this.lengthParam * this.widthParam)) +
              Number.EPSILON) *
            100
          ) / 100;
      } else if (this.manualPrice) {
        selectedSumPrice =
          Math.round(
            (this.productPrice * this.selectedQuantity + Number.EPSILON) * 100
          ) / 100;
      }
    }

    if (
      this.$orderlineMeasureCalculation &&
      !this.manualPrice &&
      this.productHasdimensions &&
      this.$measureCalculationUnit == "mm"
    ) {
      this.selectedPrice = Math.round(this.selectedPrice / 10000) / 100;
      selectedSumPrice = Math.round(selectedSumPrice / 10000) / 100;
    }

    this.selectedSumPrice = selectedSumPrice;
  }
} //
