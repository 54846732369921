
import { Component, VModel } from "vue-property-decorator";
import Vue from "vue";
import { executeCommand, getCommandList } from "../../../services/auth-service";
import PazLoader from "../../../components/utils/PazLoader.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";


export interface ICommandItem {
  description: string;
  command: string;
}

@Component({
  components: { PazLoader, PazToast }
})
export default class PazCommandSelector extends Vue {

  $refs!: {
    toast: PazToast;
  }

  public loading: boolean = true;
  public disabled: boolean = false;
  public selectedCommand: any = {};
  public commands: Array<ICommandItem> = [];

  @VModel()
  public dialog: boolean;

  created() {
    this.fetchData();
  }

  async runCommand() {
    if (this.selectedCommand.length <= 1 || this.disabled) {
      return;
    }
    this.disabled = true;
    const rsp = await executeCommand({ command: this.selectedCommand });

    if (!rsp || !rsp.success) {
      this.$refs.toast.show({
        message: "Failed to add the requested queue job",
        icon: "mdi-close-circle",
        color: "red",
      });
    } else {
      this.$emit('refreshParentData');
      this.$refs.toast.show({
        message: "Succesfully added the requested queue job",
        color: 'green'
      });
    }
    this.disabled = false;
    this.dialog = false;
  }

  async fetchData() {
    const rsp = await getCommandList();
    this.commands = rsp.list;
    this.loading = false;
  }

  get getCommands() {
    return this.commands || [];
  }

}
