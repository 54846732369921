import { MultiSearchObjType } from '../models/base';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BaseMixin extends Vue {

  showDefaultServerErrorToast() {
    this.$parent.$refs.toast.show({
      message: 'An error occurred, please contact the administrator',
      icon: 'mdi-close-circle',
      color: 'red'
    });
  };

  chainServerError(type: string, result?: boolean, message?: string, instance?: string, initialToast?: boolean) {
    const fullMessage: string = message
      ? message
      : `An error occurred, ${instance || 'item'} hasn't been ${type}`;
      const toastObj = {
      message: fullMessage,
      icon: !result ? "mdi-close-circle" : "mdi-checkbox-marked-circle-outline",
      color: !result ? "red" : "green",
      yPosition: "top",
    }
    if (!initialToast)
     this.$parent.$refs.toast.show(toastObj);
    else 
      this.$refs.toast.show(toastObj);
  }

  /**
   * NOTE: Create search string with single (and) multi select (or) filter object 
   * */
  createSearchString(
    singleOperatorObj?: { (key: string): string | number | boolean | null },
    multiOperatorObj?: { (key: string): string | number | boolean | null }
  ) {
    let searchObj:
      MultiSearchObjType | {}
      = { operator: 'AND', isDeleted: 0, multi: { operator: 'OR' } };
    singleOperatorObj && Object.assign(singleOperatorObj, { operator: 'AND' });
    multiOperatorObj && Object.assign(multiOperatorObj.multi, { operator: "OR" });
    searchObj = { ...singleOperatorObj, ...multiOperatorObj }
    return searchObj;
  }

}//
