
import { Component, Mixins } from "vue-property-decorator";
import DossierListMixin from "../mixins/dossier-list.mixin";
import AccountMixin from "../../../mixins/account.mixin";

@Component({})
export default class DossierListTable extends Mixins(
  DossierListMixin,
  AccountMixin
) {}
//LINK: frontendPackages/pazion-core/src/views/dossier/mixins/dossier-list.mixin.ts
