import { Component, Vue } from "vue-property-decorator";
import { fetchDossierDocuments, fetchOrderDocuments, getFileUrl} from "../../../services/dossier-service";


@Component({})
export default class DossierDocsMixin extends Vue {
  public docLoading: boolean = false;
  public dossierFiles = [];

  async getDossierDocument(dossierId: number, query?: { [key: string]: string | boolean }) {
    this.docLoading = true;
    const response = await fetchDossierDocuments(dossierId, query);

    if (response) {
      this.dossierFiles = response;      
      this.docLoading = false;
      return response.files.length > 0 && response.files[0].dossier; 
    } else {
      this.docLoading = false;
    }
  }

  async getOrderDocument(dossierId: number) {
    this.docLoading = true;
    const response = await fetchOrderDocuments(dossierId);
    if (response) {
      this.dossierFiles = response;
      this.docLoading = false;
      return true
    } else {
      this.docLoading = false;
    }
  }

  async getFileData(docId: number) {
    this.docLoading = true;
    const file = await getFileUrl(docId);
     const convertedFile = 
       file ? "data:application/pdf;base64," + file.content
      : "No Data";
    this.docLoading = false;
    return convertedFile;
  }

}
