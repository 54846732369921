
import { Vue, Component, VModel, Prop } from "vue-property-decorator";

@Component
export default class PazOrderAttributesDialog extends Vue {
  @VModel({
    default: false,
  })
  showDialog!: boolean;

  @Prop() modalTitle: string;

  public loading: boolean = false;
} //
