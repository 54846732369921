
import {Vue, Component, Prop, VModel} from "vue-property-decorator";

@Component({})
export default class TextInput extends Vue {
  @VModel({})
  public popopObjectArr!: object[];

  public localErrors: object[] = [];

  private listOfPopupTypes = [
    'success',
    'error',
    'warning',
    'info'
  ];
  // mounted() {
  //   console.log('Popup has been mounted')
  //   console.log(this.popopObjectArr)
  // }

}
