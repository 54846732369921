
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class PazFooter extends Vue {

  @Prop({ default: false })
  showFooterInfo!: boolean;

  getVersion() {
    return process.env.VUE_APP_VERSION;
  }
}
