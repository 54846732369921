var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedContract && !_vm.loading)?_c('v-data-table',{ref:"contractsDataTable",staticClass:"elevation-2",attrs:{"headers":_vm.getContractLinesHeaders,"fixed-header":true,"items":_vm.getContractLines,"item-class":"v-row-group__subrow","no-data-text":"No contracts found.","no-results-text":"No matching contracts found.","light":"","loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('v-text-field',{on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.transactionSubject",fn:function({ item }){return [_c('v-select',{attrs:{"items":_vm.transactionSubjects,"dense":"","item-text":"nameTech","item-value":"id","hide-details":""},on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.transactionSubject.id),callback:function ($$v) {_vm.$set(item.transactionSubject, "id", $$v)},expression:"item.transactionSubject.id"}})]}},{key:"item.quantity",fn:function({ item }){return [_c('v-text-field',{attrs:{"rules":[(v) => v >= 0 || 'Quantity should be positive'],"type":"number"},on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.contractLineFrequency",fn:function({ item }){return [_c('v-select',{attrs:{"items":_vm.frequencyTypes,"dense":"","item-text":"name","item-value":"id","hide-details":""},on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.contractLineFrequency.id),callback:function ($$v) {_vm.$set(item.contractLineFrequency, "id", $$v)},expression:"item.contractLineFrequency.id"}})]}},{key:"item.price",fn:function({ item }){return [_c('v-text-field',{attrs:{"rules":[
        (value) =>
          _vm.baseRegexpObject.priceRegex.test(value) || 'Wrong format',
      ]},on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"item.priceExtra",fn:function({ item }){return [_c('v-text-field',{attrs:{"rules":[
        (value) =>
          value
            ? _vm.baseRegexpObject.priceRegex.test(value) ||
            'Wrong format'
            : '',
      ]},on:{"change":function($event){return _vm.onLineChange(item)}},model:{value:(item.priceExtra),callback:function ($$v) {_vm.$set(item, "priceExtra", $$v)},expression:"item.priceExtra"}})]}},{key:"item.actions",fn:function({ item }){return [_c('paz-tooltip-button',{attrs:{"icon":"mdi-minus-circle","color":"black","tooltip":"Delete line"},on:{"click":function($event){_vm.onDeleteContractLine(
        item, _vm.contractLines.map((x) => x.id).indexOf(item.id)
      )}}}),_c('span',{class:(!item.id || item.hasUpdate) && 'blink_element'},[(!_vm.loading)?_c('paz-tooltip-button',{attrs:{"icon":"mdi-content-save","color":item.id ? 'success' : 'warning',"tooltip":item.id ? 'Save line' : 'Add line'},on:{"click":function($event){return _vm.$emit('updateContractLine', item)}}}):_vm._e()],1)]}}],null,false,1970091666)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }