import { ICardAttrValuesModel } from '../../../models/member_card.model';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CardTemplaterMixin extends Vue {

  $refs!: {
    qrcanvasElement: Element;
  };

  private defaultmoveElement = {
    text: "",
    value: "",
    use: false,
    transform: "",
    style: '',
    width: null,
    height: null,
    isGlobal: false,
    class: ''
  };

  private exludedFields = ['token'];

  // Initialize VueMovable
  getmovableConfig(selected: number) {
    return {
      draggable: selected,
      throttleDrag: 1,

      resizable: selected,
      throttleResize: 1,
      keepRatio: false,

      scalable: selected,
      throttleScale: 0.01,

      rotatable: selected,
      throttleRotate: 0.2,

      origin: false,
      snappable: true,
      snapThreshold: 5,
      horizontalGuidlines: [0],
      verticalGuidlines: [0],
      elementGuidelines: Array.apply(
        null,
        document.querySelectorAll(".moveable__element")
      )
    }
  }

  getLabelPoistion(element: MouseEvent, position: string) {
    const elementHeight = element && element.target.offsetHeight;
    if (position === 'top')
      return element && element.clientY > 180;
    else
      return element && element.clientY < (181 + (elementHeight > 30 ? elementHeight : 0));
  }

  /**
   * NOTE: Movable operations
   **/
  selectItem(index: number) {
    this.selectedItem = index;
    this.selectedPlotElement = this.designAttributes[index];
  }

  selectElementPosition(event: MouseEvent) {
    this.selectedVisibleElement = event;
  }

  handleDrag({ target, transform }) {
    target.style.transform = transform;
    this.designAttributes[this.selectedItem].transform = transform;
  }

  handleResize({ target, width, height }) {
    this.designAttributes[this.selectedItem].width = width;
    this.designAttributes[this.selectedItem].height = height;
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
  }

  handleRotate({ target, transform }) {
    target.style.transform = transform;
    this.designAttributes[this.selectedItem].transform = transform;
  }

  changeLabelstatus(data: { index: number, status: boolean }) {
    this.designAttributes[data.index].use = data.status;
  }

  changeCustomCSS(stylesVal: string) {
    const { id } = this.selectedPlotElement;
    this.designAttributes.filter(desAttr => desAttr.id === id).style = stylesVal;
  }

  addSystemImgAttributes(item: object, status: boolean) {
    const currerntAttrs = [...this.designAttributes];
    let changedAttr = { ...item };
    changedAttr.use = status;
    const reletedSystemAttr = currerntAttrs.findIndex(desAttr => {
      return desAttr?.system === changedAttr.system
    });

    if (reletedSystemAttr > -1 && !status) {
      this.designAttributes = currerntAttrs.filter(desAttr => desAttr?.system !== changedAttr.system);
    }
    else if (reletedSystemAttr > -1 && status) {
      this.designAttributes[reletedSystemAttr] = changedAttr;     
    }
    else {
      this.designAttributes.push(changedAttr);  
    }
        
  }
  //END 

  /**
   * NOTE: Reformat attributes for templater
   */
  preparingCardAttributes(attrRes: ICardAttrValuesModel[]) {
    return attrRes.map((attr: ICardAttrValuesModel) => {
      let currentEl = { ...this.defaultmoveElement };
      Object.assign(currentEl, {
        value: attr.value,
        text: attr.cardAttribute.nameTech,
        id: attr.id,
        fieldType: attr.cardAttribute.fieldType,
        system: null,
        isGlobal: !attr.cardAttribute.isUserAttribute && !attr.cardMember,
        card: attr.card,
        dynamic: attr.cardAttribute.fieldOptions && attr.cardAttribute.fieldOptions.length > 0,
        cardAttributeId: attr.cardAttribute.id
      });
      return currentEl;
    });
  }

  // Validate layout method, can We set previous template
  validatePreviousTemplate(cardLayout: Array<object> | null) {
    return cardLayout && Array.isArray(cardLayout)
      && cardLayout.length > 0 && this.designAttributes
      && this.designAttributes.length > 0;
  }

  /**
   * NOTE: add to plot system attrs
   */
  obtainSystemCardElement(name: string, value: string, type?: string, isMemberAttr?: boolean, specialStyles?: string) {
    const systemElement = {
      use: false,
      id: Math.random() * 10,
      fieldType: type || 'image',
      text: name,
      system: !isMemberAttr ? name : `_${name}`,
      value: value,
      cardDefault: true,
      transform: '',
      style: specialStyles || '',
      width: null,
      height: null,
      isMemberAttr: isMemberAttr
    };
    this.designAttributes.push(systemElement);
    this.systemAttributes.push(systemElement);  
  }

  checkField(key: string) {
    const cardvalue = ['id', 'name', 'subdomain', 'logo', 'icon','timePeriod', 'startDate'];
    const imageTypeFields = ['logo', 'icon', 'ageCheckImage', 'ageCheck'];
    return {
      cardType: cardvalue.includes(key),
      valueType: imageTypeFields.includes(key) ? 'image' : 'text'
    }
  }

  setCardFieldsValues(req: string, checkedField: any, filedName: string) {
    if (this.currentCard.hasOwnProperty(req) && checkedField.cardType) {
      this.obtainSystemCardElement(filedName, this.currentCard[req], checkedField.valueType);
    } else if (req === 'barcode') {
      if (this.currentCard.barcode) {
        this.obtainSystemCardElement(filedName, (this.firstMember?.cardNumber || ''), 'barcode', true);
      }
    } else if (req === 'qrcode') {
      if (this.currentCard.qrcode) {
        this.obtainSystemCardElement(filedName, (this.firstMember?.cardNumber?.toString() || ''), 'qrcode', true);
      }
    } else if (req === 'ageCheck') {
      this.obtainSystemCardElement(filedName, this.currentCard['ageCheckImage'], 'image', true);
    }
  }

  async setCardSpecialField() {
    const requiredFieldsList = this.fieldsRequiredList;
    if (requiredFieldsList) {
      for (let req in requiredFieldsList) {
        if(!this.exludedFields.includes(req)) {
          const filedName = requiredFieldsList[req].hasOwnProperty('name') ? requiredFieldsList[req].name : req;
          const checkedField = this.checkField(req);
          this.setCardFieldsValues(req, checkedField, filedName);
        }
      }
    }
    if (this.memberRequiredFields) {
      for (let req in this.memberRequiredFields) {
        if (this.firstMember.hasOwnProperty(req) && !this.exludedFields.includes(req)) {
          this.obtainSystemCardElement(req, this.firstMember[req], this.memberRequiredFields[req].type, true);
        }
      }
    }
  }

  /**
   * NOTE: set layout
   */
  async renderCurrentLayout() {
    const cardLayout = this.currentCard.layout && JSON.parse(this.currentCard.layout);

    await this.setCardSpecialField();

    if (this.validatePreviousTemplate(cardLayout)) {

      const currentAttrArray = [...this.designAttributes];
      const currentSystemArray = [...this.systemAttributes];

      const regularAttrs = await currentAttrArray.map(cardLt => {   
        let modEl = cardLt;
        const existInTemplate = cardLayout.find(cardAttr => cardAttr.id === cardLt.id);    
        if (existInTemplate)  {
          existInTemplate.value = cardLt.value;
          Object.assign(modEl, existInTemplate)
        }
        return modEl
      }).filter(el => el && !el.system);

      this.systemAttributes = currentSystemArray.map(sat => {
        let modEl = sat;
        const existInTemplate = cardLayout.find(cat => cat && cat.system === sat.system);
        if (existInTemplate) {
           modEl = existInTemplate;
          if (existInTemplate.fieldType === 'barcode') 
            existInTemplate.value = this.firstMember?.cardNumber;
          else if (existInTemplate.fieldType === 'qrcode') {
            existInTemplate.value = this.firstMember?.cardNumber.toString();
          }
          else if (existInTemplate.system === '_ageCheck')
            modEl.value = this.currentCard['ageCheckImage']
          else if (existInTemplate.hasOwnProperty('isMemberAttr') && existInTemplate.isMemberAttr) {
            modEl.value = this.firstMember && this.firstMember[existInTemplate.system]
          }
          else
            modEl.value = this.currentCard[existInTemplate.system];
        }
        return modEl
      })

      this.designAttributes = [...regularAttrs, ... this.systemAttributes]; 
    }
  }

}//