
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class PazTooltipButton extends Vue {

  @Prop({default: false})
  public xLarge!: boolean;

  @Prop({default: ''})
  public tooltip!: string;

  @Prop({default: false})
  public disabled!: boolean;

  @Prop({default: false})
  public loading!: boolean;

  @Prop({default: 'primary'})
  public color!: string;

  @Prop({default: ''})
  public icon!: string;

  @Prop({default: false})
  public noclick!: boolean;

  onclick(event) {
    if (this.noclick) {
      return;
    }
    this.$emit("click", event);
  }
}
