
import { Component, Mixins, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import {
  getMarketplaces,
  getOrderAttributes,
  getOrderDetails,
  getProductAccountPrice,
  getProductType,
  updateOrder,
  updateOrderLine,
} from "../../services/catalog-service";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
//import OrderCustomerTab from "./tabs/OrderCustomerTab.vue";
import FulfilmentTab from "./tabs/FulfilmentTab.vue";
import AttributesTab from "./tabs/AttributesTab.vue";
import { formatDate, formatDateYMD } from "../../helpers/format";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import OrderItemsTab from "./tabs/OrderItemsTab.vue";
import PazOrderStatus from "../order/PazOrderStatus.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazDateField from "@pazion/pazion-core/src/components/utils/PazDateField.vue";
import PazAdressPicker from "../picker/PazAdressPicker.vue";
import CustomerPicker from "../picker/CustomerPicker.vue";
import { IOrderAttributeName, IOrderOne } from "../../models/order";
import { IMarketplace } from "../../models/base";
import PazOrderAttributesDialog from "./PazOrderAttributeDialog.vue";
import { PatchPricing } from "./mixins/patchPricingMixin";
import { IproductTypesModel } from "../../models/product";

@Component({
  components: {
    PazLoader,
    OrderItemsTab,
    //OrderCustomerTab,
    FulfilmentTab,
    AttributesTab,
    PazOrderStatus,
    CustomerPicker,
    PazTooltipButton,
    PazConfirmation,
    PazDateField,
    PazAdressPicker,
    PazOrderAttributesDialog,
  },
  methods: {
    formatDate,
    formatDateTime,
    formatDateYMD,
  },
})
export default class PazOrderDialog extends Mixins(
  PatchPricing
) {
  $refs!: {
    confirm: PazConfirmation;
  };

  @VModel({
    default: false,
  })
  public showDialog!: boolean;

  @Prop({ default: true }) public editAble!: boolean;
  @Prop({ default: true }) public canOrderEditableWithStatus: boolean;
  @Prop({ default: 0 }) public orderId!: number;
  @Prop() public orderStatuses!: {};
  @Prop() public showProductionBtn?: boolean;
  @Prop() public fromFulfilment: boolean;

  public orderDate: string = "";
  public loading: boolean = false;
  public orderData = {} as IOrderOne;
  public showCustomerPicker: boolean = false;
  public showAdressPicker: boolean = false;
  public orderNotes: string = "";
  public selectedOrderStatus: string = "";
  public orderAttributeNames: IOrderAttributeName[] = [];
  public marketplaces: IMarketplace[] = [];
  public currentAddressType: number = 0;
  public currentAddrPickedId: number | null = null;
  public showAttributesModal: boolean = false;
  public productTypes: IproductTypesModel[] | [] = [];

  async updateData() {
    await this.fetchData(false);
    this.$emit("order:update");
  }

  async doDuplicateOrder() {
    const orderId = this.orderId;
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Duplicate",
      "Are you sure you want to duplicate order  `" + orderId + "`?"
    );
    if (confirm) {
      this.showDialog = false;
      this.$emit("duplicate-order", orderId);
    }
  }

  async fetchData(withLoading?: boolean) {
    if (withLoading == undefined || withLoading) this.loading = true;
    this.orderData = await getOrderDetails(
      this.orderId,
      this.$productionManagement
    );
    this.orderAttributeNames = await getOrderAttributes();
    this.marketplaces = await getMarketplaces();

    this.productTypes =  await getProductType();

    this.orderNotes = this.orderData?.notes;
    this.orderDate = formatDateTime(
      this.orderData?.order?.orderDate,
      null,
      "YYYY-MM-DD"
    );
    this.loading = false;
    this.fromFulfilment &&
      document.getElementById('deliveryTab')?.click();
  }

  productionAction() {
    this.$emit("onShowOrderProduction", this.orderData?.order);
    this.showDialog = false;
  }

  openAddressPicker(addresttType: number, pickedId?: number) {
    this.showAdressPicker = true;
    this.currentAddressType = addresttType;
    this.currentAddrPickedId = pickedId;
  }

  get cOrderStatuses(): any[] {
    let statusOfSelectedOrder: string = this.orderData.order.status;

    if (!statusOfSelectedOrder) {
      return [];
    }

    let selectableStatuses: string[] =
      this.orderStatuses.orderStatuses[statusOfSelectedOrder];

    if (
      selectableStatuses &&
      selectableStatuses.length !== 0 &&
      !selectableStatuses.includes(statusOfSelectedOrder)
    )
      selectableStatuses.push(statusOfSelectedOrder);

    if (!this.selectedOrderStatus)
      this.selectedOrderStatus = statusOfSelectedOrder;

    if (Array.isArray(selectableStatuses)) {
      return selectableStatuses.map((status: string) => {
        return { status, label: status.replaceAll("_", " ") };
      });
    }
    return [];
  }

  get hasDelivery(): boolean {
    return (
      this.orderData?.fulfilments?.length > 0 &&
      this.orderData?.fulfilmentOrderLines?.length > 0
    );
  }

  get hasAttributes(): boolean {
    return this.orderData?.orderAttributes?.length > 0;
  }

  get getOrderStatus(): boolean {
    return this.orderData?.order?.status || "";
  }

  get getOrderType(): string {
    return this.orderData?.order?.orderType || "";
  }

  get getOrderDataContact() {
    return this.orderData.order?.customer.business
      ? this.orderData.order?.customer.business +
      " - " +
      this.orderData.order?.customer.contact
      : this.orderData.order?.customer.contact;
  }

  get getOrderDataShippingAddress() {
    const currentAddress =
      this.orderData.order?.shippingaddress?.hasOwnProperty("id") &&
      this.orderData.order?.shippingaddress;
    return currentAddress
      ? `${currentAddress.name}, ${currentAddress.street} ${currentAddress.houseNumber}, ${currentAddress.zipcode}`
      : "";
  }

  get getOrderDataBillingAddress() {
    const currentAddress =
      this.orderData.order?.billingaddress?.hasOwnProperty("id") &&
      this.orderData.order?.billingaddress;
    return currentAddress
      ? `${currentAddress.name}, ${currentAddress.street} ${currentAddress.houseNumber}, ${currentAddress.zipcode}`
      : "";
  }

  async updateOrderNotes(value: string) {
    await updateOrder(this.orderData.id, { notes: value });
    this.$emit("order:update");
  }

  async updateOrderMarketplace(value: number) {
    await updateOrder(this.orderData.id, { marketplace: value });
    this.$emit("order:update");
  }

  async updateOrderStatus(value: string) {
    await updateOrder(this.orderData.id, { status: value });
    this.$emit("order:update");
  }

  async updateOrderReference(value: string) {
    await updateOrder(this.orderData.id, { reference: value });
    this.$emit("order:update");
  }

  async updateAccount(accountId: number) {
    const response = await updateOrder(this.orderData.id, { accountId: accountId });
    if (response && response.success) {
      if (this.$pricelistManagement && this.orderData.orderlines.length > 0) {
        this.orderData.orderlines.forEach(async (line, index) => {
          const spPrice = await getProductAccountPrice(
            {
              filter: JSON.stringify({
                operator: 'AND',
                product_catalog_pricelist_account__account_id: accountId,
                product_catalog_product_pricelist__product_id: line?.product?.id
              })
            }
          )
          if (spPrice && spPrice.length && spPrice.length > 0) {
            const spObjectPrice = spPrice.shift().product_catalog_product_pricelist__price;
            const sPrice = this.patchCalculatedPrice(parseFloat(spObjectPrice), line);
            const res = await updateOrderLine(line.id, { price: sPrice })
            if (res && (index + 1) === this.orderData.orderlines.length) {
              this.fetchData();
            }
          } else {
            if (index + 1 === this.orderData.orderlines.length) {
              this.fetchData();
            }
          }
        })
      } else {
        this.fetchData();
      }
    }
  }

  async updateAddress(adressId: number) {
    let addressTypeField = {};
    if (this.currentAddressType === 1)
      addressTypeField = { shippingAddressId: adressId };
    else if (this.currentAddressType === 2)
      addressTypeField = { billingAddressId: adressId };
    await updateOrder(this.orderData.id, addressTypeField);
    this.fetchData();
  }

  async addAttributeToOrder(data: {
    instance: string;
    nameTech: string;
    value: string;
  }) {
    this.loading = true;
    await updateOrder(this.orderData.id, { attribute: data });
    this.fetchData();
    this.$emit("order:update");
    this.loading = false;
  }

  openCustomerPicker() {
    if (!this.canOrderEditableWithStatus) {
      return;
    }
    this.showCustomerPicker = true;
  }

  //  async updateShippingAdress(adressId: number) {
  //   await updateOrder(this.orderDetailsData.id, {shippingAddressId: adressId});
  //   this.$emit('update-data');
  // }

  get canCopyOrder() {
    //@ts-ignore
    return this.$copyOrder != undefined && this.$copyOrder;
  }

  get canEditOrderLineStatus() {
    //@ts-ignore
    return this.$editOrderLineStatus != undefined && this.$editOrderLineStatus;
  }

  get orderHasProduction() {
    //@ts-ignore
    return !this.orderData?.production?.length > 0;
  }

  get getOrderAttributesCount() {
    return this.orderData?.orderAttributes?.length;
  }

  mounted() {
    this.fetchData();
  }

}//
