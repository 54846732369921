
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import LineChart from "../../components/chart/LineChart.vue";
import PazStockMutationDialog from "../../components/dialog/PazStockMutationDialog.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazTooltipButton from '@pazion/pazion-core/src/components/controls/PazTooltipButton.vue'
import {DataTableHeader} from "vuetify";
import {getAllProducts, getStock, getStockTransactionTypes, getWarehouses} from "../../services/catalog-service";
import {formatDate} from "../../helpers/format";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import {IStockMutationTransactionType, IStockResponseItem} from "../../models/stock";
import {IProduct} from "../../models/product";
import {IWarehouse} from "../../models/warehouse";

@Component({
  components: {LineChart, PazRefreshButton, PazTooltipButton, PazStockMutationDialog, PazToast, PazConfirmation},
    methods: {
        formatDate,
    }
})
export default class Stock extends Vue {
  $refs!: {
      toast: PazToast,
      confirm: PazConfirmation,
      confirmDeleteStock: PazConfirmation
  };
  public products: IProduct[] = [];
  public transactionTypes: IStockMutationTransactionType[] = [];
  public warehouses: IWarehouse[] = [];

  public stockItems: IStockResponseItem[] = [];
  public stockTableHeaders: DataTableHeader[] = [
      {
          text: 'id',
          align: 'start',
          sortable: false,
          value: 'mutation.id',
          width: 13,
      },
      {
          text: 'Mutation date',
          align: 'start',
          sortable: true,
          value: 'mutationDate',
      },
      {
          text: 'SKU',
          align: 'start',
          sortable: true,
          value: 'product.sku',
      },
      {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'productDescription',
      },
      {
          text: 'Transactions type',
          align: 'start',
          sortable: true,
          value: 'transactionType',
      },
      {
          text: 'Quantity',
          align: 'start',
          sortable: true,
          value: 'quantity',
      },
  ];
  public footer: object = {
      'items-per-page-options': [50, 100, 200],
  };

  public search: string = '';
  public showStockDialog: boolean = false;
  public loading: boolean = true;

  createNewStock() {
      this.switchStockDialog()
  }

  switchStockDialog() {
      this.showStockDialog = !this.showStockDialog;
  }

  async fetchData() {
    this.loading = true;
    this.stockItems = await getStock();
    this.products = await getAllProducts();
    this.transactionTypes = await getStockTransactionTypes();
    this.loading = false;
    this.warehouses = await getWarehouses()
  }

  created() {
      this.fetchData();
  }
}
