
import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { baseRegexp } from "../../helpers/constants";
import {
  getAdressForAccount,
  deleteAdress,
  getAddressesTypes,
} from "../../services/catalog-service";
import PazAdressPicker from "../picker/PazAdressPicker.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import { IAddressType } from "../../models/adress";

@Component({
  components: {
    PazAdressPicker,
    PazTooltipButton,
    PazLoader,
    PazConfirmation,
  },
})
export default class PazAccountDialog extends Vue {
  @VModel({
    default: false,
  })
  public showAccountDialog!: boolean;
  @Prop() account!: any;
  public loading: boolean = false;
  public accountFormModel: boolean = false;
  public accountFormAddressesModel: boolean = false;
  public formValidations = {
    contact: [(value) => !!value || "Required."],
    email: [(value) => baseRegexp.emailRegex.test(value) || "Wrong format"],
    phone: [(value) => baseRegexp.phoneRegex.test(value) || "Wrong format"],
  };
  public accountObject = {
    contact: null,
    email: null,
    notes: null,
    vat: null,
    kvk: null,
    reference: null,
    business: null
  };
  public addressesItem = [];
  public showAdressPicker = false;
  public selectedAddress = {};
  public addressTypes: IAddressType[] = [];

  getNameOfType(addressTypeId: number) {
   return this.addressTypes.find(
      (type: IAddressType) => type.id == addressTypeId
    ).nameTech;
  }

  getAddressString(currentAddress) {
    return `(${this.getNameOfType(currentAddress.addressType)}), ${currentAddress.name}, ${currentAddress.street} ${currentAddress.houseNumber}, ${currentAddress.zipcode}`;
  }

  onAccountEdit(address) {
    this.showAdressPicker = true;
    this.selectedAddress = address;
  }

  async accountUpdateHandler(account: any) {
    await this.$refs.accountForm.validate();
    if (!this.accountFormModel) return;
    this.$emit("onAccountChange", {
      data: account,
      isEdit: this.isEditAccount,
    });
  }

  get isEditAccount() {
    return !!this.account;
  }

  async fetchAddressesData() {
    this.loading = true;
    if (this.account) {
      this.addressesItem = await getAdressForAccount(this.account.id);
     this.addressTypes = await getAddressesTypes();
    }
   
    this.showAdressPicker = false;
    this.loading = false;
  }

  async onDeleteAdress(address: any) {
    const confirm = await this.$refs.confirmDeleteAddress.openConfirmDialog(
      "Delete",
      `Do you want to delete address: ${address.name}?`
    );
    if (!confirm) return;
    const response = await deleteAdress(address.id);

    if (!response || !response.hasOwnProperty("id")) {
      this.$parent.$refs.toast.show({
        message: "An error occurred, Account hasn't been deleted",
        icon: "mdi-close-circle",
        color: "red",
      });
      return;
    }
    this.fetchAddressesData();
  }

  mounted() {
    this.account && Object.assign(this.accountObject, this.account);
    this.fetchAddressesData();
  }   
}
