
import {Vue, VModel, Prop, Component} from "vue-property-decorator";

@Component({})
export default class SecretInput extends Vue {
  @VModel({})
  public itemValue!: any;

  @Prop({default: false})
  public properties!: { name: string, class: string, id: string, label: string };

  @Prop({default: false})
  public path!: string;
}
