
import {Component} from 'vue-property-decorator';
import {authentication} from '../../store';
import Vue from 'vue';
import router from "../../router";
import {logoutUser} from "../../services/auth-service";

@Component({})
export default class Logout extends Vue {

  created() {

    if (authentication.isUserLoggedIn) {
      logoutUser();
      authentication.logout();
    } else {
      authentication.afterLogout();
    }
    this.$router.push({name: 'login'});
    console.log('go to login logout created', this.$route);
    
  }
}
