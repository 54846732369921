import { ITablePaginationOptions, QueryStringParams } from "src/models/base";
import { ContractApiInterface, ContractsInterface, TransactionSubject, ContractParamsInterface } from "../../../models/contracts.model";
import { Component, Watch, Mixins } from "vue-property-decorator";
import { createContract, deleteContract, getContracts, getTransactionSubject, updateContract } from "../../../services/contracts-service";
import TransactionsFiltersMixin from "./transactions-filters.mixin";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import { uploadFlowFileToQueue } from "@pazion/pazion-core/src/services/base-service";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";


@Component({
  components: {
    FileUploader
  }
})
export default class ContractsListingMixin extends Mixins(
  TransactionsFiltersMixin
) {
  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
    confirmDeleteContract: PazConfirmation;
    confirmUploadFile: PazConfirmation;
  };
  public loading: boolean = false;
  public search: string = "";
  public contractsArray = {} as ContractsInterface;
  public selectedContract: ContractApiInterface | null = null;
  public showContractDetailsModal: boolean = false;
  public footerProps = {
    itemsPerPageOptions: [50, 100, 200, -1],
  };
  private _timerId: ReturnType<typeof setTimeout> | null = null;
  public transactionSubjects = [] as TransactionSubject[];
  public totalItems = 1;
  public paginationOptions: ITablePaginationOptions = { itemsPerPage: 200 };

  public defaultQueryPaginationOpt = {
    pagination: 1,
    perPage: 200,
    page: 1
  };

  public dataTablesHeaders = [
    {
      text: "Contract",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "name"
    },
    {
      text: "Customer",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "contractNumber"
    },
    {
      text: "Start date",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "startDate"
    },
    {
      text: "End date",
      align: "left",
      class: "cell-truncate",
      sortable: false,
      value: "endDate"
    },
    {
      text: "Actions",
      class: "cell-truncate text-right mx-5",
      value: "actions",
      align: "right",
      sortable: false,
    }
  ];
  public uploadedFile: File | null = null;
  async onFileSelect(file: File) {
    this.uploadedFile = file;
  }
  showContractDetails(contract?: ContractApiInterface) {
    contract && (this.selectedContract = contract);
    this.showContractDetailsModal = true;
  }


  closeContractDetailsModal() {
    this.selectedContract = null;
    this.showContractDetailsModal = false;
  }

  closeAssetDetailsModal() {
    this.selectedAsset = null;
    this.showAssetsDetailsModal = false;
  }

  async showImportPrinterDialog() {
    //help
    const confirm = await this.$refs.confirmUploadFile.openConfirmDialog(
      "Upload printer file",
      ``
    );
    if (confirm && this.uploadedFile) {
      let rsp = await uploadFlowFileToQueue('printer-file', this.uploadedFile);
      if (rsp.success) {
        this.$refs.toast.show({ message: "Added file to queue" });
      } else {
        this.$refs.toast.show({ message: "Failed to add file to queue", color: "red" });
      }
    }
  }

  /**
   * SECTION: CRUD OPERATIONS CONTRACT
   */
  // NOTE: SAVE CONTRACT
  async saveContract({ selectedContract, contractObject }: ContractParamsInterface) {
    const contractDataObj = contractObject;
    delete contractDataObj.contractLine
    delete contractDataObj.contractAsset
    const response = !selectedContract ? await createContract(contractDataObj) :
      await updateContract(selectedContract.id, contractDataObj);
    if (!response || !response.hasOwnProperty("id")) {
      this.$refs.toast.show({
        message: response?.data?.details || "An error occurred, Contract hasn't been saved",
        icon: "mdi-close-circle",
        color: "red",
      });
      return;
    }
    !selectedContract && (this.showContractDetailsModal = false);
    this.fetchData();
  }

  // NOTE: Delete Contracts
  async deleteContract(contract: ContractApiInterface) {
    const confirm = await this.$refs.confirmDeleteContract?.openConfirmDialog(
      "Delete",
      `Do you want to delete contract: ${contract.name}?`
    );
    confirm && deleteContract(contract.id);
  }

  //NOTE: Search
  onSearch() {
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const searchString = this.createSearchString(this.search);

      const queryParams = searchString
        ? {
          // TODO: Add search fields.
          filter: JSON.stringify({
            "operator": "OR",
            reference: searchString,
            name: searchString
          }),
        } : {}
      // TODO: Add order function.
      //const currentOrder = this.createOrderParams();
      await this.getDefaultContracts(queryParams, false, undefined);
    }, 800);
  }

  createSearchString(search: string) {
    return search.length > 0 ? { like: `%${this.search}%` } : null;
  }

  async getSupportInstance() {
    this.transactionSubjects = await getTransactionSubject();
  }

  //NOTE: MAIN request
  async getDefaultContracts(
    params?: QueryStringParams,
    isAllRecords?: boolean,
    order?: { [key: string]: "ASC" | "DESC" }
  ) {
    let reqParams = {} as QueryStringParams;
    const fromId = this.$route.query && this.$route.query.fromTransactions;
    params && (reqParams = params);
    if (reqParams && !reqParams.hasOwnProperty("filter")) {
      reqParams = Object.assign(reqParams, {
        filter: JSON.stringify({ isDeleted: 0, id: fromId }),
      });
    }

    if (!reqParams.hasOwnProperty('paginataion'))
      Object.assign(reqParams, this.defaultQueryPaginationOpt)

    order && Object.assign(reqParams, { orderBy: JSON.stringify(order) });
    this.loading = true;
    let response;
    try {
      response = await getContracts({ ...reqParams });
    } catch (error) {
      this.loading = false;
    }

    if (response) {
      this.contractsArray = response;
    }
    this.loading = false
  }//


  get getTableDataHeaders() {
    return this.dataTablesHeaders;
  }

  get getContractsArray() {
    return this.contractsArray && this.contractsArray._data;
  }

  fetchData(clearContractId?: boolean) {
    clearContractId &&
      this.$route.query &&
      this.$router.replace({ query: null }).catch(() => { });
    this.getDefaultContracts(this.defaultQueryPaginationOpt, false, undefined);
  }


  // NOTE: FROM Pagination
  @Watch("paginationOptions")
  onUpdatePagination(val, oldVal) {
    if (
      val.itemsPerPage !== oldVal.itemsPerPage ||
      val.page !== oldVal.page ||
      val.sortBy.length > 0
    ) {
      let paginationParams;
      let orderBy;
      if (val.itemsPerPage > 0)
        paginationParams = {
          pagination: 1,
          perPage: val.itemsPerPage,
          page: val.page
        };
      else {
        paginationParams = this.defaultQueryPaginationOpt;
        paginationParams.perPage = 200;
      }
      //filters = this.createFiltersObject();
      //filters && Object.assign(paginationParams, {filter: JSON.stringify(filters)});
      this.getDefaultContracts(paginationParams, val.itemsPerPage === -1, orderBy);
    }
  }

  mounted() {
    this.getSupportInstance();
  }

}//
