
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FileUploader extends Vue {
  @Prop({ default: () => [] }) rules: [];
  @Prop({ default: "image/*" }) accept: string;
  @Prop({ default: "" }) label: string;
  @Prop() placeholder: string;
  @Prop() prependIcon: string;
  @Prop({ default: false }) toBase64: boolean;
  @Prop({ default: ".pdf" }) fileExtension?: string;
  @Prop({ default: false }) hideInputField?: boolean;

  async onUpload(file: File) {
    if (!this.toBase64) this.$emit("onUpload", file);
    else {
      const convertedFile = await this.fileToBase64(file);
      if (convertedFile) this.$emit("onUpload", convertedFile);
    }
  }

  async fileToBase64(file): Promise<unknown> {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    return await toBase64(file);
  }

}
