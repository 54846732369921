
import {
  Component,
  Emit,
  Mixins,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { IApiCustomer } from "../../models/base";
import {
  getAccountById,
  getAccounts,
  updateAccount,
} from "../../services/catalog-service";
import BaseMixin from '../../mixins/base.mixin';

@Component
export default class CustomerPicker extends Mixins(
    BaseMixin
) {
  @VModel({
    default: false,
  })
  public showDialog!: boolean;
  public editItem: boolean = false;
  public showCustomerPicker: boolean = true;
  public loading: boolean = true;
  public loadingEntity: boolean = false;

  public selectedProp: IApiCustomer | null = null;
  public errorMessage: string = "";
  public items: IApiCustomer[] = [];

  public id: number = 0;
  public business: string = "";
  public vat: string = "";
  public kvk: string = "";
  public contact: string = "";
  public email: string = "";
  public phone: string = "";
  public reference: string = "";
  public notes: string = "";
  public search: string = "";
  public _timerId: ReturnType<typeof setTimeout> = setTimeout(() => {}, 500);

  @Prop({ default: 0 })
  public pickedId!: number | undefined;
  @Prop()
  public customer!: IApiCustomer | null;

  fetchEntriesDebounced() {
    // cancel pending call
    clearTimeout(this._timerId);
    // delay new call 500ms
    this._timerId = setTimeout(() => {
      this.fetchData();
    }, 500);
  }

  async fetchData() {
    this.loading = true;
    let filter = {};
    const searchValue = { like: `%${this.search}%` };
    const searchFilter = this.search ? this.createSearchString(
      { "isDeleted": 0 },
      { multi: { "business": searchValue, "contact": searchValue, "email": searchValue, "reference": searchValue } }) :  {};
    if (this.pickedId && !this.search) {
      filter = { id: this.pickedId };
    } else if (this.search) {
      filter = searchFilter;
    } else {
      filter = null;
    }

    this.items = await getAccounts(filter, undefined);

    if (this.pickedId != undefined && !this.search) {
      const val = this.items.find((value) => {
        if (value.id == this.pickedId) {
          return value;
        }
      });
      if (val !== undefined) {
        this.selectedProp = val;
        this.showCustomerPicker = false;
      }
    }
    this.loading = false;
  }

  get nothingSelected() {
    return this.selectedProp === null;
  }

  async updateSelected() {
    if (this.selectedProp == null) {
      this.reset();
      return;
    }
    if (this.id == this.selectedProp.id) {
      return;
    }
    this.loadingEntity = true;
    const account = await getAccountById(this.selectedProp.id);
    this.business = account.business; 
    this.contact = account.contact; 
    this.email = account.email; 
    this.id = account.id; 
    this.kvk = account.kvk;
    this.notes = account.notes; 
    this.phone = account.phone;
    this.reference = account.reference;
    this.vat = account.vat; 
    this.loadingEntity = false;
    this.showCustomerPicker = false;
  }

  reset() {
    this.id = 0;
    this.business = "";
    this.vat = "";
    this.kvk = "";
    this.contact = "";
    this.phone = "";
    this.email = "";
    this.reference = "";
    this.notes = "";
  }

  async saveAndpickValue() {
    const rsp = await updateAccount(this.id, {
      accountType: 1,
      business: this.business, 
      contact: this.contact,
      email: this.email, 
      id: this.id,
      kvk: this.kvk, 
      notes: this.notes, 
      phone: this.phone,
      reference: this.reference, 
      vat: this.vat
    });
    this.selectedProp = rsp;
    this.fetchData();
    this.editItem = false;
  }

  async fnEditItem() {
    this.editItem = true;
  }
  async closeEdit() {
    this.editItem = false;
  }

  async selectAndClose() {
    this.showDialog = false;
    this.$emit("picked-value", this.id);
  }

  @Watch("search")
  onChangeSearch(value: string) {
    if (value) {
      this.fetchEntriesDebounced();
    }
  }

  @Watch("showDialog")
  dialogChanger(newValue: boolean) {
    if (newValue) {
      this.fetchData();
    }
  }
  @Watch("selectedProp")
  async valueChanged() {
    await this.updateSelected();
  }

  @Emit("picked-value")
  pickValue() {
    this.reset();
    this.selectedProp = null;
    this.showCustomerPicker = true;
  }
  @Emit("new-entry")
  newEntry() {
    this.reset();
    this.selectedProp = null;
    this.editItem = true;
    this.showCustomerPicker = true;
  }

  filterfunc(item: IApiCustomer, queryText: string) {
    return (
      (typeof item.business == "string" &&
        item.business
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1) ||
      (typeof item.contact == "string" &&
        item.contact
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1) ||
      (typeof item.email == "string" &&
        item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1) ||
      (typeof item.reference == "string" &&
        item.reference
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1)
    );
  }
}
