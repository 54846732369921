
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  getAddressesTypes,
  getAdressById,
  getAdressForAccount,
  updateAdress,
} from "../../services/catalog-service";
import { IAddressType, IOrderAdress } from "../../models/adress";
import { getCountries } from "../../services/base-service";
import { Country } from "../../models/country";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";

@Component({
  methods: {
    updateAdress,
  },
  components: {
    PazLoader,
  },
})
export default class PazAdressPicker extends Vue {
  @VModel({
    default: false,
  })
  public showDialog!: boolean;
  public loading: boolean = true;
  public loadingEntity: boolean = false;

  public selectedProp: IOrderAdress | null = null;
  public errorMessage: string = "";
  public items: IOrderAdress[] = [];
  public addressTypes: IAddressType[] = [];
  public currentAddressType: string = "";

  @Prop({ default: "unknown" })
  public addressType!: string;

  @Prop({ default: 0 })
  public pickedId!: number | undefined;

  @Prop()
  public accountId!: number;

  @Prop({ default: false }) onlyEditor?: boolean;

  public editItem: boolean = false;

  private city: any = "";
  private email: any = "";
  private houseNumber: number | null = null;
  private houseNumberExt: string = "";
  private id: number = 0;
  private street: string = "";
  private streetExt: string = "";
  private zipcode: string = "";
  private name: string = "";
  private country: number = 0;
  public countries: Country[] = [];
  private addressFormModel: boolean = false;

  async updateSelected() {
    if (!this.selectedProp) {
      this.reset();
      return;
    }
    if (this.id == this.selectedProp.id) {
      return;
    }

    this.loadingEntity = true;
    const address = await getAdressById(this.selectedProp.id);

    this.country = address.country;
    this.city = address.city;
    this.email = address.email;
    this.houseNumber = address.houseNumber;
    this.houseNumberExt = address.houseNumberExt;
    this.id = address.id;
    this.name = address.name;
    this.street = address.street;
    this.streetExt = address.streetExt;
    this.zipcode = address.zipcode;
    this.loadingEntity = false;
    this.editItem = true;
  }

  getNameOfType(addressTypeId: number) {
    let addressType: IAddressType | undefined = this.addressTypes.find(
      (type: IAddressType) => {
        return type.id == addressTypeId;
      }
    );
    if (!addressType) {
      return "";
    }
    return addressType.nameTech;
  }

  reset() {
    this.city = "";
    this.email = "";
    this.country = 0;
    this.houseNumber = null;
    this.houseNumberExt = "";
    this.id = 0;
    this.name = "";
    this.street = "";
    this.streetExt = "";
    this.zipcode = "";
  }

  closePicker() {
    this.showDialog = false;
    this.selectedProp = null;
    this.editItem = false;
  }

  async fetchData() {
    this.loading = true;
    this.items = await getAdressForAccount(this.accountId);
    this.addressTypes = await getAddressesTypes();
    this.currentAddressType = this.addressType;
    this.countries = await getCountries();
    if (this.pickedId) {
      const val = this.items.find((value) => {
        if (value.id == this.pickedId) {
          return value;
        }
      });
      
      if (val) {
        this.selectedProp = val;
        this.currentAddressType =  this.selectedProp.addressType;
      }
    }
    this.loading = false;
  }

  async selectAndClose() {
    this.showDialog = false;
    this.$emit("picked-value", this.id);
  }

  @Watch("selectedProp")
  async valueChanged() {
    await this.updateSelected();
  }

  @Watch("showDialog")
  dialogChanger(newValue: boolean) {
    if (newValue) {
      this.fetchData(); 
    } else this.reset();
  }

  async saveAndPickValue() {   
    await this.$refs.addressForm.validate();    
    if (!this.addressFormModel) return;     
    const rsp = await updateAdress(this.id, this.accountId, {
      addressType: this.currentAddressType ? this.currentAddressType : this.addressType,
      country: this.country,
      name: this.name,
      city: this.city,
      email: this.email,
      houseNumber: this.houseNumber,
      houseNumberExt: this.houseNumberExt,
      street: this.street,
      streetExt: this.streetExt,
      zipcode: this.zipcode,
    });
    this.selectedProp = rsp;
    this.editItem = false;
    this.$emit("picked-value", rsp.id);
    this.fetchData();
  }

  async fnEditItem(isNew?: boolean) {
    if(!this.haveAddresses || isNew) {
      this.reset();
      this.selectedProp = null;
    }
    this.editItem = !this.editItem;
  }

  async closeEdit() {
    this.editItem = false;
  }

  get haveAddresses() {
    return this.items.length !== 0;
  }

  @Emit("picked-value")
  pickedValue() {
    if (this.selectedProp === null) {
      return null;
    }
    this.showDialog = false;
    return this.selectedProp.id;
  }

  filterFunc(item: IOrderAdress) {
    return item.addressType == this.addressType;
  }
}
