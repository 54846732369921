
import { Component, Vue, Prop } from "vue-property-decorator";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import FileUploader from "@pazion/pazion-core/src/components/common/FileUploader.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { VueEditor } from "vue2-editor";
import dayjs from 'dayjs'
import { CardAttributeModel } from "../../../models/member_card.model";
import CardDetailsFileUploader from "../atoms/CardDetailsFileUploader.vue";

@Component({
  components: {
    PazMenuDatePicker,
    FileUploader,
    PazLoader,
    VueEditor,
    CardDetailsFileUploader
  },
})
export default class CardAttrValueEditor extends Vue {

  @Prop({ default: null }) field!: CardAttributeModel;
  @Prop() cardMember?: number;

  public attributesInstance = {} as CardAttributeModel;
  public accountItems = [];
  public renderConfig = {};
  public codeValue = "";


  /*
   * NOTE:
   *  https://github.com/davidroyer/vue2-editor/
   * params: https://quilljs.com/docs/modules/toolbar/
   * */

  public customToolbar = [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'align': [] }]
  ]

  setObjectData(value: any, key: string) {
    this.attributesInstance[key] = value;
    this.$emit('onaAttrFill',
      {
        attrData: this.attributesInstance,
        valueStatus: !!this.field.value,
        cardMember: this.cardMember
      })
  }

  htmlEditorFired() {
    if (this.attributesInstance.fieldType === 'dynamic_text')
      this.setObjectData(this.attributesInstance.value, 'value');
  }


  get fieldType() {
    return this.field?.fieldType;
  }

  get propertyHasLoaded() {
    return (
      this.attributesInstance && this.attributesInstance.hasOwnProperty("value")
    );
  }

  get getItems() {
    const cardListAttr = JSON.parse(
      this.attributesInstance?.fieldOptions
    );
    const convertedArray = cardListAttr.map((key) => {
     return {
      id: key,
      name: key
     }
    });
    return convertedArray;
  }

  get getPickedDate() {
    const safeDate = this.attributesInstance.value ? this.attributesInstance.value : dayjs().format();
    return safeDate;
  }

  mounted() {
    this.attributesInstance = { ...this.field };
  }

}//
