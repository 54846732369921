import { deleteAccount, getAccounts, updateAccount } from '../../../services/catalog-service';
import { Component, Mixins} from 'vue-property-decorator'
import { IAddressType } from 'src/models/adress';
import BaseMixin from '../../../mixins/base.mixin';


@Component()
export default class AccountsListMixin extends Mixins(
  BaseMixin
) {

  $refs!: {
    accountForm: HTMLElement
  }
  
  public headers: object[] = [
    {
      text: "id",
      align: "left",
      sortable: true,
      class: "nowrap",
      value: "id",
    },
    {
      text: "vat",
      class: "nowrap",
      align: "left",
      sortable: false,
      value: "vat",
    },
    {
      text: "Name",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "contact",
    },
    {
      text: "email",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "email",
    },
    {
      text: "phone",
      class: "nowrap",
      cellClass: "nowrap",
      align: "left",
      sortable: true,
      value: "phone",
    },
    {
      text: "notes",
      class: "nowrap",
      align: "center",
      sortable: true,
      value: "notes",
    },
    {
      text: "actions",
      align: "right",
      class: "",
      sortable: false,
      value: "actions",
    },
  ];
  public loading: boolean = false;
  public accountItems = [];
  public search: string = '';
  private _timerId: number|null = null;
  public showAccountDialog: boolean = false;
  public selectedAccount = {};


  async getDefaultAccounts(filter?: {}, search?: string) {
    const response = await getAccounts(filter, search);
    if (response)
      this.accountItems = response;
  }

 
  showAccountDetails(account: any) {
    this.selectedAccount = account;
    this.showAccountDialog = true;
  }

  async updateAccount(account: {data: any, isEdit: boolean}) {
    let response;
    if (!account.hasOwnProperty('accountType')) account.data.accountType = 1;
    if (account.isEdit) {
     response =  await updateAccount(account.data.id, account.data);
    } else {
      response = await updateAccount(0, account.data);
    }

    if (response) {
      this.showAccountDialog = false;
      this.selectedAccount = {};   
      this.getDefaultAccounts();  
    } 
  }

  async deleteAccount(account: any) {
    const confirm = await this.$refs.confirmDeleteAccount.openConfirmDialog(
      "Delete",
      `Do you want to delete account: ${account.contact}?`
    );
    if (!confirm) return;
    let response = await deleteAccount(account.id, true);
 
    if (!response || !response.hasOwnProperty("id")) {
      this.$refs.toast.show({
        message: "An error occurred, Account hasn't been deleted",
        icon: "mdi-close-circle",
        color: "red",
      });
      return;
    }
    this.getDefaultAccounts({}, this.search);
  }

  getNameOfType(addressTypeId: number) {
    //@ts-ignore
    let addressType: IAddressType | undefined = this.addressTypes.find(
      (type: IAddressType) => {
        return type.id == addressTypeId;
      }
    );
    if (!addressType) {
      return "";
    }
    return addressType.nameTech;
  }

  onSearch() {
    this.loading = true;
    clearTimeout(this._timerId);
    const searchValue = { "like": `%${this.search}%` };
    const searchFilter = this.search ? this.createSearchString(
      { "isDeleted": 0 }, 
      {multi: { "business": searchValue, "contact": searchValue, "email": searchValue, "reference": searchValue }}) : {}
    this._timerId = setTimeout(async () => {
      await this.getDefaultAccounts(searchFilter, undefined);
      this.loading = false;
    }, 1000);
  }


  mounted() {
    this.getDefaultAccounts();   
  }

}//
