
import { Component, Mixins } from "vue-property-decorator";
import DossierListMixin from "../mixins/dossier-list.mixin";
import AccountMixin from "../../../mixins/account.mixin";
import listAppItem from "../dossierApp/parts/listAppItem.vue";

@Component({
  components: { listAppItem },
})
export default class DossierListApp extends Mixins(
  DossierListMixin,
  AccountMixin
) {

}

//LINK: frontendPackages/pazion-core/src/views/dossier/mixins/dossier-list.mixin.ts
