import {KeyValuePair} from "./models";

export interface LogLevel {

    level: number;
    color: string;
    short: string;
    description: string;
}

export const LOG_LEVELS: LogLevel[] = [
    {level: 0, color: '#d5001a', short: 'Emergency', description: 'Emergency: system is unusable'},
    {level: 1, color: '#F44336', short: 'Alert', description: 'Alert: action must be taken immediately'},
    {level: 2, color: '#FF3800', short: 'Critical', description: 'Critical: critical conditions'},
    {level: 3, color: '#ff9800', short: 'Error', description: 'Error: error conditions'},
    {level: 4, color: '#ffb507', short: 'Warning', description: 'Warning: warning conditions'},
    {level: 5, color: '#b2ddcc', short: 'Notice', description: 'Notice: normal but significant condition'},
    {level: 6, color: '#42b3d5', short: 'Info', description: 'Informational: informational messages'},
    {level: 7, color: '#27539b', short: 'Debug', description: 'Debug: debug-level messages'}
];

export const LOG_LEVEL_MAP: KeyValuePair = {};
export const COLOR_MAP: KeyValuePair = {};
LOG_LEVELS.forEach(logLevel => {
    LOG_LEVEL_MAP[logLevel.level] = logLevel.short.toUpperCase();
    COLOR_MAP[logLevel.level] = logLevel.color;
});
