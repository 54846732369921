
import Vue from 'vue';

import {Component} from 'vue-property-decorator';
import {getLogs} from '../../services/auth-service';
import DataDialog from "../../components/utils/DataDialog.vue";
import PazRefreshButton from "../../components/controls/PazRefreshButton.vue";
import PazTextSearch from "../../components/controls/PazTextSearch.vue";

import {LOG_LEVELS, COLOR_MAP, LOG_LEVEL_MAP, LogLevel} from "../../models/log-models";
import PazListFilterSelector from "../../components/utils/PazListFilterSelector.vue";

@Component({
  components: {
    DataDialog,
    PazRefreshButton,
    PazTextSearch,
    PazListFilterSelector
  }
})
export default class LogsPage extends Vue {

  public extraInfo = {};
  public dialog = false;
  public logLevels: LogLevel[] = LOG_LEVELS;
  public loading: boolean = false;
  public autoupdate: boolean = false;
  public search: string = '';
  public items = [];
  public selectedLogLevels: LogLevel[] = [];
  public headers: object[] = [
    {
      text: 'id',
      align: 'right',
      sortable: true,
      value: 'id',
      class: 'nowrap',
      cellClass: 'nowrap',
    },
    {
      class: 'nowrap',
      cellClass: 'nowrap',
      text: 'date',
      align: 'right',
      sortable: true,
      value: 'timestamp',
    },
    {
      class: 'nowrap',
      text: 'log level',
      align: 'center',
      sortable: true,
      value: 'priority',
    },
    {
      text: 'message',
      align: 'left',
      sortable: true,
      value: 'message',
    },
    {
      class: 'nowrap',
      text: 'extra info',
      align: 'right',
      sortable: true,
      value: 'extra',
    },
  ];

  priorityColor(priorityLevel: number) {
    if (COLOR_MAP.hasOwnProperty(priorityLevel)) {
      return COLOR_MAP[priorityLevel];
    }
    return 'blue';
  }

  priorityName(priorityLevel: number) {
    if (LOG_LEVEL_MAP.hasOwnProperty(priorityLevel)) {
      return LOG_LEVEL_MAP[priorityLevel];
    }
    return priorityLevel;
  }

  isEmpty(item: any) {
    return item.extra.length == 0 || item.extra == '[]';

  }

  get filteredItems() {
    if (this.ietsGeselecteerd) {
      return this.doFilter(this.items);
    }
    return this.items;
  }

  doFilter(items: any[]) {
    const lvls = this.selectedLogLevels;
    return items.filter(function (item) {
      return lvls.includes(item.priority);
    });
  }

  created() {
    this.fetchData();
    this.selectedLogLevels = LOG_LEVELS.slice();
  }

  async fetchData() {
    this.loading = true;
    this.items = await getLogs();
    this.loading = false;
  }

  showInfo(item: any) {
    this.extraInfo = JSON.parse(item.extra);
    this.dialog = true;
  }

  toggle() {
    this.$nextTick(() => {
      if (this.allesGeselecteerd) {
        this.selectedLogLevels = [];
      } else {
        this.selectedLogLevels = LOG_LEVELS.slice();
      }
    });
  }

  get allesGeselecteerd(): boolean {
    return this.selectedLogLevels.length === LOG_LEVELS.length;
  }

  get ietsGeselecteerd(): boolean {
    return this.selectedLogLevels.length > 0 && !this.allesGeselecteerd;
  }

  get dropdownIcon(): string {
    if (this.allesGeselecteerd) {
      return 'mdi-close-box';
    }
    if (this.ietsGeselecteerd) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }
}
