
import {Vue, Component, Prop, VModel, Watch} from "vue-property-decorator";
// :class="properties.class"
//  :list="!!input.datalist ? key +'-datalist' : ''"
//:error="!!errors.fields.some(el => el.key === key)"
//:error-messages="!!errors.fields.some(el => el.key === key) ? getErrorsByKey(key) : null"
//:error-count="!!errors.fields.some(el => el.key === key) ?  getErrorsByKey(key).length : 0"

@Component({})
export default class TextInput extends Vue {
  @VModel({})
  public itemValue!: any;

  @Prop({default: false})
  public properties!: { name: string, class: string, id: string, label: string };

  @Prop({default: false})
  public path!: string;

  @Prop({default: false})
  public success!: object[];
  public localSuccess: boolean = false;

  @Prop({})
  public error!: object[];
  public localError: boolean = false;
  public localErrorMessages: string[] = [];


  @Watch('error')
  public watcherForErrors() {
    this.localError = this.checkifErrorExists(this.path, this.error);
  }


  @Watch('success')
  public watcherForSuccess() {
    this.localSuccess = this.checkIfInObjectIfPathIsPresent(this.path, this.success)
  }

  public checkIfInObjectIfPathIsPresent(ID: string, array: object[]) {
    let inArray = false;
    array.forEach((item: object) => {
      if (item.key === ID) {
        inArray = true;
      }
    })

    return inArray;
  }

  public checkifErrorExists(ID: string, array: object[]) {
    let inArray = false;

    array.forEach((item: { key: string, value: object }) => {
      if (item.key === ID) {
        inArray = true;
        if ('value' in item) {
          for (let [key, message] of Object.entries(item.value)) {
            this.localErrorMessages.push(message.toString())
          }
        }
      }
    })

    if (!inArray) {
      this.localErrorMessages = [];
    }

    return inArray;
  }
}
