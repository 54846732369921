
import { Component, Vue } from "vue-property-decorator";
import { deleteCardMember, getCardMembers, getCards } from "../../services/cards-service";
import CardMemberDetailsModal from "./modals/CardMemberDetailsModal.vue";
import CardMemberImportModal from "./modals/CardMemberImportModal.vue";
import CardMemberExportModal from "./modals/CardMemberExportModal.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazRefreshButton from "@pazion/pazion-core/src/components/controls/PazRefreshButton.vue";
import PazConfirmation from "@pazion/pazion-core/src/components/utils/PazConfirmation.vue";
import PazToast from "@pazion/pazion-core/src/components/utils/PazToast.vue";
import { ICardMemberModel, ICardModel } from "../../models/member_card.model";

@Component({
  components: {
    CardMemberDetailsModal,
    CardMemberImportModal,
    CardMemberExportModal,
    PazTooltipButton,
    PazRefreshButton,
    PazConfirmation,
    PazToast,
  },
})
export default class CardMembersListing extends Vue {
  $refs!: {
    toast: PazToast;
    confirm: PazConfirmation;
  };

  public loading: boolean = false;
  public showCardMemberModal: boolean = false;
  public showCardMembersOperationsModal: boolean = false;
  public showCardMembersExportModal: boolean = false;
  public search: string = "";
  public cardMembersItems = [] as ICardMemberModel[];
  public selectedCardMembersItem = {} as ICardMemberModel;
  public cardItems = [] as ICardModel[];
  public selectedCard: number | null = null;


  public headers = [
    {
      text: "lid",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "id",
    },
    {
      text: "Account",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "card.client.business",
    },
    {
      text: "Card",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "card.name",
    },
    {
      text: "Pass number",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "cardNumber",
    },
    {
      text: "Email",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "email",
    },
    {
      text: "Active",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "isActive",
    },
    {
      text: "Valid till",
      class: "nowrap",
      align: "left",
      sortable: true,
      value: "validUntil",
    },
    {
      text: "Actions",
      class: "nowrap",
      align: "right",
      sortable: false,
      value: "actions",
    },
  ];

  onSearch(search: string) {
    this.search = search;
  }

  showMemberConfigModal(member: ICardMemberModel | null, status: boolean, refreshData?: boolean) {
    this.selectedCardMembersItem = member;
    this.showCardMemberModal = status;
    refreshData && this.getCardMembers();
  }

  showMembersOperationsModal(status: boolean,) {
    this.showCardMembersOperationsModal = status;
  }


  async setFilter() {
    if(this.selectedCard) {
     await this.getCardMembers(this.selectedCard);
    } else
     await this.getCardMembers();
  }

  async getCardsList() {
    this.cardItems = await getCards({filter: JSON.stringify({isDeleted: 0 })});
    if(this.cardItems && this.cardItems.length)
    this.selectedCard = this.cardItems[0].id;
  }

  async getCardMembers(cardId?: number) {
    this.loading = true;
    let filterObj: {isDeleted: number, card?: number} = { isDeleted: 0 }
    if(this.selectedCard) 
      filterObj.card = this.selectedCard
    else
      delete filterObj.card
    const querryParams = { expand: "card,client", filter: JSON.stringify(filterObj)};
    this.cardMembersItems = await getCardMembers(querryParams);
    this.loading = false;
  }

  async deleteCardMember(member: ICardMemberModel) {
    const confirm = await this.$refs.confirm.openConfirmDialog(
      "Delete",
      `Do you want to delete member: ${member.name}?`
    );
    if (confirm) {
      this.loading = true;
      const result = await deleteCardMember(member.id);
      if (!result || (result && !result.id)) {
        this.$refs.toast.show({
          message:
            result?.response?.data?.detail ||
            "An error occurred, card hasn't been delete",
          icon: "mdi-close-circle",
          color: "red",
        });
        this.loading = false;
      }
      else await this.getCardMembers();
    }
  }

 async mounted() {
   await this.getCardsList();
    await this.getCardMembers(this.selectedCard);
  }

}//
