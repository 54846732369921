import { Vue, Component } from "vue-property-decorator";
import * as dayjs from 'dayjs'


@Component({})
export default class TransactionsFiltersMixin extends Vue {

  public startDatePicker: boolean = false;
  public endDatePicker: boolean = false;

  public companyFilter: number | null = null;
  public statusFilter: string | null = null;
  public startDateFilter: Date | null = dayjs().format('YYYY-MM-DD');
  public endDateFilter: Date | null = dayjs().add(1, 'M').format('YYYY-MM-DD');

  //NOTE: Create params from filters
  // TODO: startDate, endDate, account - doesn't work
  createFiltersObject() {
    let filter: {
        account?: number | null;
        status?: string | null;
        startDate?: Date | null 
        endDate?: Date | null;
    } = {
      // account:  null,
      status:  null,
      // startDate: null, 
      // endDate: null
    };

    // this.companyFilter !== null && this.companyFilter
    //   ? Object.assign(filter, { account: this.companyFilter })
    //   : delete filter.account;

    this.statusFilter !== null && this.statusFilter
      ? Object.assign(filter, { status: this.statusFilter })
      : delete filter.status;

    // this.startDateFilter !== null && this.startDateFilter
    //   ? Object.assign(filter, { startDate: this.startDateFilter })
    //   : delete filter.startDate;

    // this.endDateFilter !== null && this.endDateFilter
    //   ? Object.assign(filter, { endDate: this.endDateFilter })
    //   : delete filter.endDate;

      
    return filter
  }

}//

