
import { Component, Prop, Mixins, VModel } from "vue-property-decorator";
import DossierDocumentsList from "./DossierDocumentsList.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import DossierDocsMixin from "../../../views/dossier/mixins/dossier-docs.mixin";
import { formatDateTime } from "../../../services/base-service";
import { CreatedAt, IDossierOneFile } from "../../../models/dossier.model";

@Component({
  components: {
    DossierDocumentsList,
    PazLoader,
  },
})
export default class DossierCoursePage extends Mixins(DossierDocsMixin) {
  @VModel({ default: false }) public showDialog!: boolean;
  @Prop() public dossierId: string;
  @Prop() public selectedOrderProp?: number | undefined;

  public fileData: any = null;

  public selectedDossier: object | null = null;

  public cardFooterStyle: string = `position: sticky;
     bottom: 0;
     background: #546E7A;
     color: #fff;
     z-index: 10;`;

  public tableHeadings = [
    { title: "Label", class: "text-left" },
    { title: "File", class: "text-center" },
    { title: "description", class: "text-center" },
    { title: "version", class: "text-center" },
    { title: "uploaded", class: "text-right" },
    { title: "actions", class: "text-right" },
  ];

  downloadFile(file: { data: string; doc: IDossierOneFile }) {
    this.fileData = file;
    this.$nextTick(() => {
     this.$refs.referralLink && this.$refs.referralLink.click();
    });
  }

  getDossierStatus(status: boolean) {
    return status ? "Active" : "Inactive";
  }

  getFormatDate(createdAt: CreatedAt) {
    return formatDateTime(createdAt.date, createdAt.timezone, "YYYY-MM-DD");
  }

  async getSelectedDossier() {
    if (this.selectedOrderProp){
      this.selectedDossier = await this.getOrderDocument(this.selectedOrderProp);

      this.$delete(this.tableHeadings, 2);
      this.$delete(this.tableHeadings, 2);
      this.$delete(this.tableHeadings, 2);


    }else
      this.selectedDossier = await this.getDossierDocument(this.dossierId);
  }

  returnToList() {
    if (this.selectedOrderProp) this.$emit("onBackToOrders");
    else this.$router.push("/dossier");
  }

  get isDossier() {
    return this.selectedDossier && this.selectedDossier.hasOwnProperty("id");
  }

  mounted() {
    this.getSelectedDossier();
  }
}
