
import Vue from "vue";
import {Component, Prop, VModel} from "vue-property-decorator";

@Component
export default class PazTextSearch extends Vue {

  @VModel()
  public textSearch: string;

  @Prop({default: 'Search'})
  public title!: string;

  @Prop({default: 'Enter your search...'})
  public placeholder!: string;

  @Prop({default: 'mdi-text-search'})
  public icon!: string;

  @Prop({default: ''})
  public extraClasses: string;

}
