
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { CardAttributeModel, ICardModel, ICardAttrValuesModel, IcardFieldTypesConverted, IcardFieldTypes } from "../../../models/member_card.model";
import { cardDeleteAttrubute, createCard, getCardAttributes, getCardAttributeTypes, getCards, postCardAttribute, putCardAttribute, updateCard } from "../../../services/cards-service";
import { getAccounts } from "../../../services/catalog-service";
import CardAttributesConstructor from "../parts/CardAttributesConstructor.vue";
import CardDetailsFileUploader from "../atoms/CardDetailsFileUploader.vue";
import PazLoader from "@pazion/pazion-core/src/components/utils/PazLoader.vue";
import { AxiosError } from "axios";
import PazMenuDatePicker from "@pazion/pazion-core/src/components/input/PazMenuDatepicker.vue";
import dayjs from "dayjs";
import CardAttributesOperationsMixin from "../mixins/card_attrubute-operations";
import { formatDateTime } from "@pazion/pazion-core/src/services/base-service";

@Component({
  components: {
    PazLoader,
    CardAttributesConstructor,
    CardDetailsFileUploader,
    PazMenuDatePicker
  },
  methods: {
    formatDateTime
  }
})
export default class extends Mixins(
    CardAttributesOperationsMixin
) {
  @VModel({
    default: false,
  })
  public showConfigModal!: boolean;

  @Prop({ default: null }) selectedCard!: ICardModel | null;

  public loading: boolean = false;
  public cardConfigFormModel: boolean = false;
  public accountItems = [];
  public exictingCardAttrs = [] as CardAttributeModel[];

  public cardModel: ICardModel = {
    id: null,
    name: '',
    subdomain: '',
    logo: null,
    icon: null,
    backgroundImage: null,
    background: '#FFFFFF',
    barcode: false,
    qrcode: false,
    ageCheck: false,
    ageCheckImage: null,
    timePeriod: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    isActive: false,
    changedAt: null,
    createdAt: new Date(),
    client: null,
  };

  private attributesDefaultObj = {
    id: 0,
    isActive: false,
    nameTech: "New attribute",
    fieldType: 'text',
    isUserAttribute: true,
    fieldOptions: undefined,
    value: ''
  } as CardAttributeModel;

  public cardAttributes = [

  ] as ICardAttrValuesModel[];

  public attributeTypes = [] as IcardFieldTypesConverted[];


  setObjectData<T extends keyof ICardModel>(value: any, key: T) {
    this.cardModel[key] = value;
  }

  handleSaveError(res: AxiosError | Response, instance?: string, operation?: string) {
    const currentInstance = instance ? instance : 'card';
    const currentOperation= operation ? operation : 'saved';
    this.$parent.$refs.toast.show({
      message: res?.response?.data?.detail || `An error occurred, ${currentInstance} hasn't been ${currentOperation}`,
      icon: "mdi-close-circle",
      color: "red",
    });
    this.loading = false;
  }

  /**
   * NOTE: Attributes Lines methods
   */
  addAttrNewLine() {
    const defObject = {...this.attributesDefaultObj}
    this.exictingCardAttrs.push(defObject);
  }

  async createAttribute(attrLine: CardAttributeModel) {
    let postData = attrLine;
    delete postData.id
    postData.card = this.selectedCard.id;   
    try {
      this.loading = true;
      const res = await postCardAttribute(postData);
      if (!res || !res.hasOwnProperty('id')) {
        this.handleSaveError(res, 'attribute', 'created');
        this.loading = false;
        return
      }
      this.fetchCardDetails();
    } catch (error) {
      this.loading = false;
      this.handleSaveError(error, 'attribute', 'created');
    }
  }

  async updateAtrtibute(atrLineId: number, attrLine: CardAttributeModel) {
    try {
      this.loading = true;
      const res = await putCardAttribute(atrLineId, attrLine);
      if (!res || !res.hasOwnProperty('id')) {
        this.handleSaveError(res, 'attribute', 'created');
        this.loading = false;
      }
      this.fetchCardDetails();
    }
    catch (error) {
      this.loading = false;
      // TODO: add default error
    }
  }

  updateAttrLine(data: { index: number; attrLine: CardAttributeModel }) {
    this.exictingCardAttrs[data.index] = data.attrLine;
    delete this.exictingCardAttrs[data.index].isEdit;
    delete this.exictingCardAttrs[data.index].value;
    const postData = this.exictingCardAttrs[data.index];
    if(typeof postData.fieldOptions === 'object' && postData.fieldOptions) {
      const fieldOpt = postData.fieldOptions
      postData.fieldOptions = JSON.stringify(fieldOpt);
    }
    if (data.attrLine.id === 0) 
      this.createAttribute(postData);
    else this.updateAtrtibute(data.attrLine.id, postData);
  }

  deleteAttrLine(index: number) {
    this.exictingCardAttrs.splice(index, 1);
  }

  async deleteAttribute(attribute: CardAttributeModel) {
    const confirm = await this.$parent.$refs.confirm.openConfirmDialog(
      "Delete",
      `Do you want to delete an attribute: ${attribute.nameTech}?`
    );
    this.loading = true;
    const res = confirm && await cardDeleteAttrubute(attribute?.id);
    if (!res || !res.hasOwnProperty('id')) {
      this.handleSaveError(res, 'attribute', 'delete');
      this.loading = false;
      return
    } 
    // TODO: Add error toast
    this.loading = false;
    this.fetchCardDetails();
  }


  createPostData(cardModel: ICardModel) {
    let modifyData = { ...cardModel };
    delete modifyData.id;
    delete modifyData.changedAt;
    delete modifyData.createdAt;
    return modifyData;
  }

  //
  async saveCard(isUpdate: boolean) {
    await this.$refs.cardConfigForm.validate();
    if (!this.cardConfigFormModel) return
    let result = null;
    this.loading = true;
    try {
      if (!isUpdate) {
        const postData = this.createPostData(this.cardModel);
        result = await createCard(postData);
      } else {
        result = await updateCard(this.selectedCard.id, this.cardModel);
      }
      if (!result || (result && !result.id))
        this.handleSaveError(result);
      else {
        this.showConfigModal = false;
        this.$emit('onCardUpdate')
      }
    } catch (error) {
      this.handleSaveError(error ? error : result);
    }
  }

  async fetchCardDetails() {
    this.loading = true;
    this.accountItems = await getAccounts();
    if (this.selectedCard) {
      this.cardModel = await getCards(undefined, this.selectedCard?.id);
      const attrCardFilter = {
        filter: JSON.stringify({ card: this.selectedCard?.id, isDeleted: 0})
      }
      this.exictingCardAttrs = await getCardAttributes(attrCardFilter, undefined);
      const resAttrTypes:IcardFieldTypes = await getCardAttributeTypes();
      if (resAttrTypes) this.attributeTypes = this.createFieldTypesArray(resAttrTypes);
    }
    this.loading = false;
  }

  get getExistingAttr() {
    return this.exictingCardAttrs && this.exictingCardAttrs.length > 0 && this.exictingCardAttrs;
  }

  get blockMultiNewItem() {
    const newAttrsArray =
      this.exictingCardAttrs.filter((item: CardAttributeModel) => !item.id) || [];
    return !!(newAttrsArray && newAttrsArray.length > 0);
  }

  mounted() {
    this.fetchCardDetails();
  }
} //
