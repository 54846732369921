
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component
  export default class designAttributesViewer extends Vue {

    @Prop({default: ()=> []}) attributesArray: any[];
    @Prop() blockTitle!: string;
    
  }
